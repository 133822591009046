import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { sdtmServices } from "../../../APIServices/CDISC/sdtmServices";

const AddTemplate = ({ Show, Hide, Size, Title, Submit, getDataTemplates }) => {
  const [templateName, setTemplateName] = useState("");

  const addNewDataTemplate = async () => {
    let userData = {};
    userData.templateName = templateName;
    let data = await sdtmServices.addNewDataTemplate(userData);
    if (data?.statusCode === 200) {
      cleanInputBox();
      getDataTemplates();
    }
  };

  const cleanInputBox = () => {
    Hide();
    setTemplateName("");
  };

  return (
    <div>
      <Modal
        show={Show}
        onHide={Hide}
        size={Size}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>{Title}</Modal.Title>
          <div onClick={cleanInputBox} className="close_modal" title="Close">
            X
          </div>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
              <Form.Label>
                Title <span>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Template Name"
                onChange={(e) => {
                  setTemplateName(e.target.value);
                }}
                value={templateName}
                required
              />
            </Form.Group>
          </Row>
          <div className="text-end border-top pt-3 footer_button">
            <Button
              onClick={() => addNewDataTemplate()}
              disabled={!templateName}
            >
              {Submit}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddTemplate;
