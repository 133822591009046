import React, { useEffect, useRef, useState } from "react";
import { Button, Table } from "react-bootstrap";
import UseToggle from "../../../Hooks/useToggle";
import Moment from "react-moment";
import { packageServices } from "../../../../APIServices/Editor/packageServices";
import UpdatePackageTagsModal from "./UpdatePackageTagsModal";
import Swal from "sweetalert2";

const PackageTagsTableData = ({
  packageTagsList,
  getPackageTagList,
  onMouseDown,
  tableRef,
}) => {
  const {
    toggle,
    setToggle,
  } = UseToggle();

  const ref = useRef(null);
  const [action, setAction] = useState("");
  const [id, setId] = useState("");
  const [tag, setTag] = useState("");

  const handleUpdateTags = async (e, item) => {
    setTag(item?.tags);
    setId(item?._id);
    setToggle();
  };

  const handleDelete = async (e, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete this Tag?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await packageServices.deletePackageTagData(id);
        if (data?.statusCode === 200) {
          getPackageTagList()
        }
      }
    });
  }

  const handleUpdate = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Update this Tag?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {}
        userData.id = id
        userData.tag = tag
        let data = await packageServices.updatePackageTagData(userData);
        if (data?.statusCode === 200) {
          getPackageTagList()
        }
      }
    });
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setAction("");
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  return (
    <>
      <div className="table-container overflow-auto">
        <Table id="resizable-table" className="custom-table m-0" ref={tableRef}>
          <thead>
            <tr>
              <th
                onMouseDown={(e) => onMouseDown(e, 0)}
                style={{ width: "40px" }}
              >
                Sr.
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 1)}>Tags</th>
              <th
                onMouseDown={(e) => onMouseDown(e, 3)}
                style={{ width: "200px" }}
              >
                Modified Date | Time
              </th>
              <th
                onMouseDown={(e) => onMouseDown(e, 4)}
                style={{ width: 70 }}
                className="text-center"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody ref={ref}>
            {packageTagsList?.length > 0 &&
              packageTagsList?.map((item, index) => (
                <tr className="relative">
                  <td>{index + 1}</td>
                  <td>{item.tags}</td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm" withTitle>
                      {item.updatedAt}
                    </Moment>
                  </td>
                  <td className="text-center">
                    <div className="text-center action__buttons">
                      {action === item._id ? (
                        <>
                          <div className="saperate_action_button">
                            <Button
                              onClick={(e) => handleUpdateTags(e, item)}
                              title="Update Tag"
                              className="rounded-circle"
                            >
                              <i className="fa-solid fa-edit"></i>
                            </Button>
                            <Button
                              onClick={(e) => handleDelete(e, item.tagId)}
                              title="Delete Tag"
                              className="rounded-circle close_circle"
                            >
                              <i className="fa-solid fa-trash-can"></i>
                            </Button>
                          </div>
                          <Button
                            onClick={() => setAction("")}
                            title="Close Action"
                            className="rounded-circle close_circle"
                          >
                            <i className="fa-solid fa-xmark"></i>
                          </Button>
                        </>
                      ) : (
                        <Button
                          onClick={() => setAction(item._id)}
                          title="Open Action"
                          className="rounded-circle"
                        >
                          <i className="fa-solid fa-bars"></i>
                        </Button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {packageTagsList?.length > 0 ? (
          ""
        ) : (
          <div className="noRecordFound">Record not found</div>
        )}
      </div>
      <UpdatePackageTagsModal
        Show={!toggle}
        Hide={setToggle}
        Size={"md"}
        setTag={setTag}
        handleUpdate={handleUpdate}
        tag={tag}
        Title={"Update Tag"}
        Submit="Update"
      />
    </>
  );
};

export default PackageTagsTableData;
