import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { designationPermissionServices } from "../../../../APIServices/designationPermissionServices";

const ExportSDTMDomainModal = ({ Show, Hide, Size, Title, Submit }) => {

  let [extension, setExtension] = useState("");

  const exportDesignationDataMultiFormat = async () => {
    let userData = {}
    userData.extension = extension
    let data = await designationPermissionServices.exportDesignationDataMultiFormat(userData)
    if (data?.data) {
      Hide()
    }
  }
  return (
    <Modal
      show={Show}
      onHide={Hide}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={()=>Hide()} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>Format <span>*</span></Form.Label>
            <Form.Select
              onChange={(e) => setExtension(e.target.value)}
              aria-label="Default select example"
            >
              <option>Select Format</option>
              <option value="csv">CSV</option>
              <option value="excel">Excel</option>
            </Form.Select>
          </Form.Group>
        </Row>
        <div className="hstack gap-3 justify-content-end border-top pt-3 footer_button">
          <Button disabled={!extension} onClick={exportDesignationDataMultiFormat}>{Submit}</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ExportSDTMDomainModal;
