import React, { useState } from "react";
import Pagination from "../../Hooks/Pagination";
import TableHeader from "../../../component/TableHeader";
import PackageTableData from "./PackageTableData";

const PackageRevenue = ({ roleType, onMouseDown, tableRef }) => {
  const [orderList, setOrderList] = useState([
    {
      _id: 1,
      userId: "",
      name: "Siddharth Vairagi",
      email: "siddharthvairagi786@gmail.com",
      orderId: 19554,
      items: ["packageId1", "packageId2"],
      payment: 455,
      currency: "$",
      status: "completed",
      createdAt: "2024-03-16T08:10:42.059+00:00",
    },
    {
      _id: 2,
      userId: "",
      name: "Siddharth Vairagi",
      email: "siddharthvairagi786@gmail.com",
      orderId: 19555,
      items: ["packageId1", "packageId2"],
      payment: 455,
      currency: "$",
      status: "completed",
      createdAt: "2024-03-16T08:10:42.059+00:00",
    },
    {
      _id: 3,
      userId: "",
      name: "Siddharth Vairagi",
      email: "siddharthvairagi786@gmail.com",
      orderId: 19556,
      items: ["packageId1", "packageId2"],
      payment: 455,
      currency: "$",
      status: "completed",
      createdAt: "2024-03-16T08:10:42.059+00:00",
    },
    {
      _id: 4,
      userId: "",
      name: "Siddharth Vairagi",
      email: "siddharthvairagi786@gmail.com",
      orderId: 19557,
      items: ["packageId1", "packageId2"],
      payment: 455,
      currency: "$",
      status: "completed",
      createdAt: "2024-03-16T08:10:42.059+00:00",
    },
    {
      _id: 5,
      userId: "",
      name: "Siddharth Vairagi",
      email: "siddharthvairagi786@gmail.com",
      orderId: 19558,
      items: ["packageId1", "packageId2"],
      payment: 455,
      currency: "$",
      status: "completed",
      createdAt: "2024-03-16T08:10:42.059+00:00",
    },
    {
      _id: 6,
      userId: "",
      name: "Siddharth Vairagi",
      email: "siddharthvairagi786@gmail.com",
      orderId: 19559,
      items: ["packageId1", "packageId2"],
      payment: 455,
      currency: "$",
      status: "completed",
      createdAt: "2024-03-16T08:10:42.059+00:00",
    },
    {
      _id: 7,
      userId: "",
      name: "Siddharth Vairagi",
      email: "siddharthvairagi786@gmail.com",
      orderId: 19560,
      items: ["packageId1", "packageId2"],
      payment: 455,
      currency: "$",
      status: "completed",
      createdAt: "2024-03-16T08:10:42.059+00:00",
    },
    {
      _id: 8,
      userId: "",
      name: "Siddharth Vairagi",
      email: "siddharthvairagi786@gmail.com",
      orderId: 19561,
      items: ["packageId1", "packageId2"],
      payment: 455,
      currency: "$",
      status: "completed",
      createdAt: "2024-03-16T08:10:42.059+00:00",
    },
    {
      _id: 9,
      userId: "",
      name: "Siddharth Vairagi",
      email: "siddharthvairagi786@gmail.com",
      orderId: 19562,
      items: ["packageId1", "packageId2"],
      payment: 455,
      currency: "$",
      status: "completed",
      createdAt: "2024-03-16T08:10:42.059+00:00",
    },
    {
      _id: 10,
      userId: "",
      name: "Siddharth Vairagi",
      email: "siddharthvairagi786@gmail.com",
      orderId: 19563,
      items: ["packageId1", "packageId2"],
      payment: 455,
      currency: "$",
      status: "completed",
      createdAt: "2024-03-16T08:10:42.059+00:00",
    },
  ]);
  const [searchData, setSearchData] = useState("");

  const getPackageOrderData = async () => {
    setOrderList(orderList);
  };

  const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = orderList?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(orderList?.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % orderList?.length;
    setItemOffset(newOffset);
  };

  const handleSearch = async (searchValue) => {
    let userData = {};
    userData.searchData = searchValue || searchData;
    setOrderList(orderList);
  };

  return (
    <>
      <TableHeader
        Search
        handleSearch={handleSearch}
        setSearchData={setSearchData}
        SearchReverseData={getPackageOrderData}
      />
      <PackageTableData
        onMouseDown={onMouseDown}
        tableRef={tableRef}
        orderList={currentItems}
        getPackageOrderData={getPackageOrderData}
        roleType={roleType}
      />
      <Pagination
        Result={orderList}
        itemsPerPage={itemsPerPage}
        handlePageClick={handlePageClick}
        pageCount={pageCount}
      />
    </>
  );
};

export default PackageRevenue;
