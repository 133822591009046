import config from "../../config/config.json";
import { headers } from "../../APIHelpers/Headers";
import saveAs from "file-saver";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../../APIHelpers/Responses";

export const subscriptionPlanServices = {
  //Subscription Plan
  searchSubcriptionPlan,
  addNewSubcriptionPlan,
  updateSubscriptionPlan,
  changeSubscriptionPlanStatus,
  getSubscriptionPlanList,
  getSubscriptionPlanListByStatus,
  getSubscriptionTransectionList,
  exportSubscriptionBillingTransectionsMultiFormat,
  getSubscriptionBillingTransectionForYearGraph,
  getSubscriptionBillingTransectionForWeekGraph,
};

// Subscription Billing Transection For Week Graph
async function getSubscriptionBillingTransectionForWeekGraph() {
  const response = await fetch(
    `${config.API_URL}getSubscriptionBillingTransectionForWeekGraph`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Subscription Billing Transection For Year Graph
async function getSubscriptionBillingTransectionForYearGraph(year) {
  const response = await fetch(
    `${config.API_URL}getSubscriptionBillingTransectionForYearGraph/${year}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Export Subscription Billing Transection in Multiformat
async function exportSubscriptionBillingTransectionsMultiFormat(userData) {
  const response = await fetch(
    `${config.API_URL}exportSubscriptionBillingTransectionsMultiFormat`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  if (data.statusCode === 400) {
    await handleResponse(data);
    return data;
  } else {
    saveAs(url, data?.data);
    return data;
  }
}

// Get Subscription Transection List
async function getSubscriptionTransectionList() {
  const response = await fetch(
    `${config.API_URL}getSubscriptionTransectionList`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Add New Subscription Plan
async function searchSubcriptionPlan(userData) {
  const response = await fetch(
    `${config.API_URL}searchSubcriptionPlan`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Add New Subscription Plan
async function addNewSubcriptionPlan(userData) {
  const response = await fetch(
    `${config.API_URL}addNewSubcriptionPlan`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Update Subscription Plan
async function updateSubscriptionPlan(userData) {
  const response = await fetch(
    `${config.API_URL}updateSubscriptionPlan`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Change Status Subscription Plan
async function changeSubscriptionPlanStatus(userData) {
  const response = await fetch(
    `${config.API_URL}changeSubscriptionPlanStatus`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Get All Subscription Plan List for Table
async function getSubscriptionPlanList() {
  const response = await fetch(
    `${config.API_URL}getSubscriptionPlanList`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get All Subscription Plan List By Id
async function getSubscriptionPlanListByStatus(status) {
  const response = await fetch(
    `${config.API_URL}getSubscriptionPlanListByStatus/${status}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
