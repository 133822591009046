import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { sdtmServices } from "../../../../APIServices/CDISC/sdtmServices";

const AddSTDMTerminologyFileData = ({ Show, Hide, Size, Title, Submit, STDMId, getSTDMCodeData }) => {
  let [type, setType] = useState("");
  let [name, setName] = useState("");
  let [codeId, setCodeId] = useState("");
  let [dataType, setDataType] = useState("");
  let [extensible, setExtensible] = useState("");
  let [loader, setLoader] = useState(false);
  let [codeList, setCodeList] = useState([]);

  const [fields, setFields] = useState([
    { terms: "", order: "", decodeValue: "" },
  ]);

  const handleInputChange = (index, event) => {
    const values = [...fields];
    values[index][event.target.name] = event.target.value;
    setFields(values);
  };

  const handleAddField = () => {
    setFields([...fields, { terms: "", order: "", decodeValue: "" }]);
  };

  const handleRemoveField = (index) => {
    const values = [...fields];
    values.splice(index, 1);
    setFields(values);
  };

  const addNewSTDMCode = async () => {
    setLoader(true)
    let userData = {};
    userData.stdmId = STDMId;
    userData.type = type;
    userData.codeId = codeId;
    if (type === "custom") {
      userData.codeName = name;
      userData.dataType = dataType;
    }
    if (type === "custom" || extensible === "Yes") {
      userData.termsData = fields;
    }
    let data = await sdtmServices.addNewSTDMCode(
      userData
    );
    if (data?.statusCode === 200) {
      cleanInputBox();
      getSTDMCodeData(STDMId);
    }
    setLoader(false)
  };

  const getSTDMTerminologyCodeList = async (STDMId) => {
    let data = await sdtmServices.getSTDMTerminologyCodeList(STDMId)
    setCodeList(data?.data)
  }

  useEffect(() => {
    getSTDMTerminologyCodeList(STDMId);
  }, [STDMId]);

  const cleanInputBox = () => {
    Hide();
    setType("");
    setName("");
    setCodeId("");
    setDataType("");
    setFields([
      { terms: "", order: "", decodeValue: "" },
    ]);
  };

  return (
    <Modal
      show={Show}
      onHide={Hide}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={cleanInputBox} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>
              Code List Type <span>*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => setType(e.target.value)}
              aria-label="Default select example"
            >
              <option>Select Code List Type</option>
              <option
                selected={type === "standard" && "standard"}
                value="standard"
              >
                Standard
              </option>
              <option selected={type === "custom" && "custom"} value="custom">
                Custom
              </option>
            </Form.Select>
          </Form.Group>
          {type === "standard" && (
            <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
              <Form.Label>
                Code Id <span>*</span>
              </Form.Label>
              <Form.Select
                onChange={(e) => { setCodeId(e.target.value); setExtensible(e.target[e.target.selectedIndex].title) }}
                aria-label="Default select example"
              >
                <option>Select Code Id</option>
                {codeList?.map((item, index) => (
                  <option title={item?.parent_extensible} key={index} value={item?.codelist_code}>{item?.parent_id}</option>
                ))}
              </Form.Select>
            </Form.Group>
          )}
          {type === "custom" && (
            <>
              <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
                <Form.Label>
                  Code Id <span>*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Code Id"
                  value={codeId}
                  onChange={(e) => setCodeId(e.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
                <Form.Label>
                  Code Name <span>*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Code Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
                <Form.Label>
                  Data Type <span>*</span>
                </Form.Label>
                <Form.Select
                  onChange={(e) => setDataType(e.target.value)}
                  aria-label="Default select example"
                >
                  <option>Select Data Type</option>
                  <option value={"text"}>Text</option>
                  <option value={"float"}>Float</option>
                  <option value={"integer"}>Integer</option>
                </Form.Select>
              </Form.Group>
            </>
          )}
          {(extensible === "Yes" || type === "custom") &&
            <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
              <div className="hstack justify-content-between">
                <Form.Label>
                  Terms <span>*</span>
                </Form.Label>
                <button
                  className="w-auto p-0"
                  style={{ fontSize: 12 }}
                  onClick={handleAddField}
                >
                  <i className="fa fa-square-plus"></i> Add More
                </button>
              </div>
              {fields.map((field, index) => (
                <div className="hstack gap-2 mb-2" key={index}>
                  <Form.Control
                    type="text"
                    placeholder="Enter Terms Type"
                    name="terms"
                    value={field.terms}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                  <Form.Control
                    type="text"
                    placeholder="Enter Order"
                    name="order"
                    value={field.order}
                    style={{ width: 100 }}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                  <Form.Control
                    type="text"
                    placeholder="Enter Decode Value"
                    name="decodeValue"
                    value={field.decodeValue}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                  {fields.length > 1 && (
                    <button
                      className="w-auto p-0 text-danger"
                      title="Delete this Row"
                      style={{ fontSize: 12 }}
                      onClick={() => handleRemoveField(index)}
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                  )}
                </div>
              ))}
            </Form.Group>
          }
        </Row>
      </Modal.Body>
      <Modal.Footer className="pt-2 px-2">
        <div className="text-end footer_button">
          <Button onClick={addNewSTDMCode} disabled={!type || !codeId || loader}>
            {Submit}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AddSTDMTerminologyFileData;
