import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

const RenameLibraryModal = ({
  Show,
  Hide,
  Size,
  Title,
  Submit,
  handleUpdateLibraryData,
  libraryFolderName,
  setLibraryFolderName,
  fileDescription,
  setFileDescription,
}) => {

  const [error, setError] = useState("");
  const validateFileTitle = (input) => {
    const fileTitleRegex = /^[a-zA-Z0-9_]+$/;

    if (!fileTitleRegex.test(input)) {
      setError(
        "File Title should only contain capital letters, small letters, or numbers"
      );
    } else {
      setError("");
    }
  };

  return (
    <div>
      <Modal
        show={Show}
        onHide={Hide}
        size={Size}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>{Title}</Modal.Title>
          <div onClick={Hide} className="close_modal" title="Close">
            X
          </div>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Form.Group as={Col} md="12" className="add_sponsor_field">
              <Form.Label>
                Title <span>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                value={libraryFolderName}
                onChange={(e) => {
                  setLibraryFolderName(e.target.value);
                  validateFileTitle(e.target.value);
                }}
                placeholder="Enter File Title"
                required
              />
              {error && (
                <span style={{ fontSize: 10, color: "red", fontWeight: 600 }}>
                  File Title should only contain capital letters, small letters,
                  underScore, and numbers "Space Not Allowed"
                </span>
              )}
            </Form.Group>
            <Form.Group as={Col} md="12" className="add_sponsor_field">
              <Form.Label>
                Description <span>*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter File Description"
                value={fileDescription}
                onChange={(e) => setFileDescription(e.target.value)}
                required
              />
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer className="p-0 px-2">
          <div className="text-end footer_button">
            <Button
              disabled={!libraryFolderName || error}
              onClick={() => {
                handleUpdateLibraryData();
                Hide();
              }}
            >
              {Submit}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default RenameLibraryModal;
