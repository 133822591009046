import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { invitedDataServices } from "../../APIServices/invitedDataServices";
import { addressServices } from "../../APIServices/addressServices";
import ExpiredInvitationMessage from "./ExpiredInvitationMessage";
import InviteConfirmation from "./InviteConfirmation";

const SendInvitation = () => {
  let { token } = useParams();
  let [email, setEmail] = useState("");
  let [expired, setExpired] = useState("");
  let [roleType, setRoleType] = useState("");
  let [countryId, setCountryId] = useState("");
  let [stateId, setStateId] = useState("");
  let [cityId, setCityId] = useState("");
  let [countryList, setCountryList] = useState([]);
  let [stateList, setStateList] = useState([]);
  let [cityList, setCityList] = useState([]);
  let [address, setAddress] = useState("");
  let [zipCode, setZipCode] = useState("");
  let [gender, setGender] = useState("");
  let [phoneNumber, setPhoneNumber] = useState("");
  let [firstName, setFirstName] = useState("");
  let [middleName, setMiddleName] = useState("");
  let [lastName, setLastName] = useState("");
  let [phoneError, setPhoneError] = useState("");

  const getInvitationData = async () => {
    let data = await invitedDataServices.getInvitationData(token);
    setRoleType(data?.roleType);
    setEmail(data?.email);
    setExpired(data?.status);
  };

  const getCountries = async () => {
    let data = await addressServices.getCountries();
    setCountryList(data?.data);
  };

  const getStates = async (id) => {
    let data = await addressServices.getStates(id);
    setStateList(data?.data);
  };

  const getCities = async (id) => {
    let data = await addressServices.getCities(id);
    setCityList(data?.data);
  };

  const registeredUserByInvitation = async () => {
    let userData = {};
    userData.firstName = firstName;
    userData.middleName = middleName;
    userData.lastName = lastName;
    userData.phone = phoneNumber;
    userData.gender = gender;
    userData.address = address;
    userData.countryId = countryId;
    userData.stateId = stateId;
    userData.cityId = cityId;
    userData.zipCode = zipCode;
    userData.token = token;
    let data = await invitedDataServices.registeredUserByInvitation(userData);
    if (data?.statusCode === 200) {
      clearInputData();
      getInvitationData();
    }
  };

  const clearInputData = () => {
    setFirstName("");
    setMiddleName("");
    setLastName("");
    setPhoneNumber("");
    setGender("");
    setAddress("");
    setCountryId("");
    setStateId("");
    setCityId("");
    setZipCode("");
  };
  const validatePhoneNumber = (phone) => {
    const phonePattern = /^\d{10}$/;
    if (phonePattern.test(phone)) {
      setPhoneError("");
    } else {
      setPhoneError("Phone Number Should have 10 digit");
    }
  };

  useEffect(() => {
    getCountries();
    getInvitationData();
  }, []);
  return (
    <>
      <div className="Form_justification">
        {expired === "invited" ? (
          <div className="invite__form">
            <p className="border-bottom">
              {roleType === "subadmin" ? "Subadmin" : "User"} Invitation Form
            </p>
            <Row>
              <Col md={12}>
                <Row>
                  <Form.Group
                    as={Col}
                    md="4"
                    className="add_sponsor_field mb-3"
                  >
                    <Form.Label>
                      First Name <span>*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      onChange={(e) => setFirstName(e.target.value)}
                      value={firstName}
                      placeholder="Enter First Name"
                    />
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="4"
                    className="add_sponsor_field mb-3"
                  >
                    <Form.Label>Middle Name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      onChange={(e) => setMiddleName(e.target.value)}
                      value={middleName}
                      placeholder="Enter Middle Name"
                    />
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="4"
                    className="add_sponsor_field mb-3"
                  >
                    <Form.Label>
                      Last Name <span>*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      onChange={(e) => setLastName(e.target.value)}
                      value={lastName}
                      placeholder="Enter Last Name"
                    />
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="4"
                    className="add_sponsor_field mb-3"
                  >
                    <Form.Label>
                      Email <span>*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      disabled
                      value={email}
                      type="email"
                      placeholder="Enter Email ID"
                    />
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="4"
                    className="add_sponsor_field mb-3"
                  >
                    <Form.Label>
                      Phone Number <span>*</span>
                    </Form.Label>
                    <Form.Control
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                        validatePhoneNumber(e.target.value);
                      }}
                      value={phoneNumber}
                      type="number"
                      placeholder="Enter Phone Number"
                    />
                    {phoneError && (
                      <span
                        className="message"
                        style={{ color: "red", fontSize: 10 }}
                      >
                        {phoneError}
                      </span>
                    )}
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="4"
                    className="add_sponsor_field mb-3"
                  >
                    <Form.Label>
                      Gender <span>*</span>
                    </Form.Label>
                    <Form.Select
                      onChange={(e) => setGender(e.target.value)}
                      aria-label="Default select example"
                    >
                      <option>Select Gender</option>
                      <option selected={gender === "male"} value="male">
                        Male
                      </option>
                      <option selected={gender === "female"} value="female">
                        Female
                      </option>
                      <option selected={gender === "other"} value="other">
                        Other
                      </option>
                    </Form.Select>
                  </Form.Group>
                </Row>
              </Col>
            </Row>
            <Row>
              <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
                <Form.Label>
                  Address <span>*</span>
                </Form.Label>
                <Form.Control
                  required
                  onChange={(e) => setAddress(e.target.value)}
                  value={address}
                  as="textarea"
                  placeholder="Enter Address"
                />
              </Form.Group>
              <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
                <Form.Label>
                  Country <span>*</span>
                </Form.Label>
                <Form.Select
                  onChange={(e) => setCountryId(e.target.value)}
                  onClick={(e) =>
                    getStates(e.target[e.target.selectedIndex].title)
                  }
                  aria-label="Default select example"
                >
                  <option>Select Country</option>
                  {countryList?.length > 0
                    ? countryList?.map((item) => (
                        <option
                          selected={item._id === countryId}
                          value={item._id}
                          title={item.id}
                        >
                          {item.name}
                        </option>
                      ))
                    : ""}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
                <Form.Label>
                  State <span>*</span>
                </Form.Label>
                <Form.Select
                  onChange={(e) => setStateId(e.target.value)}
                  onClick={(e) =>
                    getCities(e.target[e.target.selectedIndex].title)
                  }
                  aria-label="Default select example"
                >
                  <option>Select State</option>
                  {stateList?.length > 0
                    ? stateList?.map((item) => (
                        <option
                          selected={item._id === stateId}
                          value={item._id}
                          title={item.id}
                        >
                          {item.name}
                        </option>
                      ))
                    : ""}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
                <Form.Label>
                  City <span>*</span>
                </Form.Label>
                <Form.Select
                  onChange={(e) => setCityId(e.target.value)}
                  aria-label="Default select example"
                >
                  <option>Select City</option>
                  {cityList?.length > 0
                    ? cityList?.map((item) => (
                        <option selected={item._id === cityId} value={item._id}>
                          {item.name}
                        </option>
                      ))
                    : ""}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
                <Form.Label>
                  Zip Code <span>*</span>
                </Form.Label>
                <Form.Control
                  required
                  type="number"
                  placeholder="Enter Zip Code"
                  onChange={(e) => setZipCode(e.target.value)}
                  value={zipCode}
                />
              </Form.Group>
            </Row>
            <div className="hstack gap-3 justify-content-end border-top pt-3 footer_button">
              <Button onClick={clearInputData} className="close_circle">
                Reset
              </Button>
              <Button
                disabled={
                  !firstName ||
                  !lastName ||
                  !email ||
                  !phoneNumber ||
                  !gender ||
                  !address ||
                  !countryId ||
                  !stateId ||
                  !cityId ||
                  !zipCode ||
                  !token ||
                  phoneError
                }
                onClick={registeredUserByInvitation}
              >
                Submit
              </Button>
            </div>
          </div>
        ) : expired === "registered" ? (
          <InviteConfirmation />
        ) : (
          <ExpiredInvitationMessage />
        )}
      </div>
    </>
  );
};

export default SendInvitation;
