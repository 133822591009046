import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import ReactQuill from "react-quill";
import { packageServices } from "../../../../APIServices/Editor/packageServices";

const UpdatePackageDetailsModal = ({ Show, Hide, Size, Title, packageData, getRequestedDisabledPackage, Submit }) => {
  const [packageName, setPackageName] = useState("");
  const [description, setDescription] = useState("");
  const [longDescription, setLongDescription] = useState("");
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState([]);

  const handleUpdate = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Update this Package?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {}
        userData.id = packageData?._id
        userData.packageName = packageName
        userData.description = description
        userData.longDescription = longDescription
        userData.category = category
        userData.tagId = tags
        let data = await packageServices.updateDisabledPackagesData(userData);
        if (data?.statusCode === 200) {
          getRequestedDisabledPackage()
        }
      }
    });
  }

  const [categoryList, setCategoryList] = useState([]);
  const [tagList, setTagList] = useState([]);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ font: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      [{ align: [] }],
      [{ color: [] }, { background: [] }],
      ["clean"],
    ],
  };

  const Editor = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  const getActivePackageCategory = async () => {
    let data = await packageServices.getActivePackageCategory();
    setCategoryList(data?.data);
  };

  const getPackageTagList = async () => {
    let data = await packageServices.getPackageTagList();
    setTagList(data?.data);
  };

  useEffect(() => {
    getPackageTagList();
    getActivePackageCategory();
    setPackageName(packageData?.packageName || "");
    setDescription(packageData?.description || "");
    setLongDescription(packageData?.longDescription || "");
    setCategory(packageData?.categoryId || "");
    setTags(packageData?.tagId || "");
  }, [packageData]);
  
  return (
    <Modal
      show={Show}
      onHide={Hide}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={()=>Hide()} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
        <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
          <Form.Label>
            Folder Name <span>*</span>
          </Form.Label>
          <Form.Control
            required
            type="text"
            value={packageData?.name}
            disabled
            placeholder="Enter Folder Name"
          />
        </Form.Group>
        <Form.Group as={Col} md="8" className="add_sponsor_field mb-3">
          <Form.Label>Package Name <span>*</span></Form.Label>
          <Form.Control
            required
            type="text"
            onChange={(e) => setPackageName(e.target.value)}
            value={packageName}
            placeholder="Enter Package Name"
          />
        </Form.Group>
        <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
          <Form.Label>
            Short Description <span>*</span>
          </Form.Label>
          <Form.Control
            required
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            as="textarea"
            placeholder="Enter Description"
          />
        </Form.Group>
        <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
          <Form.Label>
            Long Description <span>*</span>
          </Form.Label>
          <div className="sidebar_Content">
            <ReactQuill
              modules={modules}
              formats={Editor}
              theme="snow"
              value={longDescription}
              onChange={setLongDescription}
            />
          </div>
        </Form.Group>
        <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
          <Form.Label>
            Category <span>*</span>
          </Form.Label>
          <Form.Select
            onChange={(e) => setCategory(e.target.value)}
            aria-label="Default select example"
          >
            <option>Select Category</option>
            {categoryList?.map((item, index) => (
              <option
                key={index}
                selected={category === item?.categoryId}
                value={item?._id}
              >
                {item?.category}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
          <Form.Label>Tags</Form.Label>
          <Form.Select
            aria-label="Default select example"
            onChange={(e) => setTags(e.target.value)}
          >
            <option>Select Tags</option>
            {tagList?.map((item, index) => (
              <option key={index} value={item?.tagId}>
                {item?.tags}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
          <Form.Label>Selected Tag List</Form.Label>
          <div className="badgesChips">
            <div className="chip hstack mt-1">
              <span>
                <b>tag 1</b>
              </span>
              <div className="vr"></div>
              <i
                className="fa-regular fa-circle-xmark"
                style={{ color: "red" }}
                title="Delete"
              ></i>
            </div>
          </div>
        </Form.Group>
        </Row>
        <div className="hstack justify-content-end border-top pt-3 footer_button">
          <Button disabled={!category} onClick={() => { handleUpdate(); Hide() }}>{Submit}</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UpdatePackageDetailsModal;
