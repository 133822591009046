import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import TableHeader from "../../../../component/TableHeader";
import UseToggle from "../../../Hooks/useToggle";
import { documentAIServices } from "../../../../APIServices/AIAlpha/documentAIServices";
import AddNewDocumentMetadataModal from "./AddNewDocumentMetadataModal";
import Moment from "react-moment";
import Swal from "sweetalert2";
import UpdateDocumentMetadataModal from "./UpdateDocumentMetadataModal";
import ViewDocumentAIModal from "./ViewDocumentAIModal";

const DocumentMetadataTableData = ({
  dcouementMetadataList,
  setDocumentMetadataList,
  viewDocumentMetadata,
  getDocumentMetadataList,
  caseId,
  onMouseDown,
  tableRef,
}) => {
  const { toggle, setToggle, toggle1, setToggle1, toggle2, setToggle2 } =
    UseToggle();
  const ref = useRef(null);
  const [action, setAction] = useState("");
  const [steps, setSteps] = useState([""]);
  const [id, setId] = useState("");
  const [searchData, setSearchData] = useState("");
  const [documentMetadata, setDocumentMetadata] = useState("");
  const [order, setOrder] = useState("");
  const [viewProgramPrompt, setViewProgramPrompt] = useState([]);
  const handleSearch = async (searchValue) => {
    let userData = {};
    userData.searchData = searchValue || searchData;
    let data = await documentAIServices.searchDocumentMetadata(userData);
    setDocumentMetadataList(data?.data);
  };

  const handleStatusChange = async (id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Change Status this Document Metadata?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = id;
        userData.status = status;
        let data = await documentAIServices.changeDcoumentMetadataStatus(
          userData
        );
        if (data?.statusCode === 200) {
          getDocumentMetadataList(caseId);
        }
      }
    });
  };

  const handleUpdate = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Update this Document Metadata?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = id;
        userData.caseId = caseId;
        userData.steps = steps;
        userData.metadata = documentMetadata;
        userData.orderNumber = order;
        let data = await documentAIServices.updateDocumentMetadata(userData);
        if (data?.statusCode === 200) {
          getDocumentMetadataList(caseId);
        }
      }
    });
  };

  const updateDocumentMetadataModal = (item) => {
    setDocumentMetadata(item.metadata);
    setSteps(item.steps);
    setId(item._id);
    setOrder(item.orderNumber);
    setToggle1();
  };

  const viewDocumentAIPrompt = (e, steps) => {
    setToggle2();
    setViewProgramPrompt(steps);
  };

  const handleDelete = async (e, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete this Document Metadata?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await documentAIServices.deleteDocumentMetadata(id);
        if (data?.statusCode === 200) {
          getDocumentMetadataList(caseId);
        }
      }
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setAction("");
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [caseId]);
  return (
    <>
      {viewDocumentMetadata && (
        <>
          <div className="hstack justify-content-between sponsors_section_filter">
            <div className="hstack gap-2">
              <p style={{ marginBottom: 6, fontWeight: "bold" }}>
                {viewDocumentMetadata} Metadata
              </p>
            </div>
            <TableHeader
              Search
              handleSearch={handleSearch}
              setSearchData={setSearchData}
              SearchReverseData={getDocumentMetadataList}
              SearchValue={caseId}
              AddNew
              handleAddNew={setToggle}
              titleAddNew="Add New Document case"
            />
          </div>
          <div className="table-container overflow-auto">
            <Table
              id="resizable-table"
              className="custom-table m-0"
              ref={tableRef}
            >
              <thead>
                <tr>
                  <th
                    onMouseDown={(e) => onMouseDown(e, 0)}
                    style={{ width: 40 }}
                  >
                    Sr.
                  </th>
                  <th onMouseDown={(e) => onMouseDown(e, 1)}>Metadata</th>
                  <th
                    onMouseDown={(e) => onMouseDown(e, 2)}
                    className="text-center"
                    style={{ width: 120 }}
                  >
                    Status
                  </th>
                  <th
                    onMouseDown={(e) => onMouseDown(e, 3)}
                    className="text-center"
                    style={{ width: 170 }}
                  >
                    Updated Date | Time
                  </th>
                  <th
                    onMouseDown={(e) => onMouseDown(e, 4)}
                    className="text-center"
                    style={{ width: 70 }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody ref={ref}>
                {dcouementMetadataList?.map((item, index) => (
                  <tr className="relative">
                    <td>{index + 1}</td>
                    <td title={item.metadata}>{item.metadata}</td>
                    <td className="status_select">
                      <Form.Select
                        aria-label="Default select example"
                        onChange={(e) =>
                          handleStatusChange(item._id, e.target.value)
                        }
                        className={
                          item.status === "active"
                            ? "active_status"
                            : "inactive_status"
                        }
                      >
                        <option
                          selected={item.status === "active"}
                          value="active"
                        >
                          Active
                        </option>
                        <option
                          selected={item.status === "inactive"}
                          value="inactive"
                        >
                          Inactive
                        </option>
                      </Form.Select>
                    </td>
                    <td>
                      <Moment format="DD MMM YYYY | HH:mm" withTitle>
                        {item.updatedAt}
                      </Moment>
                    </td>
                    <td className="text-center">
                      <div className="text-center action__buttons">
                        {action === item._id ? (
                          <>
                            <div className="saperate_action_button">
                              <Button
                                onClick={(e) =>
                                  viewDocumentAIPrompt(e, item.steps)
                                }
                                title="View"
                                className="rounded-circle"
                              >
                                <i className="fa-solid fa-eye"></i>
                              </Button>
                              <Button
                                onClick={() =>
                                  updateDocumentMetadataModal(item)
                                }
                                title="Edit"
                                className="rounded-circle"
                              >
                                <i className="fa-solid fa-pen-to-square"></i>
                              </Button>
                              <Button
                                onClick={(e) => handleDelete(e, item._id)}
                                title="Delete"
                                className="rounded-circle close_circle"
                              >
                                <i className="fa-solid fa-trash-can"></i>
                              </Button>
                            </div>
                            <Button
                              title="Close Action"
                              className="rounded-circle close_circle"
                            >
                              <i className="fa-solid fa-xmark"></i>
                            </Button>
                          </>
                        ) : (
                          <Button
                            onClick={() => setAction(item._id)}
                            title="Open Action"
                            className="rounded-circle "
                          >
                            <i className="fa-solid fa-bars"></i>
                          </Button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <AddNewDocumentMetadataModal
              Show={!toggle}
              caseId={caseId}
              getDocumentMetadataList={getDocumentMetadataList}
              Hide={setToggle}
              Size={"md"}
              Title={"Add New Document Metadata"}
              Submit={"Submit"}
            />
            <UpdateDocumentMetadataModal
              Show={!toggle1}
              Hide={setToggle1}
              Size={"md"}
              Title={"Update Document Metadata"}
              setDocumentMetadata={setDocumentMetadata}
              documentMetadata={documentMetadata}
              setOrder={setOrder}
              order={order}
              setSteps={setSteps}
              steps={steps}
              handleUpdate={handleUpdate}
              Submit={"Update"}
            />
            <ViewDocumentAIModal
              Show={!toggle2}
              Hide={setToggle2}
              Size={"md"}
              Title={"View Document Prompt"}
              viewProgramPrompt={viewProgramPrompt}
            />
          </div>
        </>
      )}
    </>
  );
};

export default DocumentMetadataTableData;
