import React, { useState } from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { programAIServices } from "../../../../APIServices/AIAlpha/programAIServices";

const AddNewProgramPromptModal = ({
  Show,
  Hide,
  Size,
  Title,
  Submit,
  programId,
  getProgramPrompts,
}) => {
  const [name, setName] = useState("");
  const [steps, setSteps] = useState([""]);
  const addNewProgramPrompt = async () => {
    let userData = {};
    userData.programId = programId;
    userData.name = name;
    userData.steps = steps;
    let data = await programAIServices.addNewProgramPrompt(userData);
    if (data?.statusCode === 200) {
      closeModal();
      getProgramPrompts(programId);
    }
  };

  const handleAddInput = () => {
    setSteps([...steps, ""]);
  };

  const handleRemoveInput = (index) => {
    const newSteps = [...steps];
    newSteps.splice(index, 1);
    setSteps(newSteps);
  };

  const handleChange = (index, event) => {
    const newSteps = [...steps];
    newSteps[index] = event.target.value;
    setSteps(newSteps);
  };

  const closeModal = () => {
    setName("");
    setSteps([""]);
    Hide();
  };
  return (
    <Modal
      show={Show}
      onHide={closeModal}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={closeModal} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body
        style={{ maxHeight: "calc(100vh - 175px)", overflow: "auto" }}
      >
        <Row>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>
              Type<span>*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter Type"
            />
          </Form.Group>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-2">
            <Form.Label>
              Prompt <span>*</span>
              <i
                className="fa-solid fa-circle-plus ms-2 buttonBaseColor"
                onClick={handleAddInput}
                title="Add Feature"
              ></i>
            </Form.Label>
            {steps?.map((item, index) => (
              <div key={index} className="input-groups position-relative">
                <InputGroup>
                  <InputGroup.Text
                    id="inputGroupPrepend"
                    style={{
                      height: "31.7px",
                      width: "60px",
                    }}
                  >
                    Step {index + 1}
                  </InputGroup.Text>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Write here..."
                    value={item}
                    className="mb-2"
                    onChange={(e) => handleChange(index, e)}
                  />
                </InputGroup>
                {steps?.length > 1 && (
                  <div className="input-group-appends" title="Remove">
                    <span onClick={() => handleRemoveInput(index)}>x</span>
                  </div>
                )}
              </div>
            ))}
          </Form.Group>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div className="hstack gap-3 justify-content-end footer_button">
          <Button className="close_circle" onClick={() => setName("")}>
            Reset
          </Button>
          <Button disabled={!name} onClick={addNewProgramPrompt}>
            {Submit}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AddNewProgramPromptModal;
