import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import UpdateSDTMVeriableModal from "./UpdateSDTMVeriableModal";
import Moment from "react-moment";
import Swal from "sweetalert2";
import { sdtmServices } from "../../../../../APIServices/CDISC/sdtmServices";
import UseToggle from "../../../../Hooks/useToggle";

const SDTMVeriableTableData = ({
  variableList,
  getVariablesList,
  onMouseDown,
  tableRef,
}) => {
  const { toggle, setToggle } = UseToggle();
  const ref = useRef(null);
  const [action, setAction] = useState("");
  const [id, setId] = useState("");
  let [version, setVersion] = useState([]);
  let [versionId, setVersionId] = useState("");
  let [order, setOrder] = useState("");
  let [sdtmClass, setSDTMClass] = useState([]);
  let [classId, setClassId] = useState("");
  let [domain, setDomain] = useState([]);
  let [domainId, setDomainId] = useState("");
  let [variableName, setVariableName] = useState("");
  let [variableLabel, setVariableLabel] = useState("");
  let [variableType, setVariableType] = useState("");
  let [variableComment, setVariableComment] = useState([]);
  let [commentId, setCommentId] = useState("");
  let [variableMethod, setVariableMethod] = useState([]);
  let [methodId, setMethodId] = useState("");
  let [origin, setOrigin] = useState("");
  let [terms, setTerms] = useState("");
  let [role, setRole] = useState("");
  let [note, setNote] = useState("");
  let [core, setCore] = useState("");
  let [valueLevel, setValueLevel] = useState("");
  let [codeList, setCodeList] = useState("");
  let [codeListData, setCodeListData] = useState([]);

  const handleStatusChange = async (id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Change Status this Veriable?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = id;
        userData.status = status;
        let data = await sdtmServices.changeVariablesStatus(userData);
        if (data?.statusCode === 200) {
          getVariablesList();
        }
      }
    });
  };

  const handleUpdate = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Update this Veriable?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = id;
        userData.order = order;
        userData.versionId = versionId;
        userData.classId = classId;
        userData.domainId = domainId;
        userData.name = variableName;
        userData.label = variableLabel;
        userData.type = variableType;
        userData.commentId = commentId;
        userData.mathodId = methodId;
        userData.origin = origin;
        userData.terms = terms;
        userData.role = role;
        userData.note = note;
        userData.core = core;
        userData.valueLevel = valueLevel;
        userData.codeList = codeList;
        let data = await sdtmServices.updateVariables(userData);
        if (data?.statusCode === 200) {
          getVariablesList();
        }
      }
    });
  };

  const updateVariableModal = (item) => {
    setClassId(item.classId);
    setVersionId(item.versionId);
    setDomainId(item.domainId);
    setOrder(item.order);
    setVariableName(item.name);
    setVariableLabel(item.label);
    setVariableType(item.type);
    setCommentId(item.commentId);
    setMethodId(item.mathodId);
    setOrigin(item.origin);
    setTerms(item.terms);
    setRole(item.role);
    setNote(item.note);
    setCore(item.core);
    setCodeList(item.codeList);
    setId(item._id);
    setValueLevel(item.valueLevel);
    setToggle();
    getActiveClass(item.versionId);
    getActiveDomains(item.classId);
  };

  const deleteVariables = async (e, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete this Veriable?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await sdtmServices.deleteVariables(id);
        if (data?.statusCode === 200) {
          getVariablesList();
        }
      }
    });
  };

  const getActiveVersions = async () => {
    let data = await sdtmServices.getActiveVersions();
    setVersion(data?.data);
  };

  const getActiveClass = async (versionId) => {
    let data = await sdtmServices.getActiveClass(versionId);
    setSDTMClass(data?.data);
  };

  const getActiveDomains = async (classId) => {
    let data = await sdtmServices.getActiveDomains(classId);
    setDomain(data?.data);
  };

  const getActiveVariableComments = async () => {
    let data = await sdtmServices.getActiveVariableComments();
    setVariableComment(data?.data);
  };

  const getActiveVariableMathods = async () => {
    let data = await sdtmServices.getActiveVariableMathods();
    setVariableMethod(data?.data);
  };

  const getSTDMCodeList = async () => {
    let data = await sdtmServices.getSTDMCodeList();
    setCodeListData(data?.data);
  };

  useEffect(() => {
    getActiveVersions();
    getActiveClass();
    getActiveDomains();
    getActiveVariableComments();
    getActiveVariableMathods();
    getSTDMCodeList();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setAction("");
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <>
      <div className="table-container overflow-auto">
        <Table
          id="resizable-table"
          className="custom-table tableLibrary m-0"
          ref={tableRef}
        >
          <thead>
            <tr>
              <th onMouseDown={(e) => onMouseDown(e, 0)} style={{ width: 40 }}>
                Sr.
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 1)}>Order</th>
              <th onMouseDown={(e) => onMouseDown(e, 2)}>Version</th>
              <th onMouseDown={(e) => onMouseDown(e, 3)}>Class</th>
              <th onMouseDown={(e) => onMouseDown(e, 4)}>Domain</th>
              <th onMouseDown={(e) => onMouseDown(e, 5)}>Veriable Name</th>
              <th onMouseDown={(e) => onMouseDown(e, 6)}>Veriable Label</th>
              <th onMouseDown={(e) => onMouseDown(e, 7)}>Veriable Type</th>
              <th onMouseDown={(e) => onMouseDown(e, 8)}>Controlled Terms</th>
              <th onMouseDown={(e) => onMouseDown(e, 9)}>Role</th>
              <th onMouseDown={(e) => onMouseDown(e, 10)}>Origin</th>
              <th onMouseDown={(e) => onMouseDown(e, 11)}>Method</th>
              <th onMouseDown={(e) => onMouseDown(e, 12)}>Comment</th>
              <th onMouseDown={(e) => onMouseDown(e, 13)}>CDISC Note</th>
              <th onMouseDown={(e) => onMouseDown(e, 14)}>Core</th>
              <th onMouseDown={(e) => onMouseDown(e, 15)}>Code List</th>
              <th onMouseDown={(e) => onMouseDown(e, 16)}>Value Level</th>
              <th
                onMouseDown={(e) => onMouseDown(e, 17)}
                style={{ width: 120 }}
                className="text-center"
              >
                Status
              </th>
              <th
                onMouseDown={(e) => onMouseDown(e, 18)}
                style={{ width: 180 }}
              >
                Updated Date | Time
              </th>
              <th
                onMouseDown={(e) => onMouseDown(e, 19)}
                style={{ width: 70 }}
                className="text-center"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody ref={ref}>
            {variableList?.length > 0 &&
              variableList?.map((item, index) => (
                <tr className="relative">
                  <td>{index + 1}</td>
                  <td>{item.order}</td>
                  <td>{item.version}</td>
                  <td>{item.class}</td>
                  <td>{item.domain}</td>
                  <td>{item.name}</td>
                  <td>{item.label}</td>
                  <td>{item.type}</td>
                  <td>{item.terms}</td>
                  <td className="text-capitalize">
                    {item.role === "recordQualifier"
                      ? "Record Qualifier"
                      : item.role === "synonymQualifier"
                        ? "Synonym Qualifier"
                        : item.role === "variableQualifier"
                          ? "Variable Qualifier"
                          : item.role === "resultQualifier"
                            ? "Result Qualifier"
                            : item.role}
                  </td>
                  <td>{item.origin}</td>
                  <td>{item.mathod}</td>
                  <td>{item.comment}</td>
                  <td>{item.note}</td>
                  <td>{item.core}</td>
                  <td>{item.codeListData}</td>
                  <td className="text-capitalize">{item.valueLevel}</td>
                  <td className="status_select">
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) =>
                        handleStatusChange(item._id, e.target.value)
                      }
                      className={
                        item.status === "active"
                          ? "active_status"
                          : "inactive_status"
                      }
                    >
                      <option
                        selected={item.status === "active"}
                        value="active"
                      >
                        Active
                      </option>
                      <option
                        selected={item.status === "inactive"}
                        value="inactive"
                      >
                        Inactive
                      </option>
                    </Form.Select>
                  </td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm" withTitle>
                      {item.updatedAt}
                    </Moment>
                  </td>
                  <td className="text-center">
                    <div className="text-center action__buttons">
                      {action === item._id ? (
                        <>
                          <div className="saperate_action_button">
                            <Button
                              onClick={() => updateVariableModal(item)}
                              title="Edit"
                              className="rounded-circle"
                            >
                              <i className="fa-solid fa-pen-to-square"></i>
                            </Button>
                            <Button
                              onClick={(e) => deleteVariables(e, item._id)}
                              title="Delete"
                              className="rounded-circle close_circle"
                            >
                              <i className="fa-solid fa-trash-can"></i>
                            </Button>
                          </div>
                          <Button
                            title="Close Action"
                            className="rounded-circle close_circle"
                          >
                            <i className="fa-solid fa-xmark"></i>
                          </Button>
                        </>
                      ) : (
                        <Button
                          onClick={() => setAction(item._id)}
                          title="Open Action"
                          className="rounded-circle "
                        >
                          <i className="fa-solid fa-bars"></i>
                        </Button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {variableList?.length > 0 ? (
          ""
        ) : (
          <div className="noRecordFound">Record not found</div>
        )}
      </div>
      <UpdateSDTMVeriableModal
        Show={!toggle}
        Hide={setToggle}
        Size={"lg"}
        Title={"Update Variable"}
        setVariableName={setVariableName}
        variableName={variableName}
        version={version}
        versionId={versionId}
        setVersionId={setVersionId}
        order={order}
        setOrder={setOrder}
        sdtmClass={sdtmClass}
        classId={classId}
        setClassId={setClassId}
        domain={domain}
        domainId={domainId}
        setDomainId={setDomainId}
        variableLabel={variableLabel}
        setVariableLabel={setVariableLabel}
        variableType={variableType}
        setVariableType={setVariableType}
        terms={terms}
        setTerms={setTerms}
        role={role}
        setRole={setRole}
        note={note}
        setNote={setNote}
        core={core}
        setCore={setCore}
        codeList={codeList}
        setCodeList={setCodeList}
        valueLevel={valueLevel}
        setValueLevel={setValueLevel}
        getActiveClass={getActiveClass}
        getActiveDomains={getActiveDomains}
        origin={origin}
        setOrigin={setOrigin}
        commentId={commentId}
        setCommentId={setCommentId}
        variableComment={variableComment}
        methodId={methodId}
        setMethodId={setMethodId}
        variableMethod={variableMethod}
        codeListData={codeListData}
        handleUpdate={handleUpdate}
        Submit={"Update"}
      />
    </>
  );
};

export default SDTMVeriableTableData;
