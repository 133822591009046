import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { documentAIServices } from "../../../APIServices/AIAlpha/documentAIServices";

const AddNewDocumentNewCaseModal = ({ Show, Hide, Size, Title, Submit, getDocumentCaseList }) => {
  const [caseName, setCaseName] = useState("")

  const addNewDocumentCase = async () => {
    let userData = {}
    userData.caseName = caseName
    let data = await documentAIServices.addNewDocumentCase(userData)
    if (data?.statusCode === 200) {
      closeModal()
      getDocumentCaseList()
    }
  }

  const closeModal = () => {
    setCaseName("")
    Hide()
  }
  return (
    <Modal
      show={Show}
      onHide={closeModal}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={closeModal} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>Document Case Name<span>*</span></Form.Label>
            <Form.Control
              required
              type="text"
              value={caseName}
              onChange={(e) => setCaseName(e.target.value)}
              placeholder="Enter Case Name"
            />
          </Form.Group>
        </Row>
        <div className="hstack gap-3 justify-content-end border-top pt-3 footer_button">
          <Button className="close_circle" onClick={()=>setCaseName("")}>Reset</Button>
          <Button disabled={!caseName} onClick={addNewDocumentCase}>{Submit}</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddNewDocumentNewCaseModal;
