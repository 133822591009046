import React, { useEffect, useState } from "react";
import DataTemplateSidebar from "./DataTemplateSidebar";
import { sdtmServices } from "../../../APIServices/CDISC/sdtmServices";
import { Button, Form } from "react-bootstrap";
import { FaSquarePlus } from "react-icons/fa6";
import { FaTrashAlt } from "react-icons/fa";

const DataTemplate = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [libraryList, setLibraryList] = useState([]);
  const [inputSets, setInputSets] = useState([]);
  const [tempId, setTempId] = useState("");
  const [tempName, setTempName] = useState("");

  const getDataTemplates = async () => {
    let data = await sdtmServices.getDataTemplates();
    setLibraryList(data?.data);
  };

  const updateDataTemplates = async () => {
    let userData = {};
    userData.id = tempId;
    userData.columnData = inputSets;
    let data = await sdtmServices.updateDataTemplates(userData);
    if (data?.statusCode === 200) {
      getDataTemplates();
    }
  };

  useEffect(() => {
    getDataTemplates();
  }, []);

  const addInputSet = () => {
    setInputSets([...inputSets, ["", "", ""]]);
  };

  const removeInputSet = (index) => {
    if (inputSets.length > 1) {
      const newInputSets = inputSets.filter((_, i) => i !== index);
      setInputSets(newInputSets);
    }
  };

  const handleInputChange = (setIndex, inputIndex, value) => {
    const newInputSets = inputSets.map((inputs, i) =>
      i === setIndex
        ? inputs.map((input, j) => (j === inputIndex ? value : input))
        : inputs
    );
    setInputSets(newInputSets);
  };

  return (
    <>
      <div className="d-flex">
        <div
          style={{ width: isCollapsed ? "0%" : "20%" }}
          className={`rightHeightColumn ${
            isCollapsed ? "collapsed" : "collapsed"
          }`}
        >
          <div
            className={`mx-2 ${
              isCollapsed ? "leaveOptionBox" : "leaveOptionBox "
            }`}
          >
            <DataTemplateSidebar
              getDataTemplates={getDataTemplates}
              libraryList={libraryList}
              setInputSets={setInputSets}
              tempId={tempId}
              setTempId={setTempId}
              setTempName={setTempName}
            />
          </div>
        </div>
        <div
          style={{
            width: isCollapsed ? "100%" : "80%",
            background: "#fff",
            marginLeft: isCollapsed ? "0" : "auto",
          }}
          className={isCollapsed ? "collapsed" : "collapsed ps-2"}
        >
          <div className="helpComponentSection">
            {tempId && libraryList?.length > 0 && (
              <>
                <Form.Group className="mb-2 add_sponsor_field">
                  <Form.Label className="mb-1 fw-bold">
                    Add {tempName} Column{" "}
                    <FaSquarePlus
                      style={{ cursor: "pointer" }}
                      title="Add More Columns"
                      onClick={addInputSet}
                    />
                  </Form.Label>
                  {inputSets.map((inputSet, setIndex) => (
                    <div key={setIndex} className="hstack gap-2">
                      <Form.Control
                        type="text"
                        className="rounded-1"
                        placeholder="Enter Name"
                        value={inputSet[0]}
                        onChange={(e) =>
                          handleInputChange(setIndex, 0, e.target.value)
                        }
                      />
                      <Form.Control
                        type="text"
                        className="rounded-1"
                        placeholder="Enter Label"
                        value={inputSet[1]}
                        onChange={(e) =>
                          handleInputChange(setIndex, 1, e.target.value)
                        }
                      />
                      <Form.Select
                        onChange={(e) =>
                          handleInputChange(setIndex, 2, e.target.value)
                        }
                      >
                        <option value="">Select Type</option>
                        <option selected={inputSet[2] === "text"} value="text">Text</option>
                        <option selected={inputSet[2] === "number"} value="number">Number</option>
                        <option selected={inputSet[2] === "date"} value="date">Date</option>
                        <option selected={inputSet[2] === "time"} value="time">Time</option>
                      </Form.Select>
                      {inputSets?.length > 1 && (
                        <FaTrashAlt
                          style={{ cursor: "pointer", fontSize: 35 }}
                          className="text-danger"
                          title="Delete Column Set"
                          onClick={() => removeInputSet(setIndex)}
                        />
                      )}
                    </div>
                  ))}
                </Form.Group>
                <div className="text-end footer_button">
                  <Button onClick={updateDataTemplates}>Save</Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DataTemplate;
