import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { sdtmServices } from "../../../../APIServices/CDISC/sdtmServices";

const UpdateSTDMTerminologyFileData = ({ Show, Hide, Size, Title, Submit, STDMId, codeData, getSTDMCodeData }) => {
  let [type, setType] = useState("");
  let [name, setName] = useState("");
  let [codeId, setCodeId] = useState("");
  let [dataType, setDataType] = useState("");
  let [terms, setTerms] = useState("");
  let [order, setOrder] = useState("");
  let [decodeValue, setDecodeValue] = useState("");

  const updateSTDMCodeData = async () => {
    let userData = {};
    userData.id = codeData?._id;
    userData.order = order;
    if (type === "custom") {
      userData.codeId = codeId;
      userData.terms = terms;
      userData.codeName = name;
      userData.dataType = dataType;
      userData.decodeValue = decodeValue;
    }
    let data = await sdtmServices.updateSTDMCode(
      userData
    );
    if (data?.statusCode === 200) {
      cleanInputBox();
      getSTDMCodeData(STDMId);
    }
  };

  useEffect(() => {
    setType(codeData?.type);
    setTerms(codeData?.terms);
    setOrder(codeData?.order);
    setDecodeValue(codeData?.decodeValue);
    setDataType(codeData?.dataType);
    setCodeId(codeData?.codeId);
    setName(codeData?.codeName);
  }, [codeData]);

  const cleanInputBox = () => {
    Hide();
    setType("");
    setTerms("");
    setDecodeValue("");
    setCodeId("");
    setDataType("");
    setOrder("");
    setName("");
  };

  return (
    <Modal
      show={Show}
      onHide={Hide}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={cleanInputBox} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
              <Form.Label>
                Order <span>*</span>
              </Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Code Name"
                value={order}
                onChange={(e) => setOrder(e.target.value)}
              />
            </Form.Group>
            <Form.Label>
              Code List Type <span>*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => setType(e.target.value)}
              aria-label="Default select example"
              disabled
            >
              <option>Select Code List Type</option>
              <option
                selected={type === "standard" && "standard"}
                value="standard"
              >
                Standard
              </option>
              <option selected={type === "custom" && "custom"} value="custom">
                Custom
              </option>
            </Form.Select>
          </Form.Group>
            <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
              <Form.Label>
                Code Id <span>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Code Id"
                value={codeId}
                disabled={type === "standard"}
                onChange={(e) => setCodeId(e.target.value)}
              />
            </Form.Group>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>
              Code Name <span>*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Code Name"
              disabled={type === "standard"}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>
              Data Type <span>*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => setDataType(e.target.value)}
              aria-label="Default select example"
              value={dataType}
              disabled={type === "standard"}
            >
              <option>Select Data Type</option>
              <option value={"text"}>Text</option>
              <option value={"float"}>Float</option>
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>
              Terms <span>*</span>
            </Form.Label>
            <Form.Control
              type="text"
              disabled={type === "standard"}
              placeholder="Enter Terms"
              value={terms}
              onChange={(e) => setTerms(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>
              Decode Value <span>*</span>
            </Form.Label>
            <Form.Control
              type="text"
              disabled={type === "standard"}
              placeholder="Enter Decode Value"
              value={decodeValue}
              onChange={(e) => setDecodeValue(e.target.value)}
            />
          </Form.Group>
        </Row>
      </Modal.Body>
      <Modal.Footer className="pt-2 px-2">
        <div className="text-end footer_button">
          <Button onClick={updateSTDMCodeData} disabled={!type}>
            {Submit}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateSTDMTerminologyFileData;
