import React, { useEffect, useState } from "react";
import Pagination from "../../../Hooks/Pagination";
import TableHeader from "../../../../component/TableHeader";
import TransectionTableData from "./TransectionTableData";
import UseToggle from "../../../Hooks/useToggle";
import ExportTransectionRecordModal from "./ExportTransectionHistoryModal";
import { Modal } from "react-bootstrap";
import { subscriptionPlanServices } from "../../../../APIServices/Editor/subscriptionPlanServices";
import ViewAnalyticsChartModal from "./ViewAnalyticsChartModal";

const TransectionHistory = ({
  Show,
  Hide,
  Size,
  Title,
  onMouseDown,
  tableRef,
  themeColor,
}) => {
  const { toggle, setToggle, toggle1, setToggle1 } = UseToggle();
  const [searchData, setSearchData] = useState("");
  const [transectionList, setTransectionList] = useState([]);

  const getSubscriptionTransectionList = async () => {
    let data = await subscriptionPlanServices.getSubscriptionTransectionList();
    setTransectionList(data?.data);
  };

  const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = transectionList?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(transectionList?.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % transectionList?.length;
    setItemOffset(newOffset);
  };

  const handleSearch = async (searchValue) => {
    let userData = {};
    userData.searchData = searchValue || searchData;
  };

  const viewAnalytics = () => {
    setToggle1();
    Hide();
  };

  useEffect(() => {
    getSubscriptionTransectionList();
  }, []);

  return (
    <>
      <Modal
        show={Show}
        onHide={Hide}
        size={Size}
        backdrop="static"
        keyboard={false}
        className={!toggle && "d-none"}
      >
        <Modal.Header>
          <Modal.Title>{Title}</Modal.Title>
          <div onClick={() => Hide()} className="close_modal" title="Close">
            X
          </div>
        </Modal.Header>
        <Modal.Body>
          <>
            <TableHeader
              Search
              handleSearch={handleSearch}
              setSearchData={setSearchData}
              SearchReverseData={currentItems}
              Analytics
              titleAnalytics="View Analytics"
              handleAnalytics={viewAnalytics}
              Export
              handleExport={setToggle}
              titleExport="Export Transection Records"
            />
            <TransectionTableData
              onMouseDown={onMouseDown}
              tableRef={tableRef}
              transectionList={currentItems}
            />
            <Pagination
              Result={currentItems}
              itemsPerPage={itemsPerPage}
              handlePageClick={handlePageClick}
              pageCount={pageCount}
            />
            <ExportTransectionRecordModal
              Show={!toggle}
              Hide={setToggle}
              Size={"md"}
              Title={"Export Transection Records"}
              Submit={"Export"}
            />
          </>
        </Modal.Body>
      </Modal>
      <ViewAnalyticsChartModal
        Show={!toggle1}
        Hide={setToggle1}
        Size={"lg"}
        Title="View Billing Analytics"
        themeColor={themeColor}
      />
    </>
  );
};

export default TransectionHistory;
