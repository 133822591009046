import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import UseToggle from "../../Hooks/useToggle";
import Pagination from "../../Hooks/Pagination";
import AddNewProgramLanguageModal from "./AddNewProgramLanguageModal";
import TableHeader from "../../../component/TableHeader";
import ProgramLanguageTableData from "./ProgramLanguageTableData";
import ProgramPromptTableData from "./ProgramPrompts/ProgramPromptTableData";
import { programAIServices } from "../../../APIServices/AIAlpha/programAIServices.jsx";

const AIPrograms = ({ onMouseDown, tableRef }) => {
  const { toggle, setToggle } = UseToggle();
  const [viewProgramPrompt, setViewProgramPrompt] = useState("");
  const [programId, setProgramId] = useState("");
  const [searchData, setSearchData] = useState("");
  const [programLanguages, setProgramLanguages] = useState([]);
  const [programPrompts, setProgramPrompts] = useState([]);
  const getProgramLanguage = async () => {
    let data = await programAIServices.getProgramLanguage();
    setProgramLanguages(data?.data);
  };

  const getProgramPrompts = async (programId) => {
    setProgramId(programId);
    let data = await programAIServices.getProgramPrompts(programId);
    setProgramPrompts(data?.data);
  };

  const itemsPerPageProgramLanguage = 10;
  const [itemOffsetProgramLanguage, setItemOffsetProgramLanguage] = useState(0);
  const endOffsetProgramLanguage =
    itemOffsetProgramLanguage + itemsPerPageProgramLanguage;
  const currentItemsProgramLanguage = programLanguages?.slice(
    itemOffsetProgramLanguage,
    endOffsetProgramLanguage
  );
  const pageCountProgramLanguage = Math.ceil(
    programLanguages?.length / itemsPerPageProgramLanguage
  );
  const handlePageClickProgramLanguage = (event) => {
    const newOffset =
      (event.selected * itemsPerPageProgramLanguage) % programLanguages?.length;
    setItemOffsetProgramLanguage(newOffset);
  };

  const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = programPrompts?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(programPrompts?.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % programPrompts?.length;
    setItemOffset(newOffset);
  };

  const handleSearch = async (searchValue) => {
    let userData = {};
    userData.searchData = searchValue || searchData;
    let data = await programAIServices.searchProgramLanguage(userData);
    setProgramLanguages(data?.data);
  };

  useEffect(() => {
    getProgramLanguage();
  }, []);

  return (
    <>
      <Row>
        <Col md={6} className="mb-3">
          <TableHeader
            Search
            handleSearch={handleSearch}
            setSearchData={setSearchData}
            SearchReverseData={getProgramLanguage}
            AddNew
            handleAddNew={setToggle}
            titleAddNew="Add New Program Language"
          />
          <ProgramLanguageTableData
            onMouseDown={onMouseDown}
            tableRef={tableRef}
            programLanguages={currentItemsProgramLanguage}
            setViewProgramPrompt={setViewProgramPrompt}
            getProgramPrompts={getProgramPrompts}
            getProgramLanguage={getProgramLanguage}
          />
          <Pagination
            Result={currentItemsProgramLanguage}
            itemsPerPage={itemsPerPageProgramLanguage}
            handlePageClick={handlePageClickProgramLanguage}
            pageCount={pageCountProgramLanguage}
          />
        </Col>
        {viewProgramPrompt && (
          <Col md={6}>
            <ProgramPromptTableData
              onMouseDown={onMouseDown}
              tableRef={tableRef}
              viewProgramPrompt={viewProgramPrompt}
              programPrompts={currentItems}
              programId={programId}
              getProgramPrompts={getProgramPrompts}
              setProgramPrompts={setProgramPrompts}
            />
            <Pagination
              Result={programPrompts}
              itemsPerPage={itemsPerPage}
              handlePageClick={handlePageClick}
              pageCount={pageCount}
            />
          </Col>
        )}
      </Row>
      <AddNewProgramLanguageModal
        Show={!toggle}
        getProgramLanguage={getProgramLanguage}
        Hide={setToggle}
        Size={"md"}
        Title={"Add New Program Language"}
        Submit={"Submit"}
      />
    </>
  );
};

export default AIPrograms;
