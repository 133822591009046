import config from "../config/config.json";
import { headers } from "../APIHelpers/Headers";
import {
  headersWithAuthWithoutBody,
} from "../APIHelpers/Responses";

export const addressServices = {
  getCountries,
  findCountryName,
  getStates,
  findStateName,
  getCities,
  findCityName
};

// Get All Country
async function getCountries() {
  const response = await fetch(
    `${config.API_URL}getCountries`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Find Country
async function findCountryName(id) {
  const response = await fetch(
    `${config.API_URL}findCountryName/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get States Based on Country
async function getStates(countryId) {
  const response = await fetch(
    `${config.API_URL}getStates/${countryId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Find State
async function findStateName(id) {
  const response = await fetch(
    `${config.API_URL}findStateName/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get City Based on State
async function getCities(stateId) {
  const response = await fetch(
    `${config.API_URL}getCities/${stateId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Find City
async function findCityName(id) {
  const response = await fetch(
    `${config.API_URL}findCityName/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}