import React, { useEffect, useState } from "react";
import Pagination from "../../Hooks/Pagination";
import TableHeader from "../../../component/TableHeader";
import PackageTableData from "./PackageTableData";
import PackageCategories from "./PackageCategories/PackageCategories";
import PackageTags from "./PackageTags/PackageTags";
import { packageServices } from "../../../APIServices/Editor/packageServices";
import PackageRequest from "./PackageRequest/PackageRequest";
import NewVersionPackage from "./UpdateNewVersionPackage/NewVersionPackage";

const Packages = ({ roleType, onMouseDown, tableRef }) => {
  const [packageList, setPackageList] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [status, setStatus] = useState("");

  const getPackageForReview = async () => {
    let data = await packageServices?.getPackageForReview();
    setPackageList(data?.data);
  };

  const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = packageList?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(packageList?.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % packageList?.length;
    setItemOffset(newOffset);
  };

  const handleSearch = async (searchValue) => {
    let userData = {};
    userData.searchData = searchValue || searchData;
    let data = await packageServices.searchPackages(userData);
    setPackageList(data.data);
  };

  const openCategoryTable = () => {
    setStatus("category");
  };

  const openTagTable = () => {
    setStatus("tag");
  };

  const openReviewPackageTable = () => {
    setStatus("review");
  };

  const openRequestPackageTable = () => {
    setStatus("version");
  };

  const openRequestVersionUpdatePackageTable = () => {
    setStatus("update");
  };

  useEffect(() => {
    getPackageForReview();
  }, []);

  return (
    <>
      {status === "active" ? (
        <PackageCategories
          status={status}
          setStatus={setStatus}
          onMouseDown={onMouseDown}
          tableRef={tableRef}
        />
      ) : status === "inactive" ? (
        <PackageTags
          status={status}
          setStatus={setStatus}
          onMouseDown={onMouseDown}
          tableRef={tableRef}
        />
      ) : status === "version" ? (
        <PackageRequest
          status={status}
          setStatus={setStatus}
          onMouseDown={onMouseDown}
          tableRef={tableRef}
        />
      ) : status === "update" ? (
        <NewVersionPackage
          status={status}
          setStatus={setStatus}
          onMouseDown={onMouseDown}
          tableRef={tableRef}
          roleType={roleType}
        />
      ) : (
        <>
          <TableHeader
            Search
            handleSearch={handleSearch}
            setSearchData={setSearchData}
            searchData={searchData}
            SearchReverseData={getPackageForReview}
            Status={status}
            TextButton4={roleType === "admin"}
            TextButtonName4={"Request Packages"}
            handleTextButton4={openRequestPackageTable}
            TextButton5={roleType === "admin"}
            TextButtonName5={"Version Update Request"}
            handleTextButton5={openRequestVersionUpdatePackageTable}
            TextButton6={roleType === "admin"}
            TextButtonName6={"Review Packages"}
            handleTextButton6={openReviewPackageTable}
            TextButton7={roleType === "admin"}
            TextButtonName7={"Categories"}
            handleTextButton7={openCategoryTable}
            TextButton8={roleType === "admin"}
            TextButtonName8={"Tags"}
            handleTextButton8={openTagTable}
          />
          <PackageTableData
            onMouseDown={onMouseDown}
            tableRef={tableRef}
            packageList={currentItems}
            getPackageForReview={getPackageForReview}
            roleType={roleType}
          />
          <Pagination
            Result={packageList}
            itemsPerPage={itemsPerPage}
            handlePageClick={handlePageClick}
            pageCount={pageCount}
          />
        </>
      )}
    </>
  );
};

export default Packages;
