import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import LibraryGeneralProperties from "./LibraryGeneralProperties";
import LibraryTableProperties from "./LibraryTableProperties";
import { libraryFolderAndDataServices } from "../../../APIServices/Editor/libraryFolderAndDataServices";

const LibraryPropertiesModal = ({
  Show,
  Hide,
  Size,
  Title,
  libraryProperties,
  columnList,
  activeTab,
  setActiveTab,
  getLibraryDataByFolderId,
  setColumnProperty,
  columnStatus,
  setLibraryId,
  userCurrrentData
}) => {

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const [selectedRows, setSelectedRows] = useState([]);

  const handleChange = (e, index) => {
    const { checked, name } = e.target;
    let updatedSelectedRows = [...selectedRows];

    if (name === 'selectAll') {
      if (checked) {
        updatedSelectedRows = columnList?.map((item, index) => index);
      } else {
        updatedSelectedRows = [];
      }
    } else {
      if (checked) {
        if (!updatedSelectedRows?.includes(index)) {
          updatedSelectedRows?.push(index);
        }
      } else {
        updatedSelectedRows = updatedSelectedRows.filter((item) => item !== index);
      }
    }

    setSelectedRows(updatedSelectedRows);
  };

  const libraryHideShowColumn = async () => {
    let userData = {};
    userData.libraryId = libraryProperties?.libraryId;
    userData.indexs = selectedRows;
    userData.userId = userCurrrentData?._id
    let data = await libraryFolderAndDataServices.libraryHideShowColumn(userData);
    if (data?.statusCode === 200) {
      getLibraryDataByFolderId(libraryProperties?.libraryId);
      setColumnProperty("")
      setSelectedRows([])
      setActiveTab("file")
      setLibraryId("")
    }
  };

  const cleanDataCloseModal = () => {
    Hide()
    setSelectedRows([])
    setActiveTab("file")
  }

  useEffect(() => {
    setSelectedRows(columnStatus);
  }, [columnStatus]);
  return (
    <div>
      <Modal
        show={Show}
        onHide={Hide}
        size={Size}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>{Title}</Modal.Title>
          <div onClick={cleanDataCloseModal} className="close_modal" title="Close">
            X
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="hstack justify-content-center gap-3 mb-3 footer_button">
            <Button
              onClick={() => handleTabClick("file")}
              style={{
                backgroundColor: activeTab === "file" ? "" : "transparent",
                color: activeTab === "file" ? "" : "var(--icon_color)",
              }}
            >
              File
            </Button>
            <Button
              onClick={() => handleTabClick("table")}
              style={{
                backgroundColor: activeTab === "table" ? "" : "transparent",
                color: activeTab === "table" ? "" : "var(--icon_color)",
              }}
            >
              Table
            </Button>
          </div>
          {activeTab === "file" && <LibraryGeneralProperties libraryProperties={libraryProperties} />}
          {activeTab === "table" &&
            <LibraryTableProperties
              selectedRows={selectedRows}
              handleChange={handleChange}
              columnList={columnList}
            />
          }
        </Modal.Body>
        <Modal.Footer className="p-0 px-2">
          <div className="text-end footer_button">
            <Button
              onClick={() => {
                libraryHideShowColumn();
                Hide();
              }}
            >
              Update
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default LibraryPropertiesModal;
