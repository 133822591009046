import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import UpdateSDTMDomainModal from "./UpdateSDTMDomainModal";
import Moment from "react-moment";
import Swal from "sweetalert2";
import UseToggle from "../../../Hooks/useToggle";
import { sdtmServices } from "../../../../APIServices/CDISC/sdtmServices";

const SDTMDomainTableData = ({
  domainList,
  getDomainsList,
  onMouseDown,
  tableRef,
}) => {
  const { toggle, setToggle } = UseToggle();
  const ref = useRef(null);
  const [action, setAction] = useState("");
  const [id, setId] = useState("");
  const [version, setVersion] = useState([]);
  const [sdtmClass, setSDTMClass] = useState([]);
  const [domainName, setDomainName] = useState("");
  const [code, setCode] = useState("");
  const [versionId, setVersionId] = useState("");
  const [classId, setClassId] = useState("");
  let [type, setType] = useState("");
  let [structure, setStructure] = useState("");
  let [repeating, setRepeating] = useState("");
  let [reference, setReference] = useState("");
  let [comment, setComment] = useState("");
  const [keyVariables, setKeyVariables] = useState([{}]);

  const handleStatusChange = async (id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Change Status this Class?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = id;
        userData.status = status;
        let data = await sdtmServices.changeDomainsStatus(userData);
        if (data?.statusCode === 200) {
          getDomainsList();
        }
      }
    });
  };

  const handleUpdate = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Update this Class?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = id;
        userData.versionId = versionId;
        userData.classId = classId;
        userData.domain = domainName;
        userData.code = code;
        userData.type = type;
        userData.structure = structure;
        userData.keyVariables = keyVariables;
        userData.repeating = repeating;
        userData.comments = comment;
        userData.reference = reference;
        let data = await sdtmServices.updateDomains(userData);
        if (data?.statusCode === 200) {
          getDomainsList();
        }
      }
    });
  };

  const updateDesignationModal = (item) => {
    setDomainName(item.domain);
    setClassId(item.classId);
    setVersionId(item.versionId);
    setCode(item.code);
    setStructure(item.structure);
    setRepeating(item.repeating);
    setReference(item.reference);
    setComment(item.comments);
    setKeyVariables(item.keyVariables);
    setType(item.type);
    setId(item._id);
    setToggle();
  };

  const handleDelete = async (e, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete this Class?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await sdtmServices.deleteDomains(id);
        if (data?.statusCode === 200) {
          getDomainsList();
        }
      }
    });
  };

  const getActiveVersions = async () => {
    let data = await sdtmServices.getActiveVersions();
    setVersion(data?.data);
  };

  const getActiveClass = async (versionId) => {
    let data = await sdtmServices.getActiveClass(versionId);
    setSDTMClass(data?.data);
  };

  useEffect(() => {
    getActiveVersions();
    getActiveClass();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setAction("");
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <>
      <div className="table-container overflow-auto">
        <Table
          id="resizable-table"
          className="custom-table m-0 tableLibrary"
          ref={tableRef}
        >
          <thead>
            <tr>
              <th onMouseDown={(e) => onMouseDown(e, 0)} style={{ width: 40 }}>
                Sr.
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 1)}>Version</th>
              <th onMouseDown={(e) => onMouseDown(e, 2)}>Class Name</th>
              <th onMouseDown={(e) => onMouseDown(e, 3)}>Domain Label</th>
              <th onMouseDown={(e) => onMouseDown(e, 4)}>Domain Name</th>
              <th onMouseDown={(e) => onMouseDown(e, 5)}>Domain Type</th>
              <th onMouseDown={(e) => onMouseDown(e, 6)}>Purpose</th>
              <th onMouseDown={(e) => onMouseDown(e, 7)}>Structure</th>
              <th onMouseDown={(e) => onMouseDown(e, 8)}>Key Variable</th>
              <th onMouseDown={(e) => onMouseDown(e, 9)}>Repeating</th>
              <th onMouseDown={(e) => onMouseDown(e, 10)}>Reference Data</th>
              <th onMouseDown={(e) => onMouseDown(e, 11)}>Comment</th>
              <th
                onMouseDown={(e) => onMouseDown(e, 12)}
                style={{ width: 120 }}
                className="text-center"
              >
                Status
              </th>
              <th
                onMouseDown={(e) => onMouseDown(e, 13)}
                style={{ width: 180 }}
              >
                Updated Date | Time
              </th>
              <th
                onMouseDown={(e) => onMouseDown(e, 14)}
                style={{ width: 70 }}
                className="text-center"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody ref={ref}>
            {domainList?.length > 0 &&
              domainList?.map((item, index) => (
                <tr className="relative">
                  <td>{index + 1}</td>
                  <td>{item.version}</td>
                  <td>{item.class}</td>
                  <td>{item.domain}</td>
                  <td>{item.code}</td>
                  <td>{item.type}</td>
                  <td>Tabulation</td>
                  <td>{item.structure}</td>
                  <td>{item.keyVariables && item.keyVariables.join(", ")}</td>
                  <td className="text-capitalize">{item.repeating}</td>
                  <td className="text-capitalize">{item.reference}</td>
                  <td>{item.comments}</td>
                  <td className="status_select">
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) =>
                        handleStatusChange(item._id, e.target.value)
                      }
                      className={
                        item.status === "active"
                          ? "active_status"
                          : "inactive_status"
                      }
                    >
                      <option
                        selected={item.status === "active"}
                        value="active"
                      >
                        Active
                      </option>
                      <option
                        selected={item.status === "inactive"}
                        value="inactive"
                      >
                        Inactive
                      </option>
                    </Form.Select>
                  </td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm" withTitle>
                      {item.updatedAt}
                    </Moment>
                  </td>
                  <td className="text-center">
                    <div className="text-center action__buttons">
                      {action === item._id ? (
                        <>
                          <div className="saperate_action_button">
                            <Button
                              onClick={() => updateDesignationModal(item)}
                              title="Edit"
                              className="rounded-circle"
                            >
                              <i className="fa-solid fa-pen-to-square"></i>
                            </Button>
                            <Button
                              onClick={(e) => handleDelete(e, item._id)}
                              title="Delete"
                              className="rounded-circle close_circle"
                            >
                              <i className="fa-solid fa-trash-can"></i>
                            </Button>
                          </div>
                          <Button
                            title="Close Action"
                            className="rounded-circle close_circle"
                          >
                            <i className="fa-solid fa-xmark"></i>
                          </Button>
                        </>
                      ) : (
                        <Button
                          onClick={() => setAction(item._id)}
                          title="Open Action"
                          className="rounded-circle "
                        >
                          <i className="fa-solid fa-bars"></i>
                        </Button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {domainList?.length > 0 ? (
          ""
        ) : (
          <div className="noRecordFound">Record not found</div>
        )}
      </div>
      <UpdateSDTMDomainModal
        Show={!toggle}
        Hide={setToggle}
        Size={"lg"}
        Title={"Update Domain"}
        version={version}
        setVersionId={setVersionId}
        versionId={versionId}
        sdtmClass={sdtmClass}
        setClassId={setClassId}
        classId={classId}
        setDomainName={setDomainName}
        domainName={domainName}
        type={type}
        setType={setType}
        code={code}
        setCode={setCode}
        repeating={repeating}
        setRepeating={setRepeating}
        reference={reference}
        setReference={setReference}
        structure={structure}
        setStructure={setStructure}
        comment={comment}
        setComment={setComment}
        keyVariables={keyVariables}
        setKeyVariables={setKeyVariables}
        handleUpdate={handleUpdate}
        getActiveClass={getActiveClass}
        Submit={"Update"}
      />
    </>
  );
};

export default SDTMDomainTableData;
