import config from "../../config/config.json";
import saveAs from "file-saver";
import { headers } from "../../APIHelpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody
} from "../../APIHelpers/Responses";

export const packageServices = {
  //Package Category
  searchPackageCategory,
  addNewPackageCategory,
  updatePackageCategoryData,
  changePackageCategoryStatus,
  deletePackageCategoryData,
  getPackageCategoryList,
  getActivePackageCategory,
  exportPackageCategoryMultiFormat,

  //Package Tags
  searchPackageTags,
  addNewPackageTags,
  updatePackageTagData,
  deletePackageTagData,
  getPackageTagList,
  exportPackageTagsMultiFormat,

  //Package
  searchPackages,
  getPackageForReview,
  getRequestedDisabledPackage,
  assignPackageForMultipleROle,
  updatePackageReviewStatus,
  updatePackageStatus,
  updatePackagesData,
  updateAdminValidatorPackageStatus,
  publishPackageData,
  getExampleForPackageValidation,
  getSoftwareForPackageValidation,
  getPackageStatementsForAdmin,
  getPackageChangeLogs,
  updatePublishPackageChangelogData,
  deletePackageFolderData,
  updateDisabledPackagesData,
  getPublishedPackageListForConsultant,
  addNewRequestVersionPackage,
  getUpdateRequestPackage,
  updateStatusInRequestVersionPackage,
  assignUpdatedPackageForReview,
  packageReviewStatusForUpdatePackage,
  getExampleForPackage
};

// Search Package Category
async function searchPackageCategory(userData) {
  const response = await fetch(
    `${config.API_URL}searchPackageCategory`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Add New Package Category
async function addNewPackageCategory(userData) {
  const response = await fetch(
    `${config.API_URL}addNewPackageCategory`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Update Package Category
async function updatePackageCategoryData(userData) {
  const response = await fetch(
    `${config.API_URL}updatePackageCategoryData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Update Admin Validator Package Status
async function updateAdminValidatorPackageStatus(userData) {
  const response = await fetch(
    `${config.API_URL}updateAdminValidatorPackageStatus`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Publish Package With Version
async function publishPackageData(userData) {
  const response = await fetch(
    `${config.API_URL}publishPackageData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Change Package Category Status
async function changePackageCategoryStatus(userData) {
  const response = await fetch(
    `${config.API_URL}changePackageCategoryStatus`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Get All Package Category List for Table
async function getPackageCategoryList() {
  const response = await fetch(
    `${config.API_URL}getPackageCategoryList`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get All Active Package Category List for Table
async function getActivePackageCategory() {
  const response = await fetch(
    `${config.API_URL}getActivePackageCategory`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Delete Package category
async function deletePackageCategoryData(id) {
  const response = await fetch(
    `${config.API_URL}deletePackageCategoryData/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Export Package Category in Multiformat
async function exportPackageCategoryMultiFormat(userData) {
  const response = await fetch(
    `${config.API_URL}exportPackageCategoryMultiFormat`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  if (data.statusCode === 400) {
    await handleResponse(data);
    return data;
  } else {
    saveAs(url, data?.data);
    return data;
  }
}

// Search Package Tags
async function searchPackageTags(userData) {
  const response = await fetch(
    `${config.API_URL}searchPackageTags`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Add New Package Tags
async function addNewPackageTags(userData) {
  const response = await fetch(
    `${config.API_URL}addNewPackageTags`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Update Package Tag
async function updatePackageTagData(userData) {
  const response = await fetch(
    `${config.API_URL}updatePackageTagData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Get All Package Tag List for Table
async function getPackageTagList() {
  const response = await fetch(
    `${config.API_URL}getPackageTagList`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Delete Package Tag
async function deletePackageTagData(id) {
  const response = await fetch(
    `${config.API_URL}deletePackageTagData/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Export Package Tag in Multiformat
async function exportPackageTagsMultiFormat(userData) {
  const response = await fetch(
    `${config.API_URL}exportPackageTagsMultiFormat`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  if (data.statusCode === 400) {
    await handleResponse(data);
    return data;
  } else {
    saveAs(url, data?.data);
    return data;
  }
}

// Search Package
async function searchPackages(userData) {
  const response = await fetch(
    `${config.API_URL}searchPackages`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Get All Package For Review
async function getPackageForReview() {
  const response = await fetch(
    `${config.API_URL}getPackageForReview`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get requested Disabled Packages
async function getRequestedDisabledPackage() {
  const response = await fetch(
    `${config.API_URL}getRequestedDisabledPackage`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Assign Package
async function assignPackageForMultipleROle(userData) {
  const response = await fetch(
    `${config.API_URL}assignPackageForMultipleROle`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Update Package Review Status
async function updatePackageReviewStatus(userData) {
  const response = await fetch(
    `${config.API_URL}updatePackageReviewStatus`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Update Package Status
async function updatePackageStatus(userData) {
  const response = await fetch(
    `${config.API_URL}updatePackageStatus`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Update Package
async function updatePackagesData(userData) {
  const response = await fetch(
    `${config.API_URL}updatePackagesData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Get All Example For Validation
async function getExampleForPackageValidation(id) {
  const response = await fetch(
    `${config.API_URL}getExampleForPackageValidation/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get All Software for package
async function getSoftwareForPackageValidation(id) {
  const response = await fetch(
    `${config.API_URL}getSoftwareForPackageValidation/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get All Statement for package
async function getPackageStatementsForAdmin(id) {
  const response = await fetch(
    `${config.API_URL}getPackageStatementsForAdmin/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get All Changelogs for package
async function getPackageChangeLogs(id) {
  const response = await fetch(
    `${config.API_URL}getPackageChangeLogs/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Update Publish Package Changelog Data
async function updatePublishPackageChangelogData(userData) {
  const response = await fetch(
    `${config.API_URL}updatePublishPackageChangelogData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Update Disabled Package Details
async function updateDisabledPackagesData(userData) {
  const response = await fetch(
    `${config.API_URL}updateDisabledPackagesData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Delete Packages
async function deletePackageFolderData(id) {
  const response = await fetch(
    `${config.API_URL}deletePackageFolderData/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Get Published Packages
async function getPublishedPackageListForConsultant() {
  const response = await fetch(
    `${config.API_URL}getPublishedPackageListForConsultant`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Add New Request Verion Package
async function addNewRequestVersionPackage(userData) {
  const response = await fetch(
    `${config.API_URL}addNewRequestVersionPackage`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Get Update Request Package Data
async function getUpdateRequestPackage() {
  const response = await fetch(
    `${config.API_URL}getUpdateRequestPackage`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Update Package Status Update Package Version Data
async function updateStatusInRequestVersionPackage(userData) {
  const response = await fetch(
    `${config.API_URL}updateStatusInRequestVersionPackage`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Assign requested Updated Packages For Review
async function assignUpdatedPackageForReview(userData) {
  const response = await fetch(
    `${config.API_URL}assignUpdatedPackageForReview`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Package Review Status For Update Package
async function packageReviewStatusForUpdatePackage(userData) {
  const response = await fetch(
    `${config.API_URL}packageReviewStatusForUpdatePackage`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Get All Example For Package
async function getExampleForPackage(id) {
  const response = await fetch(
    `${config.API_URL}getExampleForPackage/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
