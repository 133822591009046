import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { sdtmServices } from "../../../../../APIServices/CDISC/sdtmServices";

const AddNewSDTMVeriableModal = ({
  Show,
  Hide,
  Size,
  Title,
  Submit,
  getVariablesList,
}) => {
  let [version, setVersion] = useState([]);
  let [versionId, setVersionId] = useState("");
  let [sdtmClass, setSDTMClass] = useState([]);
  let [order, setOrder] = useState("");
  let [classId, setClassId] = useState("");
  let [domain, setDomain] = useState([]);
  let [domainId, setDomainId] = useState("");
  let [variableName, setVariableName] = useState("");
  let [variableLabel, setVariableLabel] = useState("");
  let [variableType, setVariableType] = useState("");
  let [variableComment, setVariableComment] = useState([]);
  let [commentId, setCommentId] = useState("");
  let [variableMethod, setVariableMethod] = useState([]);
  let [codeListData, setCodeListData] = useState([]);
  let [methodId, setMethodId] = useState("");
  let [origin, setOrigin] = useState("");
  let [terms, setTerms] = useState("");
  let [role, setRole] = useState("");
  let [note, setNote] = useState("");
  let [core, setCore] = useState("");
  let [codeList, setCodeList] = useState("");
  let [valueLevel, setValueLevel] = useState("");

  const addNewVariable = async () => {
    let userData = {};
    userData.order = order;
    userData.versionId = versionId;
    userData.classId = classId;
    userData.domainId = domainId;
    userData.name = variableName;
    userData.label = variableLabel;
    userData.type = variableType;
    userData.commentId = commentId;
    userData.mathodId = methodId;
    userData.origin = origin;
    userData.terms = terms;
    userData.role = role;
    userData.note = note;
    userData.core = core;
    userData.valueLevel = valueLevel;
    userData.codeList = codeList;
    let data = await sdtmServices.addNewVariable(userData);
    if (data?.statusCode === 200) {
      closeModal();
      getVariablesList();
    }
  };

  const getActiveVersions = async () => {
    let data = await sdtmServices.getActiveVersions();
    setVersion(data?.data);
  };

  const getActiveClass = async (versionId) => {
    let data = await sdtmServices.getActiveClass(versionId);
    setSDTMClass(data?.data);
  };

  const getActiveDomains = async (classId) => {
    let data = await sdtmServices.getActiveDomains(classId);
    setDomain(data?.data);
  };

  const getActiveVariableComments = async () => {
    let data = await sdtmServices.getActiveVariableComments();
    setVariableComment(data?.data);
  };

  const getActiveVariableMathods = async () => {
    let data = await sdtmServices.getActiveVariableMathods();
    setVariableMethod(data?.data);
  };

  const getSTDMCodeList = async () => {
    let data = await sdtmServices.getSTDMCodeList();
    setCodeListData(data?.data);
  };

  useEffect(() => {
    getActiveVersions();
    getActiveClass();
    getActiveDomains();
    getActiveVariableMathods();
    getActiveVariableComments();
    getSTDMCodeList();
  }, []);

  const closeModal = () => {
    setVariableName("");
    setClassId("");
    setVersionId("");
    setDomainId("");
    setOrder("");
    setVariableLabel("");
    setVariableType("");
    setCommentId("");
    setMethodId("");
    setOrigin("");
    setTerms("");
    setRole("");
    setNote("");
    setCore("");
    setValueLevel("");
    setCodeList("");
    Hide();
  };
  return (
    <Modal
      show={Show}
      onHide={closeModal}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={closeModal} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Order <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              required
              type="number"
              value={order}
              onChange={(e) => setOrder(e.target.value)}
              placeholder="Enter Order No."
            />
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Version <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => {
                setVersionId(e.target.value);
                getActiveClass(e.target.value);
              }}
              value={versionId}
            >
              <option value="">Select Version</option>
              {version?.map((item, index) => (
                <option
                  selected={item.versionId === item._id}
                  key={index}
                  value={item._id}
                >
                  {item.version}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Class <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => {
                setClassId(e.target.value);
                getActiveDomains(e.target.value);
              }}
              value={classId}
            >
              <option value="">Select Class</option>
              {sdtmClass?.map((item, index) => (
                <option
                  selected={item.classId === item._id}
                  key={index}
                  value={item._id}
                >
                  {item.class}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Domain <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => setDomainId(e.target.value)}
              value={domainId}
            >
              <option value="">Select Domain</option>
              {domain?.map((item, index) => (
                <option
                  selected={item.domainId === item._id}
                  key={index}
                  value={item._id}
                >
                  {item.domain}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Veriable Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              value={variableName}
              onChange={(e) => setVariableName(e.target.value)}
              placeholder="Enter Veriable Name"
            />
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Veriable Label <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              value={variableLabel}
              onChange={(e) => setVariableLabel(e.target.value)}
              placeholder="Enter Veriable Name"
            />
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Veriable Type <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select onChange={(e) => setVariableType(e.target.value)}>
              <option value="">Select Type</option>
              <option selected={variableType === "char" && "char"} value="char">
                Char
              </option>
              <option selected={variableType === "num" && "num"} value="num">
                Num
              </option>
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>Controlled Terms</Form.Label>
            <Form.Control
              type="text"
              value={terms}
              onChange={(e) => setTerms(e.target.value)}
              placeholder="Enter Veriable Name"
            />
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Role <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select onChange={(e) => setRole(e.target.value)}>
              <option value="">Select Type</option>
              <option
                selected={role === "identifier" && "identifier"}
                value="identifier"
              >
                Identifier
              </option>
              <option
                selected={role === "recordQualifier" && "recordQualifier"}
                value="recordQualifier"
              >
                Record Qualifier
              </option>
              <option selected={role === "topic" && "topic"} value="topic">
                Topic
              </option>
              <option
                selected={role === "synonymQualifier" && "synonymQualifier"}
                value="synonymQualifier"
              >
                Synonym Qualifier
              </option>
              <option
                selected={role === "variableQualifier" && "variableQualifier"}
                value="variableQualifier"
              >
                Variable Qualifier
              </option>
              <option selected={role === "timing" && "timing"} value="timing">
                Timing
              </option>
              <option
                selected={role === "grouping" && "grouping"}
                value="grouping"
              >
                Grouping
              </option>
              <option
                selected={role === "qualifier" && "qualifier"}
                value="qualifier"
              >
                Qualifier
              </option>
              <option
                selected={role === "resultQualifier" && "resultQualifier"}
                value="resultQualifier"
              >
                Result Qualifier
              </option>
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Origin <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select onChange={(e) => setOrigin(e.target.value)}>
              <option value="">Select Origin</option>
              <option
                selected={origin === "Protocol" && "Protocol"}
                value="Protocol"
              >
                Protocol
              </option>
              <option
                selected={origin === "Assigned" && "Assigned"}
                value="Assigned"
              >
                Assigned
              </option>
              <option
                selected={origin === "Derived" && "Derived"}
                value="Derived"
              >
                Derived
              </option>
              <option selected={origin === "CRF" && "CRF"} value="CRF">
                CRF
              </option>
              <option selected={origin === "eDT" && "eDT"} value="eDT">
                eDT
              </option>
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>Variable Comment</Form.Label>
            <Form.Select
              onChange={(e) => {
                setCommentId(e.target.value);
              }}
              value={commentId}
              disabled={methodId}
            >
              <option value="">Select Variable Comment</option>
              {variableComment?.map((item, index) => (
                <option
                  selected={item.commentId === item._id}
                  key={index}
                  value={item._id}
                >
                  {item.commentId}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>Variable Method</Form.Label>
            <Form.Select
              onChange={(e) => setMethodId(e.target.value)}
              value={methodId}
              disabled={commentId}
            >
              <option value="">Select Variable Method</option>
              {variableMethod?.map((item, index) => (
                <option
                  selected={item.mathodId === item._id}
                  key={index}
                  value={item._id}
                >
                  {item.mathodId}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Core <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select onChange={(e) => setCore(e.target.value)}>
              <option value="">Select Type</option>
              <option selected={core === "req" && "req"} value="req">
                Req
              </option>
              <option selected={core === "perm" && "perm"} value="perm">
                Perm
              </option>
              <option selected={core === "exp" && "exp"} value="exp">
                Exp
              </option>
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Code List <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => setCodeList(e.target.value)}
              value={codeList}
            >
              <option value="">Select Code List</option>
              {codeListData?.map((item, index) => (
                <option key={index} value={item?.codeList}>{item?.codeId}</option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Value Level <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => setValueLevel(e.target.value)}
              value={valueLevel}
            >
               <option value="">
                Select Value Level
              </option>
              <option value="yes">
                Yes
              </option>
              <option value="no">
                No
              </option>
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>
              CDISC Note <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              required
              as="textarea"
              value={note}
              onChange={(e) => setNote(e.target.value)}
              placeholder="Enter Veriable Name"
            />
          </Form.Group>
        </Row>
        <div className="hstack gap-3 justify-content-end border-top pt-3 footer_button">
          <Button className="close_circle" onClick={() => setVariableName("")}>
            Reset
          </Button>
          <Button disabled={!variableName} onClick={addNewVariable}>
            {Submit}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddNewSDTMVeriableModal;
