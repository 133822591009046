import React, { useState } from "react";
import "./Auth.css";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Forget_Password from "../../img/login.jpg";
import { NavLink } from "react-router-dom";
import { authServices } from "../../APIServices/authServices";

const ChangePassword = ({ currentUserData }) => {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const toggleCurrentPasswordVisibility = () => {
    setShowCurrentPassword((prevState) => !prevState);
  };

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevState) => !prevState);
  };

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  const changePassword = async () => {
    let userData = {}
    userData.currentPassword = currentPassword
    userData.newPassword = newPassword
    userData.repeatPassword = repeatPassword
    let data = await authServices.changePassword(userData)
    if (data?.statusCode === 200) {
      resetInputField()
      currentUserData()
    }
  }

  const [error, setError] = useState("");

  const validatePassword = (input) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>]).{8,}$/;

    if (!passwordRegex.test(input)) {
      setError(
        "Password must contain at least one lowercase letter, one uppercase letter, one digit, one special character, and be at least 8 characters long."
      );
    } else {
      setError("");
    }
  };

  const logout = async () => {
    await authServices.logout();
  }

  const resetInputField = () => {
    setCurrentPassword("")
    setNewPassword("")
    setRepeatPassword("")
  }
  return (
    <>
      <Container fluid className="p-0 section__Module">
        <div className="hero">
          <div className="overlay-black"></div>
          <img className="hero-img" src={Forget_Password} alt="Hero" />
        </div>
        <section className="sign-up">
          <form className="sign-up__form" action="#">
            <h1>
              Change Password
            </h1>
            <Row>
              <Form.Group as={Col} md="12" className="add_sponsor_field mb-3 eye_password relative">
                <Form.Label> Current Password <span>*</span> </Form.Label>
                <Form.Control
                  required
                  type={showCurrentPassword ? "text" : "password"}
                  placeholder="Enter Old Password"
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  value={currentPassword}
                />
                <i onClick={toggleCurrentPasswordVisibility}>
                  {showCurrentPassword ? <i className="fa-solid fa-eye-slash"></i> : <i class='fa-solid fa-eye'></i>}
                </i>
              </Form.Group>
              <Form.Group as={Col} md="12" className="add_sponsor_field mb-3 eye_password relative">
                <Form.Label>New Password <span>*</span></Form.Label>
                <Form.Control
                  required
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter New Password"
                  onChange={(e) => { setNewPassword(e.target.value); validatePassword(e.target.value) }}
                  value={newPassword}
                />
                <i onClick={togglePasswordVisibility}>
                  {showPassword ? <i className="fa-solid fa-eye-slash"></i> : <i class='fa-solid fa-eye'></i>}
                </i>
                {!newPassword ?
                  "" :
                  error ? (
                    <span style={{ fontSize: 10, color: "red", fontWeight: 600 }}>
                      Password Must have 1 Capital Letter, 1 Small Letter, 1 Special
                      Character with 8 Character
                    </span>
                  ) : (
                    <span style={{ fontSize: 10, color: "green", fontWeight: 600 }}>
                      Strong Password
                    </span>
                  )}
              </Form.Group>
              <Form.Group as={Col} md="12" className="add_sponsor_field mb-3 eye_password relative">
                <Form.Label>Confirm Password <span>*</span></Form.Label>
                <Form.Control
                  required
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Enter Password"
                  onChange={(e) => setRepeatPassword(e.target.value)}
                  value={repeatPassword} />
                <i onClick={toggleConfirmPasswordVisibility}>
                  {showConfirmPassword ? <i className="fa-solid fa-eye-slash"></i> : <i class='fa-solid fa-eye'></i>}
                </i>
                {newPassword === repeatPassword ?
                  newPassword?.length === 0 ? "" :
                    <span style={{ fontSize: 10, color: "green", fontWeight: 600 }}>
                      Password Match
                    </span>
                  :
                  <span style={{ fontSize: 10, color: "red", fontWeight: 600 }}>
                    Password Not Match
                  </span>
                }
              </Form.Group>
            </Row>
            <div className="hstack gap-3 justify-content-end pt-3 footer_button">
              <Button className="w-50 close_circle" onClick={resetInputField}>Reset</Button>
              {error || newPassword !== repeatPassword ? (
                <Button className="w-50">{"Change Password"}</Button>
              ) : (
                <Button className="w-50" onClick={changePassword}>{"Change Password"}</Button>
              )}
            </div>
            <p className="message mt-3">
              <NavLink onClick={logout}>Back to Login</NavLink>
            </p>
          </form>
        </section>
      </Container>
    </>
  );
};

export default ChangePassword;
