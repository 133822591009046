import React from "react";
import { Col, Row } from "react-bootstrap";

const HomeWidget = ({ roleType, totalReviewer, totalOrganization, totalConsultant, totalDeveloper, totalAIUser, totalSubadmin, totalLogs, totalFailedLogs, AIUserPermission, ConsultantPermission, DeveloperPermission, OrganizationPermission, permission }) => {
  return (
    <>
      <Row>
        {(roleType === "organization") &&
          <Col xl={3} lg={3} md={6} className="mb-20">
            <div className="box-shadow-min border-radius-3 height-100-p widget-style1">
              <div className="d-flex flex-wrap align-items-center">
                <div className="circle-icon">
                  <div className="icon border-radius-100 font-24 text-theme">
                    <i className="fa-solid fa-users"></i>
                  </div>
                </div>
                <div className="widget-data">
                  <div className="weight-800 font-18">{totalReviewer || 0}</div>
                  <div className="weight-500">Total Reviewer</div>
                </div>
              </div>
            </div>
          </Col>
        }
        {(OrganizationPermission && (permission?.dashboardModuleData || roleType === "admin")) &&
          <Col xl={3} lg={3} md={6} className="mb-20">
            <div className="box-shadow-min border-radius-3 height-100-p widget-style1">
              <div className="d-flex flex-wrap align-items-center">
                <div className="circle-icon">
                  <div className="icon border-radius-100 font-24 text-theme">
                    <i className="fa-solid fa-users"></i>
                  </div>
                </div>
                <div className="widget-data">
                  <div className="weight-800 font-18">{totalOrganization || 0}</div>
                  <div className="weight-500">Total Organization</div>
                </div>
              </div>
            </div>
          </Col>
        }
        {(ConsultantPermission && (permission?.dashboardModuleData || roleType === "admin")) &&
          <Col xl={3} lg={3} md={6} className="mb-20">
            <div className="box-shadow-min border-radius-3 height-100-p widget-style1">
              <div className="d-flex flex-wrap align-items-center">
                <div className="circle-icon">
                  <div className="icon border-radius-100 font-24 text-theme">
                    <i className="fa-solid fa-users"></i>
                  </div>
                </div>
                <div className="widget-data">
                  <div className="weight-800 font-18">{totalConsultant || 0}</div>
                  <div className="weight-500">Total Consultant</div>
                </div>
              </div>
            </div>
          </Col>
        }
        {(DeveloperPermission && (permission?.dashboardModuleData || roleType === "admin")) &&
          <Col xl={3} lg={3} md={6} className="mb-20">
            <div className="box-shadow-min border-radius-3 height-100-p widget-style1">
              <div className="d-flex flex-wrap align-items-center">
                <div className="circle-icon">
                  <div className="icon border-radius-100 font-24 text-theme">
                    <i className="fa-solid fa-users"></i>
                  </div>
                </div>
                <div className="widget-data">
                  <div className="weight-800 font-18">{totalDeveloper || 0}</div>
                  <div className="weight-500">Total Developer</div>
                </div>
              </div>
            </div>
          </Col>
        }
        {(AIUserPermission && (permission?.dashboardModuleData || roleType === "admin")) &&
          <Col xl={3} lg={3} md={6} className="mb-20">
            <div className="box-shadow-min border-radius-3 height-100-p widget-style1">
              <div className="d-flex flex-wrap align-items-center">
                <div className="circle-icon">
                  <div className="icon border-radius-100 font-24 text-theme">
                    <i className="fa-solid fa-users"></i>
                  </div>
                </div>
                <div className="widget-data">
                  <div className="weight-800 font-18">{totalAIUser || 0}</div>
                  <div className="weight-500">Total AI User</div>
                </div>
              </div>
            </div>
          </Col>
        }
        {roleType === "admin" &&
          <Col xl={3} lg={3} md={6} className="mb-20">
            <div className="box-shadow-min border-radius-3 height-100-p widget-style1">
              <div className="d-flex flex-wrap align-items-center">
                <div className="circle-icon">
                  <div className="icon border-radius-100 font-24 text-theme">
                    <i className="fa-solid fa-users"></i>
                  </div>
                </div>
                <div className="widget-data">
                  <div className="weight-800 font-18">{totalSubadmin || 0}</div>
                  <div className="weight-500">Total Subadmin</div>
                </div>
              </div>
            </div>
          </Col>
        }
        <Col xl={3} lg={3} md={6} className="mb-20">
          <div className="box-shadow-min border-radius-3 height-100-p widget-style1">
            <div className="d-flex flex-wrap align-items-center">
              <div className="circle-icon">
                <div className="icon border-radius-100 font-24 text-theme">
                  <i className="fa-solid fa-clock-rotate-left"></i>
                </div>
              </div>
              <div className="widget-data">
                <div className="weight-800 font-18">{totalLogs || 0}</div>
                <div className="weight-500">Total Logs</div>
              </div>
            </div>
          </div>
        </Col>
        <Col xl={3} lg={3} md={6} className="mb-20">
          <div className="box-shadow-min border-radius-3 height-100-p widget-style1">
            <div className="d-flex flex-wrap align-items-center">
              <div className="circle-icon">
                <div className="icon border-radius-100 font-24 text-theme">
                  <i className="fa-solid fa-clock-rotate-left"></i>
                </div>
              </div>
              <div className="widget-data">
                <div className="weight-800 font-18">{totalFailedLogs || 0}</div>
                <div className="weight-500">Total Failed Logs</div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default HomeWidget;
