import React from "react";
import { Modal } from "react-bootstrap";

const ViewOrderDetails = ({ Show, Hide, Size, Title}) => {
  return (
    <Modal
      show={Show}
      onHide={Hide}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={()=>Hide()} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <>View Details Data</>
      </Modal.Body>
    </Modal>
  );
};

export default ViewOrderDetails;
