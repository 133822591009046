import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import UseToggle from "../../../Hooks/useToggle";
import Moment from "react-moment";
import UpdateCategoryModal from "./UpdateCategoryModal";
import Swal from "sweetalert2";
import { packageServices } from "../../../../APIServices/Editor/packageServices";

const PackageCategoriesTableData = ({
  packageCategoryList,
  getPackageCategoryList,
  onMouseDown,
  tableRef,
}) => {
  const {
    toggle,
    setToggle,
  } = UseToggle();

  const ref = useRef(null);
  const [action, setAction] = useState("");
  const [id, setId] = useState("");
  const [category, setCategory] = useState("");

  const handleUpdateCategory = async (e, item) => {
    setCategory(item?.category);
    setId(item?._id);
    setToggle();
  };

  const handleDelete = async (e, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete this Category?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await packageServices.deletePackageCategoryData(id);
        if (data?.statusCode === 200) {
          getPackageCategoryList()
        }
      }
    });
  }

  const handleUpdate = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Update this Category?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {}
        userData.id = id
        userData.category = category
        let data = await packageServices.updatePackageCategoryData(userData);
        if (data?.statusCode === 200) {
          getPackageCategoryList()
        }
      }
    });
  }

  const handleStatusChange = async (id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Change Status this Category?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {}
        userData.id = id
        userData.status = status
        let data = await packageServices.changePackageCategoryStatus(userData);
        if (data?.statusCode === 200) {
          getPackageCategoryList()
        }
      }
    });
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setAction("");
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  return (
    <>
      <div className="table-container overflow-auto">
        <Table id="resizable-table" className="custom-table m-0" ref={tableRef}>
          <thead>
            <tr>
              <th
                onMouseDown={(e) => onMouseDown(e, 0)}
                style={{ width: "40px" }}
              >
                Sr.
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 1)}>Category</th>
              <th className="text-center" onMouseDown={(e) => onMouseDown(e, 2)}
                style={{ width: "140px" }}>Status</th>
              <th
                onMouseDown={(e) => onMouseDown(e, 3)}
                style={{ width: "200px" }}
              >
                Modified Date | Time
              </th>
              <th
                onMouseDown={(e) => onMouseDown(e, 4)}
                style={{ width: 70 }}
                className="text-center"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody ref={ref}>
            {packageCategoryList?.length > 0 &&
              packageCategoryList?.map((item, index) => (
                <tr className="relative">
                  <td>{index + 1}</td>
                  <td>{item.category}</td>
                  <td className="status_select">
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) => handleStatusChange(item._id, e.target.value)}
                      className={
                        item.status === "active"
                          ? "active_status"
                          : "inactive_status"
                      }
                    >
                      <option
                        selected={item.status === "active"}
                        value="active"
                      >
                        {" "}
                        Active{" "}
                      </option>
                      <option
                        selected={item.status === "inactive"}
                        value="inactive"
                      >
                        Inactive
                      </option>
                    </Form.Select>
                  </td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm" withTitle>
                      {item.updatedAt}
                    </Moment>
                  </td>
                  <td className="text-center">
                    <div className="text-center action__buttons">
                      {action === item._id ? (
                        <>
                          <div className="saperate_action_button">
                            <Button
                              onClick={(e) => handleUpdateCategory(e, item)}
                              title="Update category"
                              className="rounded-circle"
                            >
                              <i className="fa-solid fa-edit"></i>
                            </Button>
                            <Button
                              onClick={(e) => handleDelete(e, item.categoryId)}
                              title="Delete Category"
                              className="rounded-circle close_circle"
                            >
                              <i className="fa-solid fa-trash-can"></i>
                            </Button>
                          </div>
                          <Button
                            onClick={() => setAction("")}
                            title="Close Action"
                            className="rounded-circle close_circle"
                          >
                            <i className="fa-solid fa-xmark"></i>
                          </Button>
                        </>
                      ) : (
                        <Button
                          onClick={() => setAction(item._id)}
                          title="Open Action"
                          className="rounded-circle"
                        >
                          <i className="fa-solid fa-bars"></i>
                        </Button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {packageCategoryList?.length > 0 ? (
          ""
        ) : (
          <div className="noRecordFound">Record not found</div>
        )}
      </div>
      <UpdateCategoryModal
        Show={!toggle}
        Hide={setToggle}
        Size={"md"}
        setCategory={setCategory}
        handleUpdate={handleUpdate}
        category={category}
        Title={"Update Category"}
        Submit="Update"
      />
    </>
  );
};

export default PackageCategoriesTableData;
