import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { sdtmServices } from "../../../APIServices/CDISC/sdtmServices";

const UpdateTemplate = ({
  Show,
  Hide,
  Size,
  Title,
  Submit,
  getDataTemplates,
  updateTemp,
}) => {
  const [templateName, setTemplateName] = useState("");

  const updateDataTemplates = async () => {
    let userData = {};
    userData.id = updateTemp?._id;
    userData.templateName = templateName;
    let data = await sdtmServices.updateDataTemplates(userData);
    if (data?.statusCode === 200) {
      Hide();
      getDataTemplates();
    }
  };

  const cleanInputBox = () => {
    Hide();
    setTemplateName("");
  };

  useEffect(() => {
    setTemplateName(updateTemp.templateName);
  }, [updateTemp]);

  return (
    <div>
      <Modal
        show={Show}
        onHide={Hide}
        size={Size}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>{Title}</Modal.Title>
          <div onClick={cleanInputBox} className="close_modal" title="Close">
            X
          </div>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
              <Form.Label>
                Title <span>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Template Name"
                onChange={(e) => {
                  setTemplateName(e.target.value);
                }}
                value={templateName}
                required
              />
            </Form.Group>
          </Row>
          <div className="text-end border-top pt-3 footer_button">
            <Button
              onClick={() => updateDataTemplates()}
              disabled={!templateName}
            >
              {Submit}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UpdateTemplate;
