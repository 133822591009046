import React from "react";
import { Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const InviteConfirmation = () => {
  return (
    <>
      <div className="invite__form_message">
        <p>Confirmation</p>
        <div className="Success__taag mb-3" >
          <i style={{color: "green"}} className="fa-solid fa-circle-check" alt="Registration Successful"></i>
        </div>
        <span>You have been successfully registered. </span>
        <span> Please wait for "Administrator" Approval. </span>
        <span> After that check your mail, then you are able to login </span>
        <div className="hstack gap-3 justify-content-center mt-4 footer_button">
          <NavLink to={'/login'}>
            <Button>Login</Button>
          </NavLink>
        </div>
      </div >
    </>
  );
};

export default InviteConfirmation;
