import config from "../../config/config.json";
import { headers } from "../../APIHelpers/Headers";
import saveAs from "file-saver";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../../APIHelpers/Responses";
import axios from "axios";

export const libraryFolderAndDataServices = {
  // search Library Folder,
  searchLibraryFolder,

  // update Library Folder Data,
  updateLibraryFolderData,

  // change Status Library Folder Data,
  changeStatusLibraryFolderData,

  // delete Library Folder And Data,
  deleteLibraryFolderAndData,

  // get Library Folder Data,
  getLibraryFolderData,

  // get Library Data By FolderId,
  getLibraryDataByFolderId,

  //Library Column Hide & Show
  libraryHideShowColumn,

  // upload File in Library,
  uploadFileinLibrary,

  // Duplicate Library Folder And Data
  duplicateLibraryFolderAndData,

  // export Library File Multi Format
  exportLibraryFileMultiFormat,
};


// export Library File Multi Format
async function exportLibraryFileMultiFormat(userData) {
  const response = await fetch(
    `${config.API_URL}exportLibraryFileMultiFormat`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  if (data.statusCode === 400) {
    await handleResponse(data);
    return data;
  } else {
    saveAs(url, data?.data);
    return data;
  }
}

// Duplicate Library Folder And Data
async function duplicateLibraryFolderAndData(userData) {
  const response = await fetch(
    `${config.API_URL}duplicateLibraryFolderAndData`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Duplicate Library Folder And Data
async function libraryHideShowColumn(userData) {
  const response = await fetch(
    `${config.API_URL}libraryHideShowColumn`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

//  upload File in Library,
async function uploadFileinLibrary(formData) {
  const response = await axios.post(
    `${config.API_URL}uploadFileinLibrary`,
    formData
  );
  const data = response?.data;
  await handleResponse(data);
  return data;
}

// get Library Folder Data,
async function getLibraryFolderData(type) {
  const response = await fetch(
    `${config.API_URL}getLibraryFolderData/${type}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// delete Library Folder And Data,
async function deleteLibraryFolderAndData(id) {
  const response = await fetch(
    `${config.API_URL}deleteLibraryFolderAndData/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

//  change Status Library Folder Data,
async function changeStatusLibraryFolderData(userData) {
  const response = await fetch(
    `${config.API_URL}changeStatusLibraryFolderData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

//  update Library Folder Data,
async function updateLibraryFolderData(userData) {
  const response = await fetch(
    `${config.API_URL}updateLibraryFolderData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// search Library Folder,
async function searchLibraryFolder(userData) {
  const response = await fetch(
    `${config.API_URL}searchLibraryFolder`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

// get Library Data By FolderId,
async function getLibraryDataByFolderId(libraryId) {
  const response = await fetch(
    `${config.API_URL}getLibraryDataByFolderId/${libraryId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
