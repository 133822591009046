import React from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

const UpdateVariableMethodModal = ({
  Show,
  Hide,
  Size,
  Title,
  description,
  setDescription,
  handleUpdate,
  mathodId,
  setMethodId,
  Submit,
}) => {
  return (
    <Modal
      show={Show}
      onHide={Hide}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={() => Hide()} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>
              Add Variable <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              value={mathodId}
              onChange={(e) => setMethodId(e.target.value)}
              placeholder="Enter method Name"
            />
          </Form.Group>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>
              Description <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              required
              as="textarea"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter Description"
            />
          </Form.Group>
        </Row>
        <div className="hstack justify-content-end border-top pt-3 footer_button">
          <Button
            onClick={() => {
              handleUpdate();
              Hide();
            }}
          >
            {Submit}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateVariableMethodModal;
