import React, { useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";

const ProcedureValidationOutput = ({
  Show,
  Hide,
  Size,
  Title,
  output,
  baseModal,
}) => {
  const [fontSize, setFontSize] = useState(12);

  function fontIncrease() {
    setFontSize((prevFontSize) => prevFontSize + 1);
  }

  function fontDecrease() {
    setFontSize((prevFontSize) => Math.max(prevFontSize - 1, 5));
  }

  return (
    <>
      <Modal
        show={Show}
        onHide={Hide}
        size={Size}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>{Title}</Modal.Title>
          <div
            onClick={() => {
              Hide();
              baseModal();
            }}
            className="close_modal"
            title="Close"
          >
            X
          </div>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="OutputToolbar border-bottom">
            <div className="OutputToolbar-Left px-2 hstack">
              <i
                className="p-2 fa-solid fa-square-plus"
                onClick={fontIncrease}
                title="Increase Font Size"
              ></i>
              <div className="vr"></div>
              <i
                className="p-2 fa-solid fa-square-minus"
                onClick={fontDecrease}
                title="Decrease Font Size"
              ></i>
              <div className="vr"></div>
              <Dropdown style={{ marginTop: "-4px" }}>
                <Dropdown.Toggle
                  variant="none"
                  id="dropdown-basic"
                  className="m-0 p-1 px-2"
                >
                  <i
                    className="fa-solid fa-download"
                    title="Download Generated Data"
                  ></i>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    href="#"
                    title="Download result as an HTML file"
                  >
                    <i className="fa-solid fa-file-code"></i> HTML
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    title="Download result as an PDF file"
                  >
                    <i className="fa-solid fa-file-pdf"></i> PDF
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div
            className="htmlOutputHeight overflow-auto"
            style={{ maxHeight: "calc(100vh - 150px)", fontSize: fontSize }}
            dangerouslySetInnerHTML={{ __html: output }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProcedureValidationOutput;
