import config from "../../config/config.json";
import { headers } from "../../APIHelpers/Headers";
import saveAs from "file-saver";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../../APIHelpers/Responses";

export const creditPriceServices = {
  //Credit Price
  updateCreditPriceData,
  updateTokenCreditData,
  creditPrice,

  // Billing Transactions
  getBillingTransections,

  // Export Billing Transections
  exportBillingTransectionsMultiFormat,

  // export Credit Details of User MultiFormat
  exportCreditDetailsofUserMultiFormat,

  // Billing Transections for Invoice,
  getBillingTransectionsforInvoice,

  // Credit Transection By User
  getCreditDetailsofUser,

  // search Credit Details ForUser
  searchCreditDetailsForUser,

  // send Reward of User
  sendRewardofUser,

  // update GST Price Data
  updateGSTPriceData,

  // Billing Transection For Week Graph
  getBillingTransectionForWeekGraph,

  // Billing Transection For Year Graph
  getBillingTransectionForYearGraph,
};

// Billing Transection For Week Graph
async function getBillingTransectionForWeekGraph() {
  const response = await fetch(
    `${config.API_URL}getBillingTransectionForWeekGraph`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Billing Transaction For Year Graph
async function getBillingTransectionForYearGraph(year) {
  const response = await fetch(
    `${config.API_URL}getBillingTransectionForYearGraph/${year}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// update GST Price Data
async function updateGSTPriceData(userData) {
  const response = await fetch(
    `${config.API_URL}updateGSTPriceData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// update Token
async function updateTokenCreditData(userData) {
  const response = await fetch(
    `${config.API_URL}updateTokenCreditData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// send Reward of User
async function sendRewardofUser(userData) {
  const response = await fetch(
    `${config.API_URL}sendRewardofUser`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// search Credit Details ForUser
async function searchCreditDetailsForUser(userData) {
  const response = await fetch(
    `${config.API_URL}searchCreditDetailsForUser`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Update Price
async function updateCreditPriceData(userData) {
  const response = await fetch(
    `${config.API_URL}updateCreditPriceData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Credit Price
async function creditPrice() {
  const response = await fetch(
    `${config.API_URL}creditPrice`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Credit Price
async function getCreditDetailsofUser() {
  const response = await fetch(
    `${config.API_URL}getCreditDetailsofUser`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Billing Transections for Invoice,
async function getBillingTransectionsforInvoice(transactionId) {
  const response = await fetch(
    `${config.API_URL}getBillingTransectionsforInvoice/${transactionId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Billing Transactions
async function getBillingTransections(type) {
  const response = await fetch(
    `${config.API_URL}getBillingTransections/${type}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Export Billing Transections
async function exportBillingTransectionsMultiFormat(userData) {
  const response = await fetch(
    `${config.API_URL}exportBillingTransectionsMultiFormat`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  if (data.statusCode === 400) {
    await handleResponse(data);
    return data;
  } else {
    saveAs(url, data?.data);
    return data;
  }
}

// Export Billing Transections
async function exportCreditDetailsofUserMultiFormat(userData) {
  const response = await fetch(
    `${config.API_URL}exportCreditDetailsofUserMultiFormat`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  if (data.statusCode === 400) {
    await handleResponse(data);
    return data;
  } else {
    saveAs(url, data?.data);
    return data;
  }
}
