import React, { useEffect, useState } from "react";
import AddNewReviewerModal from "./AddNewReviewerModal";
import UseToggle from "../Hooks/useToggle";
import Pagination from "../Hooks/Pagination";
import ExportReviewerModal from "./ExportReviewerModal";
import TableHeader from "../../component/TableHeader";
import ReviewerTableData from "./ReviewerTableData";
import { userServices } from "../../APIServices/usersServices";
import ReviewersInvitation from "./ReviewerInvitation/ReviewersInvitation";
import { invitedDataServices } from "../../APIServices/invitedDataServices";

const Reviewers = ({ onMouseDown, tableRef }) => {
  const { toggle, setToggle, toggle1, setToggle1, toggle2, setToggle2 } =
    UseToggle();

  const [reviewerList, setReviewerList] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [invitedList, setInvitedList] = useState([]);

  const getInvitation = async () => {
    let data = await invitedDataServices.getInvitation("reviewer");
    setInvitedList(data?.data);
  };

  const getUserData = async () => {
    let data = await userServices.getUserData("reviewer");
    setReviewerList(data?.data);
  };

  const itemsPerPage = 5;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = reviewerList?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(reviewerList?.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % reviewerList?.length;
    setItemOffset(newOffset);
  };

  const handleSearch = async (searchValue) => {
    let userData = {};
    userData.searchData = searchValue || searchData;
    userData.roleType = "reviewer";
    let data = await userServices.searchUserData(userData);
    setReviewerList(data?.data);
  };

  const openInvitationHistory = () => {
    setToggle2();
    getInvitation();
  };

  useEffect(() => {
    getUserData();
  }, []);
  return (
    <>
      <TableHeader
        Search
        handleSearch={handleSearch}
        setSearchData={setSearchData}
        SearchReverseData={getUserData}
        AddNew
        handleAddNew={setToggle}
        titleAddNew="Add New Reviewer"
        InviteHistory
        handleInviteHistory={openInvitationHistory}
        titleInviteHistory="Invitation History"
        Export
        handleExport={setToggle1}
        titleExport="Export Reviewers Data"
      />
      <ReviewerTableData
        onMouseDown={onMouseDown}
        tableRef={tableRef}
        reviewerList={currentItems}
        getUserData={getUserData}
      />
      <Pagination
        Result={reviewerList}
        itemsPerPage={itemsPerPage}
        handlePageClick={handlePageClick}
        pageCount={pageCount}
      />
      <AddNewReviewerModal
        Show={!toggle}
        Hide={setToggle}
        Size={"lg"}
        Title={"Add New Reviewer"}
        getUserData={getUserData}
        Submit={"Submit"}
      />
      <ReviewersInvitation
        onMouseDown={onMouseDown}
        tableRef={tableRef}
        Show={!toggle2}
        Hide={setToggle2}
        Size={"lg"}
        Title={"Reviewers Invitation History"}
        invitedList={invitedList}
        setInvitedList={setInvitedList}
        getInvitation={getInvitation}
      />
      <ExportReviewerModal
        Show={!toggle1}
        Hide={setToggle1}
        Size={"md"}
        Title={"Export Reviewers"}
        Submit={"Export"}
      />
    </>
  );
};

export default Reviewers;
