import React, { useEffect, useState } from "react";
import HomeWidget from "./HomeWidget";
import { Col, Row } from "react-bootstrap";
import {
  TotalUsersChart,
  TotalSubadminChart,
  TotalLogsChart,
} from "../component/Chart";
import { userServices } from "../APIServices/usersServices";
import { loginActivitiesServices } from "../APIServices/loginActivitiesServices";

const Dashboard = ({ themeColor, permission, roleType, AIUserPermission, ConsultantPermission, DeveloperPermission, OrganizationPermission }) => {

  const [subadminChartData, setSubadminChartData] = useState([]);
  const [totalSubadmin, setTotalSubadmin] = useState(0);
  const [consultantChartData, setConsultantChartData] = useState([]);
  const [developerChartData, setDeveloperChartData] = useState([]);
  const [organizationChartData, setOrganizationChartData] = useState([]);
  const [aiUserChartData, setAIUserChartData] = useState([]);
  const [reviewerChartData, setReviewerChartData] = useState([]);
  const [totalReviewer, setTotalReviewer] = useState(0);
  const [totalConsultant, setTotalConsultant] = useState(0);
  const [totalDeveloper, setTotalDeveloper] = useState(0);
  const [totalAIUser, setTotalAIUser] = useState(0);
  const [totalChartLogs, setTotalChartLogs] = useState([]);
  const [totalOrganization, setTotalOrganization] = useState(0);
  const [totalLogs, setTotalLogs] = useState(0);
  const [totalFailedLogs, setTotalFailedLogs] = useState(0);

  const getChartOrganizationData = async () => {
    let data = await userServices.getChartUserData("organization")
    setOrganizationChartData(data?.totalChartData)
    setTotalOrganization(data?.totalUserData)
  }

  const getChartReviewerData = async () => {
    let data = await userServices.getChartUserData("reviewer")
    setReviewerChartData(data?.totalChartData)
    setTotalReviewer(data?.totalUserData)
  }

  const getChartConsultantData = async () => {
    let data = await userServices.getChartUserData("consultant")
    setConsultantChartData(data?.totalChartData)
    setTotalConsultant(data?.totalUserData)
  }

  const getChartDeveloperData = async () => {
    let data = await userServices.getChartUserData("developer")
    setDeveloperChartData(data?.totalChartData)
    setTotalDeveloper(data?.totalUserData)
  }

  const getChartAIUserData = async () => {
    let data = await userServices.getChartUserData("aiuser")
    setAIUserChartData(data?.totalChartData)
    setTotalAIUser(data?.totalUserData)
  }

  const getChartSubadminData = async () => {
    let data = await userServices.getChartUserData("subadmin")
    setSubadminChartData(data?.totalChartData)
    setTotalSubadmin(data?.totalUserData)
  }

  const getTotalFailedLoginRecord = async () => {
    let data = await loginActivitiesServices.getTotalFailedLoginRecord()
    setTotalFailedLogs(data?.data)
  }

  const getTotalChartLoginHistoryRecord = async () => {
    let data = await loginActivitiesServices.getTotalChartLoginHistoryRecord()
    setTotalChartLogs(data?.chartData)
    setTotalLogs(data?.totalData)
  }

  useEffect(() => {
    getChartReviewerData();
    getChartOrganizationData();
    getChartConsultantData();
    getChartDeveloperData();
    getChartAIUserData();
    getChartSubadminData();
    getTotalFailedLoginRecord();
    getTotalChartLoginHistoryRecord();
  }, []);

  return (
    <>
      <HomeWidget
        permission={permission}
        roleType={roleType}
        totalConsultant={totalConsultant}
        totalOrganization={totalOrganization}
        totalDeveloper={totalDeveloper}
        totalSubadmin={totalSubadmin}
        totalAIUser={totalAIUser}
        totalLogs={totalLogs}
        totalFailedLogs={totalFailedLogs}
        totalReviewer={totalReviewer}
        AIUserPermission={AIUserPermission}
        ConsultantPermission={ConsultantPermission}
        DeveloperPermission={DeveloperPermission}
        OrganizationPermission={OrganizationPermission}
      />

      <Row>
        {(totalReviewer > 0 && roleType === "organization") &&
          <Col xl={3} lg={3} md={6} className="mb-20">
            <div className="widget-style1 box-shadow-min border-radius-3 hstack justify-content-center">
              <TotalUsersChart
                title="Reviewer"
                themeColor={themeColor}
                userChartData={reviewerChartData}
              />
            </div>
          </Col>
        }
        {totalOrganization > 0 && (OrganizationPermission && (permission?.dashboardModuleData || roleType === "admin")) &&
          <Col xl={3} lg={3} md={6} className="mb-20">
            <div className="widget-style1 box-shadow-min border-radius-3 hstack justify-content-center">
              <TotalUsersChart
                title="Organization"
                themeColor={themeColor}
                userChartData={organizationChartData}
              />
            </div>
          </Col>
        }
        {totalConsultant > 0 && (ConsultantPermission && (permission?.dashboardModuleData || roleType === "admin")) &&
          <Col xl={3} lg={3} md={6} className="mb-20">
            <div className="widget-style1 box-shadow-min border-radius-3 hstack justify-content-center">
              <TotalUsersChart
                title="Consultant"
                themeColor={themeColor}
                userChartData={consultantChartData}
              />
            </div>
          </Col>
        }
        {totalDeveloper > 0 && (DeveloperPermission && (permission?.dashboardModuleData || roleType === "admin")) &&
          <Col xl={3} lg={3} md={6} className="mb-20">
            <div className="widget-style1 box-shadow-min border-radius-3 hstack justify-content-center">
              <TotalUsersChart
                title="Developer"
                themeColor={themeColor}
                userChartData={developerChartData}
              />
            </div>
          </Col>
        }
        {totalAIUser > 0 && (AIUserPermission && (permission?.dashboardModuleData || roleType === "admin")) &&
          <Col xl={3} lg={3} md={6} className="mb-20">
            <div className="widget-style1 box-shadow-min border-radius-3 hstack justify-content-center">
              <TotalUsersChart
                title="AI User"
                themeColor={themeColor}
                userChartData={aiUserChartData}
              />
            </div>
          </Col>
        }
        {totalSubadmin > 0 && roleType === "admin" &&
          <Col xl={3} lg={3} md={6} className="mb-20">
            <div className="widget-style1 box-shadow-min border-radius-3 hstack justify-content-center">
              <TotalSubadminChart
                themeColor={themeColor}
                subadminChartData={subadminChartData}
              />
            </div>
          </Col>
        }
        <Col xl={3} lg={3} md={6} className="mb-20">
          <div className="widget-style1 box-shadow-min border-radius-3 hstack justify-content-center">
            <TotalLogsChart
              themeColor={themeColor}
              totalChartLogs={totalChartLogs}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
