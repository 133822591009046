import config from "../config/config.json";
import saveAs from "file-saver";
import { headers } from "../APIHelpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody
} from "../APIHelpers/Responses";

export const designationPermissionServices = {
  //Designation
  searchDesignation,
  addNewDesignation,
  updateDesignations,
  changeDesignationsStatus,
  getActiveDesignations,
  getDesignationsList,
  deleteDesignations,
  getUserByDesignations,
  exportDesignationDataMultiFormat,

  //Permission
  searchPermission,
  addNewPermission,
  updatePermissions,
  assignPermissionLists,
  getCurrentUserAssignedPermission,
  getAssignedPermission,
  changePermissionStatus,
  getActivePermissions,
  getPermissionsList,
  deletePermissions,
  exportPermissionDataMultiFormat
};

// Add New Designation
async function searchDesignation(userData) {
  const response = await fetch(
    `${config.API_URL}searchDesignation`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Add New Designation
async function addNewDesignation(userData) {
  const response = await fetch(
    `${config.API_URL}addNewDesignation`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Update Designation
async function updateDesignations(userData) {
  const response = await fetch(
    `${config.API_URL}updateDesignations`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

//  Change Designations Status
async function changeDesignationsStatus(userData) {
  const response = await fetch(
    `${config.API_URL}changeDesignationsStatus`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Get All Active Designation
async function getActiveDesignations() {
  const response = await fetch(
    `${config.API_URL}getActiveDesignations`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get All Designation List for Table
async function getDesignationsList() {
  const response = await fetch(
    `${config.API_URL}getDesignationsList`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Delete Designation
async function deleteDesignations(id) {
  const response = await fetch(
    `${config.API_URL}deleteDesignations/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Get All User By Designation
async function getUserByDesignations(id, role) {
  const response = await fetch(
    `${config.API_URL}getUserByDesignations/${id}/${role}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Export Designation in Multiformat
async function exportDesignationDataMultiFormat(userData) {
  const response = await fetch(
    `${config.API_URL}exportDesignationDataMultiFormat`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  if (data.statusCode === 400) {
    await handleResponse(data);
    return data;
  } else {
    saveAs(url, data?.data);
    return data;
  }
}

// Add New Permission
async function searchPermission(userData) {
  const response = await fetch(
    `${config.API_URL}searchPermission`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Add New Permission
async function addNewPermission(userData) {
  const response = await fetch(
    `${config.API_URL}addNewPermission`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data)
  return data;
}

// Update Permission
async function updatePermissions(userData) {
  const response = await fetch(
    `${config.API_URL}updatePermissions`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Assign Permission Lists
async function assignPermissionLists(userData) {
  const response = await fetch(
    `${config.API_URL}assignPermissionLists`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Get Current User Assign Permission Lists
async function getCurrentUserAssignedPermission() {
  const response = await fetch(
    `${config.API_URL}getCurrentUserAssignedPermission`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Assign Permission Lists
async function getAssignedPermission(id) {
  const response = await fetch(
    `${config.API_URL}getAssignedPermission/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//  Change Permission Status
async function changePermissionStatus(userData) {
  const response = await fetch(
    `${config.API_URL}changePermissionStatus`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Get All Active Permission
async function getActivePermissions() {
  const response = await fetch(
    `${config.API_URL}getActivePermissions`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get All Permission List for Table
async function getPermissionsList() {
  const response = await fetch(
    `${config.API_URL}getPermissionsList`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Delete Permission
async function deletePermissions(id) {
  const response = await fetch(
    `${config.API_URL}deletePermissions/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Export Permission in Multiformat
async function exportPermissionDataMultiFormat(userData) {
  const response = await fetch(
    `${config.API_URL}exportPermissionDataMultiFormat`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  if (data.statusCode === 400) {
    await handleResponse(data);
    return data;
  } else {
    saveAs(url, data?.data);
    return data;
  }
}