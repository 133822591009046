import React, { useState } from "react";
import "./Auth.css";
import { Col, Container, Form, Row, Button } from "react-bootstrap";
import Login_Image from "../../img/LoginImage.webp";
import { NavLink } from "react-router-dom";
import { authServices } from "../../APIServices/authServices";
const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validated, setValidated] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() !== false) {
      e.stopPropagation();
      let userData = {}
      userData.email = email
      userData.password = password
      userData.roles = ["admin", "subadmin", "organization", "reviewer"]
      await authServices.login(userData);
    }
    setValidated(true);
  };

  return (
    <>
      <Container fluid className="p-0 section__Module">
        <div className="hero">
          <div className="imglogin">
            <img
              className="hero-img"
              src={Login_Image}
              alt="Hero"
            />
          </div>
        </div>
        <section className="sign-up">
          <form className="sign-up__form" action="#">
            <h1>
              Login
            </h1>
            <Row>
              <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
                <Form.Label>Email <span>*</span></Form.Label>
                <Form.Control
                  required
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter Email ID" />
              </Form.Group>
              <Form.Group as={Col} md="12" className="add_sponsor_field mb-3 eye_password relative">
                <Form.Label>Password <span>*</span></Form.Label>
                <Form.Control
                  required
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter Password" />
                <i onClick={togglePasswordVisibility}>
                  {showPassword ? <i className="fa-solid fa-eye-slash"></i> : <i class='fa-solid fa-eye'></i>}
                </i>
              </Form.Group>
            </Row>
            <div className="footer_button">
              <Button className="mb-3 w-100"
                disabled={!email || !password}
                validated={validated}
                onClick={(e) => handleSubmit(e)}
              >
                Login
              </Button>
            </div>
            <div className="d-flex justify-content-between">
              <p className="message"></p>
              <p className="message"><NavLink to="/forgetPassword">Forgot your password?</NavLink></p>
            </div>
          </form>
        </section>
      </Container>
    </>
  );
};

export default Login;
