import React, { useRef } from "react";
import { Button, Form, Table } from "react-bootstrap";
import Moment from "react-moment";
import Swal from "sweetalert2";

const TransectionTableData = ({ transectionList, onMouseDown, tableRef }) => {
  const textRef = useRef(null);

  function CopyToClipboard(data) {
    navigator.clipboard
      .writeText(data)
      .then(() => {
        alert("Data copied to clipboard successfully!");
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
      });
  }

  const handleStatusChange = async (id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Change Status this Transection?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = id;
        userData.status = status;
        // let data = await documentAIServices.changeDcoumentCaseStatus(userData);
        // if (data?.statusCode === 200) {
        //   getAlltransectionList()
        // }
      }
    });
  };
  return (
    <>
      <div className="table-container overflow-auto">
        <Table
          id="resizable-table"
          className="custom-table tableLibrary m-0"
          ref={tableRef}
        >
          <thead>
            <tr>
              <th
                onMouseDown={(e) => onMouseDown(e, 0)}
                style={{ width: "40px" }}
              >
                Sr.
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 1)}>Name</th>
              <th onMouseDown={(e) => onMouseDown(e, 2)}>Email</th>
              <th onMouseDown={(e) => onMouseDown(e, 3)}>Plan Name</th>
              <th onMouseDown={(e) => onMouseDown(e, 4)}>Type</th>
              <th onMouseDown={(e) => onMouseDown(e, 5)}>Price</th>
              <th onMouseDown={(e) => onMouseDown(e, 6)}>Transection ID</th>
              <th onMouseDown={(e) => onMouseDown(e, 7)}>
                Transection Date | Time
              </th>
              <th
                onMouseDown={(e) => onMouseDown(e, 8)}
                style={{ width: "120px" }}
                className="text-center"
              >
                Status
              </th>
              <th
                onMouseDown={(e) => onMouseDown(e, 9)}
                style={{ width: "40px" }}
                className="text-center"
              >
                Invoice
              </th>
            </tr>
          </thead>
          <tbody>
            {transectionList?.length > 0 &&
              transectionList?.map((item, index) => (
                <tr className="relative">
                  <td>{index + 1}</td>
                  <td>{item.fullName}</td>
                  <td>{item.billingEmail}</td>
                  <td>{item.planName}</td>
                  <td className="text-capitalize">
                    {item.planType === "month" ? "Monthly" : "Yearly"}
                  </td>
                  <td className="green_color_status">
                    {item.currency + item.payment}
                  </td>
                  <td ref={textRef}>
                    <div className="d-flex justify-content-between">
                      <span>{item.transactionId}</span>
                      <i
                        onClick={() => CopyToClipboard(item.transactionId)}
                        style={{ cursor: "pointer" }}
                        title="Copy Tranction Id"
                        className="fa-regular fa-copy"
                      ></i>
                    </div>
                  </td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm" withTitle>
                      {item.createdAt}
                    </Moment>
                  </td>
                  <td className="status_select">
                    <Form.Select
                      aria-label="Default select example"
                      disabled={item.status === "succeeded"}
                      onChange={(e) =>
                        handleStatusChange(item._id, e.target.value)
                      }
                      className={
                        item.status === "succeeded"
                          ? "active_status"
                          : item.status === "failed" ||
                            item.status === "cancelled"
                          ? "inactive_status"
                          : "pending_status"
                      }
                    >
                      {item.status === "pending" && (
                        <option
                          selected={item.status === "pending"}
                          value="pending"
                        >
                          Pending
                        </option>
                      )}
                      <option
                        selected={item.status === "succeeded"}
                        value="succeeded"
                      >
                        Completed
                      </option>
                      {item.status === "cancelled" && (
                        <option
                          selected={item.status === "cancelled"}
                          value="cancelled"
                        >
                          Cancelled
                        </option>
                      )}
                      {item.status === "bounced" && (
                        <option
                          selected={item.status === "bounced"}
                          value="bounced"
                        >
                          Bounced
                        </option>
                      )}
                      {item.status === "failed" && (
                        <option
                          selected={item.status === "failed"}
                          value="failed"
                        >
                          Failed
                        </option>
                      )}
                    </Form.Select>
                  </td>
                  <td className="text-center">
                    <div className="text-center action__buttons">
                      {item.status === "succeeded" ? (
                        <a
                          href={`/transectionInvoiceReceipt/${item.transactionId}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Button
                            title="View Invoice"
                            className="rounded-circle "
                          >
                            <i className="fa-solid fa-eye"></i>
                          </Button>
                        </a>
                      ) : (
                        <Button
                          title="View Invoice"
                          disabled
                          className="rounded-circle "
                        >
                          <i className="fa-solid fa-eye"></i>
                        </Button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {transectionList?.length > 0 ? (
          ""
        ) : (
          <div className="noRecordFound">Record not found</div>
        )}
      </div>
    </>
  );
};

export default TransectionTableData;
