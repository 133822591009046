import React from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";

const UpdateDocumentMetadataModal = ({ Show, Hide, Size, Title, documentMetadata, setDocumentMetadata, handleUpdate, setOrder, order, Submit, steps, setSteps }) => {

  const handleAddInput = () => {
    setSteps([...steps, '']);
  };

  const handleRemoveInput = (index) => {
    const newSteps = [...steps];
    newSteps.splice(index, 1);
    setSteps(newSteps);
  };

  const handleChange = (index, event) => {
    const newSteps = [...steps];
    newSteps[index] = event.target.value;
    setSteps(newSteps);
  };

  return (
    <Modal
      show={Show}
      onHide={Hide}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={() => Hide()} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Form.Group as={Col} md="3" className="add_sponsor_field mb-3">
            <Form.Label>Order Number<span>*</span></Form.Label>
            <Form.Control
              required
              type="text"
              value={order}
              onChange={(e) => setOrder(e.target.value)}
              placeholder="Order No."
            />
          </Form.Group>
          <Form.Group as={Col} md="9" className="add_sponsor_field mb-3">
            <Form.Label>Document Metadata<span>*</span></Form.Label>
            <Form.Control
              required type="text"
              placeholder="Enter Metadata"
              value={documentMetadata}
              onChange={(e) => setDocumentMetadata(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-2">
            <Form.Label>
              Prompt <span>*</span>
              <i className="fa-solid fa-circle-plus ms-2 buttonBaseColor" onClick={handleAddInput} title="Add Feature"></i>
            </Form.Label>
            {steps?.map((item, index) => (
              <div key={index} className="input-groups position-relative">
                <InputGroup>
                  <InputGroup.Text id="inputGroupPrepend" style={{
                    height: "31.7px",
                    width: "60px"
                  }}>
                    Step {index + 1}
                  </InputGroup.Text>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Write here..."
                    value={item}
                    className="mb-2"
                    onChange={(e) => handleChange(index, e)}
                  />
                </InputGroup>
                {steps?.length > 1 &&
                  <div className="input-group-appends" title="Remove">
                    <span onClick={() => handleRemoveInput(index)}>x</span>
                  </div>
                }
              </div>
            ))}
          </Form.Group>
        </Row>
        <div className="hstack justify-content-end border-top pt-3 footer_button">
          <Button disabled={!documentMetadata} onClick={() => { handleUpdate(); Hide() }}>{Submit}</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateDocumentMetadataModal;
