import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import CompanyLogo from "../../../../img/Logo.png";
import { useParams } from "react-router-dom";
import Moment from "react-moment";
import { creditPriceServices } from "../../../../APIServices/AIAlpha/creditPriceServices";

const TransectionInvoiceReceipt = () => {
  const [invoiceData, setInvoiceData] = useState("");
  const { transactionId } = useParams();
  const handlePrint = () => {
    window.print();
  };
  const getBillingTransectionsforInvoice = async (transactionId) => {
    let data = await creditPriceServices.getBillingTransectionsforInvoice(
      transactionId
    );
    setInvoiceData(data?.data);
    console.log("data>>>>>>>", data);
  };
  useEffect(() => {
    getBillingTransectionsforInvoice(transactionId);
  }, [transactionId]);

  return (
    <>
      <div
        style={{ height: "100vh", overflow: "auto" }}
        className="position-relative rerceiptTable"
      >
        <Table>
          <tbody>
            <Table>
              <tr>
                <td>
                  <h6 style={{ fontSize: 20 }}>
                    <b>Order Id : #{invoiceData?.orderId}</b>
                  </h6>
                </td>
                <td>
                  <div className="text-end">
                    <h6 style={{ fontSize: 20 }}>
                      <b>Invoice No. : {invoiceData?.invoiceNumber}</b>
                    </h6>
                    <label>
                      <b>
                        Invoice Date | Time :{" "}
                        <Moment format="DD MMM YYYY | HH:ss">
                          {invoiceData?.createdAt}
                        </Moment>
                      </b>
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <h6 style={{ fontSize: 15 }}>
                    <img
                      style={{ width: 80 }}
                      src={CompanyLogo}
                      alt="Company Logo"
                    />
                  </h6>
                </td>
                <td>
                  <h6 style={{ fontSize: 15 }}>
                    <b>Billing Details</b>
                  </h6>
                </td>
              </tr>
              <tr style={{ verticalAlign: "top" }}>
                <td style={{ width: "50%" }}>
                  <label>
                    <b>Seller Name :</b> Galax Biotech
                  </label>
                  <br />
                  <label>
                    <b>Email :</b> hulimavu@gmail.com
                  </label>
                  <br />
                  <label>
                    <b>Address :</b> 14,1st Cross Rd, Bannerghatta Rd, behind
                    New Era Girls Hoste, Janatha Colony, Hulimavu, Bengaluru,
                    Karnataka 560076•099640 36984
                  </label>
                  <br />
                  <label>
                    <b>GSTIN No. :</b> 16543adcc65s4dc3a4d
                  </label>
                  <br />
                  <label>
                    <b>State Name :</b> Karnataka
                  </label>
                  <br />
                  <label>
                    <b>State Code :</b> 0121343
                  </label>
                  <br />
                </td>
                <td style={{ width: "50%" }}>
                  <label>
                    <b>Buyer Name :</b> {invoiceData?.customerName}
                  </label>
                  <br />
                  <label>
                    <b>Email :</b> {invoiceData?.billingEmail}
                  </label>
                  <br />
                  <label>
                    <b>Phone :</b> {invoiceData?.billingPhone || "N/A"}
                  </label>
                  <br />
                  <label>
                    <b>Address :</b> {invoiceData?.billingAddress?.line1},{" "}
                    {invoiceData?.billingAddress?.line2},{" "}
                    {invoiceData?.billingAddress?.city},{" "}
                    {invoiceData?.billingAddress?.state},{" "}
                    {invoiceData?.billingAddress?.country},{" "}
                    {invoiceData?.billingAddress?.postal_code},
                  </label>
                  <br />
                  <label>
                    <b>GSTIN No. :</b> {invoiceData?.gstNumber || "N/A"}
                  </label>
                  <br />
                  <label>
                    <b>State Name :</b>{" "}
                    {invoiceData?.billingAddress?.state || "N/A"}
                  </label>
                  <br />
                  <label>
                    <b>State Code :</b>{" "}
                    {invoiceData?.billingAddress?.stateCode || "N/A"}
                  </label>
                  <br />
                </td>
              </tr>
            </Table>
            <Table>
              <thead>
                <tr>
                  <th>Items</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>HSN/SAC</th>
                  <th style={{ width: 60 }}>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{"Credits"}</td>
                  <td>
                    {invoiceData?.currency}
                    {invoiceData?.payment / invoiceData?.credit}
                  </td>
                  <td>x {invoiceData?.credit}</td>
                  <td>{invoiceData?.hsn || "N/A"}</td>
                  <td>
                    <div className="text-center">
                      {invoiceData?.currency}
                      {(
                        Number(invoiceData?.payment) -
                        (Number(invoiceData?.cgst || 0) +
                          Number(invoiceData?.sgst || 0))
                      ).toFixed(2)}
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
            <Table>
              <tbody>
                <tr>
                  <td>
                    <div className="text-end me-1">
                      <label>
                        <b>Subtotal :</b> {invoiceData?.currency}
                        {(
                          Number(invoiceData?.payment) -
                          (Number(invoiceData?.cgst || 0) +
                            Number(invoiceData?.sgst || 0))
                        ).toFixed(2)}
                      </label>
                      <br />
                      <label>
                        <b>CGST :</b> {invoiceData?.currency}
                        {invoiceData?.cgst?.toFixed(2) || 0}
                      </label>
                      <br />
                      <label>
                        <b>SGST :</b> {invoiceData?.currency}
                        {invoiceData?.sgst?.toFixed(2) || 0}
                      </label>
                      <br />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="text-end me-1">
                      <label>
                        <b>Total :</b> {invoiceData?.currency}
                        {invoiceData?.payment?.toFixed(2)}
                      </label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>
                      <b>Thanks for shopping with us.</b>
                    </label>
                    <br />
                    <label>
                      <i
                        className="fa-solid fa-heart me-1"
                        style={{ color: "red" }}
                      ></i>
                      <b>with AI Alpha Team</b>
                    </label>
                    <br />
                  </td>
                </tr>
              </tbody>
            </Table>
          </tbody>
        </Table>
        <div className="footer_button hstack text-center justify-content-center">
          <Button
            className="printFloatingButton p-3 py-2 printButton"
            style={{ width: 150 }}
            title="Print Invoice"
            onClick={handlePrint}
          >
            <i className="fa-solid fa-print me-1"></i>Print Invoice
          </Button>
        </div>
      </div>
    </>
  );
};

export default TransectionInvoiceReceipt;
