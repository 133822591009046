import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { sdtmServices } from "../../../../APIServices/CDISC/sdtmServices";

const AddNewSDTMVersionModal = ({
  Show,
  Hide,
  Size,
  Title,
  Submit,
  getVersionsList,
}) => {
  let [version, setVersion] = useState("");

  const addNewVersion = async () => {
    let userData = {};
    userData.version = version;
    let data = await sdtmServices.addNewVersion(userData);
    if (data?.statusCode === 200) {
      closeModal();
      getVersionsList();
    }
  };

  const closeModal = () => {
    setVersion("");
    Hide();
  };
  return (
    <Modal
      show={Show}
      onHide={closeModal}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={closeModal} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>
              Version <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              value={version}
              onChange={(e) => setVersion(e.target.value)}
              placeholder="Enter Version Name"
            />
          </Form.Group>
        </Row>
        <div className="hstack gap-3 justify-content-end border-top pt-3 footer_button">
          <Button className="close_circle" onClick={() => setVersion("")}>
            Reset
          </Button>
          <Button disabled={!version} onClick={addNewVersion}>
            {Submit}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddNewSDTMVersionModal;
