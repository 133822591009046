import React, { useRef, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import UpdatePackageDetails from "./UpdatePackageDetails";
import UseToggle from "../../Hooks/useToggle";
import Moment from "react-moment";
import AssignePackages from "./AssignePackages";
import { packageServices } from "../../../APIServices/Editor/packageServices";
import Swal from "sweetalert2";
import PublishPackages from "./PublishPackages";

const PackageTableData = ({
  packageList,
  roleType,
  getPackageForReview,
  onMouseDown,
  tableRef,
}) => {
  const { toggle, setToggle, toggle1, setToggle1, toggle2, setToggle2 } =
    UseToggle();

  const ref = useRef(null);
  const [packageStatus, setPackageStatus] = useState("");
  const [id, setId] = useState("");
  const [packageId, setPackageId] = useState("");
  const [packageData, setPackageData] = useState("");
  const [validator, setValidator] = useState("");
  const [adminValidator, setAdminValidator] = useState("");
  const [organization, setOrganization] = useState("");
  const [reviewer, setReviewer] = useState("");
  const [version, setVersion] = useState("");

  const updatePackageReviewStatus = async (e, id, reviewStatus) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Change Status this Package?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = id;
        userData.reviewStatus = reviewStatus;
        let data = await packageServices.updatePackageReviewStatus(userData);
        if (data?.statusCode === 200) {
          getPackageForReview();
        }
      }
    });
  };

  const updatePackageStatus = async (e, id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Change Review Status this Package?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = id;
        userData.status = status;
        let data = await packageServices.updatePackageStatus(userData);
        if (data?.statusCode === 200) {
          getPackageForReview();
        }
      }
    });
  };

  const updatePackageStatusData = (e, id, status, version) => {
    if (status === "published") {
      setToggle2();
      setPackageStatus(status);
      setId(id);
      setVersion(version);
    } else {
      updatePackageStatus(e, id, status);
    }
  };

  const updateAdminValidatorPackageStatus = async (e, id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Change Syntax Status this Package?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = id;
        userData.syntaxStatus = status;
        let data = await packageServices.updateAdminValidatorPackageStatus(
          userData
        );
        if (data?.statusCode === 200) {
          getPackageForReview();
        }
      }
    });
  };

  const updatePackageDetails = async (e, item) => {
    setPackageData(item);
    setToggle1();
  };

  const assignPackages = async (e, item) => {
    setPackageId(item?._id);
    setPackageData(item);
    setReviewer(item?.reviewerId);
    setAdminValidator(item?.adminValidatorId);
    setValidator(item?.validatorId);
    setOrganization(item?.organizationId);
    setToggle();
  };

  return (
    <>
      <div className="table-container overflow-auto">
        <Table
          id="resizable-table"
          className="custom-table tableLibrary m-0"
          ref={tableRef}
        >
          <thead>
            <tr>
              <th
                onMouseDown={(e) => onMouseDown(e, 0)}
                style={{ width: "40px" }}
              >
                Sr.
              </th>
              {roleType === "admin" && (
                <th onMouseDown={(e) => onMouseDown(e, 1)}>Developer Name</th>
              )}
              {roleType === "admin" && (
                <th onMouseDown={(e) => onMouseDown(e, 2)}>Folder Name</th>
              )}
              <th
                onMouseDown={(e) =>
                  onMouseDown(e, roleType === "admin" ? 3 : 1)
                }
              >
                Package Name
              </th>
              <th
                onMouseDown={(e) =>
                  onMouseDown(e, roleType === "admin" ? 4 : 2)
                }
                style={{ width: "80px" }}
              >
                Version
              </th>
              {(roleType === "subadmin" || roleType === "admin") && (
                <th
                  onMouseDown={(e) =>
                    onMouseDown(e, 5)
                  }
                  style={{ width: "80px" }}
                >
                  Package Type
                </th>
              )}
              <th
                onMouseDown={(e) =>
                  onMouseDown(e, roleType === "admin" ? 6 : 3)
                }
                className="text-center"
              >
                {roleType === "admin" ? "Status" : "Review Status"}
              </th>
              {(roleType === "subadmin" || roleType === "admin") && (
                <th onMouseDown={(e) => onMouseDown(e, 7)}>
                  Creation Date | Time
                </th>
              )}
              <th
                onMouseDown={(e) =>
                  onMouseDown(e, roleType === "admin" ? 8 : 4)
                }
              >
                Published Date | Time
              </th>
              <th
                onMouseDown={(e) =>
                  onMouseDown(e, roleType === "admin" ? 9 : 5)
                }
              >
                {roleType === "admin" ? "Review" : "Validate"} Date | Time
              </th>
              <th
                onMouseDown={(e) =>
                  onMouseDown(e, roleType === "admin" ? 10 : 6)
                }
              >
                {roleType === "admin" ? "Review" : "Validate"} Status
              </th>
              <th
                onMouseDown={(e) =>
                  onMouseDown(e, roleType === "admin" ? 11 : 7)
                }
              >
                {roleType === "admin" ? "Organization" : "Validator"}
              </th>
              {roleType === "organization" && (
                <th onMouseDown={(e) => onMouseDown(e, 8)}>Reviewer</th>
              )}
              {roleType === "admin" && (
                <>
                  <th onMouseDown={(e) => onMouseDown(e, 12)}>Syntax Status</th>
                  <th onMouseDown={(e) => onMouseDown(e, 13)}>Validator</th>
                </>
              )}
              <th
                onMouseDown={(e) =>
                  onMouseDown(e, roleType === "admin" ? 14 : 9)
                }
                style={{ width: 70 }}
                className="text-center"
              >
                {roleType === "admin" ? "Edit" : "View"}
              </th>
              <th
                onMouseDown={(e) =>
                  onMouseDown(e, roleType === "admin" ? 15 : 10)
                }
                style={{ width: 70 }}
                className="text-center"
              >
                Assign
              </th>
              <th
                onMouseDown={(e) =>
                  onMouseDown(e, roleType === "admin" ? 16 : 11)
                }
              ></th>
            </tr>
          </thead>
          <tbody ref={ref}>
            {packageList?.length > 0 &&
              packageList?.map((item, index) => (
                <tr className="relative">
                  <td>{index + 1}</td>
                  {roleType === "admin" && <td>{item.developerDetails}</td>}
                  {roleType === "admin" && <td>{item.name}</td>}
                  <td>{item.packageName}</td>
                  <td>{item.version}</td>
                  {(roleType === "subadmin" || roleType === "admin") && (
                  <td className="text-capitalize">{item.packageType}</td>
                  )}
                  {roleType === "admin" ? (
                    <td className="status_select">
                      <Form.Select
                        onChange={(e) =>
                          updatePackageStatusData(
                            e,
                            item._id,
                            e.target.value,
                            item.version
                          )
                        }
                        aria-label="Default select example"
                        disabled={item.syntaxStatus === "rejected" ? false : (item.organizationStatus !== "completed" || item.disableMode)}
                        className={
                          item.status === "published"
                            ? "active_status"
                            : item.status === "inactive" || item.status === "rejected"
                              ? "inactive_status"
                              : "pending_status"
                        }
                      >

                        {item.syntaxStatus === "rejected" ?
                          <option
                            selected={item.status === "rejected"}
                            value="rejected"
                          >
                            Rejected
                          </option>
                          :
                          <>
                            <option
                              selected={item.status === "inactive"}
                              value="inactive"
                            >
                              Inactive
                            </option>
                            :
                            <option
                              selected={item.status === "published"}
                              value="published"
                            >
                              Publish
                            </option>
                          </>
                        }
                        {item.status === "pending" && (
                          <option
                            selected={item.status === "pending"}
                            value="pending"
                          >
                            Pending
                          </option>
                        )}
                      </Form.Select>
                    </td>
                  ) : (
                    <td className="status_select">
                      <Form.Select
                        onChange={(e) =>
                          updatePackageReviewStatus(e, item._id, e.target.value)
                        }
                        aria-label="Default select example"
                        disabled={
                          item.validatorStatus !== "completed" ||
                          item.status === "published"
                        }
                        className={
                          item.reviewStatus === "completed"
                            ? "active_status"
                            : item.reviewStatus === "rejected"
                              ? "inactive_status"
                              : item.reviewStatus === "ongoing"
                                ? "pending_status"
                                : "pending_status"
                        }
                      >
                        {item.reviewStatus === "pending" && (
                          <option
                            selected={item.reviewStatus === "pending"}
                            value="pending"
                          >
                            Pending
                          </option>
                        )}
                        <option
                          selected={item.reviewStatus === "completed"}
                          value="completed"
                        >
                          Completed
                        </option>
                        {item.reviewStatus === "ongoing" && (
                          <option
                            selected={item.reviewStatus === "ongoing"}
                            value="ongoing"
                          >
                            Ongoing
                          </option>
                        )}
                        <option
                          selected={item.reviewStatus === "rejected"}
                          value="rejected"
                        >
                          Rejected
                        </option>
                      </Form.Select>
                    </td>
                  )}
                  {(roleType === "subadmin" || roleType === "admin") && (
                    <td>
                      <Moment format="DD MMM YYYY | HH:mm" withTitle>
                        {item.createdAt}
                      </Moment>
                    </td>
                  )}
                  <td>
                    {item.publishDateTime ? (
                      <Moment format="DD MMM YYYY | HH:mm" withTitle>
                        {item.publishDateTime}
                      </Moment>
                    ) : (
                      "_"
                    )}
                  </td>
                  {roleType === "admin" ? (
                    <td className={`text-capitalize`}>
                      {!item.reviewDateTime ? (
                        "_"
                      ) : (
                        <Moment format="DD MMM YYYY | HH:mm" withTitle>
                          {item.reviewDateTime}
                        </Moment>
                      )}
                    </td>
                  ) : (
                    <td className={`text-capitalize`}>
                      {!item.validatorDateTime ? (
                        "_"
                      ) : (
                        <Moment format="DD MMM YYYY | HH:mm" withTitle>
                          {item.validatorDateTime}
                        </Moment>
                      )}
                    </td>
                  )}
                  {roleType === "admin" ? (
                    <td
                      className={`text-capitalize 
                  ${item.organizationStatus === "rejected"
                          ? "red_color_status"
                          : item.organizationStatus === "completed"
                            ? "green_color_status"
                            : item.organizationStatus === "pending"
                              ? "gray_color_status"
                              : item.organizationStatus === "ongoing"
                                ? "gray_color_status"
                                : ""
                        }`}
                    >
                      {item.organizationId ? item.organizationStatus : "_"}
                    </td>
                  ) : roleType === "reviewer" ? (
                    <td
                      className={`text-capitalize 
                  ${item.reviewStatus === "rejected"
                          ? "red_color_status"
                          : item.reviewStatus === "completed"
                            ? "green_color_status"
                            : item.reviewStatus === "pending"
                              ? "gray_color_status"
                              : item.reviewStatus === "ongoing"
                                ? "gray_color_status"
                                : ""
                        }`}
                    >
                      {item.reviewerId ? item.reviewStatus : "_"}
                    </td>
                  ) : (
                    <td
                      className={`text-capitalize 
                  ${item.validatorStatus === "rejected"
                          ? "red_color_status"
                          : item.validatorStatus === "completed"
                            ? "green_color_status"
                            : item.validatorStatus === "pending"
                              ? "gray_color_status"
                              : item.validatorStatus === "ongoing"
                                ? "gray_color_status"
                                : ""
                        }`}
                    >
                      {item.validatorId ? item.validatorStatus : "_"}
                    </td>
                  )}
                  {roleType === "admin" ? (
                    <td
                      className={`text-capitalize ${!item.organizationId ? "red_color_status" : ""
                        }`}
                    >
                      {!item.organizationId
                        ? "Not Assigned"
                        : item.organizationDetails}
                    </td>
                  ) : (
                    <>
                      <td
                        className={`text-capitalize ${!item.validatorId ? "red_color_status" : ""
                          }`}
                      >
                        {!item.validatorId
                          ? "Not Assigned"
                          : item.validatorDetails}
                      </td>
                      <td
                        className={`text-capitalize ${!item.reviewerId ? "red_color_status" : ""
                          }`}
                      >
                        {!item.reviewerId
                          ? "Not Assigned"
                          : item.reviewerDetails}
                      </td>
                    </>
                  )}
                  {roleType === "admin" && (
                    <>
                      <td className="status_select">
                        {item.syntaxStatus === "pending" ? (
                          "_"
                        ) : (
                          <Form.Select
                            onChange={(e) =>
                              updateAdminValidatorPackageStatus(
                                e,
                                item._id,
                                e.target.value
                              )
                            }
                            aria-label="Default select example"
                            disabled={
                              item.organizationStatus === "completed" ||
                              item.organizationStatus === "ongoing" ||
                              item.status === "published"
                            }
                            className={
                              item.syntaxStatus === "completed"
                                ? "active_status"
                                : item.syntaxStatus === "rejected"
                                  ? "inactive_status"
                                  : "pending_status"
                            }
                          >
                            {item.syntaxStatus === "completed" && (
                              <option
                                selected={item.syntaxStatus === "completed"}
                                value="completed"
                              >
                                Completed
                              </option>
                            )}
                            <option
                              selected={item.syntaxStatus === "rejected"}
                              value="rejected"
                            >
                              Rejected
                            </option>
                            <option
                              selected={item.syntaxStatus === "ongoing"}
                              value="ongoing"
                            >
                              Ongoing
                            </option>
                          </Form.Select>
                        )}
                      </td>
                      <td
                        className={`text-capitalize ${!item.adminValidatorId ? "red_color_status" : ""
                          }`}
                      >
                        {!item.adminValidatorId
                          ? "Not Assigned"
                          : item.adminValidatorDetails}
                      </td>
                    </>
                  )}
                  <td className="text-center">
                    <div className="text-center action__buttons">
                      <Button
                        onClick={(e) => updatePackageDetails(e, item)}
                        title={`${roleType === "admin" ? "Update" : "View"
                          } Package Details`}
                        className="rounded-circle"
                      >
                        <i
                          class={`fa-solid ${roleType === "admin" ? "fa-edit" : "fa-eye"
                            }`}
                        ></i>
                      </Button>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="text-center action__buttons">
                      <Button
                        style={
                          !item.packageName
                            ? { cursor: "no-drop" }
                            : { cursor: "pointer" }
                        }
                        disabled={!item.packageName}
                        onClick={(e) => assignPackages(e, item)}
                        title="Assign & Change"
                        className="rounded-circle"
                      >
                        <i className="fa-solid fa-tasks"></i>
                      </Button>
                    </div>
                  </td>
                  <td></td>
                </tr>
              ))}
          </tbody>
        </Table>
        {packageList?.length > 0 ? (
          ""
        ) : (
          <div className="noRecordFound">Record not found</div>
        )}
      </div>
      <AssignePackages
        Show={!toggle}
        Hide={setToggle}
        Size={"md"}
        packageId={packageId}
        getPackageForReview={getPackageForReview}
        validator={validator}
        adminValidator={adminValidator}
        organization={organization}
        reviewer={reviewer}
        roleType={roleType}
        packageData={packageData}
        Submit="Assigne"
      />

      <UpdatePackageDetails
        Show={!toggle1}
        Hide={setToggle1}
        Size={"xl"}
        roleType={roleType}
        packageData={packageData}
        getPackageForReview={getPackageForReview}
        Title={`${roleType === "admin" ? "Update" : "View"} Package Details`}
        Submit="Update"
      />

      <PublishPackages
        Show={!toggle2}
        Hide={setToggle2}
        Size={"md"}
        packageStatus={packageStatus}
        setPackageStatus={setPackageStatus}
        setId={setId}
        id={id}
        setVersion={setVersion}
        version={version}
        getPackageForReview={getPackageForReview}
        Title={`Publish Package`}
        Submit="Publish"
      />
    </>
  );
};

export default PackageTableData;
