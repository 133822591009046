import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { invitedDataServices } from "../../../APIServices/invitedDataServices";

const SendUserInvitaionModal = ({ Show, Hide, Size, Title, Submit, role, getInvitation, setHideClass }) => {
  let [email, setEmail] = useState("");

  const inviteUser = async () => {
    let userData = {}
    userData.email = email;
    userData.roleType = role;
    let data = await invitedDataServices.inviteUser(userData)
    if (data?.statusCode === 200) {
      hideModal();
      getInvitation();
    }
  }

  const hideModal = () => {
    setEmail("");
    Hide();
    setHideClass("")
  }

  return (
    <Modal
      show={Show}
      onHide={Hide}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={hideModal} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>Email <span>*</span></Form.Label>
            <Form.Control
              required
              type="email"
              placeholder="Enter Email ID"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
        </Row>
        <div className="hstack gap-3 justify-content-end border-top pt-3 footer_button">
          <Button className="close_circle" onClick={() => setEmail("")}>Reset</Button>
          <Button disabled={!email} onClick={inviteUser}>{Submit}</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SendUserInvitaionModal;
