import React, { useRef } from "react";
import { Button, Form, Table } from "react-bootstrap";
import Moment from "react-moment";
import Swal from "sweetalert2";

const AIBackupTableData = ({ getAllBackupList, onMouseDown, tableRef }) => {
  const textRef = useRef(null);

  function CopyToClipboard(data) {
    navigator.clipboard
      .writeText(data)
      .then(() => {
        alert("Data copied to clipboard successfully!");
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
      });
  }
  const handleStatusChange = async (id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Change Status this Transection?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = id;
        userData.status = status;
        // let data = await documentAIServices.changeDcoumentCaseStatus(userData);
        // if (data?.statusCode === 200) {
        //   getAllBackupList()
        // }
      }
    });
  };
  return (
    <>
      <div className="table-container overflow-auto">
        <Table id="resizable-table" className="custom-table m-0" ref={tableRef}>
          <thead>
            <tr>
              <th onMouseDown={(e) => onMouseDown(e, 0)} style={{ width: 40 }}>Sr.</th>
              <th onMouseDown={(e) => onMouseDown(e, 1)} style={{ width: 130 }}>Name</th>
              <th onMouseDown={(e) => onMouseDown(e, 2)} style={{ width: 133 }}>Email</th>
              <th onMouseDown={(e) => onMouseDown(e, 3)} style={{ width: 110 }}>Total Items</th>
              <th onMouseDown={(e) => onMouseDown(e, 4)} style={{ width: 70 }}>Price</th>
              <th onMouseDown={(e) => onMouseDown(e, 5)} style={{ width: 200 }}>Transection ID</th>
              <th onMouseDown={(e) => onMouseDown(e, 6)} style={{ width: 200 }}>Payment ID</th>
              <th onMouseDown={(e) => onMouseDown(e, 7)} style={{ width: 200 }}>Transection Date | Time</th>
              <th onMouseDown={(e) => onMouseDown(e, 8)} style={{ width: 120 }} className="text-center">
               Payment Status
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 9)} style={{ width: 200 }}>Backup Date | Time</th>
              <th onMouseDown={(e) => onMouseDown(e, 10)} style={{ width: 120 }} className="text-center">
               Backup Status
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 11)} style={{ width: 80 }} className="text-center">
                Invoice
              </th>
            </tr>
          </thead>
          <tbody>
            {getAllBackupList?.length > 0 &&
              getAllBackupList?.map((item, index) => (
                <tr className="relative">
                  <td>{index + 1}</td>
                  <td>{item.fullName}</td>
                  <td>{item.billingEmail}</td>
                  <td className="green_color_status">{item.totalItem}</td>
                  <td className="green_color_status">
                    {item.currency + item.payment}
                  </td>
                  <td ref={textRef}>
                    <div
                      className="d-flex justify-content-between"
                      title={item.transactionId}
                    >
                      <span className="transactionId">
                        {item.transactionId}
                      </span>
                      <i
                        onClick={() => CopyToClipboard(item.transactionId)}
                        style={{ cursor: "pointer" }}
                        title="Copy Tranction Id"
                        className="fa-regular fa-copy"
                      ></i>
                    </div>
                  </td>
                  <td ref={textRef}>
                    <div
                      className="d-flex justify-content-between"
                      title={item.paymentId}
                    >
                      <span className="paymentId">
                        {item.paymentId || "N/A"}
                      </span>
                      {item.paymentId && (
                        <i
                          onClick={() => CopyToClipboard(item.paymentId)}
                          style={{ cursor: "pointer" }}
                          title="Copy Payment Id"
                          className="fa-regular fa-copy"
                        ></i>
                      )}
                    </div>
                  </td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm" withTitle>
                      {item.createdAt}
                    </Moment>
                  </td>
                  <td className="status_select">
                    <Form.Select
                      aria-label="Default select example"
                      disabled={item.status === "succeeded"}
                      onChange={(e) =>
                        handleStatusChange(item._id, e.target.value)
                      }
                      className={
                        item.status === "succeeded"
                          ? "active_status"
                          : item.status === "canceled"
                          ? "inactive_status"
                          : "pending_status"
                      }
                    >
                      {item.status === "pending" && (
                        <option
                          selected={item.status === "pending"}
                          value="pending"
                        >
                          Pending
                        </option>
                      )}
                      <option
                        selected={item.status === "succeeded"}
                        value="succeeded"
                      >
                        Completed
                      </option>
                    </Form.Select>
                  </td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm" withTitle>
                      {item.backupDateTime}
                    </Moment>
                  </td>
                  <td className="status_select">
                    <Form.Select
                      aria-label="Default select example"
                      disabled={item.status === "succeeded"}
                      onChange={(e) =>
                        handleStatusChange(item._id, e.target.value)
                      }
                      className={
                        item.status === "succeeded"
                          ? "active_status"
                          : item.status === "canceled"
                          ? "inactive_status"
                          : "pending_status"
                      }
                    >
                      {item.status === "pending" && (
                        <option
                          selected={item.status === "pending"}
                          value="pending"
                        >
                          Pending
                        </option>
                      )}
                      <option
                        selected={item.status === "succeeded"}
                        value="succeeded"
                      >
                        Completed
                      </option>
                    </Form.Select>
                  </td>
                  <td className="hstack justify-content-center">
                    {item.status === "succeeded" ? (
                      <a
                        href={`/invoiceReceipt/${item.transactionId}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="text-center action__buttons">
                          <Button
                            title="View Invoice"
                            className="rounded-circle "
                          >
                            <i className="fa-solid fa-eye"></i>
                          </Button>
                        </div>
                      </a>
                    ) : (
                      <div className="text-center action__buttons">
                        <Button
                          title="View Invoice"
                          className="rounded-circle "
                          disabled
                        >
                          <i className="fa-solid fa-eye"></i>
                        </Button>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {getAllBackupList?.length > 0 ? (
          ""
        ) : (
          <div className="noRecordFound">Record not found</div>
        )}
      </div>
    </>
  );
};

export default AIBackupTableData;
