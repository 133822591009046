import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { designationPermissionServices } from "../../../APIServices/designationPermissionServices";

const AddNewDesignationModal = ({ Show, Hide, Size, Title, Submit, getDesignationsList }) => {
  let [designation, setDesignation] = useState("")

  const addNewDesignation = async () => {
    let userData = {}
    userData.designation = designation
    let data = await designationPermissionServices.addNewDesignation(userData)
    if (data?.statusCode === 200) {
      closeModal()
      getDesignationsList()
    }
  }

  const closeModal = () => {
    setDesignation("")
    Hide()
  }
  return (
    <Modal
      show={Show}
      onHide={closeModal}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={closeModal} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>Designation<span>*</span></Form.Label>
            <Form.Control
              required
              type="text"
              value={designation}
              onChange={(e) => setDesignation(e.target.value)}
              placeholder="Enter Designation"
            />
          </Form.Group>
        </Row>
        <div className="hstack gap-3 justify-content-end border-top pt-3 footer_button">
          <Button className="close_circle" onClick={()=>setDesignation("")}>Reset</Button>
          <Button disabled={!designation} onClick={addNewDesignation}>{Submit}</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddNewDesignationModal;
