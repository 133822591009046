import config from "../../config/config.json";
import { headers } from "../../APIHelpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody
} from "../../APIHelpers/Responses";

export const documentAIServices = {
  //Document Case
  searchDocumentCase,
  addNewDocumentCase,
  updateDocumentCase,
  changeDcoumentCaseStatus,
  deleteDocumentCase,
  getDocumentCaseList,
  
  //Document Metadata
  searchDocumentMetadata,
  addNewDocumentMetadata,
  updateDocumentMetadata,
  changeDcoumentMetadataStatus,
  deleteDocumentMetadata,
  getDocumentMetadataList,
};

// Add New Document Case
async function searchDocumentCase(userData) {
  const response = await fetch(
    `${config.API_URL}searchDocumentCase`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Add New Document Case
async function addNewDocumentCase(userData) {
  const response = await fetch(
    `${config.API_URL}addNewDocumentCase`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Update Document Case
async function updateDocumentCase(userData) {
  const response = await fetch(
    `${config.API_URL}updateDocumentCase`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Change Status Document Case
async function changeDcoumentCaseStatus(userData) {
  const response = await fetch(
    `${config.API_URL}changeDcoumentCaseStatus`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Get All Document Case List for Table
async function getDocumentCaseList() {
  const response = await fetch(
    `${config.API_URL}getDocumentCaseList`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Delete Document Case
async function deleteDocumentCase(id) {
  const response = await fetch(
    `${config.API_URL}deleteDocumentCase/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Add New Document Metadata
async function searchDocumentMetadata(userData) {
  const response = await fetch(
    `${config.API_URL}searchDocumentMetadata`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Add New Document Metadata
async function addNewDocumentMetadata(userData) {
  const response = await fetch(
    `${config.API_URL}addNewDocumentMetadata`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Update Document Metadata
async function updateDocumentMetadata(userData) {
  const response = await fetch(
    `${config.API_URL}updateDocumentMetadata`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Change Status Document Metadata
async function changeDcoumentMetadataStatus(userData) {
  const response = await fetch(
    `${config.API_URL}changeDcoumentMetadataStatus`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Get All Document Metadata List for Table
async function getDocumentMetadataList(caseId) {
  const response = await fetch(
    `${config.API_URL}getDocumentMetadataList/${caseId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Delete Document Metadata
async function deleteDocumentMetadata(id) {
  const response = await fetch(
    `${config.API_URL}deleteDocumentMetadata/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}