import React from "react";
import { Table } from "react-bootstrap";

const DesignationSubadmin = ({ subadminList, viewSubadmin, onMouseDown, tableRef}) => {
  return (
    <>
      {viewSubadmin && (
        <>
          <div className="hstack justify-content-between mb-2 sponsors_section_filter">
            <div className="hstack gap-2">
              <p style={{ marginBottom: 11, fontWeight: "bold" }}>
                {viewSubadmin} List
              </p>
            </div>
          </div>
          <div className="table-container overflow-auto">
            <Table id="resizable-table" className="custom-table m-0" ref={tableRef}>
              <thead>
                <tr>
                  <th onMouseDown={(e) => onMouseDown(e, 0)} style={{ width: 40 }}>Sr.</th>
                  <th onMouseDown={(e) => onMouseDown(e, 1)}>Subadmin Name</th>
                  <th onMouseDown={(e) => onMouseDown(e, 2)}>Email</th>
                  <th onMouseDown={(e) => onMouseDown(e, 3)} style={{ width: 100 }}>Phone</th>
                  <th onMouseDown={(e) => onMouseDown(e, 4)} style={{ width: 70 }}>Gender</th>
                </tr>
              </thead>
              <tbody>
                {subadminList?.map((item, index) => (
                  <tr className="relative">
                    <td>{index + 1}</td>
                    <td>{`${item.firstName} ${item.middleName} ${item.lastName}`}</td>
                    <td>{item.email}</td>
                    <td>{item.phone}</td>
                    <td className="text-capitalize">{item.gender}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </>
      )}
    </>
  );
};

export default DesignationSubadmin;
