import config from "../../config/config.json";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../../APIHelpers/Responses";
import { headers } from "../../APIHelpers/Headers";
import axios from "axios";

export const sdtmServices = {
  // Version
  addNewVersion,
  getVersionsList,
  updateVersions,
  deleteVersions,
  changeVersionsStatus,
  searchVersion,
  getActiveVersions,

  // Class
  addNewClass,
  addNewValueLevel,
  getClassList,
  updateClass,
  deleteClass,
  changeClassStatus,
  searchClass,
  getActiveClass,

  // Domain
  addNewDomain,
  getDomainsList,
  updateDomains,
  deleteDomains,
  changeDomainsStatus,
  searchDomain,
  getActiveDomains,

  //Variable
  searchVariable,
  addNewVariable,
  updateVariables,
  updateValueLevel,
  changeVariablesStatus,
  deleteVariables,
  deleteValueLevel,
  getActiveVariables,
  getVariablesList,
  getValueLevel,
  getValueLevelVariablesList,
  getWithoutValueLevelVariablesList,

  //Variable Comment
  searchVariableComment,
  addNewVariableComment,
  updateVariableComments,
  changeVariableCommentsStatus,
  deleteVariableComments,
  getActiveVariableComments,
  getVariableCommentsList,

  //Variable Method
  searchVariableMathod,
  addNewVariableMathod,
  updateVariableMathods,
  changeVariableMathodsStatus,
  getActiveVariableMathods,
  getVariableMathodsList,
  deleteVariableMathods,

  //STDM Terminology
  uploadSTDMTerminology,
  getSTDMTerminology,
  deleteSTDMTerminology,
  getSTDMTerminologyCodeList,

  //STDM List
  getSTDMList,

  //STDM Code
  addNewSTDMCode,
  updateSTDMCode,
  getSTDMCode,
  getSTDMCodeList,
  deleteSTDMCode,

  // Data Template

  addNewDataTemplate,
  getDataTemplates,
  deleteDataTemplates,
  updateDataTemplates,
};

//  upload File For STDM Terminology,
async function uploadSTDMTerminology(formData) {
  const response = await axios.post(
    `${config.API_URL}uploadSTDMTerminology`,
    formData
  );
  const data = response?.data;
  await handleResponse(data);
  return data;
}

// Add New Version
async function addNewVersion(userData) {
  const response = await fetch(
    `${config.API_URL}addNewVersion`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Get All Version List for Table
async function getVersionsList() {
  const response = await fetch(
    `${config.API_URL}getVersionsList`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Update Version
async function updateVersions(userData) {
  const response = await fetch(
    `${config.API_URL}updateVersions`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Delete Version
async function deleteVersions(id) {
  const response = await fetch(
    `${config.API_URL}deleteVersions/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

//  Change Version Status
async function changeVersionsStatus(userData) {
  const response = await fetch(
    `${config.API_URL}changeVersionsStatus`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Search Version
async function searchVersion(userData) {
  const response = await fetch(
    `${config.API_URL}searchVersion`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Get All Active Version
async function getActiveVersions() {
  const response = await fetch(
    `${config.API_URL}getActiveVersions`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Add New Class
async function addNewClass(userData) {
  const response = await fetch(
    `${config.API_URL}addNewClass`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Add New Class
async function addNewValueLevel(userData) {
  const response = await fetch(
    `${config.API_URL}addNewValueLevel`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Get All Class List for Table
async function getClassList() {
  const response = await fetch(
    `${config.API_URL}getClassList`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Update Class
async function updateClass(userData) {
  const response = await fetch(
    `${config.API_URL}updateClass`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Delete Class
async function deleteClass(id) {
  const response = await fetch(
    `${config.API_URL}deleteClass/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

//  Change Class Status
async function changeClassStatus(userData) {
  const response = await fetch(
    `${config.API_URL}changeClassStatus`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Search Class
async function searchClass(userData) {
  const response = await fetch(
    `${config.API_URL}searchClass`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Get All Active Version
async function getActiveClass(versionId) {
  const response = await fetch(
    `${config.API_URL}getActiveClass/${versionId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Value Level Variable List for Table
async function getValueLevelVariablesList(domainId) {
  const response = await fetch(
    `${config.API_URL}getValueLevelVariablesList/${domainId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Without Value Level Variable List for Table
async function getWithoutValueLevelVariablesList(domainId) {
  const response = await fetch(
    `${config.API_URL}getWithoutValueLevelVariablesList/${domainId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Add New Domain
async function addNewDomain(userData) {
  const response = await fetch(
    `${config.API_URL}addNewDomain`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Get All Domain List for Table
async function getDomainsList() {
  const response = await fetch(
    `${config.API_URL}getDomainsList`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Search Domain
async function searchDomain(userData) {
  const response = await fetch(
    `${config.API_URL}searchDomain`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Delete Domain
async function deleteDomains(id) {
  const response = await fetch(
    `${config.API_URL}deleteDomains/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Update Domain
async function updateDomains(userData) {
  const response = await fetch(
    `${config.API_URL}updateDomains`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

//  Change Class Domain
async function changeDomainsStatus(userData) {
  const response = await fetch(
    `${config.API_URL}changeDomainsStatus`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Get All Active Domain
async function getActiveDomains(classId) {
  const response = await fetch(
    `${config.API_URL}getActiveDomains/${classId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Add New Variable
async function addNewVariable(userData) {
  const response = await fetch(
    `${config.API_URL}addNewVariable`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Get All Variable List for Table
async function getVariablesList() {
  const response = await fetch(
    `${config.API_URL}getVariablesList`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get All Variable List for Table
async function getValueLevel() {
  const response = await fetch(
    `${config.API_URL}getValueLevel`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// // Get All Variable value level for Table
// async function getValueLevelVariablesList() {
//   const response = await fetch(
//     `${config.API_URL}getValueLevelVariablesList`,
//     headersWithAuthWithoutBody("GET", headers)
//   );
//   const data = await response.json();
//   return data;
// }

// Update Variable
async function updateVariables(userData) {
  const response = await fetch(
    `${config.API_URL}updateVariables`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Update Variable
async function updateValueLevel(userData) {
  const response = await fetch(
    `${config.API_URL}updateValueLevel`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Delete Variable
async function deleteVariables(id) {
  const response = await fetch(
    `${config.API_URL}deleteVariables/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Delete Variable
async function deleteValueLevel(id) {
  const response = await fetch(
    `${config.API_URL}deleteValueLevel/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

//  Change Variable Status
async function changeVariablesStatus(userData) {
  const response = await fetch(
    `${config.API_URL}changeVariablesStatus`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Search Variable
async function searchVariable(userData) {
  const response = await fetch(
    `${config.API_URL}searchVariable`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Get All Active Variable
async function getActiveVariables() {
  const response = await fetch(
    `${config.API_URL}getActiveVariables`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Add New Variable Comment
async function addNewVariableComment(userData) {
  const response = await fetch(
    `${config.API_URL}addNewVariableComment`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Get All Variable Comment List for Table
async function getVariableCommentsList() {
  const response = await fetch(
    `${config.API_URL}getVariableCommentsList`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Update Variable Comment
async function updateVariableComments(userData) {
  const response = await fetch(
    `${config.API_URL}updateVariableComments`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Delete Variable Comment
async function deleteVariableComments(id) {
  const response = await fetch(
    `${config.API_URL}deleteVariableComments/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

//  Change Variable Comment Status
async function changeVariableCommentsStatus(userData) {
  const response = await fetch(
    `${config.API_URL}changeVariableCommentsStatus`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Search Variable Comment
async function searchVariableComment(userData) {
  const response = await fetch(
    `${config.API_URL}searchVariableComment`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Get All Active Variable Comment
async function getActiveVariableComments() {
  const response = await fetch(
    `${config.API_URL}getActiveVariableComments`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Add New Variable Method
async function addNewVariableMathod(userData) {
  const response = await fetch(
    `${config.API_URL}addNewVariableMathod`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Get All Variable Method List for Table
async function getVariableMathodsList() {
  const response = await fetch(
    `${config.API_URL}getVariableMathodsList`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Update Variable Method
async function updateVariableMathods(userData) {
  const response = await fetch(
    `${config.API_URL}updateVariableMathods`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Delete Variable Method
async function deleteVariableMathods(id) {
  const response = await fetch(
    `${config.API_URL}deleteVariableMathods/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

//  Change Variable Method Status
async function changeVariableMathodsStatus(userData) {
  const response = await fetch(
    `${config.API_URL}changeVariableMathodsStatus`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Search Variable Method
async function searchVariableMathod(userData) {
  const response = await fetch(
    `${config.API_URL}searchVariableMathod`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Get All Active Variable Method
async function getActiveVariableMathods() {
  const response = await fetch(
    `${config.API_URL}getActiveVariableMathods`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get STDM Terminology Folder Name
async function getSTDMTerminology() {
  const response = await fetch(
    `${config.API_URL}getSTDMTerminology`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get STDM Code Data
async function getSTDMTerminologyCodeList(id) {
  const response = await fetch(
    `${config.API_URL}getSTDMTerminologyCodeList/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Delete STDM Terminology
async function deleteSTDMTerminology(id) {
  const response = await fetch(
    `${config.API_URL}deleteSTDMTerminology/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Get STDM List Data
async function getSTDMList() {
  const response = await fetch(
    `${config.API_URL}getSTDMList`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Add New STDM Code
async function addNewSTDMCode(userData) {
  const response = await fetch(
    `${config.API_URL}addNewSTDMCode`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Update STDM Code
async function updateSTDMCode(userData) {
  const response = await fetch(
    `${config.API_URL}updateSTDMCode`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Get STDM Code Data
async function getSTDMCode(id) {
  const response = await fetch(
    `${config.API_URL}getSTDMCode/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get STDM Code List
async function getSTDMCodeList() {
  const response = await fetch(
    `${config.API_URL}getSTDMCodeList`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Delete STDM Code
async function deleteSTDMCode(id) {
  const response = await fetch(
    `${config.API_URL}deleteSTDMCode/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Add Data Template
async function addNewDataTemplate(userData) {
  const response = await fetch(
    `${config.API_URL}addNewDataTemplate`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Get Data Template
async function getDataTemplates() {
  const response = await fetch(
    `${config.API_URL}getDataTemplates`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Delete Data Template
async function deleteDataTemplates(id) {
  const response = await fetch(
    `${config.API_URL}deleteDataTemplates/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Update Data Template
async function updateDataTemplates(userData) {
  const response = await fetch(
    `${config.API_URL}updateDataTemplates`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}
