import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import AddNewSDTMVeriableModal from "./AddNewSDTMVeriableModal";
import ExportSDTMVeriableModal from "./ExportSDTMVeriableModal";
import SDTMVeriableTableData from "./SDTMVeriableTableData";
import UseToggle from "../../../../Hooks/useToggle";
import { sdtmServices } from "../../../../../APIServices/CDISC/sdtmServices";
import TableHeader from "../../../../../component/TableHeader";
import Pagination from "../../../../Hooks/Pagination";
import VeriableFilterData from "./VeriableFilterData";

const SDTMVeriable = ({ onMouseDown, tableRef }) => {
  const { toggle, setToggle, toggle1, setToggle1, toggle2, setToggle2 } = UseToggle();
  const [searchData, setSearchData] = useState("");
  const [variableList, setVariableList] = useState([]);

  const getVariablesList = async () => {
    let data = await sdtmServices.getVariablesList();
    setVariableList(data?.data);
  };

  const itemsPerPageDesignation = 10;
  const [itemOffsetDesignation, setItemOffsetDesignation] = useState(0);
  const endOffsetDesignation = itemOffsetDesignation + itemsPerPageDesignation;
  const currentItemsDesignation = variableList?.slice(
    itemOffsetDesignation,
    endOffsetDesignation
  );

  const pageCountDesignation = Math.ceil(
    variableList?.length / itemsPerPageDesignation
  );

  const handlePageClickDesignation = (event) => {
    const newOffset =
      (event.selected * itemsPerPageDesignation) % variableList?.length;
    setItemOffsetDesignation(newOffset);
  };

  const handleSearch = async (searchValue) => {
    let userData = {};
    userData.searchData = searchValue || searchData;
    let data = await sdtmServices.searchVariable(userData);
    setVariableList(data?.data);
  };

  useEffect(() => {
    getVariablesList();
  }, []);

  return (
    <>
      <Row>
        <Col md={12} className="mb-3">
          <TableHeader
            filterIcon
            handleOpenFilter={setToggle2}
            // Search
            // handleSearch={handleSearch}
            // setSearchData={setSearchData}
            // SearchReverseData={getVariablesList}
            AddNew
            handleAddNew={setToggle}
            titleAddNew="Add New Veriable"
            Export
            handleExport={setToggle1}
            titleExport="Export Veriables Data"
          />
          <SDTMVeriableTableData
            onMouseDown={onMouseDown}
            tableRef={tableRef}
            variableList={currentItemsDesignation}
            getVariablesList={getVariablesList}
          />
          <Pagination
            Result={variableList}
            itemsPerPage={itemsPerPageDesignation}
            handlePageClick={handlePageClickDesignation}
            pageCount={pageCountDesignation}
          />
        </Col>
      </Row>
      <AddNewSDTMVeriableModal
        Show={!toggle}
        getVariablesList={getVariablesList}
        Hide={setToggle}
        Size={"lg"}
        Title={"Add New Veriable"}
        Submit={"Submit"}
      />
      <ExportSDTMVeriableModal
        Show={!toggle1}
        Hide={setToggle1}
        Size={"md"}
        Title={"Export Veriables"}
        Submit={"Export"}
      />
      <VeriableFilterData
        Show={!toggle2}
        Hide={setToggle2}
        Size={"lg"}
        Title={"Filter Value Levels"}
        Submit={"Export"}
      />
    </>
  );
};

export default SDTMVeriable;
