import React, { useEffect, useState } from "react";
import { Button, Col, Dropdown, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { packageServices } from "../../../../APIServices/Editor/packageServices";
import { userServices } from "../../../../APIServices/usersServices";
import { compareVersions } from "../../../../Utils/UniversalFunction";

const AddNewVersionRequestModal = ({ Show, Hide, Size, Title, Submit, getUpdateRequestPackage }) => {
  let [developer, setDeveloper] = useState("")
  let [packages, setPackages] = useState("")
  let [version, setVersion] = useState("")
  let [currentVersion, setCurrentVersion] = useState("")
  let [changeType, setChangeType] = useState("")
  let [developerList, setDeveloperList] = useState([])
  let [packageList, setPackageList] = useState([])
  let [changeLogs, setChangeLogs] = useState([])
  let [versionError, setVersionError] = useState("")

  const getUserData = async () => {
    let data = await userServices.getUserData("developer");
    setDeveloperList(data?.data);
  };

  const getPublishedPackageListForConsultant = async () => {
    let data = await packageServices.getPublishedPackageListForConsultant()
    setPackageList(data?.data)
  }

  const addNewRequestVersionPackage = async () => {
    let userData = {}
    userData.developer = developer
    userData.packageId = packages
    userData.version = version
    userData.changeType = changeType
    userData.changelogs = changeLogs
    let data = await packageServices.addNewRequestVersionPackage(userData)
    if (data?.statusCode === 200) {
      closeModal()
      getUpdateRequestPackage()
    }
  }

  const handleAddChangelog = (type) => {
    setChangeLogs([
      ...changeLogs,
      { type: type, changelog: "" },
    ]);
  };

  const handleRemoveChangelogs = (sectionIndex) => {
    const updatedChangelog = changeLogs.filter(
      (_, index) => index !== sectionIndex
    );
    setChangeLogs(updatedChangelog);
  };

  const updateChangelogState = (e, sectionIndex, key) => {
    const updatedChangelog = [...changeLogs];
    updatedChangelog[sectionIndex][key] = e.target.value;
    setChangeLogs(updatedChangelog);
  };

  const closeModal = () => {
    setDeveloper("")
    setVersion("")
    setPackages("")
    Hide()
  }

  const checkVersionData = (nextVersion) => {
    let data = compareVersions(currentVersion, nextVersion)
    setVersionError(data)
  }

  useEffect(() => {
    getUserData();
    getPublishedPackageListForConsultant();
  }, []);
  return (
    <Modal
      show={Show}
      onHide={closeModal}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={closeModal} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>
              Developers <span>*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => setDeveloper(e.target.value)}
              aria-label="Default select example"
            >
              <option>Select Developer</option>
              {developerList?.map((item, index) => (
                <option
                  key={index}
                  value={item?._id}
                >
                  {item?.fullName}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>
              Packages <span>*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => setPackages(e.target.value)}
              onClick={(e) => setCurrentVersion(e.target[e.target.selectedIndex].title)}
              aria-label="Default select example"
            >
              <option>Select Package</option>
              {packageList?.map((item, index) => (
                <option
                  key={index}
                  value={item?.packageId}
                  title={item.version}
                >
                  {item?.packageName}
                </option>
              ))}
            </Form.Select>
            {currentVersion &&
              <Form.Text style={{ fontSize: 12, fontWeight: 600 }}>Current Version: {currentVersion}</Form.Text>
            }
          </Form.Group>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>
              Change Type <span>*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => setChangeType(e.target.value)}
              aria-label="Default select example"
            >
              <option>Select Change Type</option>
              <option value={"minor"}>Minor Change</option>
              <option className={"major"}>Mijor Change</option>
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>Version <span>*</span></Form.Label>
            <Form.Control
              required
              type="text"
              value={version}
              onChange={(e) => {setVersion(e.target.value); checkVersionData(e.target.value)}}
              placeholder="Enter Next Version"
            />
            {versionError > 0 &&
              <Form.Text style={{ fontSize: 11, fontWeight: 400, color: "red" }}>Please Enter Valid Next Version (Every time Current Version Is less then Next Version)</Form.Text>
            }
          </Form.Group>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label className="DropDownAdd hstack">
              Change Logs <span>*</span>
              <Dropdown>
                <Dropdown.Toggle
                  variant="none"
                  id="dropdown-basic"
                  className="m-0 p-1 px-2"
                >
                  <i
                    style={{ fontSize: 13 }}
                    className="fa-solid fa-circle-plus"
                    title="Download Generated Data"
                  ></i>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#" title="Added" onClick={() => handleAddChangelog("Added")}>
                    <i className="me-2 fa-regular fa-square-plus"></i> Added
                  </Dropdown.Item>
                  <Dropdown.Item href="#" title="Updated" onClick={() => handleAddChangelog("Updated")}>
                    <i className="me-2 fa-solid fa-pen-to-square"></i> Updated
                  </Dropdown.Item>
                  <Dropdown.Item href="#" title="Fixed" onClick={() => handleAddChangelog("Fixed")}>
                    <i className="me-2 fa-solid fa-ban"></i> Fixed
                  </Dropdown.Item>
                  <Dropdown.Item href="#" title="Deleted" onClick={() => handleAddChangelog("Deleted")} style={{ color: "red" }}>
                    <i className="me-2 fa-solid fa-trash-can" style={{ color: "red" }}></i> Deleted
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Form.Label>
            {changeLogs?.map((item, index) => (
              <InputGroup className="mb-3" key={index}>
                <InputGroup.Text className="changeLogs" style={item.type === "Added" ? { backgroundColor: "green" } : item.type === "Updated" ? { backgroundColor: "orange" } : item.type === "Deleted" ? { backgroundColor: "red" } : { backgroundColor: "#000" }} id="basic-addon1">{item.type}</InputGroup.Text>
                <Form.Control
                  placeholder={`Enter ${item.type} functinality Changelogs`}
                  onChange={(e) => updateChangelogState(e, index, "changelog")}
                  value={item.changelog}
                />
                <div className="d-flex align-items-center ">
                  <i onClick={() => handleRemoveChangelogs(index)} style={{ fontSize: 12, color: "red", cursor: "pointer" }} className="mx-2 fa-regular fa-trash-can"></i>
                </div>
              </InputGroup>
            ))}
          </Form.Group>
        </Row>
        <div className="hstack gap-3 justify-content-end border-top pt-3 footer_button">
          <Button disabled={versionError !== -1 || changeLogs?.length === 0 || !developer || !packages || !version} onClick={addNewRequestVersionPackage}>{Submit}</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddNewVersionRequestModal;
