import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import { packageServices } from "../../../../APIServices/Editor/packageServices";
import { userServices } from "../../../../APIServices/usersServices";

const AssignePackages = ({ Show,
  Hide,
  Size,
  Submit,
  getUpdateRequestPackage,
  roleType,
  packageData
}) => {
  let validator = packageData?.validatorId
  let adminValidator = packageData?.adminvalidatorId
  let organization = packageData?.organizationId
  let reviewer = packageData?.reviewerId
  let [userId, setUserId] = useState("");
  let [userList, setUserList] = useState([]);
  let [activeTab, setActiveTab] = useState(roleType === "admin" ? "organization" : "validator");
  const handleTabClick = async (tab) => {
    setActiveTab(tab);
    let data;
    if (roleType === "admin") {
      if (tab === "organization") {
        data = await userServices.getUserData(tab);
      } else {
        data = await userServices.getPermissionSubadminData(tab);
      }
    } else {
      data = await userServices.getUserData(tab);
    }
    setUserList(data?.data);
  };

  const handleAssigned = async () => {
    Hide()
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Assigned Package this ${activeTab === "validator" ? "Validator" : activeTab === "reviewer" ? "Reviewer" : activeTab === "organization" ? "Organization" : activeTab === "adminValidator" ? "Admin Validator" : ""}?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Assigne it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {}
        userData.id = packageData?._id;
        userData.packageId = packageData?.packageId;
        userData.userId = userId;
        userData.type = activeTab;
        let data = await packageServices.assignUpdatedPackageForReview(userData);
        if (data?.statusCode === 200) {
          getUpdateRequestPackage()
        }
      }
    });
  }

  useEffect(() => {
    handleTabClick(roleType === "admin" ? (packageData?.syntaxStatus === "completed" ? "organization" : "adminValidator") : "validator")
    setUserId(activeTab === "validator" ? validator : activeTab === "reviewer" ? reviewer : activeTab === "organization" ? organization : activeTab === "adminValidator" ? adminValidator : "" || "")
  }, [roleType, packageData]);
  return (
    <Modal
      show={Show}
      onHide={Hide}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{`Assign ${activeTab === "validator" ? "Validator" : activeTab === "reviewer" ? "Reviewer" : activeTab === "organization" ? "Organization" : activeTab === "adminValidator" ? "Validator" : ""}`}</Modal.Title>
        <div onClick={() => Hide()} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="hstack justify-content-center gap-3 mb-3 footer_button">
          {roleType === "organization" &&
            <>

              <Button
                onClick={() => handleTabClick("validator")}
                style={{
                  backgroundColor: activeTab === "validator" ? "" : "transparent",
                  color: activeTab === "validator" ? "" : "var(--icon_color)",
                  padding: 3
                }}
              >
                Validator
              </Button>
              <Button
                onClick={() => handleTabClick("reviewer")}
                style={{
                  backgroundColor: activeTab === "reviewer" ? "" : "transparent",
                  color: activeTab === "reviewer" ? "" : "var(--icon_color)",
                  padding: 3
                }}
              >
                Reviewer
              </Button>
            </>
          }
          {roleType === "admin" &&
            <>
              {packageData?.adminvalidatorStatus === "completed" &&
                <Button
                  onClick={() => handleTabClick("organization")}
                  style={{
                    backgroundColor: activeTab === "organization" ? "" : "transparent",
                    color: activeTab === "organization" ? "" : "var(--icon_color)",
                    padding: 3
                  }}
                >
                  Organization
                </Button>
              }
              <Button
                onClick={() => handleTabClick("adminValidator")}
                style={{
                  backgroundColor: activeTab === "adminValidator" ? "" : "transparent",
                  color: activeTab === "adminValidator" ? "" : "var(--icon_color)",
                  padding: 3
                }}
              >
                Validator
              </Button>
            </>
          }
        </div>
        <Row>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>
              {activeTab === "validator" ? "Validators" : activeTab === "reviewer" ? "Reviewers" : activeTab === "organization" ? "Organizations" : activeTab === "adminValidator" ? "Validators" : ""} <span>*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => setUserId(e.target.value)}
              aria-label="Default select example"
            >
              <option>Select {activeTab === "validator" ? "Validators" : activeTab === "reviewer" ? "Reviewers" : activeTab === "organization" ? "Organizations" : activeTab === "adminValidator" ? "Validators" : ""}</option>
              {userList?.map((item, index) => (
                <option key={index} selected={(activeTab === "validator" ? validator : activeTab === "reviewer" ? reviewer : activeTab === "organization" ? organization : activeTab === "adminValidator" ? adminValidator : "") === item._id} value={item._id}>{item.fullName}</option>
              ))}
            </Form.Select>
          </Form.Group>
        </Row>
        <div className="hstack justify-content-end border-top pt-3 footer_button">
          <Button
            disabled={!userId}
            onClick={handleAssigned}
          >
            {Submit}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AssignePackages;
