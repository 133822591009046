import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import AddNewSDTMVersionModal from "./AddNewSDTMVersionModal";
import ExportSDTMVersionModal from "./ExportSDTMVersionModal";
import SDTMVersionTableData from "./SDTMVersionTableData";
import UseToggle from "../../../Hooks/useToggle";
import TableHeader from "../../../../component/TableHeader";
import Pagination from "../../../Hooks/Pagination";
import { sdtmServices } from "../../../../APIServices/CDISC/sdtmServices";

const SDTMVersion = ({ onMouseDown, tableRef }) => {
  const { toggle, setToggle, toggle1, setToggle1 } = UseToggle();
  const [searchData, setSearchData] = useState("");
  const [versionList, setVersionList] = useState([]);

  const getVersionsList = async () => {
    let data = await sdtmServices.getVersionsList();
    setVersionList(data?.data);
  };

  const itemsPerPageDesignation = 10;
  const [itemOffsetDesignation, setItemOffsetDesignation] = useState(0);
  const endOffsetDesignation = itemOffsetDesignation + itemsPerPageDesignation;
  const currentItemsDesignation = versionList?.slice(
    itemOffsetDesignation,
    endOffsetDesignation
  );

  const pageCountDesignation = Math.ceil(
    versionList?.length / itemsPerPageDesignation
  );

  const handlePageClickDesignation = (event) => {
    const newOffset =
      (event.selected * itemsPerPageDesignation) % versionList?.length;
    setItemOffsetDesignation(newOffset);
  };

  const handleSearch = async (searchValue) => {
    let userData = {};
    userData.searchData = searchValue || searchData;
    let data = await sdtmServices.searchVersion(userData);
    setVersionList(data?.data);
  };

  useEffect(() => {
    getVersionsList();
  }, []);

  return (
    <>
      <Row>
        <Col md={12} className="mb-3">
          <TableHeader
            Search
            handleSearch={handleSearch}
            setSearchData={setSearchData}
            SearchReverseData={getVersionsList}
            AddNew
            handleAddNew={setToggle}
            titleAddNew="Add New Version"
            Export
            handleExport={setToggle1}
            titleExport="Export Versions Data"
          />
          <SDTMVersionTableData
            onMouseDown={onMouseDown}
            tableRef={tableRef}
            versionList={currentItemsDesignation}
            getVersionsList={getVersionsList}
          />
          <Pagination
            Result={versionList}
            itemsPerPage={itemsPerPageDesignation}
            handlePageClick={handlePageClickDesignation}
            pageCount={pageCountDesignation}
          />
        </Col>
      </Row>
      <AddNewSDTMVersionModal
        Show={!toggle}
        getVersionsList={getVersionsList}
        Hide={setToggle}
        Size={"md"}
        Title={"Add New Version"}
        Submit={"Submit"}
      />
      <ExportSDTMVersionModal
        Show={!toggle1}
        Hide={setToggle1}
        Size={"md"}
        Title={"Export Versions"}
        Submit={"Export"}
      />
    </>
  );
};

export default SDTMVersion;
