import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { packageServices } from "../../../../APIServices/Editor/packageServices";

const AddNewCategoryModal = ({ Show, Hide, Size, Title, Submit, getPackageCategoryList }) => {
  let [category, setCategory] = useState("")

  const addNewPackageCategory = async () => {
    let userData = {}
    userData.category = category
    let data = await packageServices.addNewPackageCategory(userData)
    if (data?.statusCode === 200) {
      closeModal()
      getPackageCategoryList()
    }
  }

  const closeModal = () => {
    setCategory("")
    Hide()
  }
  return (
    <Modal
      show={Show}
      onHide={closeModal}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={closeModal} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>Category Name<span>*</span></Form.Label>
            <Form.Control
              required
              type="text"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              placeholder="Enter category"
            />
          </Form.Group>
        </Row>
        <div className="hstack gap-3 justify-content-end border-top pt-3 footer_button">
          <Button className="close_circle" onClick={()=>setCategory("")}>Reset</Button>
          <Button disabled={!category} onClick={addNewPackageCategory}>{Submit}</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddNewCategoryModal;
