import React, { useEffect, useState } from "react";
import UseToggle from "../../Hooks/useToggle";
import Pagination from "../../Hooks/Pagination";
import TableHeader from "../../../component/TableHeader";
import UserInvitationTableData from "./UserInvitationTableData";
import SendUserInvitaionModal from "./SendUserInvitaionModal";
import { Modal } from "react-bootstrap";
import { invitedDataServices } from "../../../APIServices/invitedDataServices";

const UsersInvitation = ({
  Show,
  Hide,
  Size,
  Title,
  invitedList,
  setInvitedList,
  role,
  getInvitation,
  permission,
  invitePermission,
  roleType,
  onMouseDown,
  tableRef,
}) => {
  const { toggle8, setToggle8 } = UseToggle();

  const [searchData, setSearchData] = useState("");
  const [hideClass, setHideClass] = useState("");

  const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = invitedList?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(invitedList?.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % invitedList?.length;
    setItemOffset(newOffset);
  };

  const handleSearch = async (searchValue) => {
    let userData = {};
    userData.searchData = searchValue || searchData;
    userData.roleType = role;
    let data = await invitedDataServices.searchInvitation(userData);
    setInvitedList(data?.data);
  };

  const sendInvitationModal = () => {
    setToggle8();
    setHideClass("d-none");
  };

  useEffect(() => {
    getInvitation();
  }, []);
  return (
    <Modal
      className={hideClass}
      show={Show}
      onHide={Hide}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={() => Hide()} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <>
          <TableHeader
            Search={invitePermission}
            handleSearch={handleSearch}
            setSearchData={setSearchData}
            SearchReverseData={getInvitation}
            Invite={
              (role === "aiuser"
                ? permission?.sendNewAIUserInvitation
                : role === "consultant"
                ? permission?.sendNewConsultantInvitation
                : role === "organization"
                ? permission?.sendNewOrganizationInvitation
                : role === "cdisc"
                ? permission?.sendNewCDISCInvitation
                : role === "developer"
                ? permission?.sendNewDeveloperInvitation
                : "") || roleType === "admin"
            }
            handleInvite={sendInvitationModal}
            titleInvite="Send Invitation"
          />
          <UserInvitationTableData
            onMouseDown={onMouseDown}
            tableRef={tableRef}
            invitedList={currentItems}
            getInvitation={getInvitation}
            permission={permission}
            roleType={roleType}
            role={role}
            roleData={
              role === "aiuser"
                ? "Full"
                : role === "organization"
                ? "Organization"
                : role === "cdisc"
                ? "CDISC Client"
                : role === "consultant"
                ? "Full"
                : role === "developer"
                ? "Developer"
                : ""
            }
          />
          <Pagination
            Result={invitedList}
            itemsPerPage={itemsPerPage}
            handlePageClick={handlePageClick}
            pageCount={pageCount}
          />
          <SendUserInvitaionModal
            Show={!toggle8}
            Hide={setToggle8}
            setHideClass={setHideClass}
            Size={"md"}
            Title={"Send Invitation"}
            getInvitation={getInvitation}
            role={role}
            Submit={"Invite"}
          />
        </>
      </Modal.Body>
    </Modal>
  );
};

export default UsersInvitation;
