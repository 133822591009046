import React, { useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import UseToggle from "../../../Hooks/useToggle";
import Moment from "react-moment";
import ViewRequestedChangeLogs from "./ViewRequestedChangeLogs";
import Swal from "sweetalert2";
import { packageServices } from "../../../../APIServices/Editor/packageServices";
import AssignePackages from "./AssignePackages";

const NewVersionPackageTableData = ({
  packageUpdateRequestList,
  getUpdateRequestPackage,
  onMouseDown,
  tableRef,
  roleType
}) => {
  const {
    toggle,
    setToggle,
    toggle1,
    setToggle1,
  } = UseToggle();

  const [updateRequestData, setUpdateRequestData] = useState();
  const [packageData, setPackageData] = useState("");

  const handleViewChangelogs = async (e, item) => {
    setUpdateRequestData(item);
    setToggle();
  };

  const handleAssignPackages = async (e, packageData) => {
    setToggle1();
    setPackageData(packageData)
  };

  const packageReviewStatusForUpdatePackage = async (id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Update Status this Package?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {}
        userData.id = id
        userData.status = status
        let data = await packageServices.packageReviewStatusForUpdatePackage(userData);
        if (data?.statusCode === 200) {
          getUpdateRequestPackage()
        }
      }
    });
  }

  const updateStatusInRequestVersionPackage = async (id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Update Status this Package?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {}
        userData.id = id
        userData.status = status
        let data = await packageServices.updateStatusInRequestVersionPackage(userData);
        if (data?.statusCode === 200) {
          getUpdateRequestPackage()
        }
      }
    });
  }
  return (
    <>
      <div className="table-container overflow-auto">
        <Table id="resizable-table" className="custom-table tableLibrary m-0" ref={tableRef}>
          <thead>
            <tr>
              <th
                onMouseDown={(e) => onMouseDown(e, 0)}
                style={{ width: "40px" }}
              >
                Sr.
              </th>
              {roleType === "admin" &&
                <th onMouseDown={(e) => onMouseDown(e, 1)}>Developer Name</th>
              }
              <th onMouseDown={(e) => onMouseDown(e, 2)}>Package Name</th>
              <th onMouseDown={(e) => onMouseDown(e, 3)}>Version</th>
              <th onMouseDown={(e) => onMouseDown(e, 4)}>Change Type</th>
              {roleType === "admin" &&
                <>
                  <th className="text-center" onMouseDown={(e) => onMouseDown(e, 6)}
                    style={{ width: "140px" }}>Status</th>
                  <th className="text-center" onMouseDown={(e) => onMouseDown(e, 4)}>Publish Date | Time</th>
                </>
              }
              {roleType === "admin" &&
                <th className="text-center" onMouseDown={(e) => onMouseDown(e, 4)}>Developer Status</th>
              }
              <th className="text-center" onMouseDown={(e) => onMouseDown(e, 4)}>Review Status</th>
              <th className="text-center" onMouseDown={(e) => onMouseDown(e, 4)}>Review Date | Time</th>
              <th className="text-center" onMouseDown={(e) => onMouseDown(e, 4)}>Validator</th>
              <th className="text-center" onMouseDown={(e) => onMouseDown(e, 4)}>Validation Status</th>
              <th className="text-center" onMouseDown={(e) => onMouseDown(e, 4)}>Validated Date | Time</th>
              {roleType === "admin" &&
                <th className="text-center" onMouseDown={(e) => onMouseDown(e, 4)}>Organization</th>
              }
              {roleType === "organization" &&
                <th className="text-center" onMouseDown={(e) => onMouseDown(e, 4)}>Reviewer</th>
              }
              <th
                onMouseDown={(e) => onMouseDown(e, 7)}
                style={{ width: "200px" }}
              >
                Generated Date | Time
              </th>
              <th
                onMouseDown={(e) => onMouseDown(e, 8)}
                style={{ width: 70 }}
                className="text-center"
              >
                View
              </th>
              <th
                onMouseDown={(e) => onMouseDown(e, 8)}
                style={{ width: 70 }}
                className="text-center"
              >
                Assign
              </th>
            </tr>
          </thead>
          <tbody>
            {packageUpdateRequestList?.length > 0 &&
              packageUpdateRequestList?.map((item, index) => (
                <tr className="relative">
                  <td>{index + 1}</td>
                  {roleType === "admin" &&
                    <td>{item.developerName}</td>
                  }
                  <td>{item.packageName}</td>
                  <td>{item.version}</td>
                  <td className="text-capitalize">{item.changeType}</td>
                  {roleType === "admin" &&
                    <>
                      <td className="status_select">
                        <Form.Select
                          aria-label="Default select example"
                          disabled={item.status === "completed" || item.reviewStatus === "pending" || item.reviewStatus === "ongoing"}
                          onChange={(e) => updateStatusInRequestVersionPackage(item._id, e.target.value)}
                          className={
                            item.status === "completed"
                              ? "active_status"
                              : "pending_status"
                          }
                        >
                          <option
                            selected={item.status === "completed"}
                            value="completed"
                          >
                            {" "}
                            Completed{" "}
                          </option>
                          <option
                            selected={item.status === "pending"}
                            value="pending"
                          >
                            Pending
                          </option>
                        </Form.Select>
                      </td>
                      <td>
                        {item.reviewDateTime ?
                          <Moment format="DD MMM YYYY | HH:mm" withTitle>
                            {item.reviewDateTime}
                          </Moment>
                          :
                          "_"}
                      </td>
                      <td
                        className={`text-capitalize  text-center
                  ${item.developerStatus === "completed"
                            ? "green_color_status"
                            : item.developerStatus === "ongoing"
                              ? "gray_color_status"
                              : ""
                          }`}
                      >{item.developerStatus}</td>
                    </>
                  }
                  {roleType === "admin" ?
                    <td
                      className={`text-capitalize  text-center
                  ${item.reviewStatus === "completed"
                          ? "green_color_status"
                          : item.reviewStatus === "ongoing" || item.reviewStatus === "pending"
                            ? "gray_color_status"
                            : ""
                        }`}
                    >{item.reviewStatus}</td>
                    :
                    <td className="status_select">
                      <Form.Select
                        aria-label="Default select example"
                        disabled={item.reviewStatus === "completed" || item.validatorStatus === "ongoing" || !item.validatorId}
                        onChange={(e) => packageReviewStatusForUpdatePackage(item._id, e.target.value)}
                        className={
                          item.reviewStatus === "completed"
                            ? "active_status"
                            : "pending_status"
                        }
                      >
                        <option
                          selected={item.reviewStatus === "completed"}
                          value="completed"
                        >
                          {" "}
                          Completed{" "}
                        </option>
                        <option
                          selected={item.reviewStatus === "ongoing"}
                          value="ongoing"
                        >
                          Ongoing
                        </option>
                        <option
                          selected={item.reviewStatus === "pending"}
                          value="pending"
                        >
                          Pending
                        </option>
                      </Form.Select>
                    </td>
                  }
                  <td>
                    {item.reviewDateTime ?
                      <Moment format="DD MMM YYYY | HH:mm" withTitle>
                        {item.reviewDateTime}
                      </Moment>
                      :
                      "_"}
                  </td>
                  {roleType === "admin" ?
                    <>
                      <td>{item.adminValidatorName ? item.adminValidatorName : "_"}</td>
                      <td className={`text-capitalize  text-center
                  ${item.adminvalidatorStatus === "completed"
                          ? "green_color_status"
                          : item.adminvalidatorStatus === "ongoing"
                            ? "gray_color_status"
                            : ""
                        }`}
                      >{item.adminValidatorName ? item.adminvalidatorStatus : "_"}</td>
                    </>
                    :
                    <>
                      <td>{item.validatorName ? item.validatorName : "_"}</td>
                      <td className={`text-capitalize  text-center
                  ${item.validatorStatus === "completed"
                          ? "green_color_status"
                          : item.validatorStatus === "ongoing"
                            ? "gray_color_status"
                            : ""
                        }`}
                      >{item.validatorName ? item.validatorStatus : "_"}</td>
                    </>
                  }
                  {(roleType === "organization" || roleType === "reviewer") ?
                    <td>
                      {item.validatorDateTime ?
                        <Moment format="DD MMM YYYY | HH:mm" withTitle>
                          {item.validatorDateTime}
                        </Moment>
                        : "_"}
                    </td>
                    :
                    <td>
                      {item.adminvalidatorDateTime ?
                        <Moment format="DD MMM YYYY | HH:mm" withTitle>
                          {item.adminvalidatorDateTime}
                        </Moment>
                        : "_"}
                    </td>
                  }
                  {roleType === "admin" &&
                    <td>{item.organizationName ? item.organizationName : "_"}</td>
                  }
                  {roleType === "organization" &&
                    <td>{item.reviewerName ? item.reviewerName : "_"}</td>
                  }
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm" withTitle>
                      {item.createdAt}
                    </Moment>
                  </td>
                  <td className="text-center">
                    <div className="text-center action__buttons">
                      <Button
                        onClick={(e) => handleViewChangelogs(e, item)}
                        title="Update category"
                        className="rounded-circle"
                      >
                        <i className="fa-solid fa-eye"></i>
                      </Button>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="text-center action__buttons">
                      <Button
                        onClick={(e) => handleAssignPackages(e, item)}
                        title="Update category"
                        className="rounded-circle"
                      >
                        <i className="fa-solid fa-edit"></i>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {packageUpdateRequestList?.length > 0 ? (
          ""
        ) : (
          <div className="noRecordFound">Record not found</div>
        )}
      </div>
      <ViewRequestedChangeLogs
        Show={!toggle}
        Hide={setToggle}
        Size={"md"}
        roleType={roleType}
        updateRequestData={updateRequestData}
        Title={"View Changelogs"}
      />
      <AssignePackages
        Show={!toggle1}
        Hide={setToggle1}
        Size={"md"}
        roleType={roleType}
        packageData={packageData}
        getUpdateRequestPackage={getUpdateRequestPackage}
        Title={"Assign Package"}
        Submit="Assigne"
      />
    </>
  );
};

export default NewVersionPackageTableData;
