import React, { useEffect, useRef, useState } from "react";
import { Button, Table } from "react-bootstrap";
import Moment from "react-moment";
import Swal from "sweetalert2";
import { couponServices } from "../../../APIServices/Editor/couponServices";
import UpdateCouponModal from "./UpdateCouponModal";
import UseToggle from "../../Hooks/useToggle";

const CouponHistoryTableData = ({ couponList, getCouponList, setHideClass }) => {
  const { toggle, setToggle } = UseToggle();

  const ref = useRef(null);
  const [action, setAction] = useState("");
  const [couponData, setCouponData] = useState("");

  const textRef = useRef(null);

  function CopyToClipboard(data ) {
      navigator.clipboard.writeText(data)
        .then(() => {
          alert('Data copied to clipboard successfully!');
        })
        .catch((error) => {
          console.error('Failed to copy:', error);
        });
  }

  const updateCoupon = (e, item) => {
    setToggle()
    setCouponData(item)
    setHideClass("d-none")
  }

  const deleteCoupon = async (e, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete this coupon?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, cancel it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await couponServices.deleteCoupon(id);
        if (data?.statusCode === 200) {
          getCouponList()
        }
      }
    });
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setAction("");
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  return (
    <>
      <div className="table-container">
        <Table id="resizable-table" className="custom-table">
          <thead>
            <tr>
              <th style={{ width: "40px" }}>Sr.</th>
              <th>Coupon Name</th>
              <th>Coupon Code</th>
              <th>Discount</th>
              <th>Discount Type</th>
              <th>Expiry Time</th>
              <th>Coupon Type</th>
              <th>Status</th>
              <th style={{ width: "120px" }}>Created Date | Time</th>
              <th style={{ width: "40px" }} className="text-center">
                Action
              </th>
            </tr>
          </thead>
          <tbody ref={ref}>
            {couponList?.length > 0 &&
              couponList?.map((item, index) => (
                <tr className="relative">
                  <td>{index + 1}</td>
                  <td>{item.couponName}</td>
                  <td ref={textRef}>
                    <div className="d-flex justify-content-between"><span>{item.couponCode}</span> <i onClick={() => CopyToClipboard(item.couponCode)} style={{cursor: "pointer"}} title="Copy Coupon Code" className="fa-regular fa-copy"></i></div></td>
                  {item.discountType === "flat" ?
                    <td>{item.currency + item.discount}</td>
                    :
                    <td>{item.discount}%</td>
                  }
                  <td className="text-capitalize">{item.discountType}</td>
                  <td>{item.expiryTime + (item.expiryTime > 1 ? " Days" : " Day")}</td>
                  <td className="text-capitalize">{item.couponType}</td>
                  <td className={`text-capitalize ${item.status === "active" ? "green_color_status" : item.status === "expired" ? "red_color_status" : "gray_color_status"}`}>{item.status}</td>
                  <td><Moment format="DD MMM YYYY | HH:mm" withTitle>
                    {item.createdAt}
                  </Moment></td>
                  <td className="text-center">
                    <div className="text-center action__buttons">
                      {action === item._id ? (
                        <>
                          <div className="saperate_action_button">
                            <Button
                              onClick={(e) => updateCoupon(e, item)}
                              title="Update Coupon"
                              className="rounded-circle"
                            >
                              <i className="fa-solid fa-edit"></i>
                            </Button>
                            <Button
                              title="Delete Coupon"
                              onClick={(e) => deleteCoupon(e, item._id)}
                              className="rounded-circle close_circle"
                            >
                              <i className="fa-solid fa-trash"></i>
                            </Button>
                          </div>
                          <Button
                            onClick={() => setAction("")}
                            title="Close Action"
                            className="rounded-circle close_circle"
                          >
                            <i className="fa-solid fa-xmark"></i>
                          </Button>
                        </>
                      ) : (
                        <Button
                          onClick={() => setAction(item._id)}
                          title="Open Action"
                          className="rounded-circle"
                        >
                          <i className="fa-solid fa-bars"></i>
                        </Button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {couponList?.length > 0 ? "" :
          <div className="noRecordFound">Record not found</div>
        }
      </div>
      <UpdateCouponModal
        Show={!toggle}
        Hide={setToggle}
        setHideClass={setHideClass}
        Size={"md"}
        Title={"Update Coupon"}
        getCouponList={getCouponList}
        couponData={couponData}
        Submit={"Update"}
      />
    </>
  );
};

export default CouponHistoryTableData;
