import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { invitedDataServices } from "../../../../APIServices/invitedDataServices";

const SendSubadminInvitaionModal = ({ Show, Hide, Size, Title, Submit, permissionList, designationList, getInvitation, setHideClass }) => {
  let [permissionId, setPermissionId] = useState("");
  let [designationId, setDesignationId] = useState("");
  let [email, setEmail] = useState("");

  const inviteSubadmin = async () => {
    let userData = {}
    userData.email = email;
    userData.permissionId = permissionId;
    userData.designationId = designationId;
    userData.roleType = "subadmin";
    let data = await invitedDataServices.inviteUser(userData)
    if (data?.statusCode === 200) {
      hideModal();
      getInvitation();
    }
  }

  const hideModal = () => {
    setEmail("");
    setPermissionId("");
    setDesignationId("");
    Hide();
    setHideClass("")
  }

  return (
    <Modal
      show={Show}
      onHide={Hide}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={hideModal} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>Email <span>*</span></Form.Label>
            <Form.Control
              required
              type="email"
              placeholder="Enter Email ID"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>Designation <span>*</span></Form.Label>
            <Form.Select
              aria-label="Default select example"
              onChange={(e) => setDesignationId(e.target.value)}
            >
              <option>Select Designation</option>
              {designationList?.map((item, index) => (
                <option selected={designationId === item._id} key={index} value={item._id}>{item.designation}</option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>Permission <span>*</span></Form.Label>
            <Form.Select
              aria-label="Default select example"
              onChange={(e) => setPermissionId(e.target.value)}
            >
              <option>Select Permission</option>
              {permissionList?.map((item, index) => (
                <option selected={permissionId === item._id} key={index} value={item._id}>{item.permission}</option>
              ))}
            </Form.Select>
          </Form.Group>
        </Row>
        <div className="hstack gap-3 justify-content-end border-top pt-3 footer_button">
          <Button className="close_circle" onClick={() => setEmail("")}>Reset</Button>
          <Button disabled={!email || !designationId || !permissionId} onClick={inviteSubadmin}>{Submit}</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SendSubadminInvitaionModal;
