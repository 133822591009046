import React, { useEffect, useState } from "react";
import LibraryHelpComponent from "./LibraryHelpComponent";
import LibraryHelpSidebar from "./LibraryHelpSidebar";
import { libraryFolderAndDataServices } from "../../../APIServices/Editor/libraryFolderAndDataServices";
import { Button, Form, InputGroup } from "react-bootstrap";
import ExportLibraryFileData from "./ExportLibraryFileData";
import UseToggle from "../../Hooks/useToggle";
import ColumnPropertyTable from "./ColumnPropertyTable";

const LibraryHelp = ({ onMouseDown, tableRef, userCurrrentData }) => {
  const { toggle, setToggle } = UseToggle();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [columnList, setColumnList] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [columnType, setColumnType] = useState("name");
  const [libraryList, setLibraryList] = useState([]);
  const [libraryId, setLibraryId] = useState(null);
  const [columnProperty, setColumnProperty] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const [libraryProperties, setLibraryProperties] = useState("");

  const getLibraryFolderData = async () => {
    let data = await libraryFolderAndDataServices.getLibraryFolderData("help");
    setLibraryList(data?.data);
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const getLibraryDataByFolderId = async (libraryId) => {
    let data = await libraryFolderAndDataServices.getLibraryDataByFolderId(
      libraryId
    );
    let result = data?.data;
    setColumnList(result?.column);
    setDataList(result?.data?.map((item) => Object.values(item)));
  };

  const startIndex = (Number(pageNumber) - 1) * Number(itemsPerPage);
  const endIndex = startIndex + Number(itemsPerPage);
  let result = dataList?.slice(startIndex, endIndex) || [];
  let totalPage = Math.ceil(dataList.length / itemsPerPage);

  useEffect(() => {
    getLibraryDataByFolderId(libraryId);
    getLibraryFolderData();
  }, [libraryId]);

  return (
    <>
      <div className="d-flex">
        <div
          style={{ width: isCollapsed ? "0%" : "20%" }}
          className={`rightHeightColumn ${
            isCollapsed ? "collapsed" : "collapsed"
          }`}
        >
          <div
            className={`mx-2 ${
              isCollapsed ? "leaveOptionBox" : "leaveOptionBox "
            }`}
          >
            <LibraryHelpSidebar
              setLibraryId={setLibraryId}
              libraryId={libraryId}
              getLibraryFolderData={getLibraryFolderData}
              libraryList={libraryList}
              setLibraryList={setLibraryList}
              setColumnProperty={setColumnProperty}
              columnProperty={columnProperty}
              userCurrrentData={userCurrrentData}
              setLibraryProperties={setLibraryProperties}
              libraryProperties={libraryProperties}
            />
          </div>
          {columnProperty && (
            <div
              className={` p-0 mx-2 mt-3 border-0 ${
                isCollapsed ? "leaveOptionBox " : "leaveOptionBox"
              }`}
            >
              <ColumnPropertyTable columnProperty={columnProperty} />
            </div>
          )}
        </div>
        <div
          style={{
            width: isCollapsed ? "100%" : "80%",
            background: "#fff",
            marginLeft: isCollapsed ? "0" : "auto",
          }}
          className={isCollapsed ? "collapsed" : "collapsed ps-2"}
        >
          <div className="helpComponentSection">
            <div className="d-flex justify-content-between mb-2">
              <div className="hstack gap-2">
                <i
                  id="menu-btn"
                  title={isCollapsed ? "Open Sidebar" : "Close Sidebar"}
                  className={`fa-solid ${
                    isCollapsed ? "fa-bars-staggered" : "fa-bars"
                  }`}
                  onClick={toggleCollapse}
                ></i>
                {libraryId && (
                  <div className="add_sponsor_field">
                    <Form.Select
                      onChange={(e) => setColumnType(e.target.value)}
                      aria-label="Default select example"
                    >
                      <option value="name">By Name</option>
                      <option value="label">By Label</option>
                    </Form.Select>
                  </div>
                )}
              </div>
              {libraryId && (
                <div className="sponsors_section_filter">
                  <Button onClick={setToggle} title="Export">
                    <i className="fa-solid fa-download"></i>
                  </Button>
                </div>
              )}
            </div>
            {libraryId && dataList?.length > 0 && (
              <>
                <LibraryHelpComponent
                  onMouseDown={onMouseDown}
                  tableRef={tableRef}
                  columnList={columnList}
                  dataList={result}
                  itemsPerPage={itemsPerPage}
                  pageNumber={pageNumber}
                  columnType={columnType}
                  setColumnProperty={setColumnProperty}
                />
                <div className="mt-3 add_sponsor_field hstack justify-content-between">
                  <div className="d-flex gap-2">
                    <div className="inputforSecelct">
                      <InputGroup>
                        <InputGroup.Text id="inputGroupPrepend">
                          RESULTS/ PAGE
                        </InputGroup.Text>
                        <Form.Select
                          onChange={(e) => setItemsPerPage(e.target.value)}
                          aria-label="Default select example"
                        >
                          <option selected={itemsPerPage === 5} value={5}>
                            5
                          </option>
                          <option selected={itemsPerPage === 10} value={10}>
                            10
                          </option>
                          <option selected={itemsPerPage === 15} value={15}>
                            15
                          </option>
                          <option selected={itemsPerPage === 25} value={25}>
                            25
                          </option>
                          <option selected={itemsPerPage === 50} value={50}>
                            50
                          </option>
                          <option selected={itemsPerPage === 100} value={100}>
                            100
                          </option>
                          <option selected={itemsPerPage === 500} value={500}>
                            500
                          </option>
                        </Form.Select>
                      </InputGroup>
                    </div>
                    <div className="inputforSecelct">
                      <InputGroup>
                        <InputGroup.Text id="inputGroupPrepend">
                          PAGE
                        </InputGroup.Text>
                        <Form.Control
                          type="number"
                          onChange={(e) => setPageNumber(e.target.value)}
                          value={pageNumber}
                          required
                          style={{ width: 65 }}
                        />
                        <InputGroup.Text id="inputGroupPrepend">
                          / {totalPage}
                        </InputGroup.Text>
                      </InputGroup>
                    </div>
                  </div>
                  <p className="m-0">
                    <b>
                      Columns : {libraryProperties?.column} | Rows :{" "}
                      {libraryProperties?.row}
                    </b>
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <ExportLibraryFileData
        Show={!toggle}
        Hide={setToggle}
        Size={"md"}
        libraryId={libraryId}
        Title={"Upload File"}
        Submit={"Submit"}
      />
    </>
  );
};

export default LibraryHelp;
