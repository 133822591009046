import React, { useState } from "react";
import { Button, Table } from "react-bootstrap";
import SendReward from "./SendReward";
import UseToggle from "../../Hooks/useToggle";

const CreditsTableData = ({
  creditDetais,
  onMouseDown,
  tableRef,
  getCreditDetailsofUser,
}) => {
  const { toggle1, setToggle1 } = UseToggle();
  const [reward, setReward] = useState("");

  const sendRewardModal = (e, item) => {
    setReward(item);
    setToggle1();
  };
  return (
    <>
      <div className="table-container overflow-auto">
        <Table id="resizable-table" className="custom-table m-0" ref={tableRef}>
          <thead>
            <tr>
              <th
                onMouseDown={(e) => onMouseDown(e, 0)}
                style={{ width: 40 }}
              >
                Sr.
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 1)}>Name</th>
              <th onMouseDown={(e) => onMouseDown(e, 2)}>Email</th>
              <th onMouseDown={(e) => onMouseDown(e, 3)} style={{ width: 150 }}>Purchased Credits</th>
              <th onMouseDown={(e) => onMouseDown(e, 4)} style={{ width: 130 }}>Reward Credits</th>
              <th onMouseDown={(e) => onMouseDown(e, 5)} style={{ width: 110 }}>Used Credit</th>
              <th onMouseDown={(e) => onMouseDown(e, 6)} style={{ width: 150 }}>Remaining Credits</th>
              <th
                onMouseDown={(e) => onMouseDown(e, 7)}
                style={{ width: 80 }}
                className="text-center"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {creditDetais?.length > 0 &&
              creditDetais?.map((item, index) => (
                <tr key={index} className="relative">
                  <td>{index + 1}</td>
                  <td>{item.fullName}</td>
                  <td>{item.email}</td>
                  <td className="green_color_status">
                    {item.purchasedCredits}
                  </td>
                  <td className="green_color_status">{item.rewardCredits}</td>
                  <td className="red_color_status">{item.usedCredit}</td>
                  <td>
                    <b>{item.remainingCredits}</b>
                  </td>
                  <td>
                    <div className="text-center action__buttons">
                      <Button
                        title="Send Reward"
                        className="rounded-circle"
                        onClick={(e) => sendRewardModal(e, item)}
                      >
                        <i className="fa-solid fa-paper-plane"></i>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {creditDetais?.length > 0 ? (
          ""
        ) : (
          <div className="noRecordFound">Record not found</div>
        )}
      </div>
      <SendReward
        Show={!toggle1}
        Hide={setToggle1}
        getCreditDetailsofUser={getCreditDetailsofUser}
        reward={reward}
        Size={"sm"}
        Title={"Send Rewards"}
        Submit={"Send"}
      />
    </>
  );
};

export default CreditsTableData;
