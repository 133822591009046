import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { programAIServices } from "../../../APIServices/AIAlpha/programAIServices.jsx";

const AddNewProgramLanguageModal = ({ Show, Hide, Size, Title, Submit, getProgramLanguage }) => {
  const [programLanguage, setProgramLanguage] = useState("")

  const addNewProgramLanguage = async () => {
    let userData = {}
    userData.language = programLanguage
    let data = await programAIServices.addNewProgramLanguage(userData)
    if (data?.statusCode === 200) {
      closeModal()
      getProgramLanguage()
    }
  }

  const closeModal = () => {
    setProgramLanguage("")
    Hide()
  }
  return (
    <Modal
      show={Show}
      onHide={closeModal}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={closeModal} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>Program Language<span>*</span></Form.Label>
            <Form.Control
              required
              type="text"
              value={programLanguage}
              onChange={(e) => setProgramLanguage(e.target.value)}
              placeholder="Enter Program Language"
            />
          </Form.Group>
        </Row>
        <div className="hstack gap-3 justify-content-end border-top pt-3 footer_button">
          <Button className="close_circle" onClick={()=>setProgramLanguage("")}>Reset</Button>
          <Button disabled={!programLanguage} onClick={addNewProgramLanguage}>{Submit}</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddNewProgramLanguageModal;
