import React from "react";
import { Outlet, Navigate } from "react-router";
import { getStore } from "../Utils/UniversalFunction";
import OtpVerification from "../component/Auth/OtpVerification";
import ChangePassword from "../component/Auth/ChangePassword";

const auth = getStore("token");

const DashboardPermission = ({ twoFA, authType, email, currentUserData, passwordUpdate }) => {
  if (auth) {
    return twoFA ?
      <OtpVerification
        authType={authType}
        currentUserData={currentUserData}
        email={email}
      />
      : !passwordUpdate ?
        <ChangePassword
          currentUserData={currentUserData}
        />
        : <Outlet />;
  } else {
    return <Navigate to="/" />;
  }
};

const LoginPermission = () => {
  if (!auth) {
    return <Outlet to="/" />;
  } else {
    return <Navigate to="/dashboard/analytics" />;
  }
};

export const Permission = {
  LoginPermission,
  DashboardPermission,
};
