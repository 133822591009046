import React, { useEffect, useState } from "react";
import Pagination from "../../../Hooks/Pagination";
import TableHeader from "../../../../component/TableHeader";
import PackageTagsTableData from "./PackageTagsTableData";
import { packageServices } from "../../../../APIServices/Editor/packageServices";
import AddNewPackageTagsModal from "./AddNewPackageTagsModal";
import ExportPackageTagsModal from "./ExportPackageTagsModal";
import UseToggle from "../../../Hooks/useToggle";

const PackageTags = ({ onMouseDown, tableRef, status, setStatus }) => {
  const { toggle, setToggle, toggle1, setToggle1 } = UseToggle();
  const [packageTagsList, setPackageTagsList] = useState([]);
  const [searchData, setSearchData] = useState("");

  const getPackageTagList = async () => {
    let data = await packageServices.getPackageTagList()
    setPackageTagsList(data?.data);
  };

  const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = packageTagsList?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(packageTagsList?.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % packageTagsList?.length;
    setItemOffset(newOffset);
  };

  const handleSearch = async (searchData) => {
    let userData = {};
    userData.searchData = searchData;
    let data = await packageServices.searchPackageTags(userData)
    setPackageTagsList(data?.data);
  };

  const openCategoryTable = () => {
    setStatus("category");
  };

  const openTagTable = () => {
    setStatus("tag");
  };

  const openReviewPackageTable = () => {
    setStatus("review");
  };

  const openRequestPackageTable = () => {
    setStatus("version");
  };

  const openRequestVersionUpdatePackageTable = () => {
    setStatus("update");
  };

  useEffect(() => {
    getPackageTagList();
  }, []);

  return (
    <>
      <TableHeader
        Search
        handleSearch={handleSearch}
        searchData={searchData}
        setSearchData={setSearchData}
        SearchReverseData={getPackageTagList}
        AddNew
        handleAddNew={setToggle}
        titleAddNew="Add New Tags"
        Export1
        handleExport1={setToggle1}
        titleExport1={"Export Tags"}
        Status={status}
        TextButton4
        TextButtonName4={"Request Packages"}
        handleTextButton4={openRequestPackageTable}
        TextButton5
        TextButtonName5={"Version Update Request"}
        handleTextButton5={openRequestVersionUpdatePackageTable}
        TextButton6
        TextButtonName6={"Review Packages"}
        handleTextButton6={openReviewPackageTable}
        TextButton7
        TextButtonName7={"Categories"}
        handleTextButton7={openCategoryTable}
        TextButton8
        TextButtonName8={"Tags"}
        handleTextButton8={openTagTable}
      />
      <PackageTagsTableData
        onMouseDown={onMouseDown}
        tableRef={tableRef}
        packageTagsList={currentItems}
        getPackageTagList={getPackageTagList}
      />
      <Pagination
        Result={packageTagsList}
        itemsPerPage={itemsPerPage}
        handlePageClick={handlePageClick}
        pageCount={pageCount}
      />
      <AddNewPackageTagsModal
        Show={!toggle}
        getPackageTagList={getPackageTagList}
        Hide={setToggle}
        Size={"md"}
        Title={"Add New Package Tags"}
        Submit={"Submit"}
      />
      <ExportPackageTagsModal
        Show={!toggle1}
        Hide={setToggle1}
        Size={"md"}
        Title={"Export Package Tags"}
        Submit={"Export"}
      />
    </>
  );
};

export default PackageTags;
