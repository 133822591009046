import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const TotalSubadminChart = ({ themeColor, subadminChartData }) => {
  const [chartData, setChartData] = useState({
    series: [],
    options: {},
  });

  useEffect(() => {
    const colorData = themeColor ? themeColor : "0 183 235";
    const colors = colorData.split(" ").map((color) => parseInt(color));
    setChartData({
      series: subadminChartData,
      options: {
        chart: {
          type: "donut",
        },
        legend: {
          position: "bottom",
        },
        responsive: [
          {
            breakpoint: 580,
            options: {
              chart: {
                width: 200,
                height: 200,
              },
            },
          },
        ],
        labels: ["Active", "Inactive", "Pending"],
        colors: [`rgba(${colors.join(", ")}, 1)`, `rgba(${colors.join(", ")}, 0.65)`, `rgba(${colors.join(", ")}, 0.3)`],
        plotOptions: {
          pie: {
            donut: {
              size: "65%", // Adjust the width of the donut here
            },
          },
        },
      },
    });
  }, [themeColor, subadminChartData]);

  return (
    <div className="graph__title">
      <p><b>Subadmin Status</b></p>
      <div id="chart">
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="donut"
          height="300px"
        />
      </div>
    </div>
  );
};

export default TotalSubadminChart;
