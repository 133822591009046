import config from "../../config/config.json";
import { headers } from "../../APIHelpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody
} from "../../APIHelpers/Responses";

export const programAIServices = {
  //Program Language
  searchProgramLanguage,
  addNewProgramLanguage,
  updateProgramLanguage,
  changeProgramLanguageStatus,
  deleteProgramLanguage,
  getProgramLanguage,
  
  //Program Prompt
  searchProgramPrompt,
  addNewProgramPrompt,
  updateProgramPrompt,
  changeProgramPromptStatus,
  deleteProgramPrompt,
  getProgramPrompts,
};

// Add New Document Case
async function searchProgramLanguage(userData) {
  const response = await fetch(
    `${config.API_URL}searchProgramLanguage`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Add New Document Case
async function addNewProgramLanguage(userData) {
  const response = await fetch(
    `${config.API_URL}addNewProgramLanguage`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Update Document Case
async function updateProgramLanguage(userData) {
  const response = await fetch(
    `${config.API_URL}updateProgramLanguage`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Change Status Document Case
async function changeProgramLanguageStatus(userData) {
  const response = await fetch(
    `${config.API_URL}changeProgramLanguageStatus`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Get All Document Case List for Table
async function getProgramLanguage() {
  const response = await fetch(
    `${config.API_URL}getProgramLanguage`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Delete Document Case
async function deleteProgramLanguage(id) {
  const response = await fetch(
    `${config.API_URL}deleteProgramLanguage/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Add New Document Metadata
async function searchProgramPrompt(userData) {
  const response = await fetch(
    `${config.API_URL}searchProgramPrompt`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Add New Document Metadata
async function addNewProgramPrompt(userData) {
  const response = await fetch(
    `${config.API_URL}addNewProgramPrompt`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Update Document Metadata
async function updateProgramPrompt(userData) {
  const response = await fetch(
    `${config.API_URL}updateProgramPrompt`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Change Status Document Metadata
async function changeProgramPromptStatus(userData) {
  const response = await fetch(
    `${config.API_URL}changeProgramPromptStatus`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Get All Document Metadata List for Table
async function getProgramPrompts(programId) {
  const response = await fetch(
    `${config.API_URL}getProgramPrompts/${programId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Delete Document Metadata
async function deleteProgramPrompt(id) {
  const response = await fetch(
    `${config.API_URL}deleteProgramPrompt/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}