import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import UseToggle from "../../Hooks/useToggle";
import Pagination from "../../Hooks/Pagination";
import AddNewDocumentCaseModal from "./AddNewDocumentCaseModal";
import { documentAIServices } from "../../../APIServices/AIAlpha/documentAIServices";
import TableHeader from "../../../component/TableHeader";
import DocumentCaseTableData from "./DocumentCaseTableData";
import DocumentMetadataTableData from "./DocumentMetadatas/DocumentMetadataTableData";

const AIDocuments = ({ onMouseDown, tableRef }) => {
  const { toggle, setToggle } = UseToggle();
  const [viewDocumentMetadata, setViewDocumentMetadata] = useState("");
  const [documentCaseId, setDocumentCaseId] = useState("");
  const [searchData, setSearchData] = useState("");
  const [documentCaseList, setDocumentCaseList] = useState([]);
  const [dcouementMetadataList, setDocumentMetadataList] = useState([]);
  const getDocumentCaseList = async () => {
    let data = await documentAIServices.getDocumentCaseList();
    setDocumentCaseList(data?.data);
  };

  const getDocumentMetadataList = async (caseId) => {
    setDocumentCaseId(caseId);
    let data = await documentAIServices.getDocumentMetadataList(caseId);
    setDocumentMetadataList(data?.data);
  };

  const itemsPerPageDocumentCase = 10;
  const [itemOffsetDocumentCase, setItemOffsetDocumentCase] = useState(0);
  const endOffsetDocumentCase =
    itemOffsetDocumentCase + itemsPerPageDocumentCase;
  const currentItemsDocumentCase = documentCaseList?.slice(
    itemOffsetDocumentCase,
    endOffsetDocumentCase
  );
  const pageCountDocumentCase = Math.ceil(
    documentCaseList?.length / itemsPerPageDocumentCase
  );
  const handlePageClickDocumentCase = (event) => {
    const newOffset =
      (event.selected * itemsPerPageDocumentCase) % documentCaseList?.length;
    setItemOffsetDocumentCase(newOffset);
  };

  const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = dcouementMetadataList?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(dcouementMetadataList?.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % dcouementMetadataList?.length;
    setItemOffset(newOffset);
  };

  const handleSearch = async (searchValue) => {
    let userData = {};
    userData.searchData = searchValue || searchData;
    let data = await documentAIServices.searchDocumentCase(userData);
    setDocumentCaseList(data?.data);
  };

  useEffect(() => {
    getDocumentCaseList();
  }, []);
  return (
    <>
      <Row>
        <Col md={6} className="mb-3">
          <TableHeader
            Search
            handleSearch={handleSearch}
            setSearchData={setSearchData}
            SearchReverseData={getDocumentCaseList}
            AddNew
            handleAddNew={setToggle}
            titleAddNew="Add New Document case"
          />
          <DocumentCaseTableData
            onMouseDown={onMouseDown}
            tableRef={tableRef}
            documentCaseList={currentItemsDocumentCase}
            setViewDocumentMetadata={setViewDocumentMetadata}
            getDocumentMetadataList={getDocumentMetadataList}
            getDocumentCaseList={getDocumentCaseList}
          />
          <Pagination
            Result={currentItemsDocumentCase}
            itemsPerPage={itemsPerPageDocumentCase}
            handlePageClick={handlePageClickDocumentCase}
            pageCount={pageCountDocumentCase}
          />
        </Col>
        {viewDocumentMetadata && (
          <Col md={6}>
            <DocumentMetadataTableData
              onMouseDown={onMouseDown}
              tableRef={tableRef}
              viewDocumentMetadata={viewDocumentMetadata}
              dcouementMetadataList={currentItems}
              caseId={documentCaseId}
              getDocumentMetadataList={getDocumentMetadataList}
              setDocumentMetadataList={setDocumentMetadataList}
            />
            <Pagination
              Result={dcouementMetadataList}
              itemsPerPage={itemsPerPage}
              handlePageClick={handlePageClick}
              pageCount={pageCount}
            />
          </Col>
        )}
      </Row>
      <AddNewDocumentCaseModal
        Show={!toggle}
        getDocumentCaseList={getDocumentCaseList}
        Hide={setToggle}
        Size={"md"}
        Title={"Add New Document Case"}
        Submit={"Submit"}
      />
    </>
  );
};

export default AIDocuments;
