import React, { useState } from "react";
import ChangeFont from "./ChangeFont";
import ChangeColor from "./ChangeColor";
import ChangeStatusColor from "./ChangeStatusColor";

const Appearance = ({
  getThemeColor,
  themeColor,
  getThemeFont,
  font,
  getStatusColor,
  activeColor,
  inActiveColor,
  pendingColor,
}) => {
  const [activeOptionTab, setActiveOptionTab] = useState("font");
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <>
      <div className="d-flex">
        <div
          style={{ width: isCollapsed ? "0%" : "20%" }}
          className={`rightHeightColumn ${
            isCollapsed ? "collapsed" : "collapsed"
          }`}
        >
          <div
            className={`mx-2 ${
              isCollapsed ? "leaveOptionBox" : "leaveOptionBox "
            }`}
          >
            <div
              onClick={() => setActiveOptionTab("font")}
              className={
                activeOptionTab === "font"
                  ? "optionActiveButton"
                  : "optionButton"
              }
            >
              Change Theme Font
            </div>
            <div
              onClick={() => setActiveOptionTab("color")}
              className={
                activeOptionTab === "color"
                  ? "optionActiveButton"
                  : "optionButton"
              }
            >
              Change Theme Color
            </div>
            <div
              onClick={() => setActiveOptionTab("statusColor")}
              className={
                activeOptionTab === "statusColor"
                  ? "optionActiveButton"
                  : "optionButton"
              }
            >
              Change Status Color
            </div>
          </div>
        </div>
        <div
          style={{
            width: isCollapsed ? "100%" : "80%",
            background: "#fff",
            marginLeft: isCollapsed ? "0" : "auto",
          }}
          className={isCollapsed ? "collapsed" : "collapsed ps-1"}
        >
          <div className="helpComponentSection">
            <div className="d-flex justify-content-between mb-2">
              <div className=" d-flex  gap-3 w-100">
                <i
                  id="menu-btn"
                  title={isCollapsed ? "Open Sidebar" : "Close Sidebar"}
                  className={`fa-solid ${
                    isCollapsed ? "fa-bars-staggered" : "fa-bars"
                  }`}
                  onClick={toggleCollapse}
                ></i>
                <div className="leaveManage w-100">
                  {activeOptionTab === "font" ? (
                    <ChangeFont
                      getThemeFontData={getThemeFont}
                      fontData={font}
                    />
                  ) : activeOptionTab === "color" ? (
                    <ChangeColor
                      getThemeColorData={getThemeColor}
                      themeColor={themeColor}
                    />
                  ) : activeOptionTab === "statusColor" ? (
                    <ChangeStatusColor
                      getStatusColorData={getStatusColor}
                      activeColorData={activeColor}
                      inActiveColorData={inActiveColor}
                      pendingColorData={pendingColor}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Appearance;
