import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { authServices } from "../APIServices/authServices";
import { Collapse } from "react-bootstrap";
import { MdAssessment, MdOutlineBarChart, MdUpdate } from "react-icons/md";
import { FaChalkboardTeacher, FaCommentAlt, FaTools } from "react-icons/fa";
import { BiWorld } from "react-icons/bi";
import { VscSymbolMethod, VscSymbolVariable } from "react-icons/vsc";

function Sidebar({
  roleType,
  userCurrrentData,
  isSidebarCollapsed,
  setSidebarCollapsed,
  screenWidth,
  permalink,
  setShowLargeMenuAccount,
  showLargeMenuAccount,
  setShowLargeMenuSubadmin,
  showLargeMenuSubadmin,
  showLargeMenuEditorControl,
  setShowLargeMenuEditorControl,
  showLargeMenuCDISCControl,
  setShowLargeMenuCDISCControl,
  setShowLargeMenuAIAlphaControl,
  showLargeMenuAIAlphaControl,
  AIUserPermission,
  ConsultantPermission,
  DeveloperPermission,
  OrganizationPermission,
}) {
  const accountRef = useRef(null);
  const subadminRef = useRef(null);
  const editorControlRef = useRef(null);
  const cdiscControlRef = useRef(null);
  const aiAlphaControlRef = useRef(null);
  const [viewSDTM, setViewSDTM] = useState(false);
  const [viewTerminology, setViewTerminology] = useState(false);
  const [viewVariable, setViewVariable] = useState(false);
  const [showMobileMenuAccount, setShowMobileMenuAccount] = useState(false);
  const [showMobileMenuSubadmin, setShowMobileMenuSubadmin] = useState(false);
  const [showMobileMenuEditorControl, setShowMobileMenuEditorControl] =
    useState(false);
  const [showMobileMenuCDISCControl, setShowMobileMenuCDISCControl] =
    useState(false);
  const [showMobileMenuAIAlphaControl, setShowMobileMenuAIAlphaControl] =
    useState(false);

  const handleMobileSidebar = () => {
    if (screenWidth < 767) {
      setSidebarCollapsed(!isSidebarCollapsed);
    }
  };

  const logout = async () => {
    await authServices.logout();
  };

  const handleShowMenuAccount = () => {
    setShowMobileMenuAccount(!showMobileMenuAccount);
    setShowLargeMenuAccount(!showLargeMenuAccount);
    setShowLargeMenuCDISCControl(false);
    setShowLargeMenuSubadmin(false);
    setShowLargeMenuEditorControl(false);
    setShowLargeMenuAIAlphaControl(false);
  };

  const handleShowMenuSubadmin = () => {
    setShowMobileMenuSubadmin(!showMobileMenuSubadmin);
    setShowLargeMenuSubadmin(!showLargeMenuSubadmin);
    setShowLargeMenuCDISCControl(false);
    setShowLargeMenuAccount(false);
    setShowLargeMenuEditorControl(false);
    setShowLargeMenuAIAlphaControl(false);
  };

  const handleShowMenuEditorControl = () => {
    setShowMobileMenuEditorControl(!showMobileMenuEditorControl);
    setShowLargeMenuEditorControl(!showLargeMenuEditorControl);
    setShowLargeMenuCDISCControl(false);
    setShowLargeMenuSubadmin(false);
    setShowLargeMenuAccount(false);
    setShowLargeMenuAIAlphaControl(false);
  };

  const handleShowMenuCDISCControl = () => {
    setShowMobileMenuCDISCControl(!showMobileMenuCDISCControl);
    setShowLargeMenuCDISCControl(!showMobileMenuCDISCControl);
    setShowLargeMenuEditorControl(false);
    setShowLargeMenuSubadmin(false);
    setShowLargeMenuAccount(false);
    setShowLargeMenuAIAlphaControl(false);
  };

  const handleShowMenuAIAlphaControl = () => {
    setShowMobileMenuAIAlphaControl(!showMobileMenuAIAlphaControl);
    setShowLargeMenuAIAlphaControl(!showLargeMenuAIAlphaControl);
    setShowLargeMenuCDISCControl(false);
    setShowLargeMenuSubadmin(false);
    setShowLargeMenuAccount(false);
    setShowLargeMenuEditorControl(false);
  };

  useEffect(() => {
    const handleClickAccountOutside = (event) => {
      if (accountRef.current && !accountRef.current.contains(event.target)) {
        setShowMobileMenuAccount(false);
      }
    };
    document.addEventListener("click", handleClickAccountOutside, true);

    const handleClickSubadminMenuOutside = (event) => {
      if (subadminRef.current && !subadminRef.current.contains(event.target)) {
        setShowMobileMenuSubadmin(false);
      }
    };
    document.addEventListener("click", handleClickSubadminMenuOutside, true);

    const handleClickEditorControlMenuOutside = (event) => {
      if (
        editorControlRef.current &&
        !editorControlRef.current.contains(event.target)
      ) {
        setShowMobileMenuEditorControl(false);
      }
    };
    document.addEventListener(
      "click",
      handleClickEditorControlMenuOutside,
      true
    );

    const handleClickCDISCControlMenuOutside = (event) => {
      if (
        cdiscControlRef.current &&
        !cdiscControlRef.current.contains(event.target)
      ) {
        setShowMobileMenuCDISCControl(false);
      }
    };
    document.addEventListener(
      "click",
      handleClickCDISCControlMenuOutside,
      true
    );

    const handleClickAIAlphaControlMenuOutside = (event) => {
      if (
        aiAlphaControlRef.current &&
        !aiAlphaControlRef.current.contains(event.target)
      ) {
        setShowMobileMenuAIAlphaControl(false);
      }
    };
    document.addEventListener(
      "click",
      handleClickAIAlphaControlMenuOutside,
      true
    );

    return () => {
      document.removeEventListener(
        "click",
        handleClickSubadminMenuOutside,
        true
      );
      document.removeEventListener("click", handleClickAccountOutside, true);
      document.removeEventListener(
        "click",
        handleClickEditorControlMenuOutside,
        true
      );
      document.removeEventListener(
        "click",
        handleClickAIAlphaControlMenuOutside,
        true
      );
    };
  }, []);

  return (
    <div className="sidebar-section sidebar-top">
      <div className={isSidebarCollapsed ? "mobileLogo" : "deskToplogo"}>
        <div className="d-flex align-items-center">
          {/* <i className="fa-solid fa-capsules mx-2" aria-hidden="true"></i> */}
          <div className="op-name" style={{ fontSize: 17, fontWeight: 900 }}>
            {roleType === "organization"
              ? userCurrrentData?.shortName
              : "GAS Control Panel"}
          </div>
        </div>
        <div
          onClick={() => setSidebarCollapsed(!isSidebarCollapsed)}
          className={"close_icon"}
        >
          <i className="fa-solid fa-circle-xmark mx-1"></i>
        </div>
      </div>
      <ul className="side-menu scrollbox">
        <div className="menu-item-area">
          <div className="tooltip">
            <div className="tooltipContent">Dashboard</div>
          </div>
          <NavLink to="/dashboard/analytics" onClick={handleMobileSidebar}>
            <li className={permalink === "analytics" ? "sidebar__active" : ""}>
              <div className="side-menu-item ">
                <i className="fa-solid fa-gauge"></i>
                <span className="op-name">Dashboard</span>
              </div>
            </li>
          </NavLink>
        </div>
        {roleType === "organization" && (
          <>
            <div className="menu-item-area">
              <div className="tooltip">
                <div className="tooltipContent">Reviewers</div>
              </div>
              <NavLink to="/dashboard/reviewers">
                <li
                  className={permalink === "reviewers" ? "sidebar__active" : ""}
                  onClick={handleMobileSidebar}
                >
                  <div className="side-menu-item">
                    <i
                      className="fa-solid fa-user-tie"
                      style={{ marginLeft: 2 }}
                    ></i>
                    <span className="op-name">Reviewers</span>
                  </div>
                </li>
              </NavLink>
            </div>
            <div className="menu-item-area">
              <div className="tooltip">
                <div className="tooltipContent">Validators</div>
              </div>
              <NavLink to="/dashboard/validators">
                <li
                  className={
                    permalink === "validators" ? "sidebar__active" : ""
                  }
                  onClick={handleMobileSidebar}
                >
                  <div className="side-menu-item">
                    <i
                      className="fa-solid fa-user-shield"
                      style={{ marginLeft: 2, marginRight: 5 }}
                    ></i>
                    <span className="op-name">Validators</span>
                  </div>
                </li>
              </NavLink>
            </div>
            <div className="menu-item-area">
              <div className="tooltip">
                <div className="tooltipContent">Review New Packages</div>
              </div>
              <NavLink to="/dashboard/packages">
                <li
                  className={permalink === "packages" ? "sidebar__active" : ""}
                  onClick={handleMobileSidebar}
                >
                  <div className="side-menu-item">
                    <i
                      className="fa-solid fa-cube"
                      style={{ marginLeft: 2, marginRight: 8 }}
                    ></i>
                    <span className="op-name">Review New Packages</span>
                  </div>
                </li>
              </NavLink>
            </div>
            <div className="menu-item-area">
              <div className="tooltip">
                <div className="tooltipContent">Review Updated Packages</div>
              </div>
              <NavLink to="/dashboard/updatedPackages">
                <li
                  className={
                    permalink === "updatedPackages" ? "sidebar__active" : ""
                  }
                  onClick={handleMobileSidebar}
                >
                  <div className="side-menu-item">
                    <i
                      className="fa-solid fa-cube"
                      style={{ marginLeft: 2, marginRight: 8 }}
                    ></i>
                    <span className="op-name">Review Updated Packages</span>
                  </div>
                </li>
              </NavLink>
            </div>
          </>
        )}
        {roleType === "reviewer" && (
          <div className="menu-item-area">
            <div className="tooltip">
              <div className="tooltipContent">Packages</div>
            </div>
            <NavLink to="/dashboard/packages">
              <li
                className={permalink === "packages" ? "sidebar__active" : ""}
                onClick={handleMobileSidebar}
              >
                <div className="side-menu-item">
                  <i
                    className="fa-solid fa-cube"
                    style={{ marginLeft: 2, marginRight: 8 }}
                  ></i>
                  <span className="op-name">Packages</span>
                </div>
              </li>
            </NavLink>
          </div>
        )}

        {roleType === "admin" && (
          <div className="menu-item-area" ref={cdiscControlRef}>
            {!showMobileMenuCDISCControl && (
              <div className="tooltip">
                <div className="tooltipContent">CDISC Control</div>
              </div>
            )}
            <li
              onClick={() => handleShowMenuCDISCControl()}
              style={{ cursor: "pointer" }}
              className={["terminology", "stdm-terminology", "cdisc-client", "sdtm-version", "sdtm-class", "sdtm-domain", "sdtm-variable", "adam", "variable-method", "variable-comment"].includes(permalink) ? "sidebar__active" : ""}
            >
              <div className="side-menu-item" style={{ cursor: "pointer" }}>
                <i
                  className="fa-solid fa-laptop-code"
                  style={{ marginLeft: -2, marginRight: 9 }}
                ></i>
                <span className="op-name">
                  CDISC Control{" "}
                  {showMobileMenuCDISCControl ? (
                    <i className="fa-solid fa-caret-down align_arrow"></i>
                  ) : (
                    <i className="fa-solid fa-caret-right align_arrow"></i>
                  )}
                </span>
              </div>
            </li>
            {!isSidebarCollapsed
              ?
              <Collapse in={showLargeMenuCDISCControl}>
                <div>
                  <ul className="editor">
                    <li
                      onClick={() => setViewTerminology(!viewTerminology)}
                      style={{ cursor: "pointer", marginBottom: 4 }}
                      className={(permalink === "terminology" || permalink === "stdm-terminology") ? "sidebar__active" : ""}
                    >
                      <div className="side-menu-item" style={{ cursor: "pointer", margin: "10px 0" }}>
                        <i
                          className="fa-solid fa-book"
                          style={{ marginLeft: -2, marginRight: 9 }}
                        ></i>
                        <span className="op-name">
                          Terminology{" "}
                          {viewTerminology ? (
                            <i className="fa-solid fa-caret-down align_arrow"></i>
                          ) : (
                            <i className="fa-solid fa-caret-right align_arrow"></i>
                          )}
                        </span>
                      </div>
                    </li>
                    <Collapse in={viewTerminology}>
                      <div>
                        <NavLink
                          to="/dashboard/terminology"
                          onClick={handleMobileSidebar}
                        >
                          <li
                            className={`ms-4 ${permalink === "terminology"
                              ? "sidebar__active mb-1"
                              : "mb-1"}`
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <div className="side-menu-item Sidebar_submenu_padding_btn m-0">
                              <i className="fas fa-book-open"></i>
                              <span className="op-name" style={{ marginLeft: 0 }}>
                                Terminology
                              </span>
                            </div>
                          </li>
                        </NavLink>
                        <NavLink to="/dashboard/stdm-terminology" onClick={handleMobileSidebar}>
                          <li className={`ms-4 ${permalink === "stdm-terminology" ? "sidebar__active mb-1" : "mb-1"}`}
                            style={{ cursor: "pointer" }}
                          >
                            <div className="side-menu-item Sidebar_submenu_padding_btn m-0">
                              <i class="fas fa-globe"></i>
                              <span className="op-name" style={{ marginLeft: 2 }}>
                                STDM Terminology
                              </span>
                            </div>
                          </li>
                        </NavLink>
                      </div>
                    </Collapse>
                    <NavLink
                      to="/dashboard/cdisc-client"
                      onClick={handleMobileSidebar}
                    >
                      <li
                        className={
                          permalink === "cdisc-client"
                            ? "sidebar__active mb-1"
                            : "mb-1"
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <div className="side-menu-item Sidebar_submenu_padding_btn m-0">
                          <i class="fas fa-user-tie"></i>
                          <span className="op-name" style={{ marginLeft: -3 }}>
                            CDISC Client
                          </span>
                        </div>
                      </li>
                    </NavLink>
                    <li
                      onClick={() => setViewSDTM(!viewSDTM)}
                      style={{ cursor: "pointer", marginBottom: 4 }}
                      className={(permalink === "sdtm-version" || permalink === "sdtm-class" || permalink === "sdtm-domain" || permalink === "variable-method" || permalink === "variable-comment" || permalink === "sdtm-variable") ? "sidebar__active" : ""}
                    >
                      <div className="side-menu-item" style={{ cursor: "pointer", margin: "10px 0" }}>
                        <MdOutlineBarChart style={{ marginLeft: -2, marginRight: 9, fontSize: 18, fontWeight: "bold" }} />
                        <span className="op-name">
                          SDTM{" "}
                          {viewSDTM ? (
                            <i className="fa-solid fa-caret-down align_arrow"></i>
                          ) : (
                            <i className="fa-solid fa-caret-right align_arrow"></i>
                          )}
                        </span>
                      </div>
                    </li>
                    <Collapse in={viewSDTM}>
                      <div>
                        <NavLink to="/dashboard/sdtm-version" onClick={handleMobileSidebar}>
                          <li
                            className={`ms-4 ${permalink === "sdtm-version" ? "sidebar__active mb-1" : "mb-1"}`
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <div className="side-menu-item Sidebar_submenu_padding_btn m-0">
                              <MdUpdate style={{ marginLeft: -2, marginRight: 9, fontSize: 18, fontWeight: "bold" }} />
                              <span className="op-name" style={{ marginLeft: -3 }}>
                                Version
                              </span>
                            </div>
                          </li>
                        </NavLink>
                        <NavLink to="/dashboard/sdtm-class" onClick={handleMobileSidebar}>
                          <li
                            className={`ms-4 ${permalink === "sdtm-class" ? "sidebar__active mb-1" : "mb-1"}`
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <div className="side-menu-item Sidebar_submenu_padding_btn m-0">
                              <FaChalkboardTeacher style={{ marginLeft: -2, marginRight: 9, fontSize: 18, fontWeight: "bold" }} />
                              <span className="op-name" style={{ marginLeft: -3 }}>
                                Class
                              </span>
                            </div>
                          </li>
                        </NavLink>
                        <NavLink to="/dashboard/sdtm-domain" onClick={handleMobileSidebar}>
                          <li
                            className={`ms-4 ${permalink === "sdtm-domain" ? "sidebar__active mb-1" : "mb-1"}`
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <div className="side-menu-item Sidebar_submenu_padding_btn m-0">
                              <BiWorld style={{ marginLeft: -2, marginRight: 9, fontSize: 18, fontWeight: "bold" }} />
                              <span className="op-name" style={{ marginLeft: -3 }}>
                                Domain
                              </span>
                            </div>
                          </li>
                        </NavLink>
                        <li
                          onClick={() => setViewVariable(!viewVariable)}
                          style={{ cursor: "pointer", marginBottom: 4 }}
                          className={`ms-4 ${(permalink === "variable-method" || permalink === "variable-comment" || permalink === "sdtm-variable") ? "sidebar__active" : ""}`}
                        >
                          <div className="side-menu-item" style={{ cursor: "pointer", margin: "10px 0" }}>
                            <VscSymbolMethod style={{ marginLeft: -2, marginRight: 9, fontSize: 18, fontWeight: "bold" }} />
                            <span className="op-name">
                              Veriable{" "}
                              {viewVariable ? (
                                <i className="fa-solid fa-caret-down align_arrow"></i>
                              ) : (
                                <i className="fa-solid fa-caret-right align_arrow"></i>
                              )}
                            </span>
                          </div>
                        </li>
                        <Collapse in={viewVariable}>
                          <div>
                            <NavLink to="/dashboard/variable-method" onClick={handleMobileSidebar}>
                              <li className={`ms-5 ${permalink === "veriable-method" ? "sidebar__active mb-1" : "mb-1"}`}
                                style={{ cursor: "pointer" }}
                              >
                                <div className="side-menu-item Sidebar_submenu_padding_btn m-0">
                                  <FaTools style={{ marginLeft: -2, marginRight: 9, fontSize: 15, fontWeight: "bold" }} />
                                  <span className="op-name" style={{ marginLeft: -3 }}>
                                    Method
                                  </span>
                                </div>
                              </li>
                            </NavLink>
                            <NavLink to="/dashboard/variable-comment" onClick={handleMobileSidebar}>
                              <li className={`ms-5 ${permalink === "variable-comment" ? "sidebar__active mb-1" : "mb-1"}`}
                                style={{ cursor: "pointer" }}
                              >
                                <div className="side-menu-item Sidebar_submenu_padding_btn m-0">
                                  <FaCommentAlt style={{ marginLeft: -2, marginRight: 9, fontSize: 15, fontWeight: "bold" }} />
                                  <span className="op-name" style={{ marginLeft: -3 }}>
                                    Comment
                                  </span>
                                </div>
                              </li>
                            </NavLink>
                            <NavLink to="/dashboard/sdtm-variable" onClick={handleMobileSidebar}>
                              <li
                                className={`ms-5 ${permalink === "sdtm-variable" ? "sidebar__active mb-1" : "mb-1"}`
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <div className="side-menu-item Sidebar_submenu_padding_btn m-0">
                                  <VscSymbolVariable style={{ marginLeft: -2, marginRight: 9, fontSize: 18, fontWeight: "bold" }} />
                                  <span className="op-name" style={{ marginLeft: -3 }}>
                                    Variable
                                  </span>
                                </div>
                              </li>
                            </NavLink>
                            <NavLink to="/dashboard/value-level" onClick={handleMobileSidebar}>
                              <li
                                className={`ms-5 ${permalink === "value-level" ? "sidebar__active mb-1" : "mb-1"}`
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <div className="side-menu-item Sidebar_submenu_padding_btn m-0">
                                  <BiWorld style={{ marginLeft: -2, marginRight: 9, fontSize: 18, fontWeight: "bold" }} />
                                  <span className="op-name" style={{ marginLeft: -3 }}>
                                    Value Level
                                  </span>
                                </div>
                              </li>
                            </NavLink>
                          </div>
                        </Collapse>



                      </div>
                    </Collapse>
                    <NavLink to="/dashboard/adam" onClick={handleMobileSidebar}>
                      <li
                        className={
                          permalink === "adam" ? "sidebar__active mb-1" : "mb-1"
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <div className="side-menu-item Sidebar_submenu_padding_btn m-0">
                          <MdAssessment style={{ marginLeft: -2, marginRight: 9, fontSize: 18, fontWeight: "bold" }} />
                          <span className="op-name" style={{ marginLeft: -3 }}>
                            ADAM
                          </span>
                        </div>
                      </li>
                    </NavLink>
                    <NavLink to="/dashboard/data-template" onClick={handleMobileSidebar}>
                      <li
                        className={
                          permalink === "data-template" ? "sidebar__active mb-1" : "mb-1"
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <div className="side-menu-item Sidebar_submenu_padding_btn m-0">
                          <MdAssessment style={{ marginLeft: -2, marginRight: 9, fontSize: 18, fontWeight: "bold" }} />
                          <span className="op-name" style={{ marginLeft: -3 }}>
                            Data Template
                          </span>
                        </div>
                      </li>
                    </NavLink>
                  </ul>
                </div>
              </Collapse>
              : showMobileMenuCDISCControl && (
                <div className="collapsed__submenu setting box-shadow-max">
                  <NavLink
                    to="/dashboard/terminology"
                    onClick={() =>
                      setShowMobileMenuCDISCControl(
                        !showMobileMenuCDISCControl
                      )
                    }
                  >
                    <div
                      className={`side-menu-item Sidebar_submenu_padding_btn small__sidebar gap-3 mt-0 ${permalink === "termionology"
                        ? "small__sidebar_activeSubmenu"
                        : ""
                        }`}
                    >
                      <i className="fa-solid fa-building-columns"></i>
                      <span>Termionology</span>
                    </div>
                  </NavLink>
                  <NavLink
                    to="/dashboard/cdisc-client"
                    onClick={() =>
                      setShowMobileMenuCDISCControl(
                        !showMobileMenuCDISCControl
                      )
                    }
                  >
                    <div
                      className={`side-menu-item Sidebar_submenu_padding_btn small__sidebar gap-3 mt-0 ${permalink === "cdisc-client"
                        ? "small__sidebar_activeSubmenu"
                        : ""
                        }`}
                    >
                      <i className="fa-solid fa-building-columns"></i>
                      <span>CDISC Client</span>
                    </div>
                  </NavLink>
                </div>
              )}
          </div>
        )}

        {(roleType === "admin" ||
          ConsultantPermission ||
          OrganizationPermission ||
          DeveloperPermission) && (
            <div className="menu-item-area" ref={editorControlRef}>
              {!showMobileMenuEditorControl && (
                <div className="tooltip">
                  <div className="tooltipContent">Editor Control</div>
                </div>
              )}
              <li
                onClick={() => handleShowMenuEditorControl()}
                style={{ cursor: "pointer" }}
                className={
                  permalink === "consultants" ||
                    permalink === "developers" ||
                    permalink === "packages" ||
                    permalink === "packages-revenue" ||
                    permalink === "organizations" ||
                    permalink === "editor-subscriptions"
                    ? "sidebar__active"
                    : ""
                }
              >
                <div className="side-menu-item" style={{ cursor: "pointer" }}>
                  <i
                    className="fa-solid fa-laptop-code"
                    style={{ marginLeft: -2, marginRight: 9 }}
                  ></i>
                  <span className="op-name">
                    Editor Control{" "}
                    {showMobileMenuEditorControl ? (
                      <i className="fa-solid fa-caret-down align_arrow"></i>
                    ) : (
                      <i className="fa-solid fa-caret-right align_arrow"></i>
                    )}
                  </span>
                </div>
              </li>
              {!isSidebarCollapsed ?
                <Collapse in={showLargeMenuEditorControl}>
                  <div>
                    <ul className="editor">
                      {ConsultantPermission && (
                        <NavLink
                          to="/dashboard/organizations"
                          onClick={handleMobileSidebar}
                        >
                          <li
                            className={
                              permalink === "organizations"
                                ? "sidebar__active mb-1"
                                : "mb-1"
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <div className="side-menu-item Sidebar_submenu_padding_btn m-0">
                              <i className="fa-solid fa-building-columns"></i>
                              <span className="op-name" style={{ marginLeft: 0 }}>
                                Organizations
                              </span>
                            </div>
                          </li>
                        </NavLink>
                      )}
                      {ConsultantPermission && (
                        <NavLink
                          to="/dashboard/consultants"
                          onClick={handleMobileSidebar}
                        >
                          <li
                            className={
                              permalink === "consultants"
                                ? "sidebar__active mb-1"
                                : "mb-1"
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <div className="side-menu-item Sidebar_submenu_padding_btn m-0">
                              <i className="fa-solid fa-building-user"></i>
                              <span
                                className="op-name"
                                style={{ marginLeft: -3 }}
                              >
                                Consultants
                              </span>
                            </div>
                          </li>
                        </NavLink>
                      )}
                      {DeveloperPermission && (
                        <NavLink
                          to="/dashboard/developers"
                          onClick={handleMobileSidebar}
                        >
                          <li
                            className={
                              permalink === "developers"
                                ? "sidebar__active mb-1"
                                : "mb-1"
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <div className="side-menu-item Sidebar_submenu_padding_btn m-0">
                              <i className="fa-solid fa-user-pen"></i>
                              <span
                                className="op-name"
                                style={{ marginLeft: "-3px" }}
                              >
                                Developers
                              </span>
                            </div>
                          </li>
                        </NavLink>
                      )}
                      <NavLink
                        to="/dashboard/packages"
                        onClick={handleMobileSidebar}
                      >
                        <li
                          className={
                            permalink === "packages"
                              ? "sidebar__active mb-1"
                              : "mb-1"
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <div className="side-menu-item Sidebar_submenu_padding_btn m-0">
                            <i className="fa-solid fa-cube"></i>
                            <span className="op-name">Packages</span>
                          </div>
                        </li>
                      </NavLink>
                      {roleType === "admin" && (
                        <NavLink
                          to="/dashboard/packages-revenue"
                          onClick={handleMobileSidebar}
                        >
                          <li
                            className={
                              permalink === "packages-revenue"
                                ? "sidebar__active mb-1"
                                : "mb-1"
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <div className="side-menu-item Sidebar_submenu_padding_btn m-0">
                              <i className="fa-solid fa-cubes"></i>
                              <span
                                className="op-name"
                                style={{ marginLeft: -1 }}
                              >
                                Packages Revenue
                              </span>
                            </div>
                          </li>
                        </NavLink>
                      )}
                      {DeveloperPermission && (
                        <NavLink
                          to="/dashboard/editor-subscriptions"
                          onClick={handleMobileSidebar}
                        >
                          <li
                            className={
                              permalink === "editor-subscriptions"
                                ? "sidebar__active mb-1"
                                : "mb-1"
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <div className="side-menu-item Sidebar_submenu_padding_btn m-0">
                              <i className="fa-solid fa-money-check-dollar"></i>
                              <span
                                className="op-name"
                                style={{ marginLeft: "-1px" }}
                              >
                                Subscription Plans
                              </span>
                            </div>
                          </li>
                        </NavLink>
                      )}
                      {DeveloperPermission && (
                        <NavLink
                          to="/dashboard/library-help"
                          onClick={handleMobileSidebar}
                        >
                          <li
                            className={
                              permalink === "library-help"
                                ? "sidebar__active mb-1"
                                : "mb-1"
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <div className="side-menu-item Sidebar_submenu_padding_btn m-0">
                              <i
                                className="fa-solid fa-folder-tree"
                              // style={{ marginLeft: 2, marginRight: 11 }}
                              ></i>
                              <span
                                className="op-name"
                                style={{ marginLeft: "0px" }}
                              >
                                {" "}
                                Library Help
                              </span>
                            </div>
                          </li>
                        </NavLink>
                      )}
                    </ul>
                  </div>
                </Collapse>
                : showMobileMenuEditorControl && (
                  <div className="collapsed__submenu setting box-shadow-max">
                    <NavLink
                      to="/dashboard/organizations"
                      onClick={() =>
                        setShowMobileMenuEditorControl(
                          !showMobileMenuEditorControl
                        )
                      }
                    >
                      <div
                        className={`side-menu-item Sidebar_submenu_padding_btn small__sidebar gap-3 mt-0 ${permalink === "organizations"
                          ? "small__sidebar_activeSubmenu"
                          : ""
                          }`}
                      >
                        <i className="fa-solid fa-building-columns"></i>
                        <span>Organizations</span>
                      </div>
                    </NavLink>
                    <NavLink
                      to="/dashboard/packages"
                      onClick={() =>
                        setShowMobileMenuEditorControl(
                          !showMobileMenuEditorControl
                        )
                      }
                    >
                      <div
                        className={`side-menu-item Sidebar_submenu_padding_btn small__sidebar gap-3 mt-0 ${permalink === "packages"
                          ? "small__sidebar_activeSubmenu"
                          : ""
                          }`}
                      >
                        <i className="fa-solid fa-cube"></i>
                        <span>Packages</span>
                      </div>
                    </NavLink>
                    {roleType === "admin" && (
                      <NavLink
                        to="/dashboard/packages-revenue"
                        onClick={() =>
                          setShowMobileMenuEditorControl(
                            !showMobileMenuEditorControl
                          )
                        }
                      >
                        <div
                          className={`side-menu-item Sidebar_submenu_padding_btn small__sidebar gap-2 mt-0 ${permalink === "packages-revenue"
                            ? "small__sidebar_activeSubmenu"
                            : ""
                            }`}
                        >
                          <i
                            className="fa-solid fa-cubes"
                            style={{ marginRight: 8 }}
                          ></i>
                          <span>Packages Revenue</span>
                        </div>
                      </NavLink>
                    )}
                    {ConsultantPermission && (
                      <NavLink
                        to="/dashboard/consultants"
                        onClick={() =>
                          setShowMobileMenuEditorControl(
                            !showMobileMenuEditorControl
                          )
                        }
                      >
                        <div
                          className={`side-menu-item Sidebar_submenu_padding_btn small__sidebar gap-2 mt-0 ${permalink === "consultants"
                            ? "small__sidebar_activeSubmenu"
                            : ""
                            }`}
                        >
                          <i className="fa-solid fa-building-user"></i>
                          <span style={{ marginLeft: 4 }}>Consultants</span>
                        </div>
                      </NavLink>
                    )}
                    {DeveloperPermission && (
                      <NavLink
                        to="/dashboard/developers"
                        onClick={() =>
                          setShowMobileMenuEditorControl(
                            !showMobileMenuEditorControl
                          )
                        }
                      >
                        <div
                          className={`side-menu-item Sidebar_submenu_padding_btn small__sidebar gap-2 my-0 ${permalink === "developers"
                            ? "small__sidebar_activeSubmenu"
                            : ""
                            }`}
                        >
                          <i className="fa-solid fa-user-pen"></i>
                          <span style={{ marginLeft: "4px" }}>Developers</span>
                        </div>
                      </NavLink>
                    )}
                    {DeveloperPermission && (
                      <NavLink
                        to="/dashboard/editor-subscriptions"
                        onClick={() =>
                          setShowMobileMenuEditorControl(
                            !showMobileMenuEditorControl
                          )
                        }
                      >
                        <div
                          className={`side-menu-item Sidebar_submenu_padding_btn small__sidebar gap-2 my-0 ${permalink === "editor-subscriptions"
                            ? "small__sidebar_activeSubmenu"
                            : ""
                            }`}
                        >
                          <i
                            className="fa-solid fa-dollar-sign"
                            style={{ marginLeft: 2, marginRight: 11 }}
                          ></i>
                          <span>Subscrption Plans</span>
                        </div>
                      </NavLink>
                    )}
                  </div>
                )}
            </div>
          )}

        {(roleType === "admin" || AIUserPermission) && (
          <div className="menu-item-area" ref={aiAlphaControlRef}>
            {!showMobileMenuAIAlphaControl && (
              <div className="tooltip">
                <div className="tooltipContent">AI Alpha Control</div>
              </div>
            )}
            <li
              onClick={() => handleShowMenuAIAlphaControl()}
              style={{ cursor: "pointer" }}
              className={
                permalink === "documents" ||
                  permalink === "programs" ||
                  permalink === "ai-users" ||
                  permalink === "ai-revenue" ||
                  permalink === "ai-backup"
                  ? "sidebar__active"
                  : ""
              }
            >
              <div className="side-menu-item" style={{ cursor: "pointer" }}>
                <i
                  className="fa-solid fa-microchip"
                  style={{ marginLeft: 0, marginRight: 10 }}
                ></i>
                <span className="op-name">
                  AI Alpha Control{" "}
                  {showMobileMenuAIAlphaControl ? (
                    <i className="fa-solid fa-caret-down align_arrow"></i>
                  ) : (
                    <i className="fa-solid fa-caret-right align_arrow"></i>
                  )}
                </span>
              </div>
            </li>
            {!isSidebarCollapsed
              ?
              <Collapse in={showLargeMenuAIAlphaControl}>
                <div>
                  <ul className=" aiAlphaControl">
                    <NavLink
                      to="/dashboard/programs"
                      onClick={handleMobileSidebar}
                    >
                      <li
                        className={
                          permalink === "programs"
                            ? "sidebar__active mb-1"
                            : "mb-1"
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <div className="side-menu-item Sidebar_submenu_padding_btn m-0">
                          <i
                            className="fa-solid fa-file-code"
                          // style={{ marginRight: 12 }}
                          ></i>
                          <span
                            className="op-name"
                            style={{ marginLeft: "-2px" }}
                          >
                            Programs
                          </span>
                        </div>
                      </li>
                    </NavLink>
                    <NavLink
                      to="/dashboard/documents"
                      onClick={handleMobileSidebar}
                    >
                      <li
                        className={
                          permalink === "documents"
                            ? "sidebar__active mb-1"
                            : "mb-1"
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <div className="side-menu-item Sidebar_submenu_padding_btn m-0">
                          <i
                            className="fa-solid fa-file-word"
                          // style={{ marginRight: 12 }}
                          ></i>
                          <span
                            className="op-name"
                            style={{ marginLeft: "-2px" }}
                          >
                            Documents
                          </span>
                        </div>
                      </li>
                    </NavLink>
                    {AIUserPermission && (
                      <NavLink
                        to="/dashboard/ai-users"
                        onClick={handleMobileSidebar}
                      >
                        <li
                          className={
                            permalink === "ai-users"
                              ? "sidebar__active mb-1"
                              : "mb-1"
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <div className="side-menu-item Sidebar_submenu_padding_btn m-0">
                            <i className="fa-solid fa-user-graduate"></i>
                            <span
                              className="op-name"
                              style={{ marginLeft: "-2px" }}
                            >
                              AI Users
                            </span>
                          </div>
                        </li>
                      </NavLink>
                    )}
                    {AIUserPermission && (
                      <NavLink
                        to="/dashboard/ai-revenue"
                        onClick={handleMobileSidebar}
                      >
                        <li
                          className={
                            permalink === "ai-revenue"
                              ? "sidebar__active mb-1"
                              : "mb-1"
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <div className="side-menu-item Sidebar_submenu_padding_btn m-0">
                            <i
                              className="fa-solid fa-money-check-dollar"
                            // style={{ marginLeft: 2, marginRight: 12 }}
                            ></i>
                            <span
                              className="op-name"
                              style={{ marginLeft: "-5px" }}
                            >
                              Revenue
                            </span>
                          </div>
                        </li>
                      </NavLink>
                    )}
                    {AIUserPermission && (
                      <NavLink
                        to="/dashboard/credits"
                        onClick={handleMobileSidebar}
                      >
                        <li
                          className={
                            permalink === "credits"
                              ? "sidebar__active mb-1"
                              : "mb-1"
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <div className="side-menu-item Sidebar_submenu_padding_btn m-0">
                            <i
                              className="fa-solid fa-coins"
                            // style={{ marginLeft: 2, marginRight: 12 }}
                            ></i>
                            <span
                              className="op-name"
                              style={{ marginLeft: "-2px" }}
                            >
                              Credits
                            </span>
                          </div>
                        </li>
                      </NavLink>
                    )}
                    {AIUserPermission && (
                      <NavLink
                        to="/dashboard/ai-backup"
                        onClick={handleMobileSidebar}
                      >
                        <li
                          className={
                            permalink === "ai-backup"
                              ? "sidebar__active mb-1"
                              : "mb-1"
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <div className="side-menu-item Sidebar_submenu_padding_btn m-0">
                            <i
                              className="fa-solid fa-trash-arrow-up"
                            // style={{ marginLeft: 2, marginRight: 12 }}
                            ></i>
                            <span
                              className="op-name"
                              style={{ marginLeft: "-2px" }}
                            >
                              Backup
                            </span>
                          </div>
                        </li>
                      </NavLink>
                    )}
                  </ul>
                </div>
              </Collapse>
              : showMobileMenuAIAlphaControl && (
                <div className="collapsed__submenu billing box-shadow-max">
                  <NavLink
                    to="/dashboard/programs"
                    onClick={() =>
                      setShowMobileMenuAIAlphaControl(
                        !showMobileMenuAIAlphaControl
                      )
                    }
                  >
                    <div
                      className={`side-menu-item Sidebar_submenu_padding_btn small__sidebar gap-3 mt-0 ${permalink === "subadmin"
                        ? "small__sidebar_activeSubmenu"
                        : ""
                        }`}
                    >
                      <i
                        className="fa-solid fa-file-code"
                        style={{ marginRight: 4 }}
                      ></i>
                      <span>Programs</span>
                    </div>
                  </NavLink>
                  <NavLink
                    to="/dashboard/documents"
                    onClick={() =>
                      setShowMobileMenuAIAlphaControl(
                        !showMobileMenuAIAlphaControl
                      )
                    }
                  >
                    <div
                      className={`side-menu-item Sidebar_submenu_padding_btn small__sidebar gap-3 mt-0 ${permalink === "subadmin"
                        ? "small__sidebar_activeSubmenu"
                        : ""
                        }`}
                    >
                      <i
                        className="fa-solid fa-file-word"
                        style={{ marginRight: 4 }}
                      ></i>
                      <span>Documents</span>
                    </div>
                  </NavLink>
                  {AIUserPermission && (
                    <NavLink
                      to="/dashboard/ai-users"
                      onClick={() =>
                        setShowMobileMenuAIAlphaControl(
                          !showMobileMenuAIAlphaControl
                        )
                      }
                    >
                      <div
                        className={`side-menu-item Sidebar_submenu_padding_btn small__sidebar gap-2 mt-0 ${permalink === "designation"
                          ? "small__sidebar_activeSubmenu"
                          : ""
                          }`}
                      >
                        <i className="fa-solid fa-user-graduate"></i>
                        <span style={{ marginLeft: 10 }}>AI Users</span>
                      </div>
                    </NavLink>
                  )}
                  {AIUserPermission && (
                    <NavLink
                      to="/dashboard/ai-revenue"
                      onClick={() =>
                        setShowMobileMenuAIAlphaControl(
                          !showMobileMenuAIAlphaControl
                        )
                      }
                    >
                      <div
                        className={`side-menu-item Sidebar_submenu_padding_btn small__sidebar gap-2 mt-0 ${permalink === "designation"
                          ? "small__sidebar_activeSubmenu"
                          : ""
                          }`}
                      >
                        <i
                          className="fa-solid fa-dollar-sign"
                          style={{ marginLeft: 2, marginRight: 11 }}
                        ></i>
                        <span>Subscription Plans</span>
                      </div>
                    </NavLink>
                  )}
                </div>
              )}
          </div>
        )}
        {roleType === "admin" && (
          <div className="menu-item-area" ref={subadminRef}>
            {!showMobileMenuSubadmin && (
              <div className="tooltip">
                <div className="tooltipContent">Users</div>
              </div>
            )}
            <li
              onClick={() => handleShowMenuSubadmin()}
              style={{ cursor: "pointer" }}
              className={
                permalink === "subadmin" ||
                  permalink === "designation" ||
                  permalink === "permission"
                  ? "sidebar__active"
                  : ""
              }
            >
              <div className="side-menu-item" style={{ cursor: "pointer" }}>
                <i
                  className="fa-solid fa-user-group"
                  style={{ marginLeft: "0px", marginRight: "7px" }}
                ></i>
                <span className="op-name">
                  Users{" "}
                  {showMobileMenuSubadmin ? (
                    <i className="fa-solid fa-caret-down align_arrow"></i>
                  ) : (
                    <i className="fa-solid fa-caret-right align_arrow"></i>
                  )}
                </span>
              </div>
            </li>
            {!isSidebarCollapsed ?
              <Collapse in={showLargeMenuSubadmin}>
                <div>
                  <ul className="billing">
                    <NavLink
                      to="/dashboard/subadmin"
                      onClick={handleMobileSidebar}
                    >
                      <li
                        className={
                          permalink === "subadmin"
                            ? "sidebar__active mb-1"
                            : "mb-1"
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <div className="side-menu-item Sidebar_submenu_padding_btn m-0">
                          <i className="fa-solid fa-user"></i>
                          <span className="op-name">Subadmin</span>
                        </div>
                      </li>
                    </NavLink>
                    <NavLink
                      to="/dashboard/designation"
                      onClick={handleMobileSidebar}
                    >
                      <li
                        className={
                          permalink === "designation"
                            ? "sidebar__active mb-1"
                            : "mb-1"
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <div className="side-menu-item Sidebar_submenu_padding_btn m-0">
                          <i className="fa-solid fa-user-tie"></i>
                          <span className="op-name">Designation</span>
                        </div>
                      </li>
                    </NavLink>
                    <NavLink
                      to="/dashboard/permission"
                      onClick={handleMobileSidebar}
                    >
                      <li
                        className={
                          permalink === "permission"
                            ? "sidebar__active mb-1"
                            : "mb-1"
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <div className="side-menu-item Sidebar_submenu_padding_btn m-0">
                          <i className="fa-solid fa-user-shield"></i>
                          <span className="op-name" style={{ marginLeft: -5 }}>
                            Permission
                          </span>
                        </div>
                      </li>
                    </NavLink>
                  </ul>
                </div>
              </Collapse>
              : showMobileMenuSubadmin && (
                <div className="collapsed__submenu billing box-shadow-max">
                  <NavLink
                    to="/dashboard/subadmin"
                    onClick={() =>
                      setShowMobileMenuSubadmin(!showMobileMenuSubadmin)
                    }
                  >
                    <div
                      className={`side-menu-item Sidebar_submenu_padding_btn small__sidebar gap-3 mt-0 ${permalink === "subadmin"
                        ? "small__sidebar_activeSubmenu"
                        : ""
                        }`}
                    >
                      <i className="fa-solid fa-user"></i>
                      <span>Subadmin</span>
                    </div>
                  </NavLink>
                  <NavLink
                    to="/dashboard/designation"
                    onClick={() =>
                      setShowMobileMenuSubadmin(!showMobileMenuSubadmin)
                    }
                  >
                    <div
                      className={`side-menu-item Sidebar_submenu_padding_btn small__sidebar gap-3 mt-0 ${permalink === "designation"
                        ? "small__sidebar_activeSubmenu"
                        : ""
                        }`}
                    >
                      <i className="fa-solid fa-user-tie"></i>
                      <span>Designation</span>
                    </div>
                  </NavLink>
                  <NavLink
                    to="/dashboard/permission"
                    onClick={() =>
                      setShowMobileMenuSubadmin(!showMobileMenuSubadmin)
                    }
                  >
                    <div
                      className={`side-menu-item Sidebar_submenu_padding_btn small__sidebar gap-2 my-0 ${permalink === "permission"
                        ? "small__sidebar_activeSubmenu"
                        : ""
                        }`}
                    >
                      <i className="fa-solid fa-user-shield"></i>
                      <span style={{ marginLeft: 3 }}>Permission</span>
                    </div>
                  </NavLink>
                </div>
              )}
          </div>
        )}
        <div className="menu-item-area" ref={accountRef}>
          {!showMobileMenuAccount && (
            <div className="tooltip">
              <div className="tooltipContent">Account</div>
            </div>
          )}
          <li
            onClick={() => handleShowMenuAccount()}
            style={{ cursor: "pointer" }}
            className={
              permalink === "profile" ||
                permalink === "change-password" ||
                permalink === "security" ||
                permalink === "log-history" ||
                permalink === "notifications"
                ? "sidebar__active"
                : ""
            }
          >
            <div className="side-menu-item" style={{ cursor: "pointer" }}>
              <i
                className="fa-solid fa-gear"
                style={{ marginLeft: "1px", marginRight: "10px" }}
              ></i>
              <span className="op-name">
                Account{" "}
                {showMobileMenuAccount ? (
                  <i className="fa-solid fa-caret-down align_arrow"></i>
                ) : (
                  <i className="fa-solid fa-caret-right align_arrow"></i>
                )}
              </span>
            </div>
          </li>
          {!isSidebarCollapsed ?
            <Collapse in={showLargeMenuAccount}>
              <div>
                <ul className="setting">
                  <NavLink
                    to="/dashboard/profile"
                    onClick={handleMobileSidebar}
                  >
                    <li
                      className={
                        permalink === "profile"
                          ? "sidebar__active mb-1"
                          : "mb-1"
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <div className="side-menu-item Sidebar_submenu_padding_btn m-0">
                        <i className="fa-solid fa-user"></i>
                        <span className="op-name">Profile</span>
                      </div>
                    </li>
                  </NavLink>
                  <NavLink
                    to="/dashboard/appearance"
                    onClick={handleMobileSidebar}
                  >
                    <li
                      className={
                        permalink === "appearance"
                          ? "sidebar__active mb-1"
                          : "mb-1"
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <div className="side-menu-item Sidebar_submenu_padding_btn m-0">
                        <i className="fa-solid fa-gear"></i>
                        <span
                          className="op-name"
                          style={{ marginLeft: "-3px" }}
                        >
                          Appearance
                        </span>
                      </div>
                    </li>
                  </NavLink>
                  <NavLink
                    to="/dashboard/log-data"
                    onClick={handleMobileSidebar}
                  >
                    <li
                      className={
                        permalink === "log-data"
                          ? "sidebar__active mb-1"
                          : "mb-1"
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <div className="side-menu-item Sidebar_submenu_padding_btn m-0">
                        <i className="fa-solid fa-clock-rotate-left"></i>
                        <span>Log Data</span>
                      </div>
                    </li>
                  </NavLink>
                  <NavLink
                    to="/dashboard/failed-status"
                    onClick={handleMobileSidebar}
                  >
                    <li
                      className={
                        permalink === "failed-status"
                          ? "sidebar__active mb-1"
                          : "mb-1"
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <div className="side-menu-item Sidebar_submenu_padding_btn m-0">
                        <i className="fa-solid fa-ban"></i>
                        <span>Failed Status</span>
                      </div>
                    </li>
                  </NavLink>
                </ul>
              </div>
            </Collapse>
            : showMobileMenuAccount && (
              <div className="collapsed__submenu setting box-shadow-max">
                <NavLink
                  to="/dashboard/profile"
                  onClick={() =>
                    setShowMobileMenuAccount(!showMobileMenuAccount)
                  }
                >
                  <div
                    className={`side-menu-item Sidebar_submenu_padding_btn small__sidebar gap-3 mt-0 ${permalink === "profile"
                      ? "small__sidebar_activeSubmenu"
                      : ""
                      }`}
                  >
                    <i className="fa-solid fa-user"></i>
                    <span>Profile</span>
                  </div>
                </NavLink>
                <NavLink
                  to="/dashboard/appearance"
                  onClick={() =>
                    setShowMobileMenuAccount(!showMobileMenuAccount)
                  }
                >
                  <div
                    className={`side-menu-item Sidebar_submenu_padding_btn small__sidebar gap-3 mt-0 ${permalink === "appearance"
                      ? "small__sidebar_activeSubmenu"
                      : ""
                      }`}
                  >
                    <i className="fa-solid fa-gear"></i>
                    <span style={{ marginLeft: "-3.5px" }}>Appearance</span>
                  </div>
                </NavLink>
                <NavLink
                  to="/dashboard/log-data"
                  onClick={() =>
                    setShowMobileMenuAccount(!showMobileMenuAccount)
                  }
                >
                  <div
                    className={`side-menu-item Sidebar_submenu_padding_btn small__sidebar gap-3 mt-0 ${permalink === "log-data"
                      ? "small__sidebar_activeSubmenu"
                      : ""
                      }`}
                  >
                    <i className="fa-solid fa-clock-rotate-left"></i>
                    <span style={{ marginLeft: "-3.5px" }}>Log Data</span>
                  </div>
                </NavLink>
                <NavLink
                  to="/dashboard/failed-status"
                  onClick={() =>
                    setShowMobileMenuAccount(!showMobileMenuAccount)
                  }
                >
                  <div
                    className={`side-menu-item Sidebar_submenu_padding_btn small__sidebar gap-3 mt-0 ${permalink === "failed-status"
                      ? "small__sidebar_activeSubmenu"
                      : ""
                      }`}
                  >
                    <i className="fa-solid fa-ban"></i>
                    <span style={{ marginLeft: "-3.5px" }}>
                      Failed Status
                    </span>
                  </div>
                </NavLink>
              </div>
            )}
        </div>
        <div className="menu-item-area">
          <div className="tooltip">
            <div className="tooltipContent logout">Logout</div>
          </div>
          <li className="logout">
            <div onClick={logout} className="side-menu-item">
              <i
                className="fa-solid fa-right-from-bracket"
                style={{ marginLeft: "2px", marginRight: "9px" }}
              ></i>
              <span className="op-name">Logout</span>
            </div>
          </li>
        </div>
      </ul>
    </div>
  );
}

export default Sidebar;
