import React from "react";
import { Table } from "react-bootstrap";

const TerminologyComponent = ({
  onMouseDown,
  tableRef,
  result,
  itemsPerPage,
  pageNumber
}) => {
  return (
    <>
      <div className="table-container tableLibrarySection overflow-auto">
        <Table
          id="resizable-table"
          className="m-0 tableLibrary custom-table"
          ref={tableRef}
        >
          <thead className="headerFixed">
            <tr>
              <th onMouseDown={(e) => onMouseDown(e, 0)}>Sr.</th>
              <th onMouseDown={(e) => onMouseDown(e, 1)}>Code</th>
              <th onMouseDown={(e) => onMouseDown(e, 2)}>Codelist Code</th>
              <th onMouseDown={(e) => onMouseDown(e, 3)}>Codelist Extensible (Yes/No)</th>
              <th onMouseDown={(e) => onMouseDown(e, 4)}>Codelist Parent Id</th>
              <th onMouseDown={(e) => onMouseDown(e, 4)}>Codelist Name</th>
              <th onMouseDown={(e) => onMouseDown(e, 5)}>Codelist Submission Value</th>
              <th onMouseDown={(e) => onMouseDown(e, 6)}>CDISC Synonym(s)</th>
              <th onMouseDown={(e) => onMouseDown(e, 7)}>CDISC Definition</th>
              <th onMouseDown={(e) => onMouseDown(e, 8)}>NCI Preferred Term</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {result?.map((item, index) => (
              <tr key={index}>
                <td>{(pageNumber - 1) * itemsPerPage + index + 1}</td>
                <td>{item.code}</td>
                <td>{item.codelist_code}</td>
                <td>{item.parent_extensible}</td>
                <td>{item.parent_id}</td>
                <td>{item.codelist_name}</td>
                <td>{item.cdisc_submission_value}</td>
                <td>{item.cdisc_synonym_s}</td>
                <td>{item.cdisc_definition}</td>
                <td>{item.nci_preferred_term}</td>
                <td></td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default TerminologyComponent;
