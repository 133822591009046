import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
  Table,
  Collapse
} from "react-bootstrap";
import Moment from "react-moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { packageServices } from "../../../APIServices/Editor/packageServices";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import UseToggle from "../../Hooks/useToggle";
import ProcedureValidationOutput from "./ProcedureValidationOutput";
import { copyToClipboard } from "../../../Utils/UniversalFunction";
import UpdatePublishChangelogVersion from "./UpdatePublishChangelogVersion";

const UpdatePackageDetails = ({
  Show,
  Hide,
  Size,
  Title,
  packageData,
  getPackageForReview,
  Submit,
  roleType,
}) => {
  const { toggle, setToggle, toggle1, setToggle1 } = UseToggle();
  const [open, setOpen] = useState("");
  const [copied, setCopied] = useState("");
  const [packageName, setPackageName] = useState("");
  const [folderName, setFolderName] = useState("");
  const [description, setDescription] = useState("");
  const [longDescription, setLongDescription] = useState("");
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [syntaxList, setSyntaxList] = useState([]);
  const [exampleList, setExampleList] = useState([]);
  const [packageExampleList, setPackageExampleList] = useState([]);
  const [softwareValidationList, setSoftwareValidationList] = useState([]);
  const [activeTab, setActiveTab] = useState("information");
  const [output, setOutput] = useState("");
  const [copy, setCopy] = useState("");
  const [regularPrice, setRegularPrice] = useState("");
  const [salePrice, setSalePrice] = useState("");
  const [changeLogLists, setChangeLogLists] = useState([]);
  const [changeLog, setChangeLog] = useState([]);
  const [id, setId] = useState("");
  const [version, setVersion] = useState("");
  const [packageType, setPackageType] = useState("");

  const getActivePackageCategory = async () => {
    let data = await packageServices.getActivePackageCategory();
    setCategoryList(data?.data);
  };

  const getPackageTagList = async () => {
    let data = await packageServices.getPackageTagList();
    setTagList(data?.data);
  };

  const handleUpdate = async () => {
    let userData = {};
    userData.id = packageData?._id;
    userData.regularPrice = regularPrice;
    userData.salePrice = salePrice;
    userData.packageName = packageName;
    userData.description = description;
    userData.longDescription = longDescription;
    userData.category = category;
    userData.packageType = packageType;
    userData.tags = tags;
    let data = await packageServices?.updatePackagesData(userData);
    if (data?.statusCode === 200) {
      getPackageForReview();
      Hide();
    }
  };

  const getPackageStatementsForAdmin = async (fileId) => {
    let data = await packageServices.getPackageStatementsForAdmin(fileId);
    setSyntaxList(data?.statements);
  };

  const getExampleForPackageValidation = async (id) => {
    let data = await packageServices.getExampleForPackageValidation(id);
    setExampleList(data?.data);
  };

  const getExampleForPackage = async (id) => {
    let data = await packageServices.getExampleForPackage(id);
    setPackageExampleList(data?.data);
  };

  const getSoftwareForPackageValidation = async (id) => {
    let data = await packageServices.getSoftwareForPackageValidation(id);
    setSoftwareValidationList(data?.data);
  };

  const getPackageChangeLogs = async (id) => {
    let data = await packageServices.getPackageChangeLogs(id)
    setChangeLogLists(data?.data)
  }

  const updatePublishChangelogs = (id, changelog, version) => {
    setToggle1()
    setChangeLog(changelog)
    setId(id)
    Hide()
    setVersion(version)
  }

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ font: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      [{ align: [] }],
      [{ color: [] }, { background: [] }],
      ["clean"],
    ],
  };

  const Editor = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  const handleTabClick = (tab) => {
    if (tab === "procedureValidation") {
      getExampleForPackageValidation(packageData?._id);
    } else if (tab === "interSoftwareValidation") {
      getSoftwareForPackageValidation(packageData?._id);
    } else if (tab === "statement") {
      getPackageStatementsForAdmin(packageData?._id);
    } else if (tab === "changelogs") {
      getPackageChangeLogs(packageData?._id);
    } else if (tab === "example") {
      getExampleForPackage(packageData?._id);
    }
    setActiveTab(tab);
  };

  useEffect(() => {
    getPackageTagList();
    getActivePackageCategory();
    setPackageName(packageData?.packageName || "");
    setFolderName(packageData?.name || "");
    setDescription(packageData?.description || "");
    setLongDescription(packageData?.longDescription || "");
    setCategory(packageData?.categoryId || "");
    setTags(packageData?.tagId || "");
    setRegularPrice(packageData?.regularPrice || "");
    setSalePrice(packageData?.salePrice || "");
    setPackageType(packageData?.packageType || "");
  }, [packageData]);

  const renderInformationSection = () => (
    <>
      <Row>
        <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
          <Form.Label>
            Folder Name <span>*</span>
          </Form.Label>
          <Form.Control
            required
            type="text"
            onChange={(e) => setFolderName(e.target.value)}
            value={folderName}
            disabled={roleType !== "admin"}
            placeholder="Enter Folder Name"
          />
        </Form.Group>
        <Form.Group as={Col} md="8" className="add_sponsor_field mb-3">
          <Form.Label>Package Name <span>*</span></Form.Label>
          <Form.Control
            required
            type="text"
            onChange={(e) => setPackageName(e.target.value)}
            value={packageName}
            disabled={roleType !== "admin"}
            placeholder="Enter Package Name"
          />
        </Form.Group>
        <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
          <Form.Label>Package Type</Form.Label>
          <Form.Select
            disabled={roleType !== "admin"}
            aria-label="Default select example"
            onChange={(e) => setPackageType(e.target.value)}
          >
            <option>Select Package Type</option>
            <option selected={packageType === "base"} value="base">Base Package</option>
            <option selected={packageType === "free"} value="free">Free Packages</option>
            <option selected={packageType === "paid"} value="paid">Paid packages</option>
          </Form.Select>
        </Form.Group>
        {packageType === "paid" &&
          <>
            <Form.Group as={Col} md="3" className="add_sponsor_field mb-3">
              <Form.Label>Regular Price <span>*</span></Form.Label>
              <InputGroup>
                <InputGroup.Text id="inputGroupPrepend">$</InputGroup.Text>
                <Form.Control
                  type="number"
                  onChange={(e) => setRegularPrice(e.target.value)}
                  value={regularPrice}
                  placeholder="Enter Regular Price"
                  required
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="3" className="add_sponsor_field mb-3">
              <Form.Label>Sale Price <span>*</span></Form.Label>
              <InputGroup>
                <InputGroup.Text id="inputGroupPrepend">$</InputGroup.Text>
                <Form.Control
                  type="number"
                  disabled={!regularPrice}
                  onChange={(e) => setSalePrice(e.target.value)}
                  value={salePrice}
                  placeholder="Enter Sale Price"
                  required
                />
              </InputGroup>
              {Number(regularPrice) < Number(salePrice) &&
                <Form.Text style={{ fontSize: 11, color: "red" }}>Sale Price should be less then Regular Price</Form.Text>
              }
            </Form.Group>
            {((Number(regularPrice) && Number(salePrice)) && (Number(regularPrice) > Number(salePrice))) ?
              <Form.Group as={Col} md="2" className="add_sponsor_field mb-3">
                <Form.Label>Total Discount</Form.Label>
                <InputGroup>
                  <InputGroup.Text id="inputGroupPrepend">OFF</InputGroup.Text>
                  <Form.Control
                    type="text"
                    disabled
                    value={100 - (Number(salePrice) * 100) / Number(regularPrice)}
                    placeholder="Enter Sale Price"
                    required
                  />
                  <InputGroup.Text id="inputGroupPrepend">%</InputGroup.Text>
                </InputGroup>
              </Form.Group>
              : ""}
          </>
        }
        <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
          <Form.Label>
            Short Description <span>*</span>
          </Form.Label>
          <Form.Control
            required
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            disabled={roleType !== "admin"}
            as="textarea"
            placeholder="Enter Description"
          />
        </Form.Group>
        <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
          <Form.Label>
            Long Description <span>*</span>
          </Form.Label>
          <div className="sidebar_Content">
            <ReactQuill
              modules={modules}
              formats={Editor}
              theme="snow"
              readOnly={roleType !== "admin"}
              value={longDescription}
              onChange={setLongDescription}
            />
          </div>
        </Form.Group>
        <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
          <Form.Label>
            Category <span>*</span>
          </Form.Label>
          <Form.Select
            onChange={(e) => setCategory(e.target.value)}
            aria-label="Default select example"
            disabled={roleType !== "admin"}
          >
            <option>Select Category</option>
            {categoryList?.map((item, index) => (
              <option
                key={index}
                selected={category === item?.categoryId}
                value={item?._id}
              >
                {item?.category}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
          <Form.Label>Tags</Form.Label>
          <Form.Select
            disabled={roleType !== "admin"}
            aria-label="Default select example"
            onChange={(e) => setTags(e.target.value)}
          >
            <option>Select Tags</option>
            {tagList?.map((item, index) => (
              <option key={index} value={item?.tagId}>
                {item?.tags}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
          <Form.Label>Selected Tag List</Form.Label>
          <div className="badgesChips">
            <div className="chip hstack mt-1">
              <span>
                <b>tag 1</b>
              </span>
              <div className="vr"></div>
              <i
                className="fa-regular fa-circle-xmark"
                style={{ color: "red" }}
                title="Delete"
              ></i>
            </div>
          </div>
        </Form.Group>
      </Row>
      {roleType === "admin" && (
        <div className="hstack justify-content-end border-top pt-3 footer_button">
          <Button
            disabled={
              !packageName ||
              !folderName ||
              !description ||
              !longDescription ||
              !category
            }
            onClick={handleUpdate}
          >
            {Submit}
          </Button>
        </div>
      )}
    </>
  );

  const renderProcedureValidation = () => (
    <div className="overflow-auto">
      <Table bordered className="TablePre">
        <thead>
          <tr>
            <th>Sr.</th>
            <th>Input</th>
            <th>Procedure</th>
            <th className="text-center">Output</th>
          </tr>
        </thead>
        <tbody>
          {exampleList?.map((item, index) => (
            <tr>
              <td>Example {index + 1}</td>
              <td className="p-0">
                <SyntaxHighlighter style={docco}>
                  {item.input}
                </SyntaxHighlighter>
                <i
                  onClick={() =>
                    copyToClipboard(item.input, "input" + index, setCopy)
                  }
                  className={`fa-regular ${copy === "input" + index ? "fa-circle-check" : "fa-copy"
                    }`}
                ></i>
              </td>
              <td className="p-0">
                <SyntaxHighlighter style={docco}>
                  {item.procedure}
                </SyntaxHighlighter>
                <i
                  onClick={() =>
                    copyToClipboard(
                      item.procedure,
                      "procedure" + index,
                      setCopy
                    )
                  }
                  className={`fa-regular ${copy === "procedure" + index ? "fa-circle-check" : "fa-copy"
                    }`}
                ></i>
              </td>
              <td className="p-0">
                <div className="mt-1 text-center">
                  <div className="footer_button">
                    <Button
                      onClick={() => openProcedureValidationOutput(item.output)}
                      className="m-0 p-0 px-1 w-auto"
                    >
                      View
                    </Button>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );

  const renderExample = () => (
    <>
      {packageExampleList?.map((item, index) => (
        <div key={index}>
          <div
            onClick={() => setOpen(open === index ? "" : index)}
            className="hstack justify-content-between border-bottom pointer"
          >
            <h6 className="py-2" >{item?.name}</h6>
            <i
              class={`fa-solid ${open === index ? "fa-caret-down" : " fa-caret-right"}`}
            ></i>
          </div>
          <Collapse in={open === index}>
            <div id="example-collapse-text" className="mt-2">
              <p>{item?.description}</p>
              <Form.Label>Procedure</Form.Label>
              <div style={{ fontSize: 14 }} className="position-relative">
                <SyntaxHighlighter style={dracula}>
                  {item?.procedure}
                </SyntaxHighlighter>
                <i
                  onClick={() =>
                    copyToClipboard(
                      item.procedure,
                      "procedure" + index,
                      setCopied
                    )
                  }
                  class={`fa-regular ${copied === "procedure" + index ? "fa-circle-check" : "fa-copy"}`}
                  aria-hidden="true"
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                    color: "#fff",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
              <Form.Label className="mt-4">Output</Form.Label>
              <div
                dangerouslySetInnerHTML={{ __html: item?.output }}
              />
            </div>
          </Collapse>
        </div>
      ))}
    </>
  );

  const renderInterSoftwareValidationSection = () => (
    <div className="overflow-auto">
      <Table bordered className="tableFont">
        <thead>
          <tr>
            <th>Sr.</th>
            <th>GAS</th>
            <th>SAS</th>
            <th>R</th>
          </tr>
        </thead>
        <tbody>
          {softwareValidationList?.map((item, index) => (
            <tr key={index}>
              <td style={{ backgroundColor: "transparent" }}>
                <b>Example {index + 1}</b>
              </td>
              <td style={{ backgroundColor: "transparent" }}>
                <b>Output</b>
              </td>
              {item?.validation?.map((data, key) => (
                <td key={key} style={{ backgroundColor: "transparent" }}>
                  <Form.Check
                    type="radio"
                    disabled
                    name={`${data.software}_${key}_${index}`}
                    id={`${data.software}_${key}_${index}_notmatch`}
                    label="Match"
                    checked={data.validation === "Match"}
                  />
                  <Form.Check
                    type="radio"
                    disabled
                    name={`${data.software}_${key}_${index}`}
                    id={`${data.software}_${key}_${index}_notmatch`}
                    label="Not Match"
                    checked={data.validation === "Not Match"}
                  />
                  <div className="hstack gap-2">
                    <a target="_blank" href={data.output} rel="noreferrer">
                      <i
                        className="fa-solid fa-file-pdf"
                        style={{
                          fontSize: 17,
                          cursor: "pointer",
                          color: "#000",
                        }}
                        title="View Output"
                      ></i>
                    </a>
                    <Form.Text>
                      example-{index + 1}-{data.software}-output.pdf
                    </Form.Text>
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );

  const renderStatementSection = () => (
    <>
      {syntaxList?.map((item, index) => (
        <div key={index} className="overflow-auto">
          <Form.Text>
            <b>{item?.statement}</b>
          </Form.Text>
          <br />
          <Form.Text>
            <b>Description</b>
          </Form.Text>
          <p>{item?.syntax}</p>
          <Form.Text>
            <b>Syntex :</b>
          </Form.Text>
          <p>{item?.statementDescription};</p>
          {item.arguments?.length > 0 && (
            <>
              <Form.Text>
                <b>Arguments :</b>
              </Form.Text>
              <Table bordered striped>
                <tbody>
                  {item.arguments?.map((data, key) => (
                    <tr key={key}>
                      <td>{data.title}</td>
                      <td>{data.value}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
          <hr />
        </div>
      ))}
    </>
  );

  const renderChangeLogsSection = () => (
    <>
      {changeLogLists?.map((item, index) => (
        <>
          <Form.Label key={index} className="mb-3"><b>Version {item.subVerion} – Released: <Moment format="DD MMM YYYY | HH:mm:ss" withTitle>
            {item.publishDateTime}
          </Moment></b><i onClick={() => updatePublishChangelogs(item._id, item.changelogs, item.subVerion)} style={{ cursor: "pointer" }} className="mx-3 fa fa-edit"></i></Form.Label>
          <div className="log_change mb-3">
            {(item.changelogs)?.map((data, key) => (
              <pre key={key}>- {data.type}:, {data?.changelog}</pre>
            ))}
          </div>
        </>
      ))}
    </>
  );

  const openProcedureValidationOutput = (output) => {
    setToggle();
    Hide();
    setOutput(output);
  };

  return (
    <>
      <Modal
        show={Show}
        onHide={Hide}
        size={Size}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>{Title}</Modal.Title>
          <div onClick={() => { Hide(); handleTabClick("information") }} className="close_modal" title="Close">
            X
          </div>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <div className="hstack justify-content-center gap-3 mb-3 footer_button">
              <Button
                onClick={() => handleTabClick("information")}
                style={{
                  backgroundColor:
                    activeTab === "information" ? "" : "transparent",
                  color: activeTab === "information" ? "" : "var(--icon_color)",
                }}
                className="w-auto"
              >
                Information
              </Button>
              <Button
                onClick={() => handleTabClick("procedureValidation")}
                style={{
                  backgroundColor:
                    activeTab === "procedureValidation" ? "" : "transparent",
                  color:
                    activeTab === "procedureValidation"
                      ? ""
                      : "var(--icon_color)",
                }}
                className="w-auto"
              >
                Procedure Validation
              </Button>
              <Button
                onClick={() => handleTabClick("interSoftwareValidation")}
                style={{
                  backgroundColor:
                    activeTab === "interSoftwareValidation"
                      ? ""
                      : "transparent",
                  color:
                    activeTab === "interSoftwareValidation"
                      ? ""
                      : "var(--icon_color)",
                }}
                className="w-auto"
              >
                Inter Software Validation
              </Button>
              <Button
                onClick={() => handleTabClick("changelogs")}
                style={{
                  backgroundColor:
                    activeTab === "changelogs" ? "" : "transparent",
                  color: activeTab === "changelogs" ? "" : "var(--icon_color)",
                }}
                className="w-auto"
              >
                ChangeLogs
              </Button>
              <Button
                onClick={() => handleTabClick("statement")}
                style={{
                  backgroundColor:
                    activeTab === "statement" ? "" : "transparent",
                  color: activeTab === "statement" ? "" : "var(--icon_color)",
                }}
                className="w-auto"
              >
                Statement
              </Button>
              <Button
                onClick={() => handleTabClick("example")}
                style={{
                  backgroundColor:
                    activeTab === "example" ? "" : "transparent",
                  color: activeTab === "example" ? "" : "var(--icon_color)",
                }}
                className="w-auto"
              >
                Example
              </Button>
            </div>
            <div
              style={{ maxHeight: "calc(100vh - 190px)", overflowX: "hidden" }}
            >
              {activeTab === "information" && renderInformationSection()}
              {activeTab === "procedureValidation" &&
                renderProcedureValidation()}
              {activeTab === "interSoftwareValidation" &&
                renderInterSoftwareValidationSection()}
              {activeTab === "statement" && renderStatementSection()}
              {activeTab === "changelogs" && renderChangeLogsSection()}
              {activeTab === "example" && renderExample()}
            </div>
          </Container>
        </Modal.Body>
      </Modal>

      <ProcedureValidationOutput
        Show={!toggle}
        Hide={setToggle}
        Size={"md"}
        Title="Procedure Validation Output"
        output={output}
        baseModal={Hide}
      />

      <UpdatePublishChangelogVersion
        Show={!toggle1}
        Hide={setToggle1}
        Size={"md"}
        Title={`Update Changelog for this ${version}`}
        id={id}
        setId={setId}
        changeLog={changeLog}
        setChangeLog={setChangeLog}
        baseModal={Hide}
        getPackageForReview={getPackageForReview}
        Submit={"Update"}
      />
    </>
  );
};

export default UpdatePackageDetails;
