import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { libraryFolderAndDataServices } from "../../../APIServices/Editor/libraryFolderAndDataServices";

const ExportLibraryFileData = ({ Show, Hide, Size, Title, Submit, libraryId }) => {
  let [extension, setExtension] = useState("");

  const exportLibraryFileMultiFormat = async () => {
    let userData = {};
    userData.extension = extension;
    userData.libraryId = libraryId;
    let data = await libraryFolderAndDataServices.exportLibraryFileMultiFormat(
      userData
    );
    if (data?.data) {
      cleanInputBox();
    }
  };

  const cleanInputBox = () => {
    Hide();
    setExtension("");
  };

  return (
    <Modal
      show={Show}
      onHide={Hide}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={cleanInputBox} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Form.Group as={Col} md="12" className="add_sponsor_field">
            <Form.Label>
              Format <span>*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => setExtension(e.target.value)}
              aria-label="Default select example"
            >
              <option>Select Format</option>
              <option value="csv">CSV</option>
              <option value="excel">Excel</option>
            </Form.Select>
          </Form.Group>
        </Row>
      </Modal.Body>
      <Modal.Footer className="pt-2 px-2">
        <div className="text-end footer_button">
          <Button onClick={exportLibraryFileMultiFormat} disabled={!extension}>
            {Submit}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ExportLibraryFileData;
