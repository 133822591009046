import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import UpdateSubadminModal from "./UpdateSubadminModal";
import UseToggle from "../../Hooks/useToggle";
import Moment from "react-moment";
import { userServices } from "../../../APIServices/usersServices";
import Swal from "sweetalert2";

const SubadminTableData = ({
  subadminList,
  getUserData,
  permissionList,
  designationList,
  onMouseDown,
  tableRef,
}) => {
  const { toggle, setToggle } = UseToggle();

  const ref = useRef(null);
  const [action, setAction] = useState("");
  const [subadminData, setSubadminData] = useState("");

  const handleUpdateModal = (e, item) => {
    setSubadminData(item);
    setToggle();
  };

  const handleDelete = async (e, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete this Subadmin?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await userServices.deleteUserData(id);
        if (data?.statusCode === 200) {
          getUserData();
        }
      }
    });
  };

  const handleStatusChange = async (id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Change Status this Subadmin?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = id;
        userData.status = status;
        let data = await userServices.changeUserDataStatus(userData);
        if (data?.statusCode === 200) {
          getUserData();
        }
      }
    });
  };

  const handleDesignationChange = async (id, designationId) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Change Designation this Subadmin?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = id;
        userData.designationId = designationId;
        let data = await userServices.changeUserDesignation(userData);
        if (data?.statusCode === 200) {
          getUserData();
        }
      }
    });
  };

  const handlePermissionChange = async (id, permissionId) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Change Permission this Subadmin?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = id;
        userData.permissionId = permissionId;
        let data = await userServices.changeUserPermission(userData);
        if (data?.statusCode === 200) {
          getUserData();
        }
      }
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setAction("");
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  return (
    <>
      <div className="table-container overflow-auto">
        <Table id="resizable-table" className="custom-table m-0" ref={tableRef}>
          <thead>
            <tr>
              <th
                onMouseDown={(e) => onMouseDown(e, 0)}
                style={{ width: "40px" }}
              >
                Sr.
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 1)}>Subadmin Name</th>
              <th onMouseDown={(e) => onMouseDown(e, 2)}>Email Id</th>
              <th onMouseDown={(e) => onMouseDown(e, 3)} style={{ width: 100 }}>Phone No.</th>
              <th onMouseDown={(e) => onMouseDown(e, 4)} style={{ width: 80 }}>Gender</th>
              <th
                onMouseDown={(e) => onMouseDown(e, 5)}
                className="text-center"
                style={{ width: 160 }}
              >
                Designation
              </th>
              <th
                onMouseDown={(e) => onMouseDown(e, 6)}
                className="text-center"
                style={{ width: 160 }}
              >
                Permission
              </th>
              <th
                onMouseDown={(e) => onMouseDown(e, 7)}
                style={{ width: 120 }}
                className="text-center"
              >
                Status
              </th>
              <th
                onMouseDown={(e) => onMouseDown(e, 8)}
                style={{ width: 130 }}
              >
                Registration
              </th>
              <th
                onMouseDown={(e) => onMouseDown(e, 9)}
                style={{ width: 70 }}
                className="text-center"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody ref={ref}>
            {subadminList?.length > 0 &&
              subadminList?.map((item, index) => (
                <tr className="relative">
                  <td>{index + 1}</td>
                  <td>{`${item.firstName ? item.firstName : ""} ${
                    item.middleName ? item.middleName : ""
                  } ${item.lastName ? item.lastName : ""}`}</td>
                  <td>{item.email}</td>
                  <td>{item.phone}</td>
                  <td className="text-capitalize">{item.gender}</td>
                  <td className="designation_select">
                    <Form.Select
                      aria-label="Default select example"
                      className="active_status"
                      onChange={(e) =>
                        handleDesignationChange(item._id, e.target.value)
                      }
                    >
                      <option value="">No Designation</option>
                      {designationList?.map((data, index) => (
                        <option
                          selected={item.designationId === data._id}
                          key={index}
                          value={data._id}
                        >
                          {data.designation}
                        </option>
                      ))}
                    </Form.Select>
                  </td>
                  <td className="permission_select">
                    <Form.Select
                      aria-label="Default select example"
                      className="active_status"
                      onChange={(e) =>
                        handlePermissionChange(item._id, e.target.value)
                      }
                    >
                      <option value="">No Permission</option>
                      {permissionList?.map((data, index) => (
                        <option
                          selected={item.permissionId === data._id}
                          key={index}
                          value={data._id}
                        >
                          {data.permission}
                        </option>
                      ))}
                    </Form.Select>
                  </td>
                  <td className="status_select">
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) =>
                        handleStatusChange(item._id, e.target.value)
                      }
                      className={
                        item.status === "active"
                          ? "active_status"
                          : item.status === "inactive"
                          ? "inactive_status"
                          : "pending_status"
                      }
                    >
                      <option
                        selected={item.status === "active"}
                        value="active"
                      >
                        {" "}
                        Active{" "}
                      </option>
                      <option
                        selected={item.status === "inactive"}
                        value="inactive"
                      >
                        Inactive
                      </option>
                      {item.status === "pending" && (
                        <option
                          selected={item.status === "pending"}
                          value="pending"
                        >
                          Pending
                        </option>
                      )}
                    </Form.Select>
                  </td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm" withTitle>
                      {item.createdAt}
                    </Moment>
                  </td>
                  <td className="text-center">
                    <div className="text-center action__buttons">
                      {action === item._id ? (
                        <>
                          <div className="saperate_action_button">
                            <Button
                              onClick={(e) => handleUpdateModal(e, item)}
                              title="Edit"
                              className="rounded-circle"
                            >
                              <i class="fa-solid fa-pen-to-square"></i>
                            </Button>
                            <Button
                              onClick={(e) => handleDelete(e, item._id)}
                              title="Delete"
                              className="rounded-circle close_circle"
                            >
                              <i class="fa-solid fa-trash-can"></i>
                            </Button>
                          </div>
                          <Button
                            onClick={() => setAction("")}
                            title="Close Action"
                            className="rounded-circle close_circle"
                          >
                            <i class="fa-solid fa-xmark"></i>
                          </Button>
                        </>
                      ) : (
                        <Button
                          onClick={() => setAction(item._id)}
                          title="Open Action"
                          className="rounded-circle"
                        >
                          <i class="fa-solid fa-bars"></i>
                        </Button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {subadminList?.length > 0 ? (
          ""
        ) : (
          <div className="noRecordFound">Record not found</div>
        )}
      </div>
      <UpdateSubadminModal
        Show={!toggle}
        Hide={setToggle}
        Size={"lg"}
        Title={"Update Subadmin"}
        subadminData={subadminData}
        getUserData={getUserData}
        permissionList={permissionList}
        designationList={designationList}
        Submit={"Update"}
      />
    </>
  );
};

export default SubadminTableData;
