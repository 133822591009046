import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import UseToggle from "../../../Hooks/useToggle";
import { sdtmServices } from "../../../../APIServices/CDISC/sdtmServices";
import TableHeader from "../../../../component/TableHeader";
import Pagination from "../../../Hooks/Pagination";
import ExportVariableMethodModal from "../Variable/Method/ExportVariableMethodModal";
import ValueLevelfilter from "./ValueLevelfilter";
import ValueLevelTableData from "./ValueLevelTableData";
import AddNewValueLevel from "./AddNewValueLevel";

const ValueLevelData = ({ onMouseDown, tableRef }) => {
    const { toggle, setToggle, toggle1, setToggle1, toggle3, setToggle3 } = UseToggle();
    const [searchData, setSearchData] = useState("");
    const [variableList, setVariableList] = useState([]);
    const [name, setName] = useState("");
    const [veriableId, setVeriableId] = useState("");
    const [domainId, setDomainId] = useState("");

    const getValueLevel = async () => {
        let data = await sdtmServices.getValueLevel();
        setVariableList(data?.data);
    };

    const itemsPerPageDesignation = 10;
    const [itemOffsetDesignation, setItemOffsetDesignation] = useState(0);
    const endOffsetDesignation = itemOffsetDesignation + itemsPerPageDesignation;
    const currentItemsDesignation = variableList?.slice(
        itemOffsetDesignation,
        endOffsetDesignation
    );

    const pageCountDesignation = Math.ceil(
        variableList?.length / itemsPerPageDesignation
    );

    const handlePageClickDesignation = (event) => {
        const newOffset =
            (event.selected * itemsPerPageDesignation) % variableList?.length;
        setItemOffsetDesignation(newOffset);
    };

    useEffect(() => {
        getValueLevel();
    }, []);

    return (
        <>
            <Row>
                <Col md={12} classveriableId="mb-3">
                    <TableHeader
                        filterIcon
                        handleOpenFilter={setToggle3}
                        AddNew
                        handleAddNew={setToggle}
                        titleAddNew="Add New Variable Method"
                        Export
                        handleExport={setToggle1}
                        titleExport="Export Variable Methods Data"
                    />
                    <ValueLevelTableData
                        onMouseDown={onMouseDown}
                        tableRef={tableRef}
                        designationList={currentItemsDesignation}
                        variableList={variableList}
                        getValueLevel={getValueLevel}
                    />
                    <Pagination
                        Result={variableList}
                        itemsPerPage={itemsPerPageDesignation}
                        handlePageClick={handlePageClickDesignation}
                        pageCount={pageCountDesignation}
                    />
                </Col>
            </Row>
            <AddNewValueLevel
                Show={!toggle}
                Hide={setToggle}
                Size={"lg"}
                Title={"Add New Variable Method"}
                Submit={"Submit"}
                getValueLevel={getValueLevel}
            />
            <ExportVariableMethodModal
                Show={!toggle1}
                Hide={setToggle1}
                Size={"md"}
                Title={"Export VariableMethods"}
                Submit={"Export"}
            />
            <ValueLevelfilter
                Show={!toggle3}
                Hide={setToggle3}
                Size={"lg"}
                Title={"Filter Value Levels"}
                Submit={"Export"}
            />
        </>
    );
};

export default ValueLevelData;
