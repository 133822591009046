import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { addressServices } from "../../../APIServices/addressServices";
import Swal from "sweetalert2";
import { userServices } from "../../../APIServices/usersServices";

const UpdateSubadminModal = ({
  Show,
  Hide,
  Size,
  Title,
  Submit,
  designationList,
  permissionList,
  getUserData,
  subadminData,
}) => {
  let [firstName, setFirstName] = useState();
  let [middleName, setMiddleName] = useState("");
  let [lastName, setLastName] = useState("");
  let [email, setEmail] = useState("");
  let [phoneNumber, setPhoneNumber] = useState("");
  let [gender, setGender] = useState("");
  let [address, setAddress] = useState("");
  let [zipCode, setZipCode] = useState("");
  let [country, setCountry] = useState("");
  let [state, setState] = useState("");
  let [city, setCity] = useState("");
  let [permissionId, setPermissionId] = useState("");
  let [designationId, setDesignationId] = useState("");
  let [phoneError, setPhoneError] = useState("");

  const handleUpdate = async () => {
    Hide();
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Update Data this Subadmin?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = subadminData._id;
        userData.firstName = firstName;
        userData.middleName = middleName;
        userData.lastName = lastName;
        userData.phone = phoneNumber;
        userData.gender = gender;
        let data = await userServices.updateUserData(userData);
        if (data?.statusCode === 200) {
          getUserData();
        }
      }
    });
  };

  const findCountryName = async () => {
    let data = await addressServices.findCountryName(subadminData?.countryId);
    setCountry(data?.data);
  };

  const findStateName = async () => {
    let data = await addressServices.findStateName(subadminData?.stateId);
    setState(data?.data);
  };

  const findCityName = async () => {
    let data = await addressServices.findCityName(subadminData?.cityId);
    setCity(data?.data);
  };

  const validatePhoneNumber = (phone) => {
    const phonePattern = /^\d{10}$/;
    if (phonePattern.test(phone)) {
      setPhoneError("");
    } else {
      setPhoneError("Phone Number Should have 10 digit");
    }
  };

  useEffect(() => {
    findCountryName();
    findStateName();
    findCityName();
    setFirstName(subadminData?.firstName);
    setMiddleName(subadminData?.middleName);
    setLastName(subadminData?.lastName);
    setEmail(subadminData?.email);
    setPhoneNumber(subadminData?.phone);
    setGender(subadminData?.gender);
    setPermissionId(subadminData?.permissionId);
    setDesignationId(subadminData?.designationId);
    setAddress(subadminData?.address);
    setZipCode(subadminData?.zipCode);
  }, [subadminData]);

  return (
    <Modal
      show={Show}
      onHide={Hide}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={() => Hide()} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              First Name <span>*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
              placeholder="Enter First Name"
            />
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>Middle Name</Form.Label>
            <Form.Control
              required
              type="text"
              onChange={(e) => setMiddleName(e.target.value)}
              value={middleName}
              placeholder="Enter Middle Name"
            />
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Last Name <span>*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
              placeholder="Enter Last Name"
            />
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Email <span>*</span>
            </Form.Label>
            <Form.Control
              required
              disabled
              type="email"
              placeholder="Enter Email ID"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Phone Number <span>*</span>
            </Form.Label>
            <Form.Control
              required
              onChange={(e) => {
                setPhoneNumber(e.target.value);
                validatePhoneNumber(e.target.value);
              }}
              value={phoneNumber}
              type="number"
              placeholder="Enter Phone Number"
            />
            {phoneError && (
              <span className="message" style={{ color: "red", fontSize: 10 }}>
                {phoneError}
              </span>
            )}
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Gender <span>*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => setGender(e.target.value)}
              aria-label="Default select example"
            >
              <option>Select Gender</option>
              <option selected={gender === "male"} value="male">
                Male
              </option>
              <option selected={gender === "female"} value="female">
                Female
              </option>
              <option selected={gender === "other"} value="other">
                Other
              </option>
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Designation <span>*</span>
            </Form.Label>
            <Form.Select
              aria-label="Default select example"
              disabled
              onChange={(e) => setDesignationId(e.target.value)}
            >
              <option>Select Designation</option>
              {designationList?.map((item, index) => (
                <option
                  selected={designationId === item._id}
                  key={index}
                  value={item._id}
                >
                  {item.designation}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Permission <span>*</span>
            </Form.Label>
            <Form.Select
              aria-label="Default select example"
              disabled
              onChange={(e) => setPermissionId(e.target.value)}
            >
              <option>Select Permission</option>
              {permissionList?.map((item, index) => (
                <option
                  selected={permissionId === item._id}
                  key={index}
                  value={item._id}
                >
                  {item.permission}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>
              Address <span>*</span>
            </Form.Label>
            <Form.Control
              required
              disabled
              onChange={(e) => setAddress(e.target.value)}
              value={address}
              as="textarea"
              placeholder="Enter Address"
            />
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Country <span>*</span>
            </Form.Label>
            <Form.Control required disabled type="text" value={country} />
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              State <span>*</span>
            </Form.Label>
            <Form.Control required disabled type="text" value={state} />
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              City <span>*</span>
            </Form.Label>
            <Form.Control required disabled type="text" value={city} />
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Zip Code <span>*</span>
            </Form.Label>
            <Form.Control
              onChange={(e) => setZipCode(e.target.value)}
              value={zipCode}
              required
              disabled
              type="number"
              placeholder="Enter Zip Code"
            />
          </Form.Group>
        </Row>
        <div className="hstack justify-content-end border-top pt-3 footer_button">
          <Button
            disabled={
              !firstName || !gender || !lastName || !phoneNumber || phoneError
            }
            onClick={handleUpdate}
          >
            {Submit}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateSubadminModal;
