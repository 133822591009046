import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import TableHeader from "../../../../component/TableHeader";
import UseToggle from "../../../Hooks/useToggle";
import AddNewProgramPromptModal from "./AddNewProgramPromptModal";
import Moment from "react-moment";
import Swal from "sweetalert2";
import UpdateProgramPromptModal from "./UpdateProgramPromptModal";
import { programAIServices } from "../../../../APIServices/AIAlpha/programAIServices";
import ViewProgramPromptModal from "./ViewProgramPromptModal";

const ProgramPromptTableData = ({
  programPrompts,
  setProgramPrompts,
  viewProgramPrompt,
  getProgramPrompts,
  programId,
  onMouseDown,
  tableRef,
}) => {
  const { toggle, setToggle, toggle1, setToggle1, toggle2, setToggle2 } =
    UseToggle();
  const ref = useRef(null);
  const [action, setAction] = useState("");
  const [id, setId] = useState("");
  const [searchData, setSearchData] = useState("");
  const [name, setName] = useState("");
  const [steps, setSteps] = useState([''])
  const handleSearch = async (searchValue) => {
    let userData = {};
    userData.searchData = searchValue || searchData;
    let data = await programAIServices.searchProgramPrompt(userData);
    setProgramPrompts(data?.data);
  };

  const handleStatusChange = async (id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Change Status this Program Prompts?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = id;
        userData.status = status;
        let data = await programAIServices.changeProgramPromptStatus(
          userData
        );
        if (data?.statusCode === 200) {
          getProgramPrompts(programId);
        }
      }
    });
  };

  const updateProgramPromptModal = (item) => {
    setName(item.name);
    setId(item._id);
    setSteps(item.steps);
    setToggle1();
  };

  const viewProgramPromptModal = (item) => {
    setSteps(item.steps);
    setToggle2();
  };

  const handleDelete = async (e, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete this Program Prompt?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await programAIServices.deleteProgramPrompt(id);
        if (data?.statusCode === 200) {
          getProgramPrompts(programId);
        }
      }
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setAction("");
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [programId]);
  return (
    <>
      {viewProgramPrompt && (
        <>
          <div className="hstack justify-content-between sponsors_section_filter">
            <div className="hstack gap-2">
              <p style={{ marginBottom: 6, fontWeight: "bold" }}>
                {viewProgramPrompt} Propmt
              </p>
            </div>
            <TableHeader
              Search
              handleSearch={handleSearch}
              setSearchData={setSearchData}
              SearchReverseData={getProgramPrompts}
              SearchValue={programId}
              AddNew
              handleAddNew={setToggle}
              titleAddNew="Add New Program Prompt"
            />
          </div>
          <div className="table-container overflow-auto">
            <Table
              id="resizable-table"
              className="custom-table m-0"
              ref={tableRef}
            >
              <thead>
                <tr>
                  <th
                    onMouseDown={(e) => onMouseDown(e, 0)}
                    style={{ width: 40 }}
                  >
                    Sr.
                  </th>
                  <th onMouseDown={(e) => onMouseDown(e, 1)}>Type</th>
                  <th
                    onMouseDown={(e) => onMouseDown(e, 2)}
                    className="text-center"
                    style={{ width: 120 }}
                  >
                    Status
                  </th>
                  <th
                    onMouseDown={(e) => onMouseDown(e, 3)}
                    className="text-center"
                    style={{ width: 170 }}
                  >
                    Updated Date | Time
                  </th>
                  <th
                    onMouseDown={(e) => onMouseDown(e, 4)}
                    className="text-center"
                    style={{ width: 70 }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody ref={ref}>
                {programPrompts?.map((item, index) => (
                  <tr className="relative">
                    <td>{index + 1}</td>
                    <td title={item.name}>{item.name}</td>
                    <td className="status_select">
                      <Form.Select
                        aria-label="Default select example"
                        onChange={(e) =>
                          handleStatusChange(item._id, e.target.value)
                        }
                        className={
                          item.status === "active"
                            ? "active_status"
                            : "inactive_status"
                        }
                      >
                        <option
                          selected={item.status === "active"}
                          value="active"
                        >
                          Active
                        </option>
                        <option
                          selected={item.status === "inactive"}
                          value="inactive"
                        >
                          Inactive
                        </option>
                      </Form.Select>
                    </td>
                    <td>
                      <Moment format="DD MMM YYYY | HH:mm" withTitle>
                        {item.updatedAt}
                      </Moment>
                    </td>
                    <td className="text-center">
                      <div className="text-center action__buttons">
                        {action === item._id ? (
                          <>
                            <div className="saperate_action_button">
                              <Button
                                onClick={() =>
                                  viewProgramPromptModal(item)
                                }
                                title="Edit"
                                className="rounded-circle"
                              >
                                <i className="fa-solid fa-eye"></i>
                              </Button>
                              <Button
                                onClick={() =>
                                  updateProgramPromptModal(item)
                                }
                                title="Edit"
                                className="rounded-circle"
                              >
                                <i className="fa-solid fa-pen-to-square"></i>
                              </Button>
                              <Button
                                onClick={(e) => handleDelete(e, item._id)}
                                title="Delete"
                                className="rounded-circle close_circle"
                              >
                                <i className="fa-solid fa-trash-can"></i>
                              </Button>
                            </div>
                            <Button
                              title="Close Action"
                              className="rounded-circle close_circle"
                            >
                              <i className="fa-solid fa-xmark"></i>
                            </Button>
                          </>
                        ) : (
                          <Button
                            onClick={() => setAction(item._id)}
                            title="Open Action"
                            className="rounded-circle "
                          >
                            <i className="fa-solid fa-bars"></i>
                          </Button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <AddNewProgramPromptModal
              Show={!toggle}
              programId={programId}
              getProgramPrompts={getProgramPrompts}
              Hide={setToggle}
              Size={"md"}
              Title={"Add New Program Prompt"}
              Submit={"Submit"}
            />
            <UpdateProgramPromptModal
              Show={!toggle1}
              Hide={setToggle1}
              Size={"md"}
              Title={"Update Program Prompt"}
              setName={setName}
              name={name}
              setSteps={setSteps}
              steps={steps}
              Submit={"Update"}
              id={id}
              getProgramPrompts={getProgramPrompts}
              programId={programId}
            />
            <ViewProgramPromptModal
              Show={!toggle2}
              Hide={setToggle2}
              Size={"md"}
              Title={"View Program Prompt"}
              steps={steps}
              viewProgramPrompt={viewProgramPrompt}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ProgramPromptTableData;
