import config from "../../config/config.json";
import { headers } from "../../APIHelpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody
} from "../../APIHelpers/Responses";

export const couponServices = {
  //Coupon
  searchCoupon,
  addNewCoupon,
  updateCoupon,
  deleteCoupon,
  getCouponList,
  getCouponListByUserId
};

// Add New Coupon
async function searchCoupon(userData) {
  const response = await fetch(
    `${config.API_URL}searchCoupon`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Add New Coupon
async function addNewCoupon(userData) {
  const response = await fetch(
    `${config.API_URL}addNewCoupon`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Update Coupon
async function updateCoupon(userData) {
  const response = await fetch(
    `${config.API_URL}updateCoupon`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Get All Coupon List for Table
async function getCouponList() {
  const response = await fetch(
    `${config.API_URL}getCouponList`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get All Coupon List By Id
async function getCouponListByUserId(userId) {
  const response = await fetch(
    `${config.API_URL}getCouponListByUserId/${userId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Delete Coupon
async function deleteCoupon(id) {
  const response = await fetch(
    `${config.API_URL}deleteCoupon/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}