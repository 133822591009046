import React, { useEffect, useRef, useState } from "react";
import { Button, Table } from "react-bootstrap";
// import UpdateSDTMVeriableModal from "./UpdateSDTMVeriableModal";
import Moment from "react-moment";
import Swal from "sweetalert2";
import { sdtmServices } from "../../../../APIServices/CDISC/sdtmServices";
import UseToggle from "../../../Hooks/useToggle";
import UpdateValueLevelModal from "./UpdateValueLevelModal";

const ValueLevelTableData = ({
    onMouseDown,
    tableRef,
    variableList,
    getValueLevel,
}) => {

    const { toggle, setToggle } = UseToggle();
    const ref = useRef(null);
    const [action, setAction] = useState("");
    const [id, setId] = useState("");
    const [commentId, setCommentId] = useState("");
    const [mathodId, setMathodId] = useState("");
    const [codeList, setCodeList] = useState("");
    const [valueLevelWhereclause, setValueLevelWhereclause] = useState("");
    const [whereclauseLabel, setWhereclauseLabel] = useState("");
    const [dataType, setDataType] = useState("");
    const [significant, setSignificant] = useState("");
    const [mandatory, setMandatory] = useState("");
    const [origin, setOrigin] = useState("");
    const [order, setOrder] = useState("");

    const handleUpdate = async () => {
        Swal.fire({
            title: "Are you sure?",
            text: `You want to Update this Veriable?`,
            icon: "warning",
            showCancelButton: true,
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, update it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                let userData = {};
                userData.id = id;
                userData.valueLevelWhereclause = valueLevelWhereclause;
                userData.whereclauseLabel = whereclauseLabel;
                userData.dataType = dataType;
                userData.significant = significant;
                userData.mandatory = mandatory;
                userData.origin = origin;
                userData.commentId = commentId;
                userData.mathodId = mathodId;
                userData.codeList = codeList;
                userData.order = order;
                let data = await sdtmServices.updateValueLevel(userData);
                if (data?.statusCode === 200) {
                    getValueLevel();
                    setToggle()
                }
            }
        });
    };

    const updateVariableModal = (item) => {
        setMathodId(item.mathodId);
        setCommentId(item.commentId);
        setOrigin(item.origin);
        setMandatory(item.mandatory);
        setSignificant(item.significant)
        setDataType(item.dataType);
        setWhereclauseLabel(item.whereclauseLabel);
        setCodeList(item.codeList);
        setValueLevelWhereclause(item.valueLevelWhereclause);
        setId(item._id);
        setOrder(order)
        setToggle();
    };

    const deleteValueLevel = async (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: `You want to Delete this Veriable?`,
            icon: "warning",
            showCancelButton: true,
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                let data = await sdtmServices.deleteValueLevel(id);
                if (data?.statusCode === 200) {
                    getValueLevel();
                }
            }
        });
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setAction("");
            }
        };
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, []);

    return (
        <>
            <div className="table-container overflow-auto">
                <Table
                    id="resizable-table"
                    className="custom-table tableLibrary m-0"
                    ref={tableRef}
                >
                    <thead>
                        <tr>
                            <th onMouseDown={(e) => onMouseDown(e, 0)} style={{ width: 40 }}>
                                Order
                            </th>
                            <th onMouseDown={(e) => onMouseDown(e, 1)}>Domain</th>
                            <th onMouseDown={(e) => onMouseDown(e, 3)}>Value Level Variable Name</th>
                            <th onMouseDown={(e) => onMouseDown(e, 4)}>Whereclause Variable Label</th>
                            <th onMouseDown={(e) => onMouseDown(e, 5)}>Value Level Whereclause</th>
                            <th onMouseDown={(e) => onMouseDown(e, 6)}>Whereclause Label</th>
                            <th onMouseDown={(e) => onMouseDown(e, 7)}>Data Type</th>
                            <th onMouseDown={(e) => onMouseDown(e, 8)}>Length</th>
                            <th onMouseDown={(e) => onMouseDown(e, 9)}>Significant</th>
                            <th onMouseDown={(e) => onMouseDown(e, 10)}>Mandatory</th>
                            <th onMouseDown={(e) => onMouseDown(e, 11)}>Origin</th>
                            <th onMouseDown={(e) => onMouseDown(e, 12)}>CommentId</th>
                            <th onMouseDown={(e) => onMouseDown(e, 13)}>MathodId</th>
                            <th onMouseDown={(e) => onMouseDown(e, 14)}>CodeList</th>
                            <th
                                onMouseDown={(e) => onMouseDown(e, 15)}
                                style={{ width: 180 }}
                            >
                                Updated Date | Time
                            </th>
                            <th
                                onMouseDown={(e) => onMouseDown(e, 16)}
                                style={{ width: 120 }}
                                className="text-center"
                            >
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody ref={ref}>
                        {variableList?.length > 0 &&
                            variableList?.map((item, index) => (
                                <tr className="relative">
                                    <td>{item.order}</td>
                                    <td>{item.domain}</td>
                                    <td>{item.domainValueLevelVeriableName}</td>
                                    <td>{item.valueLevelWhereclause}</td>
                                    <td>{item.domainWhereclauseVeriableName}</td>
                                    <td>{item.whereclauseLabel}</td>
                                    <td className="text-capitalize">{item.dataType}</td>
                                    <td>{item.dataType === "" ? "" : item.dataType === "text" ? 200 : 8}</td>
                                    <td>{item.significant}</td>
                                    <td className="text-capitalize">{item.mandatory}</td>
                                    <td>{item.origin}</td>
                                    <td>{item.comment}</td>
                                    <td>{item.mathod}</td>
                                    <td>{item.codeList}</td>
                                    <td>
                                        <Moment format="DD MMM YYYY | HH:mm" withTitle>
                                            {item.updatedAt}
                                        </Moment>
                                    </td>
                                    <td className="text-center">
                                        <div className="text-center action__buttons">
                                            {action === item._id ? (
                                                <>
                                                    <div className="saperate_action_button">
                                                        <Button
                                                            onClick={() => updateVariableModal(item)}
                                                            title="Edit"
                                                            className="rounded-circle"
                                                        >
                                                            <i className="fa-solid fa-pen-to-square"></i>
                                                        </Button>
                                                        <Button
                                                            onClick={(e) => deleteValueLevel(item._id)}
                                                            title="Delete"
                                                            className="rounded-circle close_circle"
                                                        >
                                                            <i className="fa-solid fa-trash-can"></i>
                                                        </Button>
                                                    </div>
                                                    <Button
                                                        title="Close Action"
                                                        className="rounded-circle close_circle"
                                                    >
                                                        <i className="fa-solid fa-xmark"></i>
                                                    </Button>
                                                </>
                                            ) : (
                                                <Button
                                                    onClick={() => setAction(item._id)}
                                                    title="Open Action"
                                                    className="rounded-circle "
                                                >
                                                    <i className="fa-solid fa-bars"></i>
                                                </Button>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
                {variableList?.length > 0 ? (
                    ""
                ) : (
                    <div className="noRecordFound">Record not found</div>
                )}
            </div>
            <UpdateValueLevelModal
                Show={!toggle}
                Hide={setToggle}
                Size={"lg"}
                Title={"Update Variable"}
                handleUpdate={handleUpdate}
                origin={origin}
                mandatory={mandatory}
                dataType={dataType}
                significant={significant}
                commentId={commentId}
                mathodId={mathodId}
                valueLevelWhereclause={valueLevelWhereclause}
                whereclauseLabel={whereclauseLabel}
                setMathodId={setMathodId}
                setCommentId={setCommentId}
                setValueLevelWhereclause={setValueLevelWhereclause}
                setWhereclauseLabel={setWhereclauseLabel}
                setDataType={setDataType}
                setSignificant={setSignificant}
                setMandatory={setMandatory}
                setOrigin={setOrigin}
                setCodeList={setCodeList}
                codeList={codeList}
                setOrder={setOrder}
                order={order}
                Submit={"Update"}
            />
        </>
    );
};

export default ValueLevelTableData;
