// eslint-disable-next-line
import React from "react";
import ReactPaginate from "react-paginate";

export default ({
  Result,
  itemsPerPage,
  handlePageClick,
  pageCount,
  width
}) => {
  return (
    <div className="pagination">
      {Result?.length > 0 &&
        <div className="paginationResultCount">{Result?.length} {Result?.length === 1 ? "RESULT" : "RESULTS"}</div>
      }
      <div className={width === "full" ? "fullwidthpagination paginate" : "paginate"}>
        {Result?.length > itemsPerPage ?
          <ReactPaginate
            breakLabel="..."
            nextLabel="Next"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            previousLabel="Prev"
            renderOnZeroPageCount={null}
          />
          : ""}
      </div>
    </div>
  );
};
