import React from "react";
import { Table } from "react-bootstrap";
import Moment from "react-moment";

const LibraryGeneralProperties = ({
  libraryProperties,
}) => {

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th style={{ width: "32%" }}>File Attribute</th>
            <th>File Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Name</td>
            <td>{libraryProperties.name}</td>
          </tr>
          <tr>
            <td>Description</td>
            <td>{libraryProperties.description}</td>
          </tr>
          <tr>
            <td>Location</td>
            <td>
              /library/{libraryProperties.type}/{libraryProperties.name}
            </td>
          </tr>
          <tr>
            <td>Rows</td>
            <td>{libraryProperties.row}</td>
          </tr>
          <tr>
            <td>Columns</td>
            <td>{libraryProperties.column}</td>
          </tr>
          <tr>
            <td>Created Date | Time</td>
            <td>
              <Moment format="DD MMM YYYY | HH:mm:ss" withTitle>
                {libraryProperties.createdAt}
              </Moment>
            </td>
          </tr>
          <tr>
            <td>Modified Date | Time</td>
            <td>
              <Moment format="DD MMM YYYY | HH:mm:ss" withTitle>
                {libraryProperties.updatedAt}
              </Moment>
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default LibraryGeneralProperties;
