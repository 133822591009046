import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import profileManImg from "../../../img/MenUserProfile.jpg";
import profileWomanImg from "../../../img/WomenUserProfile.jpg";
import OrganizationLogo from "../../../img/OrganizationLogo.jpg";
import { authServices } from "../../../APIServices/authServices";
import { addressServices } from "../../../APIServices/addressServices";

const Profile = ({ userCurrrentData, currentUserData }) => {
  const [companyName, setCompanyName] = useState("");
  const [shortName, setShortName] = useState("");
  const [website, setWebsite] = useState("");
  const [officePhone, setOfficePhone] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [countryId, setCountryId] = useState("");
  const [stateId, setStateId] = useState("");
  const [state, setState] = useState("");
  const [cityId, setCityId] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [GSTIN, setGSTIN] = useState("");
  const [GSTStateCode, setGSTStateCode] = useState("");
  const [GSTStateName, setGSTStateName] = useState("");
  const [gstStateId, setGSTStateId] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [gstType, setGSTType] = useState("");
  const [image, setImage] = useState(null);
  const [phoneError, setPhoneError] = useState("");
  const [officePhoneError, setOfficePhoneError] = useState("");
  const [gstError, setGSTError] = useState("");
  const [loader, setLoader] = useState(false);

  const updateProfile = async () => {
    setLoader(true);
    let userData = {};
    if (userCurrrentData?.roleType === "organization") {
      userData.companyName = companyName;
      userData.shortName = shortName;
      userData.website = website;
      userData.officePhone = officePhone;
    } else {
      userData.firstName = firstName;
      userData.middleName = middleName;
      userData.lastName = lastName;
      userData.gender = gender;
    }
    userData.phone = phoneNumber;
    userData.address = address;
    userData.countryId = countryId;
    userData.stateId = stateId;
    userData.cityId = cityId;
    userData.zipCode = zipCode;
    userData.gstin = GSTIN;
    userData.gstStateCode = GSTStateCode;
    userData.gstStateName = GSTStateName;
    userData.countryCode = countryCode;
    userData.gstStateId = gstStateId;
    userData.gstType = gstType;
    let data = await authServices.updateProfile(userData);
    if (data?.statusCode) {
      setLoader(false);
      currentUserData();
    }
  };

  const getCountries = async () => {
    let data = await addressServices.getCountries();
    setCountryList(data?.data);
  };

  const getStates = async (id) => {
    let data = await addressServices.getStates(id);
    setStateList(data?.data);
    setStateId("");
    setState("");
    setGSTStateCode("");
    setCityId("");
    setCity("");
    setCountryCode(id);
  };

  const handleGSTData = (e) => {
    setGSTStateId(e.target.value);
    setGSTStateCode(e.target[e.target.selectedIndex].title);
    setGSTStateName(e.target[e.target.selectedIndex].id);
  };

  const getCities = async (e) => {
    let id = e.target[e.target.selectedIndex].title
    let data = await addressServices.getCities(id);
    setCityList(data?.data);
  };

  const findStateName = async (countryId) => {
    let data = await addressServices.findStateName(countryId);
    setState(data?.data);
  };

  const findCityName = async (stateId) => {
    let data = await addressServices.findCityName(stateId);
    setCity(data?.data);
  };

  useEffect(() => {
    if (userCurrrentData?.roleType === "organization") {
      setCompanyName(userCurrrentData?.companyName);
      setShortName(userCurrrentData?.shortName);
      setWebsite(userCurrrentData?.website);
      setOfficePhone(userCurrrentData?.officePhone);
    } else {
      setFirstName(userCurrrentData?.firstName);
      setMiddleName(userCurrrentData?.middleName);
      setLastName(userCurrrentData?.lastName);
      setGender(userCurrrentData?.gender);
    }
    findStateName(userCurrrentData?.stateId);
    findCityName(userCurrrentData?.cityId);
    setPhoneNumber(userCurrrentData?.phone);
    setAddress(userCurrrentData?.address);
    setCountryId(userCurrrentData?.countryId);
    setStateId(userCurrrentData?.stateId);
    setCityId(userCurrrentData?.cityId);
    setZipCode(userCurrrentData?.zipCode);
    setImage(userCurrrentData?.profileImage);
    setGSTStateCode(userCurrrentData?.gstStateCode);
    setGSTIN(userCurrrentData?.gstin);
    setCountryCode(userCurrrentData?.countryCode);
    setGSTType(userCurrrentData?.gstType);
    getCountries();
  }, [userCurrrentData]);

  const handleImageChange = async (e) => {
    let fileData = e.target.files[0];
    if (fileData) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(fileData);

      const formData = new FormData();
      formData.append("file", fileData);

      let result = await authServices.updateProfileImage(
        formData,
        userCurrrentData?._id
      );
      if (result?.statusCode === 200) {
        currentUserData();
      }
    }
  };

  const validatePhoneNumber = (phone) => {
    const phonePattern = /^\d{10}$/;
    if (phonePattern.test(phone)) {
      setPhoneError("");
    } else {
      setPhoneError("Phone Number should have 10 digits ");
    }
  };

  const validateOfficePhoneNumber = (officePhone) => {
    const officephonePattern = /^\d{10}$/;
    if (officephonePattern.test(officePhone)) {
      setOfficePhoneError("");
    } else {
      if (officePhone?.length === 0) {
        setOfficePhoneError("");
      } else {
        setOfficePhoneError(" Office Phone Number Should have 10 digit");
      }
    }
  };
  const validGSTnumber = (GSTIN) => {
    const gstpattern =
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    if (gstpattern.test(GSTIN)) {
      setGSTError("");
    } else {
      setGSTError("Invalide GST Number");
    }
  };

  return (
    <div>
      <Row>
        <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
          <Form.Label>Profile Image</Form.Label>
          <div
            className="bg-white box-shadow-min border-radius-3"
            style={{ padding: "21px 10px" }}
          >
            <div className="avatar-upload">
              <div className="avatar-upload">
                <div className="avatar-edit">
                  <input
                    type="file"
                    id="imageUpload"
                    accept=".png, .jpg, .jpeg"
                    onChange={handleImageChange}
                  />
                  <label
                    htmlFor="imageUpload"
                    title="Upload Profile Pic"
                  ></label>
                </div>
                <div className="avatar-preview">
                  {image ? (
                    <img src={image} alt="Preview" />
                  ) : (
                    <img
                      src={
                        userCurrrentData?.roleType === "organization"
                          ? OrganizationLogo
                          : userCurrrentData?.gender === "female"
                          ? profileWomanImg
                          : profileManImg
                      }
                      alt="Default Preview"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Form.Group>
        <Col md={8}>
          <Row>
            {userCurrrentData?.roleType === "organization" ? (
              <>
                <Form.Group as={Col} md="6" className="add_sponsor_field mb-3">
                  <Form.Label>
                    Organization Name <span>*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    onChange={(e) => setCompanyName(e.target.value)}
                    value={companyName}
                    placeholder="Enter Organization Name"
                  />
                </Form.Group>
                <Form.Group as={Col} md="6" className="add_sponsor_field mb-3">
                  <Form.Label>Short Name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    onChange={(e) => setShortName(e.target.value)}
                    value={shortName}
                    placeholder="Enter Short Name"
                  />
                </Form.Group>
                <Form.Group as={Col} md="6" className="add_sponsor_field mb-3">
                  <Form.Label>
                    Website <span>*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    onChange={(e) => setWebsite(e.target.value)}
                    value={website}
                    placeholder="Enter Website"
                  />
                </Form.Group>
              </>
            ) : (
              <>
                <Form.Group as={Col} md="6" className="add_sponsor_field mb-3">
                  <Form.Label>
                    First Name <span>*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                    placeholder="Enter First Name"
                  />
                </Form.Group>
                <Form.Group as={Col} md="6" className="add_sponsor_field mb-3">
                  <Form.Label>Middle Name</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) => setMiddleName(e.target.value)}
                    value={middleName}
                    placeholder="Enter Middle Name"
                  />
                </Form.Group>
                <Form.Group as={Col} md="6" className="add_sponsor_field mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                    placeholder="Enter Last Name"
                  />
                </Form.Group>
              </>
            )}
            <Form.Group as={Col} md="6" className="add_sponsor_field mb-3">
              <Form.Label>
                Email <span>*</span>
              </Form.Label>
              <Form.Control
                required
                disabled
                type="email"
                placeholder="Enter Email ID"
                value={userCurrrentData?.email}
              />
            </Form.Group>
            <Form.Group as={Col} md="6" className="add_sponsor_field mb-3">
              <Form.Label>
                Phone Number <span>*</span>
              </Form.Label>
              <Form.Control
                required
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                  validatePhoneNumber(e.target.value);
                }}
                value={phoneNumber}
                type="number"
                placeholder="Enter Phone Number"
              />
              {phoneError && (
                <span
                  className="message"
                  style={{ color: "red", fontSize: 10 }}
                >
                  {phoneError}
                </span>
              )}
            </Form.Group>
            {userCurrrentData?.roleType === "organization" ? (
              <Form.Group as={Col} md="6" className="add_sponsor_field mb-3">
                <Form.Label>
                  Office Phone <span>*</span>
                </Form.Label>
                <Form.Control
                  required
                  onChange={(e) => {
                    setOfficePhone(e.target.value);
                    validateOfficePhoneNumber(e.target.value);
                  }}
                  value={officePhone}
                  type="number"
                  placeholder="Enter Office Phone"
                />
                {officePhoneError && (
                  <span
                    className="message"
                    style={{ color: "red", fontSize: 10 }}
                  >
                    {officePhoneError}
                  </span>
                )}
              </Form.Group>
            ) : (
              <Form.Group as={Col} md="6" className="add_sponsor_field mb-3">
                <Form.Label>
                  Gender <span>*</span>
                </Form.Label>
                <Form.Select
                  onChange={(e) => setGender(e.target.value)}
                  aria-label="Default select example"
                >
                  <option>Select Gender</option>
                  <option selected={gender === "male"} value="male">
                    Male
                  </option>
                  <option selected={gender === "female"} value="female">
                    Female
                  </option>
                  <option selected={gender === "other"} value="other">
                    Other
                  </option>
                </Form.Select>
              </Form.Group>
            )}
          </Row>
        </Col>
      </Row>
      {userCurrrentData?.roleType === "admin" ? (
        ""
      ) : (
        <Row>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>
              Address <span>*</span>
            </Form.Label>
            <Form.Control
              required
              onChange={(e) => setAddress(e.target.value)}
              value={address}
              as="textarea"
              placeholder="Enter Address"
            />
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Country <span>*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => setCountryId(e.target.value)}
              onClick={(e) => getStates(e.target[e.target.selectedIndex].title)}
              aria-label="Default select example"
            >
              <option>Select Country</option>
              {countryList?.length > 0
                ? countryList?.map((item) => (
                    <option
                      selected={item._id === countryId}
                      value={item._id}
                      title={item.id}
                    >
                      {item.name}
                    </option>
                  ))
                : ""}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              State <span>*</span>
            </Form.Label>
            {stateList?.length > 0 ? (
              <Form.Select
                onChange={(e) => setStateId(e.target.value)}
                onClick={(e) =>
                  getCities(e)
                }
                aria-label="Default select example"
              >
                <option>Select State</option>
                {stateList?.map((item) => (
                  <option
                    selected={item._id === stateId}
                    value={item._id}
                    title={item.id}
                  >
                    {item.name}
                  </option>
                ))}
              </Form.Select>
            ) : (
              <Form.Control required type="text" disabled value={state} />
            )}
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              City <span>*</span>
            </Form.Label>
            {cityList?.length > 0 ? (
              <Form.Select
                onChange={(e) => setCityId(e.target.value)}
                aria-label="Default select example"
              >
                <option>Select City</option>
                {cityList?.map((item) => (
                  <option selected={item._id === cityId} value={item._id}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>
            ) : (
              <Form.Control required type="text" disabled value={city} />
            )}
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Zip Code <span>*</span>
            </Form.Label>
            <Form.Control
              onChange={(e) => setZipCode(e.target.value)}
              value={zipCode}
              required
              type="number"
              placeholder="Enter Zip Code"
            />
          </Form.Group>
        </Row>
      )}
      <Row>
        <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
          <Form.Label>
            Address <span>*</span>
          </Form.Label>
          <Form.Control
            required
            as="textarea"
            onChange={(e) => setAddress(e.target.value)}
            value={address}
            placeholder="Type Here"
          />
        </Form.Group>
        <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
          <Form.Label>
            Country <span>*</span>
          </Form.Label>
          <Form.Select
            onChange={(e) => setCountryId(e.target.value)}
            onClick={(e) => getStates(e.target[e.target.selectedIndex].title)}
            aria-label="Default select example"
          >
            <option>Select Country</option>
            {countryList?.length > 0
              ? countryList?.map((item) => (
                  <option
                    selected={item._id === countryId}
                    value={item._id}
                    title={item.id}
                  >
                    {item.name}
                  </option>
                ))
              : ""}
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
          <Form.Label>
            State <span>*</span>
          </Form.Label>
          {stateList?.length > 0 ? (
            <Form.Select
              onChange={(e) => setStateId(e.target.value)}
              onClick={(e) => getCities(e)}
              aria-label="Default select example"
            >
              <option>Select State</option>
              {stateList?.map((item) => (
                <option
                  selected={item._id === stateId}
                  value={item._id}
                  title={item.id}
                  id={item.gst_stateCode}
                >
                  {item.name}
                </option>
              ))}
            </Form.Select>
          ) : (
            <Form.Control required type="text" disabled value={state} />
          )}
        </Form.Group>
        <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
          <Form.Label>
            City <span>*</span>
          </Form.Label>
          {cityList?.length > 0 ? (
            <Form.Select
              onChange={(e) => setCityId(e.target.value)}
              aria-label="Default select example"
            >
              <option>Select City</option>
              {cityList?.map((item) => (
                <option selected={item._id === cityId} value={item._id}>
                  {item.name}
                </option>
              ))}
            </Form.Select>
          ) : (
            <Form.Control required type="text" disabled value={city} />
          )}
        </Form.Group>
        <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
          <Form.Label>
            Zip Code<span>*</span>
          </Form.Label>
          <Form.Control
            required
            onChange={(e) => setZipCode(e.target.value)}
            value={zipCode}
            type="number"
            placeholder="Enter Zip Code"
          />
        </Form.Group>
        {countryCode === "101" && (
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Do you have GST number or not? <span>*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => setGSTType(e.target.value)}
              aria-label="Default select example"
            >
              <option>Select</option>
              <option selected={gstType === "yes"} value="yes">
                Yes
              </option>
              <option selected={gstType === "no"} value="no">
                No
              </option>
            </Form.Select>
          </Form.Group>
        )}
      </Row>
      {countryCode === "101" && gstType === "yes" && (
        <>
          <h6 className="mt-2">
            <b>GST Details : </b>
          </h6>
          <Row>
            <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
              <Form.Label>State</Form.Label>
                <Form.Select
                  onChange={(e) => handleGSTData(e)}
                  aria-label="Default select example"
                >
                  <option>Select State</option>
                  {stateList?.map((item) => (
                    <option
                      selected={item._id === gstStateId}
                      value={item.id}
                      title={item.gst_stateCode}
                      id={item.name}
                    >
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
            </Form.Group>
            <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
              <Form.Label>GSTIN</Form.Label>
              <Form.Control
                required
                onChange={(e) => {
                  setGSTIN(e.target.value);
                  validGSTnumber(e.target.value);
                }}
                value={GSTIN}
                type="text"
                placeholder="Enter GST Code"
              />
              {gstError && (
                <span
                  className="message"
                  style={{ color: "red", fontSize: 10 }}
                >
                  {gstError}
                </span>
              )}
            </Form.Group>
            <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
              <Form.Label>GST State Code</Form.Label>
              <Form.Control
                required
                disabled
                value={GSTStateCode}
                type="text"
                placeholder="Enter GST State Code"
              />
            </Form.Group>
            <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
              <Form.Label>GST State Name</Form.Label>
              <Form.Control
                required
                disabled
                value={GSTStateName}
                type="text"
                className="text-capitalize"
                placeholder="Enter GST State Code"
              />
            </Form.Group>
          </Row>
        </>
      )}
      <div className="hstack justify-content-end footer_button">
        <Button className="hstack gap-2" disabled={!firstName || phoneError} onClick={updateProfile}>
          <Spinner
            as="span"
            animation={loader && "border"}
            size="sm"
            role="status"
            aria-hidden="true"
          />
          Update
        </Button>
      </div>
    </div>
  );
};

export default Profile;
