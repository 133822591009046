import React, { useEffect, useState } from "react";
import Pagination from "../../Hooks/Pagination";
import TableHeader from "../../../component/TableHeader";
import AIRevenueTableData from "./AIRevenueTableData";
import UseToggle from "../../Hooks/useToggle";
import ExportTransectionRecordModal from "./ExportTransectionRecordModal";
import CreditPriceModal from "./CreditPriceModal";
import { creditPriceServices } from "../../../APIServices/AIAlpha/creditPriceServices";
import ViewAnalyticsChartModal from "./ViewAnalyticsChartModal";

const AIRevenue = ({ onMouseDown, tableRef, themeColor }) => {
  const { toggle, setToggle, toggle1, setToggle1, toggle2, setToggle2 } =
    UseToggle();
  const [searchData, setSearchData] = useState("");
  const [creditData, setCreditData] = useState("");
  const [revenueList, setRevenueList] = useState([]);

  const getAllRevenueList = async (type) => {
    let data = await creditPriceServices.getBillingTransections(type);
    setRevenueList(data?.data);
  };

  const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = revenueList?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(revenueList?.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % revenueList?.length;
    setItemOffset(newOffset);
  };

  const handleSearch = async (searchValue) => {
    let userData = {};
    userData.searchData = searchValue || searchData;
  };

  const creditPrice = async () => {
    let data = await creditPriceServices.creditPrice();
    setCreditData(data?.data);
    setToggle1();
  };

  useEffect(() => {
    getAllRevenueList("all");
  }, []);
  return (
    <>
      <TableHeader
        Search
        handleSearch={handleSearch}
        setSearchData={setSearchData}
        SearchReverseData={getAllRevenueList}
        Credit
        handleCredit={creditPrice}
        titleCredit="Manage Price"
        Analytics
        titleAnalytics="View Analytics"
        handleAnalytics={setToggle2}
        Export
        handleExport={setToggle}
        titleExport="Export Transection Records"
      />
      <AIRevenueTableData
        onMouseDown={onMouseDown}
        tableRef={tableRef}
        getAllRevenueList={currentItems}
      />
      <Pagination
        Result={currentItems}
        itemsPerPage={itemsPerPage}
        handlePageClick={handlePageClick}
        pageCount={pageCount}
      />
      <ExportTransectionRecordModal
        Show={!toggle}
        Hide={setToggle}
        Size={"md"}
        Title={"Export Transection Records"}
        Submit={"Export"}
      />
      <CreditPriceModal
        Show={!toggle1}
        Hide={setToggle1}
        creditData={creditData}
        Size={"sm"}
        Title={"Manage Price"}
        Submit={"Update"}
      />
      <ViewAnalyticsChartModal
        Show={!toggle2}
        Hide={setToggle2}
        Size={"lg"}
        Title="View Billing Analytics"
        themeColor={themeColor}
      />
    </>
  );
};

export default AIRevenue;
