import React, { useEffect, useState } from "react";
import AddNewSubadminModal from "./AddNewSubadminModal";
import UseToggle from "../../Hooks/useToggle";
import Pagination from "../../Hooks/Pagination";
import ExportSubadminModal from "./ExportSubadminModal";
import TableHeader from "../../../component/TableHeader";
import SubadminTableData from "./SubadminTableData";
import { userServices } from "../../../APIServices/usersServices";
import { designationPermissionServices } from "../../../APIServices/designationPermissionServices";
import SubadminsInvitation from "./SubadminInvitation/SubadminsInvitation";
import { invitedDataServices } from "../../../APIServices/invitedDataServices";

const Subadmins = ({ onMouseDown, tableRef }) => {
  const { toggle, setToggle, toggle1, setToggle1, toggle2, setToggle2 } =
    UseToggle();

  const [subadminList, setSubadminList] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [invitedList, setInvitedList] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [permissionList, setPermissionList] = useState([]);

  const getInvitation = async () => {
    let data = await invitedDataServices.getInvitation("subadmin");
    setInvitedList(data?.data);
  };

  const getUserData = async () => {
    let data = await userServices.getUserData("subadmin");
    setSubadminList(data?.data);
  };

  const getPermissionsList = async () => {
    let data = await designationPermissionServices.getActivePermissions();
    setPermissionList(data?.data);
  };

  const getDesignationsList = async () => {
    let data = await designationPermissionServices.getActiveDesignations();
    setDesignationList(data?.data);
  };

  const itemsPerPage = 5;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = subadminList?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(subadminList?.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % subadminList?.length;
    setItemOffset(newOffset);
  };

  const handleSearch = async (searchValue) => {
    let userData = {};
    userData.searchData = searchValue || searchData;
    userData.roleType = "subadmin";
    let data = await userServices.searchUserData(userData);
    setSubadminList(data?.data);
  };

  const openInvitationHistory = () => {
    setToggle2();
    getInvitation();
  };

  useEffect(() => {
    getUserData();
    getPermissionsList();
    getDesignationsList();
  }, []);
  return (
    <>
      <TableHeader
        Search
        handleSearch={handleSearch}
        setSearchData={setSearchData}
        SearchReverseData={getUserData}
        AddNew
        handleAddNew={setToggle}
        titleAddNew="Add New Subadmin"
        InviteHistory
        handleInviteHistory={openInvitationHistory}
        titleInviteHistory="Invitation History"
        Export
        handleExport={setToggle1}
        titleExport="Export Subadmins Data"
      />
      <SubadminTableData
        onMouseDown={onMouseDown}
        tableRef={tableRef}
        subadminList={currentItems}
        permissionList={permissionList}
        designationList={designationList}
        getUserData={getUserData}
      />
      <Pagination
        Result={subadminList}
        itemsPerPage={itemsPerPage}
        handlePageClick={handlePageClick}
        pageCount={pageCount}
      />
      <AddNewSubadminModal
        Show={!toggle}
        Hide={setToggle}
        Size={"lg"}
        Title={"Add New Subadmin"}
        getUserData={getUserData}
        permissionList={permissionList}
        designationList={designationList}
        Submit={"Submit"}
      />
      <SubadminsInvitation
        onMouseDown={onMouseDown}
        tableRef={tableRef}
        Show={!toggle2}
        Hide={setToggle2}
        Size={"xl"}
        Title={"Subadmins Invitation History"}
        permissionList={permissionList}
        designationList={designationList}
        invitedList={invitedList}
        setInvitedList={setInvitedList}
        getInvitation={getInvitation}
      />
      <ExportSubadminModal
        Show={!toggle1}
        Hide={setToggle1}
        Size={"md"}
        Title={"Export Subadmins"}
        Submit={"Export"}
      />
    </>
  );
};

export default Subadmins;
