import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Sidebar from "../component/Sidebar";
import Header from "../component/Header";
import Dashboard from "./Dashboard";
import Subadmins from "./User/Subadmin/Subadmins";
import Users from "./ExternalUsers/UsersLists";
import Designation from "./User/Designation/Designation";
import Permission from "./User/Permission/Permission";
import Profile from "./Account/Profile/Profile";
import LogData from "./Account/LogData/LogData";
import FailedStatus from "./Account/FailedStatus/FailedStatus";
import Appearance from "./Account/Appearance/Appearance";
import RestrictedPage from "../component/RestrictedPage";
import Reviewers from "./Reviewer/Reviewers";
import Packages from "./Editor/Packages/Packages";
import Validators from "./Validator/Validators";
import AIRevenue from "./AIAlpha/Revenue/AIRevenue";
import AllEditorSubscriptions from "./Editor/Subcriptions/AllEditorSubscriptions";
import AIDocuments from "./AIAlpha/Documents/AIDocuments";
import PackageRevenue from "./Editor/PackageRevenue/PackageRevenue";
import LibraryHelp from "./Editor/LibraryHelp/LibraryHelp";
import Credits from "./AIAlpha/Credits/Credits";
import AIPrograms from "./AIAlpha/Programs/AIPrograms";
import AIBackup from "./AIAlpha/Backup/AIBackup";
import NewVersionPackage from "./Editor/Packages/UpdateNewVersionPackage/NewVersionPackage";
import GlobalClass from "./CDISCControl/SDTM/GlobalClass/GlobalClass";
import SDTMVersion from "./CDISCControl/SDTM/Version/SDTMVersion";
import SDTMClass from "./CDISCControl/SDTM/Class/SDTMClass";
import SDTMDomain from "./CDISCControl/SDTM/Domain/SDTMDomain";
import VariableMethod from "./CDISCControl/SDTM/Variable/Method/VariableMethod";
import SDTMVeriable from "./CDISCControl/SDTM/Variable/Veriable/SDTMVeriable";
import VariableComment from "./CDISCControl/SDTM/Variable/Comment/VariableComment";
import Terminology from "./CDISCControl/CDISCTerminology/Terminology/Terminology";
import STDMTerminology from "./CDISCControl/CDISCTerminology/STDMTerminology/STDMTerminology";
import ValueLevelData from "./CDISCControl/SDTM/ValueLevel/ValueLevelData";
import DataTemplate from "./CDISCControl/DataTemplate/DataTemplate";

const Panel = ({
  roleType,
  userCurrrentData,
  currentUserData,
  themeColor,
  getThemeColor,
  getThemeFont,
  font,
  getStatusColor,
  activeColor,
  inActiveColor,
  pendingColor,
  permission,
}) => {
  const { permalink } = useParams();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(
    screenWidth < 767 ? true : false
  );
  const [showLargeMenuSubadmin, setShowLargeMenuSubadmin] = useState(false);
  const [showLargeMenuAccount, setShowLargeMenuAccount] = useState(false);
  const [showLargeMenuEditorControl, setShowLargeMenuEditorControl] =
    useState(false);
  const [showLargeMenuCDISCControl, setShowLargeMenuCDISCControl] =
    useState(false);
  const [showLargeMenuAIAlphaControl, setShowLargeMenuAIAlphaControl] =
    useState(false);

  const toggleSidebar = () => {
    setSidebarCollapsed(!isSidebarCollapsed);
    closeLargeMenu();
  };

  const closeLargeMenu = () => {
    setShowLargeMenuSubadmin(false);
    setShowLargeMenuAccount(false);
    setShowLargeMenuEditorControl(false);
    setShowLargeMenuCDISCControl(false);
    setShowLargeMenuAIAlphaControl(false);
  };

  const handleMobileSidebar = () => {
    if (screenWidth < 767) {
      setSidebarCollapsed(true);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setSidebarCollapsed(screenWidth < 767 ? true : false);
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenWidth]);

  let AIUserPermission =
    permission?.addNewAIUsers ||
    permission?.viewAIUsersDetails ||
    permission?.updateAIUsersDetails ||
    permission?.changeAIUserStatus ||
    permission?.exportAllAIUserData ||
    permission?.viewAIUserInvitationHistory ||
    permission?.sendNewAIUserInvitation ||
    permission?.resendAIUserInvitation ||
    permission?.cancelAIUserInvitation ||
    roleType === "admin";

  let ConsultantPermission =
    permission?.addNewConsultants ||
    permission?.viewConsultantsDetails ||
    permission?.updateConsultantsDetails ||
    permission?.changeConsultantStatus ||
    permission?.exportAllConsultantData ||
    permission?.viewConsultantInvitationHistory ||
    permission?.sendNewConsultantInvitation ||
    permission?.resendConsultantInvitation ||
    permission?.cancelConsultantInvitation ||
    roleType === "admin";

  let DeveloperPermission =
    permission?.addNewDevelopers ||
    permission?.viewDevelopersDetails ||
    permission?.updateDevelopersDetails ||
    permission?.changeDeveloperStatus ||
    permission?.exportAllDeveloperData ||
    permission?.viewDeveloperInvitationHistory ||
    permission?.sendNewDeveloperInvitation ||
    permission?.resendDeveloperInvitation ||
    permission?.cancelDeveloperInvitation ||
    roleType === "admin";

  let OrganizationPermission =
    permission?.addNewOrganizations ||
    permission?.viewOrganizationsDetails ||
    permission?.updateOrganizationsDetails ||
    permission?.changeOrganizationStatus ||
    permission?.exportAllOrganizationData ||
    permission?.viewOrganizationInvitationHistory ||
    permission?.sendNewOrganizationInvitation ||
    permission?.resendOrganizationInvitation ||
    permission?.cancelOrganizationInvitation ||
    roleType === "admin";

  let CDISCPermission =
    permission?.addNewCDISC ||
    permission?.viewCDISCDetails ||
    permission?.updateCDISCDetails ||
    permission?.changeCDISCtatus ||
    permission?.exportAllCDISCData ||
    permission?.viewCDISCInvitationHistory ||
    permission?.sendNewCDISCInvitation ||
    permission?.resendCDISCInvitation ||
    permission?.cancelCDISCInvitation ||
    roleType === "admin";

  const [resizingColumn, setResizingColumn] = useState(null);
  const startX = useRef(0);
  const startWidth = useRef(0);
  const tableRef = useRef(null);

  useEffect(() => {
    const onMouseMove = (e) => {
      if (resizingColumn !== null) {
        const width = startWidth.current + (e.pageX - startX.current);
        const th = tableRef.current.querySelector(
          `th:nth-child(${resizingColumn + 1})`
        );
        th.style.width = `${width}px`;
        tableRef.current.classList.add("resizing");
      }
    };

    const onMouseUp = () => {
      setResizingColumn(null);
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    if (resizingColumn !== null) {
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [resizingColumn]);

  const onMouseDown = (e, columnIndex) => {
    setResizingColumn(columnIndex);
    startX.current = e.pageX;
    startWidth.current = e.target.offsetWidth;
    document.body.style.userSelect = "none";
    tableRef.current.querySelectorAll("th").forEach((column, index) => {
      if (index !== columnIndex) {
        column.style.width = `${column.offsetWidth}px`;
      }
    });
  };

  return (
    <div>
      <Container fluid>
        <Row>
          <Col className="p-0">
            <div className="flex-wrapper">
              <div
                id="sidebar"
                className={`sidebar ${isSidebarCollapsed ? "collapsed" : ""}`}
              >
                <Sidebar
                  roleType={roleType}
                  userCurrrentData={userCurrrentData}
                  setShowLargeMenuAccount={setShowLargeMenuAccount}
                  showLargeMenuAccount={showLargeMenuAccount}
                  setShowLargeMenuSubadmin={setShowLargeMenuSubadmin}
                  showLargeMenuSubadmin={showLargeMenuSubadmin}
                  setShowLargeMenuEditorControl={setShowLargeMenuEditorControl}
                  showLargeMenuEditorControl={showLargeMenuEditorControl}
                  setShowLargeMenuCDISCControl={setShowLargeMenuCDISCControl}
                  showLargeMenuCDISCControl={showLargeMenuCDISCControl}
                  setShowLargeMenuAIAlphaControl={
                    setShowLargeMenuAIAlphaControl
                  }
                  showLargeMenuAIAlphaControl={showLargeMenuAIAlphaControl}
                  permalink={permalink}
                  screenWidth={screenWidth}
                  setSidebarCollapsed={setSidebarCollapsed}
                  isSidebarCollapsed={isSidebarCollapsed}
                  AIUserPermission={AIUserPermission}
                  ConsultantPermission={ConsultantPermission}
                  DeveloperPermission={DeveloperPermission}
                  OrganizationPermission={OrganizationPermission}
                />
              </div>
              <div className="admin-main">
                <div className="admin-main-header">
                  <i
                    id="menu-btn"
                    className={`fa-solid ${isSidebarCollapsed
                      ? screenWidth < 767
                        ? "fa-solid fa-bars"
                        : "fa-caret-right"
                      : "fa-caret-left"
                      }`}
                    onClick={toggleSidebar}
                    title={
                      isSidebarCollapsed ? "Open Sidebar" : "Close Sidebar"
                    }
                  ></i>
                  <div className="show_nav_title">
                    <i
                      className="fa-solid fa-store mx-2"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <Header
                    userCurrrentData={userCurrrentData}
                    closeLargeMenu={closeLargeMenu}
                    permalink={permalink}
                    screenWidth={screenWidth}
                    handleMobileSidebar={handleMobileSidebar}
                  />
                </div>
                <div className="admin-main-content-area">
                  {permalink === "analytics" ? (
                    <Dashboard
                      AIUserPermission={AIUserPermission}
                      ConsultantPermission={ConsultantPermission}
                      DeveloperPermission={DeveloperPermission}
                      OrganizationPermission={OrganizationPermission}
                      permission={permission}
                      themeColor={themeColor}
                      roleType={roleType}
                    />
                  ) : permalink === "reviewers" &&
                    roleType === "organization" ? (
                    <Reviewers onMouseDown={onMouseDown} tableRef={tableRef} />
                  ) : permalink === "validators" &&
                    roleType === "organization" ? (
                    <Validators onMouseDown={onMouseDown} tableRef={tableRef} />
                  ) : permalink === "organizations" &&
                    ((OrganizationPermission && roleType === "subadmin") ||
                      roleType === "admin") ? (
                    <Users
                      onMouseDown={onMouseDown}
                      tableRef={tableRef}
                      role={"organization"}
                      userPermission={OrganizationPermission}
                      permission={permission}
                      roleType={roleType}
                    />
                  ) : permalink === "cdisc-client" &&
                    ((OrganizationPermission && roleType === "subadmin") ||
                      roleType === "admin") ? (
                    <Users
                      onMouseDown={onMouseDown}
                      tableRef={tableRef}
                      role={"cdisc"}
                      userPermission={CDISCPermission}
                      permission={permission}
                      roleType={roleType}
                    />
                  ) : permalink === "ai-users" &&
                    ((AIUserPermission && roleType === "subadmin") ||
                      roleType === "admin") ? (
                    <Users
                      onMouseDown={onMouseDown}
                      tableRef={tableRef}
                      role={"aiuser"}
                      userPermission={AIUserPermission}
                      permission={permission}
                      roleType={roleType}
                    />
                  ) : permalink === "developers" &&
                    ((DeveloperPermission && roleType === "subadmin") ||
                      roleType === "admin") ? (
                    <Users
                      onMouseDown={onMouseDown}
                      tableRef={tableRef}
                      role={"developer"}
                      userPermission={DeveloperPermission}
                      permission={permission}
                      roleType={roleType}
                    />
                  ) : permalink === "consultants" &&
                    ((ConsultantPermission && roleType === "subadmin") ||
                      roleType === "admin") ? (
                    <Users
                      onMouseDown={onMouseDown}
                      tableRef={tableRef}
                      role={"consultant"}
                      userPermission={ConsultantPermission}
                      permission={permission}
                      roleType={roleType}
                    />
                  ) : permalink === "packages" &&
                    (roleType === "admin" || roleType === "organization" || roleType === "reviewer") ? (
                    <Packages
                      onMouseDown={onMouseDown}
                      tableRef={tableRef}
                      roleType={roleType}
                    />
                  ) : permalink === "updatedPackages" &&
                    (roleType === "organization" || roleType === "reviewer") ? (
                    <NewVersionPackage
                      onMouseDown={onMouseDown}
                      tableRef={tableRef}
                      roleType={roleType}
                    />
                  ) : permalink === "packages-revenue" &&
                    roleType === "admin" ? (
                    <PackageRevenue
                      onMouseDown={onMouseDown}
                      tableRef={tableRef}
                    />
                  ) : permalink === "subadmin" && roleType === "admin" ? (
                    <Subadmins onMouseDown={onMouseDown} tableRef={tableRef} />
                  ) : permalink === "documents" && roleType === "admin" ? (
                    <AIDocuments
                      onMouseDown={onMouseDown}
                      tableRef={tableRef}
                    />
                  ) : permalink === "programs" && roleType === "admin" ? (
                    <AIPrograms
                      onMouseDown={onMouseDown}
                      tableRef={tableRef}
                    />
                  ) : permalink === "ai-revenue" && roleType === "admin" ? (
                    <AIRevenue
                      onMouseDown={onMouseDown}
                      tableRef={tableRef}
                      themeColor={themeColor}
                    />
                  ) : permalink === "credits" && roleType === "admin" ? (
                    <Credits onMouseDown={onMouseDown} tableRef={tableRef} />
                  ) : permalink === "ai-backup" && roleType === "admin" ? (
                    <AIBackup
                      onMouseDown={onMouseDown}
                      tableRef={tableRef}
                      themeColor={themeColor}
                    />
                  )
                    : permalink === "editor-subscriptions" &&
                      roleType === "admin" ? (
                      <AllEditorSubscriptions
                        onMouseDown={onMouseDown}
                        tableRef={tableRef}
                        themeColor={themeColor}
                      />
                    ) : permalink === "library-help" && roleType === "admin" ? (
                      <LibraryHelp
                        userCurrrentData={userCurrrentData}
                        onMouseDown={onMouseDown}
                        tableRef={tableRef}
                      />
                    ) : permalink === "terminology" && roleType === "admin" ? (
                      <Terminology
                        userCurrrentData={userCurrrentData}
                        onMouseDown={onMouseDown}
                        tableRef={tableRef}
                      />
                    ) : permalink === "designation" && roleType === "admin" ? (
                      <Designation
                        onMouseDown={onMouseDown}
                        tableRef={tableRef}
                      />
                    ) : permalink === "stdm-terminology" && roleType === "admin" ? (
                      <STDMTerminology
                        onMouseDown={onMouseDown}
                        tableRef={tableRef}
                      />
                    ) : permalink === "sdtm-global-class" && roleType === "admin" ? (
                      <GlobalClass
                        onMouseDown={onMouseDown}
                        tableRef={tableRef}
                      />
                    ) : permalink === "sdtm-version" && roleType === "admin" ? (
                      <SDTMVersion
                        onMouseDown={onMouseDown}
                        tableRef={tableRef}
                      />
                    ) : permalink === "sdtm-class" && roleType === "admin" ? (
                      <SDTMClass
                        onMouseDown={onMouseDown}
                        tableRef={tableRef}
                      />
                    ) : permalink === "sdtm-domain" && roleType === "admin" ? (
                      <SDTMDomain
                        onMouseDown={onMouseDown}
                        tableRef={tableRef}
                      />
                    ) : permalink === "sdtm-variable" && roleType === "admin" ? (
                      <SDTMVeriable
                        onMouseDown={onMouseDown}
                        tableRef={tableRef}
                      />
                    ) : permalink === "variable-method" && roleType === "admin" ? (
                      <VariableMethod
                        onMouseDown={onMouseDown}
                        tableRef={tableRef}
                      />
                    ) : permalink === "value-level" && roleType === "admin" ? (
                      <ValueLevelData
                        onMouseDown={onMouseDown}
                        tableRef={tableRef}
                      />
                    ) : permalink === "variable-comment" && roleType === "admin" ? (
                      <VariableComment
                        onMouseDown={onMouseDown}
                        tableRef={tableRef}
                      />
                    ) : permalink === "data-template" && roleType === "admin" ? (
                      <DataTemplate
                        onMouseDown={onMouseDown}
                        tableRef={tableRef}
                      />
                    ) : permalink === "permission" && roleType === "admin" ? (
                      <Permission onMouseDown={onMouseDown} tableRef={tableRef} />
                    ) : permalink === "profile" ? (
                      <Profile
                        userCurrrentData={userCurrrentData}
                        currentUserData={currentUserData}
                      />
                    ) : permalink === "log-data" ? (
                      <LogData onMouseDown={onMouseDown} tableRef={tableRef} />
                    ) : permalink === "failed-status" ? (
                      <FailedStatus
                        onMouseDown={onMouseDown}
                        tableRef={tableRef}
                      />
                    ) : permalink === "appearance" ? (
                      <Appearance
                        getThemeColor={getThemeColor}
                        themeColor={themeColor}
                        getThemeFont={getThemeFont}
                        font={font}
                        getStatusColor={getStatusColor}
                        activeColor={activeColor}
                        inActiveColor={inActiveColor}
                        pendingColor={pendingColor}
                      />
                    ) : (
                      <RestrictedPage />
                    )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Panel;
