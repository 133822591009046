import React, { useEffect, useRef, useState } from "react";
import profileManImg from "../img/MenUserProfile.jpg";
import OrganizationLogo from "../img/OrganizationLogo.jpg";
import profileWomanImg from "../img/WomenUserProfile.jpg";
import { NavLink } from "react-router-dom";
import UseToggle from "../Pages/Hooks/useToggle";
import PasswordModal from "../Pages/Account/Password/PasswordModal";
import { authServices } from "../APIServices/authServices";
import SecurityModal from "../Pages/Account/Security/Security";

const Header = ({
  screenWidth,
  permalink,
  closeLargeMenu,
  handleMobileSidebar,
  userCurrrentData,
}) => {
  const ProfileRef = useRef(null);
  const NotificationRef = useRef(null);
  const [profileActive, setProfileActive] = useState(false);
  let [roleType, setRoleType] = useState("");
  let [firstName, setFirstName] = useState("");
  let [middleName, setMiddleName] = useState("");
  let [lastName, setLastName] = useState("");
  let [companyName, setCompanyName] = useState("");
  // const [notificationsActive, setNotificationsActive] = useState(false);
  const { toggle, setToggle, toggle1, setToggle1 } = UseToggle();

  const toggleProfile = () => {
    setProfileActive(!profileActive);
    handleMobileSidebar();
    // setNotificationsActive(false);
    closeLargeMenu();
  };

  const logout = async () => {
    await authServices.logout();
  }
  // const toggleNotifications = () => {
  //   setNotificationsActive(!notificationsActive);
  //   handleMobileSidebar();
  //   setProfileActive(false);
  //   closeLargeMenu();
  // };

  useEffect(() => {
    const handleClickProfileMenuOutside = (event) => {
      if (ProfileRef.current && !ProfileRef.current.contains(event.target)) {
        setProfileActive(false);
      }
    };
    document.addEventListener("click", handleClickProfileMenuOutside, true);

    const handleClickNotificationOutside = (event) => {
      if (
        NotificationRef.current &&
        !NotificationRef.current.contains(event.target)
      ) {
        // setNotificationsActive(false);
      }
    };
    document.addEventListener("click", handleClickNotificationOutside, true);

    return () => {
      document.removeEventListener(
        "click",
        handleClickProfileMenuOutside,
        true
      );
      document.removeEventListener(
        "click",
        handleClickNotificationOutside,
        true
      );
    };
  }, []);

  const [authType, setAuthType] = useState("")
  const [authTypeData, setAuthTypeData] = useState("")
  const [profileImage, setProfileImage] = useState("")
  const [status, setStatus] = useState(false)
  const [appTwoFA, setAppTwoFA] = useState(false)
  const [emailTwoFA, setEmailTwoFA] = useState(false)

  const getTwoFactorAuthType = async () => {
    let data = await authServices.getTwoFactorAuthType()
    setAuthType(data?.authType)
    setAuthTypeData(data?.authType)
    setStatus(data?.status)
    setAppTwoFA(data?.appTwoFA)
    setEmailTwoFA(data?.emailTwoFA)
  }

  const openTwoFactorAuth = () => {
    getTwoFactorAuthType()
    setToggle1()
  }

  useEffect(() => {
    getTwoFactorAuthType();
    setRoleType(userCurrrentData?.roleType)
    setFirstName(userCurrrentData?.firstName ? userCurrrentData?.firstName : "")
    setMiddleName(userCurrrentData?.middleName ? userCurrrentData?.middleName : "")
    setLastName(userCurrrentData?.lastName ? userCurrrentData?.lastName : "")
    setCompanyName(userCurrrentData?.companyName ? userCurrrentData?.companyName : "")
    setProfileImage(userCurrrentData?.profileImage ? userCurrrentData?.profileImage : (userCurrrentData?.roleType === "organization" ? OrganizationLogo : userCurrrentData?.gender === "female" ? profileWomanImg : profileManImg))
  }, [userCurrrentData]);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between w-100">
        <div className="mx-2">
          <div className="header__collaps">
            <i className="fa-solid fa-house mx-2" style={{ fontSize: 15 }}></i>{" "}
            <strong
              style={{
                fontSize: 15,
                letterSpacing: ".5px",
                textTransform: "uppercase",
              }}
            >
              {permalink === "analytics" ? "dashboard" : permalink.replace("-", " ")}
            </strong>
          </div>
        </div>

        <div
          ref={NotificationRef}
          className="d-flex align-items-center justify-content-between"
        >
          {/* <div
            className={`notifications ${notificationsActive ? "active" : ""}`}
          >
            {screenWidth < 767 ? (
              <NavLink
                to="/dashboard/notification"
                onClick={() => {
                  closeLargeMenu();
                  handleMobileSidebar();
                }}
              >
                <div className="profile__img">
                  <div style={{ position: "relative" }}>
                    <i
                      className={`fa-solid fa-bell ${
                        notificationsActive ? "profileNameActive" : ""
                      } ${
                        permalink === "notifications" ? "profileNameActive" : ""
                      }`}
                      title="notifications"
                    ></i>
                    <div className="dot__bell"></div>
                  </div>
                </div>
              </NavLink>
            ) : (
              <div className="profile__img" onClick={toggleNotifications}>
                <div style={{ position: "relative" }}>
                  <i
                    className={`fa-solid fa-bell ${
                      notificationsActive ? "profileNameActive" : ""
                    } ${
                      permalink === "notifications" ? "profileNameActive" : ""
                    }`}
                    title="notifications"
                  ></i>
                  <div className="dot__bell"></div>
                </div>
              </div>
            )}
            <div className="notification_dd">
              <ul className="notification_ul">
                <li className="starbucks">
                  <div className="notify_icon">
                    <img src={profileImg} alt="Profile" title="User Profile" />
                  </div>
                  <div className="notify_data">
                    <div className="title">Lorem, ipsum dolor.</div>
                    <div className="sub_title">
                      Lorem ipsum dolor sit amet consectetur.
                    </div>
                  </div>
                </li>
                <li className="baskin_robbins">
                  <div className="notify_icon">
                    <img src={profileImg} alt="Profile" title="User Profile" />
                  </div>
                  <div className="notify_data">
                    <div className="title">Lorem, ipsum dolor.</div>
                    <div className="sub_title">
                      Lorem ipsum dolor sit amet consectetur.
                    </div>
                  </div>
                </li>
                <li className="mcd">
                  <div className="notify_icon">
                    <img src={profileImg} alt="Profile" title="User Profile" />
                  </div>
                  <div className="notify_data">
                    <div className="title">Lorem, ipsum dolor.</div>
                    <div className="sub_title">
                      Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor
                      sit amet consectetur. Lorem ipsum dolor sit amet
                      consectetur.
                    </div>
                  </div>
                </li>
                <li className="pizzahut">
                  <div className="notify_icon">
                    <img src={profileImg} alt="Profile" title="User Profile" />
                  </div>
                  <div className="notify_data">
                    <div className="title">Lorem, ipsum dolor.</div>
                    <div className="sub_title">
                      Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor
                      sit amet consectetur.
                    </div>
                  </div>
                </li>
                <li className="kfc">
                  <div className="notify_icon">
                    <img src={profileImg} alt="Profile" title="User Profile" />
                  </div>
                  <div className="notify_data">
                    <div className="title">Lorem, ipsum dolor.</div>
                    <div className="sub_title">
                      Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor
                      sit amet consectetur. Lorem ipsum dolor sit amet
                      consectetur.
                    </div>
                  </div>
                </li>
                <li className="show_all">
                  <NavLink
                    onClick={toggleNotifications}
                    to="/dashboard/notifications"
                  >
                    <p className="link">Show All Notification</p>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div> */}

          <div
            ref={ProfileRef}
            className={`profile ${profileActive ? "active" : ""}`}
          >
            <div className="icon_wrap" onClick={toggleProfile}>
              <img src={profileImage} alt="Profile" title="User Profile" />
              <div
                className={`title_name d-flex gap-1 align-items-center px-2 p-1 ${profileActive ? "profileNameActive" : ""
                  }`}
              >
                <span className="name">
                  {roleType === "organization" ? companyName : firstName + " " +
                    middleName + " " +
                    lastName}
                </span>
                <i className="fas fa-chevron-down"></i>
              </div>
            </div>

            <div className="profile_dd">
              <ul className="profile_ul">
                <li>
                  <NavLink
                    className={
                      permalink === "profile"
                        ? "active_profile_menu_dropdown"
                        : ""
                    }
                    to="/dashboard/profile"
                    onClick={toggleProfile}
                  >
                    <span className="picon">
                      <i className="fas fa-user-alt"></i>
                    </span>
                    Profile
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={() => { toggleProfile(); openTwoFactorAuth() }}
                  >
                    <span className="picon">
                      <i className="fa-solid fa-shield"></i>
                    </span>
                    Security
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={() => { toggleProfile(); setToggle() }}
                  >
                    <span className="picon">
                      <i className="fa-solid fa-lock"></i>
                    </span>
                    Password
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={
                      permalink === "appearance"
                        ? "active_profile_menu_dropdown"
                        : ""
                    }
                    to="/dashboard/appearance"
                    onClick={toggleProfile}
                  >
                    <span className="picon">
                      <i className="fa-solid fa-gear"></i>
                    </span>
                    Appearance
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={`logout-link ${permalink === "logout"
                      ? "active_profile_menu_dropdown"
                      : ""
                      }`}
                    onClick={logout}
                  >
                    <span className="picon">
                      <i className="fas fa-sign-out-alt"></i>
                    </span>
                    Logout
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <PasswordModal
        Show={!toggle}
        Hide={setToggle}
        Size={"md"}
        Title={"Update Password"}
        Submit={"Update"}
      />
      <SecurityModal
        Show={!toggle1}
        Hide={setToggle1}
        Size={"md"}
        Title={"Two Factor Authentication"}
        getTwoFactorAuthType={getTwoFactorAuthType}
        setAuthType={setAuthType}
        authType={authType}
        authTypeData={authTypeData}
        status={status}
        appTwoFA={appTwoFA}
        emailTwoFA={emailTwoFA}
        roleType={roleType}
      />
    </>
  );
};

export default Header;
