import React, { useState } from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { subscriptionPlanServices } from "../../../APIServices/Editor/subscriptionPlanServices";

const AddNewSubscription = ({
  Show,
  Hide,
  Size,
  Title,
  Submit,
  getSubscriptionPlanList,
}) => {
  let [planName, setPlanName] = useState("");
  let [price, setPrice] = useState("");
  let [type, setType] = useState("");
  const [features, setFeatures] = useState([""]);

  const addNewSubcriptionPlan = async () => {
    let userData = {};
    userData.planName = planName;
    userData.price = price;
    userData.type = type;
    userData.featured = features;
    let data = await subscriptionPlanServices.addNewSubcriptionPlan(userData);
    if (data?.statusCode === 200) {
      clearInputField();
      getSubscriptionPlanList();
      Hide();
    }
  };

  const clearInputField = () => {
    setPlanName("");
    setPrice("");
    setType("");
    setFeatures([""]);
  };

  const handleAddInput = () => {
    setFeatures([...features, ""]);
  };

  const handleRemoveInput = (index) => {
    const newFeatures = [...features];
    newFeatures.splice(index, 1);
    setFeatures(newFeatures);
  };

  const handleChange = (index, event) => {
    const newFeatures = [...features];
    newFeatures[index] = event.target.value;
    setFeatures(newFeatures);
  };

  return (
    <Modal
      show={Show}
      onHide={Hide}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div
          onClick={() => {
            Hide();
            clearInputField();
          }}
          className="close_modal"
          title="Close"
        >
          X
        </div>
      </Modal.Header>
      <Modal.Body
        style={{ maxHeight: "calc(100vh - 175px)", overflow: "auto" }}
      >
        <Row>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>
              Plan Name <span>*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              onChange={(e) => setPlanName(e.target.value)}
              value={planName}
              placeholder="Enter Plan Name"
            />
          </Form.Group>
          <Form.Group as={Col} md="6" className="add_sponsor_field mb-3">
            <Form.Label>
              Price <span>*</span>
            </Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text style={{padding: "1px 10px"}}>$</InputGroup.Text>
              <Form.Control
                required
                type="number"
                onChange={(e) => setPrice(e.target.value)}
                value={price}
                style={{padding: 6}}
                placeholder="Enter Price"
              />
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} md="6" className="add_sponsor_field mb-3">
            <Form.Label>
              Type <span>*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => setType(e.target.value)}
              aria-label="Default select example"
            >
              <option>Select Type</option>
              <option value="month">Month</option>
              <option value="year">Year</option>
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-2">
            <Form.Label>
              Feature <span>*</span>
              <i
                className="fa-solid fa-circle-plus ms-2 buttonBaseColor"
                onClick={handleAddInput}
                title="Add Feature"
              ></i>
            </Form.Label>
            {features.map((feature, index) => (
              <div key={index} className="input-groups position-relative">
                <Form.Control
                  required
                  type="text"
                  placeholder="Type Feature"
                  value={feature}
                  className="mb-2"
                  onChange={(e) => handleChange(index, e)}
                />
                {features?.length > 1 && (
                  <div className="input-group-appends" title="Remove">
                    <span onClick={() => handleRemoveInput(index)}>x</span>
                  </div>
                )}
              </div>
            ))}
          </Form.Group>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div className="hstack gap-3 justify-content-end footer_button">
          <Button onClick={clearInputField} className="close_circle">
            Reset
          </Button>
          <Button
            disabled={!planName || !price || !type}
            onClick={addNewSubcriptionPlan}
          >
            {Submit}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AddNewSubscription;
