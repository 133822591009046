import React, { useState } from "react";
import "./Auth.css";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Forget_Password from "../../img/login.jpg";
import { NavLink } from "react-router-dom";
import { authServices } from "../../APIServices/authServices";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const forgotPassword = async () => {
    let userData = {};
    userData.email = email
    let data = await authServices.forgotPassword(userData)
    if (data?.statusCode === 200) {
      window.location.href = "/login";
    }
  }
  return (
    <>
      <Container fluid className="p-0 section__Module">
        <div className="hero">
          <div className="overlay-black"></div>
          <img className="hero-img" src={Forget_Password} alt="Hero" />
        </div>
        <section className="sign-up">
          <form className="sign-up__form" action="#">
            <h1>
              Forget Password
            </h1>
            <Row>
              <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
                <Form.Label>Email <span>*</span></Form.Label>
                <Form.Control
                  required
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  placeholder="Enter Email ID"
                />
              </Form.Group>
            </Row>
            {!email ?
              <div className="footer_button">
                <Button disabled className="mb-3 w-100">
                  Send Reset Password Link
                </Button>
              </div>
              :
              <div onClick={forgotPassword} className="footer_button">
                <Button className="mb-3 w-100">
                  Send Reset Password Link
                </Button>
              </div>
            }
            <p className="message">
              <NavLink to="/login">Back to Login</NavLink>
            </p>
          </form>
        </section>
      </Container>
    </>
  );
};

export default ForgetPassword;
