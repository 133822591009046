import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import UseToggle from "../../Hooks/useToggle";
import UpdateDocumentCaseModal from "./UpdateDocumentCaseModal";
import Moment from "react-moment";
import { documentAIServices } from "../../../APIServices/AIAlpha/documentAIServices";
import Swal from "sweetalert2";

const DocumentCaseTableData = ({
  documentCaseList,
  setViewDocumentMetadata,
  getDocumentMetadataList,
  getDocumentCaseList,
  onMouseDown,
  tableRef,
}) => {
  const { toggle, setToggle } = UseToggle();
  const ref = useRef(null);
  const [action, setAction] = useState("");
  const [id, setId] = useState("");
  const [documentCase, setDocumentCase] = useState("");

  const handleStatusChange = async (id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Change Status this Document Case?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = id;
        userData.status = status;
        let data = await documentAIServices.changeDcoumentCaseStatus(userData);
        if (data?.statusCode === 200) {
          getDocumentCaseList();
        }
      }
    });
  };

  const handleUpdate = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Update this Document Case?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = id;
        userData.caseName = documentCase;
        let data = await documentAIServices.updateDocumentCase(userData);
        if (data?.statusCode === 200) {
          getDocumentCaseList();
        }
      }
    });
  };

  const updateDocumentCaseModal = (item) => {
    setDocumentCase(item.caseName);
    setId(item._id);
    setToggle();
  };

  const handleDelete = async (e, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete this Document Case?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await documentAIServices.deleteDocumentCase(id);
        if (data?.statusCode === 200) {
          getDocumentCaseList();
        }
      }
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setAction("");
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  return (
    <>
      <div className="table-container overflow-auto">
        <Table id="resizable-table" className="custom-table m-0" ref={tableRef}>
          <thead>
            <tr>
              <th onMouseDown={(e) => onMouseDown(e, 0)} style={{ width: 40 }}>
                Sr.
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 1)}>Case Name</th>
              <th
                onMouseDown={(e) => onMouseDown(e, 2)}
                className="text-center"
                style={{ width: 120 }}
              >
                Status
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 3)} style={{ width: 140 }}>Updated Time</th>
              <th
                onMouseDown={(e) => onMouseDown(e, 4)}
                style={{ width: 70 }}
                className="text-center"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody ref={ref}>
            {documentCaseList?.length > 0 &&
              documentCaseList?.map((item, index) => (
                <tr className="relative">
                  <td>{index + 1}</td>
                  <td title={item.caseName}>{item.caseName}</td>
                  <td className="status_select">
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) =>
                        handleStatusChange(item._id, e.target.value)
                      }
                      className={
                        item.status === "active"
                          ? "active_status"
                          : "inactive_status"
                      }
                    >
                      <option
                        selected={item.status === "active"}
                        value="active"
                      >
                        Active
                      </option>
                      <option
                        selected={item.status === "inactive"}
                        value="inactive"
                      >
                        Inactive
                      </option>
                    </Form.Select>
                  </td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm" withTitle>
                      {item.updatedAt}
                    </Moment>
                  </td>
                  <td className="text-center">
                    <div className="text-center action__buttons">
                      {action === item._id ? (
                        <>
                          <div className="saperate_action_button">
                            <Button
                              onClick={() => {
                                setViewDocumentMetadata(item.caseName);
                                getDocumentMetadataList(item._id);
                              }}
                              title="View"
                              className="rounded-circle"
                            >
                              <i className="fa-solid fa-eye"></i>
                            </Button>
                            <Button
                              onClick={() => updateDocumentCaseModal(item)}
                              title="Edit"
                              className="rounded-circle"
                            >
                              <i className="fa-solid fa-pen-to-square"></i>
                            </Button>
                            <Button
                              onClick={(e) => handleDelete(e, item._id)}
                              title="Delete"
                              className="rounded-circle close_circle"
                            >
                              <i className="fa-solid fa-trash-can"></i>
                            </Button>
                          </div>
                          <Button
                            title="Close Action"
                            className="rounded-circle close_circle"
                          >
                            <i className="fa-solid fa-xmark"></i>
                          </Button>
                        </>
                      ) : (
                        <Button
                          onClick={() => setAction(item._id)}
                          title="Open Action"
                          className="rounded-circle "
                        >
                          <i className="fa-solid fa-bars"></i>
                        </Button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {documentCaseList?.length > 0 ? (
          ""
        ) : (
          <div className="noRecordFound">Record not found</div>
        )}
      </div>
      <UpdateDocumentCaseModal
        Show={!toggle}
        Hide={setToggle}
        Size={"md"}
        Title={"Update Document Case"}
        setDocumentCase={setDocumentCase}
        documentCase={documentCase}
        handleUpdate={handleUpdate}
        Submit={"Update"}
      />
    </>
  );
};

export default DocumentCaseTableData;
