import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import UseToggle from "../../Hooks/useToggle";
import Pagination from "../../Hooks/Pagination";
import AddNewDesignationModal from "./AddNewDesignationModal";
import { designationPermissionServices } from "../../../APIServices/designationPermissionServices";
import TableHeader from "../../../component/TableHeader";
import ExportDesignationModal from "./ExportDesignationModal";
import DesignationTableData from "./DesignationTableData";
import DesignationSubadmin from "./DesignationSubadmin";

const DesignationList = ({ onMouseDown, tableRef }) => {
  const { toggle, setToggle, toggle1, setToggle1 } = UseToggle();
  const [viewSubadmin, setViewSubadmin] = useState("");
  const [searchData, setSearchData] = useState("");
  const [designationList, setDesignationList] = useState([]);
  const [subadminList, setSubadminList] = useState([]);

  const getDesignationsList = async () => {
    let data = await designationPermissionServices.getDesignationsList();
    setDesignationList(data?.data);
  };

  const getUserByDesignations = async (id) => {
    let data = await designationPermissionServices.getUserByDesignations(
      id,
      "subadmin"
    );
    setSubadminList(data?.data);
  };

  const itemsPerPageDesignation = 10;
  const [itemOffsetDesignation, setItemOffsetDesignation] = useState(0);
  const endOffsetDesignation = itemOffsetDesignation + itemsPerPageDesignation;
  const currentItemsDesignation = designationList?.slice(
    itemOffsetDesignation,
    endOffsetDesignation
  );
  const pageCountDesignation = Math.ceil(
    designationList?.length / itemsPerPageDesignation
  );
  const handlePageClickDesignation = (event) => {
    const newOffset =
      (event.selected * itemsPerPageDesignation) % designationList?.length;
    setItemOffsetDesignation(newOffset);
  };

  const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = subadminList?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(subadminList?.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % subadminList?.length;
    setItemOffset(newOffset);
  };

  const handleSearch = async (searchValue) => {
    let userData = {};
    userData.searchData = searchValue || searchData;
    let data = await designationPermissionServices.searchDesignation(userData);
    setDesignationList(data?.data);
  };

  useEffect(() => {
    getDesignationsList();
  }, []);
  return (
    <>
      <Row>
        <Col md={6} className="mb-3">
          <TableHeader
            Search
            handleSearch={handleSearch}
            setSearchData={setSearchData}
            SearchReverseData={getDesignationsList}
            AddNew
            handleAddNew={setToggle}
            titleAddNew="Add New Designation"
            Export
            handleExport={setToggle1}
            titleExport="Export Designations Data"
          />
          <DesignationTableData
            onMouseDown={onMouseDown}
            tableRef={tableRef}
            designationList={currentItemsDesignation}
            setViewSubadmin={setViewSubadmin}
            getUserByDesignations={getUserByDesignations}
            getDesignationsList={getDesignationsList}
          />
          <Pagination
            Result={currentItemsDesignation}
            itemsPerPage={itemsPerPageDesignation}
            handlePageClick={handlePageClickDesignation}
            pageCount={pageCountDesignation}
          />
        </Col>
        {viewSubadmin && (
          <Col md={6}>
            <DesignationSubadmin
              onMouseDown={onMouseDown}
              tableRef={tableRef}
              viewSubadmin={viewSubadmin}
              subadminList={currentItems}
            />
            <Pagination
              Result={subadminList}
              itemsPerPage={itemsPerPage}
              handlePageClick={handlePageClick}
              pageCount={pageCount}
            />
          </Col>
        )}
      </Row>
      <AddNewDesignationModal
        Show={!toggle}
        getDesignationsList={getDesignationsList}
        Hide={setToggle}
        Size={"md"}
        Title={"Add New Designation"}
        Submit={"Submit"}
      />
      <ExportDesignationModal
        Show={!toggle1}
        Hide={setToggle1}
        Size={"md"}
        Title={"Export Log History"}
        Submit={"Export"}
      />
    </>
  );
};

export default DesignationList;
