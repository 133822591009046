import React from "react";
import { Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const ExpiredInvitationMessage = () => {
  return (
    <>
      <div className="invite__form_message">
        <p>Expired Invitation Link</p>
        <div className="Success__taag mb-3">
        <i style={{color: "red"}} className="fa-solid fa-circle-xmark" alt="Invitation Link Expired"></i>
        </div>
            <span>Your Invitation link has been expired.</span>
            <span>Please contact "Administrator".</span>
            <span>After that chech your mail, then you are able to Registration</span>
        <div className="hstack gap-3 justify-content-center mt-4 footer_button">
          <NavLink to={'/login'}>
            <Button>Login</Button>
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default ExpiredInvitationMessage;
