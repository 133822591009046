import React from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

const UpdateSDTMDomainModal = ({
  Show,
  Hide,
  Size,
  Title,
  domainName,
  setDomainName,
  handleUpdate,
  version,
  versionId,
  setVersionId,
  sdtmClass,
  classId,
  setClassId,
  Submit,
  type,
  setType,
  code,
  setCode,
  getActiveClass,
  setRepeating,
  repeating,
  setReference,
  reference,
  structure,
  setStructure,
  comment,
  setComment,
  keyVariables,
  setKeyVariables
}) => {

  const handleAddKeyVariable = () => {
    setKeyVariables([...keyVariables, {}]);
  };

  const handleRemoveKeyVariable = (index) => {
    const updatedKeyVariables = keyVariables.filter((_, idx) => idx !== index);
    setKeyVariables(updatedKeyVariables);
  };

  const handleKeyVariableChange = (index, value) => {
    const updatedKeyVariables = [...keyVariables];
    updatedKeyVariables[index] = value;
    setKeyVariables(updatedKeyVariables);
  };

  return (
    <Modal
      show={Show}
      onHide={Hide}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={() => Hide()} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
      <Row>
          <Form.Group as={Col} md="6" className="add_sponsor_field mb-3">
            <Form.Label>
              Version <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => {
                setVersionId(e.target.value);
                getActiveClass(e.target.value);
              }}
              value={versionId}
            >
              <option value="">Select Version</option>
              {version?.map((item, index) => (
                <option
                  selected={item.versionId === item._id}
                  key={index}
                  value={item._id}
                >
                  {item.version}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="6" className="add_sponsor_field mb-3">
            <Form.Label>
              Class <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => setClassId(e.target.value)}
              value={classId}
            >
              <option value="">Select Class</option>
              {sdtmClass?.map((item, index) => (
                <option
                  selected={item.classId === item._id}
                  key={index}
                  value={item._id}
                >
                  {item.class}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="6" className="add_sponsor_field mb-3">
            <Form.Label>
              Domain Label <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              value={domainName}
              onChange={(e) => setDomainName(e.target.value)}
              placeholder="Enter Domain Label"
            />
          </Form.Group>
          <Form.Group as={Col} md="6" className="add_sponsor_field mb-3">
            <Form.Label>
              Domain Name (Code) <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              placeholder="Enter Domain Name"
            />
          </Form.Group>
          <Form.Group as={Col} md="6" className="add_sponsor_field mb-3">
            <Form.Label>
              Domain Type <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select onChange={(e) => setType(e.target.value)} value={type}>
              <option value="">Select Type</option>
              <option
                selected={type === "standard" && "standard"}
                value="standard"
              >
                Standard
              </option>
              <option selected={type === "custom" && "custom"} value="custom">
                Custom
              </option>
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="6" className="add_sponsor_field mb-3">
            <div className="hstack justify-content-between">
              <Form.Label>
                Key variable <span className="text-danger">*</span>
              </Form.Label>
              <button
                title="Click to Add More Key Variables"
                className="w-auto p-0"
                onClick={handleAddKeyVariable}
                style={{ fontSize: 11 }}
              >
                <i className="fa-solid fa-square-plus"></i> Add More
              </button>
            </div>
            {keyVariables.map((item, index) => (
              <div className="d-flex mb-1" key={index}>
                <Form.Control
                  required
                  type="text"
                  className="w-100"
                  value={item}
                  onChange={(e) =>
                    handleKeyVariableChange(index, e.target.value)
                  }
                  placeholder="Enter Domain Name"
                />
                {keyVariables.length > 1 && (
                  <button
                    title="Delete this Row"
                    className="text-danger w-auto p-0 ms-2"
                    style={{fontSize: 12}}
                    onClick={() => handleRemoveKeyVariable(index)}
                  >
                    <i className="fa-solid fa-trash-alt"></i>
                  </button>
                )}
              </div>
            ))}
          </Form.Group>
          <Form.Group as={Col} md="6" className="add_sponsor_field mb-3">
            <Form.Label>
              Repeating <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => setRepeating(e.target.value)}
              value={repeating}
            >
              <option value="">Select Repeating</option>
              <option selected={repeating === "yes" && "yes"} value="yes">
                Yes
              </option>
              <option selected={repeating === "no" && "no"} value="no">
                No
              </option>
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="6" className="add_sponsor_field mb-3">
            <Form.Label>
              Reference Data <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => setReference(e.target.value)}
              value={reference}
            >
              <option value="">Select Reference</option>
              <option selected={reference === "yes" && "yes"} value="yes">
                Yes
              </option>
              <option selected={reference === "no" && "no"} value="no">
                No
              </option>
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="6" className="add_sponsor_field mb-3">
            <Form.Label>
              Structure <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              required
              as="textarea"
              value={structure}
              onChange={(e) => setStructure(e.target.value)}
              placeholder="Enter Domain Name"
            />
          </Form.Group>
          <Form.Group as={Col} md="6" className="add_sponsor_field mb-3">
            <Form.Label>
              Comment 
            </Form.Label>
            <Form.Control
              as="textarea"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Enter Domain Name"
            />
          </Form.Group>
        </Row>
        <div className="hstack justify-content-end border-top pt-3 footer_button">
          <Button
            disabled={!domainName}
            onClick={() => {
              handleUpdate();
              Hide();
            }}
          >
            {Submit}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateSDTMDomainModal;
