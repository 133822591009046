import React, { useEffect, useState } from "react";
import AddNewUserModal from "./AddNewUserModal";
import UseToggle from "../Hooks/useToggle";
import Pagination from "../Hooks/Pagination";
import ExportUserModal from "./ExportUserModal";
import TableHeader from "../../component/TableHeader";
import UsersTableData from "./UsersTableData";
import { userServices } from "../../APIServices/usersServices";
import UsersInvitation from "./UserInvitation/UsersInvitation";
import { invitedDataServices } from "../../APIServices/invitedDataServices";
import AllUserCouponHistory from "./ALLCouponHistory/AllUserCouponHistory";

const UsersLists = ({
  role,
  permission,
  roleType,
  userPermission,
  onMouseDown,
  tableRef,
}) => {
  const {
    toggle,
    setToggle,
    toggle1,
    setToggle1,
    toggle2,
    setToggle2,
    toggle3,
    setToggle3,
  } = UseToggle();

  const [userList, setUserList] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [invitedList, setInvitedList] = useState([]);

  const getInvitation = async () => {
    let data = await invitedDataServices.getInvitation(role);
    setInvitedList(data?.data);
  };

  const getUserData = async () => {
    let data = await userServices.getUserData(role);
    setUserList(data?.data);
  };

  const itemsPerPage = 5;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = userList?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(userList?.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % userList?.length;
    setItemOffset(newOffset);
  };

  const handleSearch = async (searchValue) => {
    let userData = {};
    userData.searchData = searchValue || searchData;
    userData.roleType = role;
    let data = await userServices.searchUserData(userData);
    setUserList(data?.data);
  };

  const openInvitationHistory = () => {
    setToggle2();
    getInvitation();
  };

  useEffect(() => {
    getUserData();
  }, [role]);

  let inviteConsultantPermission =
    permission?.viewConsultantInvitationHistory ||
    permission?.sendNewConsultantInvitation ||
    permission?.resendConsultantInvitation ||
    permission?.cancelConsultantInvitation ||
    roleType === "admin";

  let inviteDeveloperPermission =
    permission?.viewDeveloperInvitationHistory ||
    permission?.sendNewDeveloperInvitation ||
    permission?.resendDeveloperInvitation ||
    permission?.cancelDeveloperInvitation ||
    roleType === "admin";

  let inviteAIUserPermission =
    permission?.viewAIUserInvitationHistory ||
    permission?.sendNewAIUserInvitation ||
    permission?.resendAIUserInvitation ||
    permission?.cancelAIUserInvitation ||
    roleType === "admin";

  let inviteOrganizationPermission =
    permission?.viewOrganizationInvitationHistory ||
    permission?.sendNewOrganizationInvitation ||
    permission?.resendOrganizationInvitation ||
    permission?.cancelOrganizationInvitation ||
    roleType === "admin";

  let inviteCDISCPermission =
    permission?.viewCDISCInvitationHistory ||
    permission?.sendNewCDISCInvitation ||
    permission?.resendCDISCInvitation ||
    permission?.cancelCDISCInvitation ||
    roleType === "admin";

  let invitePermission =
    role === "aiuser"
      ? inviteAIUserPermission
      : role === "organization"
      ? inviteOrganizationPermission
      : role === "consultant"
      ? inviteConsultantPermission
      : role === "developer"
      ? inviteDeveloperPermission
      : role === "cdisc"
      ? inviteCDISCPermission
      : "";
  return (
    <>
      <TableHeader
        Search={userPermission}
        handleSearch={handleSearch}
        setSearchData={setSearchData}
        SearchReverseData={getUserData}
        AddNew={
          (role === "aiuser"
            ? permission?.addNewAIUsers
            : role === "consultant"
            ? permission?.addNewConsultants
            : role === "organization"
            ? permission?.addNewOrganizations
            : role === "developer"
            ? permission?.addNewCDISC
            : role === "cdisc"
            ? permission?.addNewDevelopers
            : "") || roleType === "admin"
        }
        handleAddNew={setToggle}
        titleAddNew={`Add New ${
          role === "aiuser"
            ? "AI User"
            : role === "organization"
            ? "Organization"
            : role === "consultant"
            ? "Consultant"
            : role === "developer"
            ? "Developer"
            : role === "cdisc"
            ? "CDISC Client"
            : ""
        }`}
        InviteHistory={invitePermission}
        handleInviteHistory={openInvitationHistory}
        titleInviteHistory="Invitation History"
        Coupon={role === "consultant"}
        handleCoupon={setToggle3}
        titleCoupon="Coupon History"
        Export={
          (role === "aiuser"
            ? permission?.exportAllAIUserData
            : role === "consultant"
            ? permission?.exportAllConsultantData
            : role === "organization"
            ? permission?.exportAllOrganizationData
            : role === "developer"
            ? permission?.exportAllCDISCData
            : role === "cdisc"
            ? permission?.exportAllDeveloperData
            : "") || roleType === "admin"
        }
        handleExport={setToggle1}
        titleExport={`Export ${
          role === "aiuser"
            ? "AI Users"
            : role === "organization"
            ? "Organization"
            : role === "consultant"
            ? "Consultants"
            : role === "developer"
            ? "Developers"
            : role === "cdisc"
            ? "CDISC Client"
            : ""
        } Data`}
      />
      <UsersTableData
        onMouseDown={onMouseDown}
        tableRef={tableRef}
        userList={currentItems}
        getUserData={getUserData}
        roleType={roleType}
        permission={permission}
        role={role}
        roleData={
          role === "aiuser"
            ? "Full"
            : role === "organization"
            ? "Organization"
            : role === "consultant"
            ? "Full"
            : role === "developer"
            ? "Developer"
            : role === "cdisc"
            ? "CDISC Client"
            : ""
        }
      />
      <Pagination
        Result={userList}
        itemsPerPage={itemsPerPage}
        handlePageClick={handlePageClick}
        pageCount={pageCount}
      />
      <AddNewUserModal
        Show={!toggle}
        Hide={setToggle}
        Size={"lg"}
        Title={`Add New ${
          role === "aiuser"
            ? "AI User"
            : role === "organization"
            ? "Organization"
            : role === "consultant"
            ? "Consultant"
            : role === "developer"
            ? "Developer"
            : role === "cdisc"
            ? "CDISC Client"
            : ""
        }`}
        getUserData={getUserData}
        role={role}
        Submit={"Submit"}
      />
      <UsersInvitation
        onMouseDown={onMouseDown}
        tableRef={tableRef}
        Show={!toggle2}
        Hide={setToggle2}
        Size={"lg"}
        Title={`${
          role === "aiuser"
            ? "AI Users"
            : role === "organization"
            ? "Organization"
            : role === "consultant"
            ? "Consultants"
            : role === "developer"
            ? "Developers"
            : role === "cdisc"
            ? "CDISC Client"
            : ""
        } Invitation History`}
        invitedList={invitedList}
        role={role}
        invitePermission={invitePermission}
        roleType={roleType}
        permission={permission}
        setInvitedList={setInvitedList}
        getInvitation={getInvitation}
      />
      <ExportUserModal
        Show={!toggle1}
        Hide={setToggle1}
        role={role}
        Size={"md"}
        Title={`Export ${
          role === "aiuser"
            ? "AI Users"
            : role === "organization"
            ? "Organization"
            : role === "consultant"
            ? "Consultants"
            : role === "developer"
            ? "Developers"
            : role === "cdisc"
            ? "CDISC Client"
            : ""
        }`}
        Submit={"Export"}
      />
      {role === "consultant" && (
        <AllUserCouponHistory
          onMouseDown={onMouseDown}
          tableRef={tableRef}
          Show={!toggle3}
          Hide={setToggle3}
          Size={"xl"}
          Title={`Coupon History`}
        />
      )}
    </>
  );
};

export default UsersLists;
