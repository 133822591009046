import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { themeSettingsServices } from "../../../APIServices/themeSettingsServices";

const ChangeStatusColor = ({
  getStatusColorData,
  activeColorData,
  inActiveColorData,
  pendingColorData,
}) => {
  const [activeColor, setActiveColor] = useState("");
  const [inActiveColor, setInActiveColor] = useState("");
  const [pendingColor, setPendingColor] = useState("");
  const [loader, setLoader] = useState(false);

  document.documentElement.style.setProperty(
    "--check_active_color",
    activeColor
  );
  document.documentElement.style.setProperty(
    "--check_inactive_color",
    inActiveColor
  );
  document.documentElement.style.setProperty(
    "--check_pending_color",
    pendingColor
  );

  const updateStatusColorData = async (defaultColor) => {
    setLoader(true);
    let userData = {};
    userData.statusColor = defaultColor || {
      active: activeColor,
      inactive: inActiveColor,
      pending: pendingColor,
    };
    let data = await themeSettingsServices.updateStatusColorData(userData);
    if (data?.statusCode === 200) {
      getStatusColorData();
      setLoader(false);
    }
  };

  useEffect(() => {
    setActiveColor(activeColorData);
    setInActiveColor(inActiveColorData);
    setPendingColor(pendingColorData);
  }, [activeColorData, inActiveColorData, pendingColorData]);

  return (
    <div>
      <Row>
        <Form.Group
          as={Col}
          md="4"
          className="add_sponsor_field "
          controlId="validationCustom01"
        >
          <Form.Label>Select Active Color</Form.Label>
          <Form.Select
            className="mb-2"
            onChange={(e) => setActiveColor(e.target.value)}
            aria-label="Default select example"
          >
            <option
              selected={activeColor === "rgb(0, 128, 0)"}
              style={{ color: `rgb(0, 128, 0)` }}
              value={`rgb(0, 128, 0)`}
            >
              Theme Default
            </option>
            <option
              selected={activeColor === "rgb(255, 0, 0)"}
              style={{ color: `rgb(255, 0, 0)` }}
              value={`rgb(255, 0, 0)`}
            >
              Red
            </option>
            <option
              selected={activeColor === "rgb(128, 128, 128)"}
              style={{ color: `rgb(128, 128, 128)` }}
              value={`rgb(128, 128, 128)`}
            >
              Gray
            </option>
          </Form.Select>
          <Form.Label>Active Color Result</Form.Label>
          <div className="bg-white box-shadow-min border-radius-3 mb-3 widget-style1">
            <div className="checkActiveColor text-center">
              <p className="m-0">
                <b>ACTIVE</b>
              </p>
            </div>
          </div>
        </Form.Group>
        <Form.Group
          as={Col}
          md="4"
          className="add_sponsor_field "
          controlId="validationCustom01"
        >
          <Form.Label>Select Inactive Color</Form.Label>
          <Form.Select
            className="mb-2"
            onChange={(e) => setInActiveColor(e.target.value)}
            aria-label="Default select example"
          >
            <option
              selected={inActiveColor === "rgb(255, 0, 0)"}
              style={{ color: `rgb(255, 0, 0)` }}
              value={`rgb(255, 0, 0)`}
            >
              Theme Default
            </option>
            <option
              selected={inActiveColor === "rgb(0, 128, 0)"}
              style={{ color: `rgb(0, 128, 0)` }}
              value={`rgb(0, 128, 0)`}
            >
              Green
            </option>
            <option
              selected={inActiveColor === "rgb(128, 128, 128)"}
              style={{ color: `rgb(128, 128, 128)` }}
              value={`rgb(128, 128, 128)`}
            >
              Gray
            </option>
          </Form.Select>
          <Form.Label>Inactive Color Result</Form.Label>
          <div className="bg-white box-shadow-min border-radius-3 mb-3 widget-style1">
            <div className="checkInactiveColor text-center">
              <p className="m-0">
                <b>INACTIVE</b>
              </p>
            </div>
          </div>
        </Form.Group>
        <Form.Group
          as={Col}
          md="4"
          className="add_sponsor_field "
          controlId="validationCustom01"
        >
          <Form.Label>Select Pending Color</Form.Label>
          <Form.Select
            className="mb-2"
            onChange={(e) => setPendingColor(e.target.value)}
            aria-label="Default select example"
          >
            <option
              selected={pendingColor === "rgb(128, 128, 128)"}
              style={{ color: `rgb(128, 128, 128)` }}
              value={`rgb(128, 128, 128)`}
            >
              Theme Default
            </option>
            <option
              selected={pendingColor === "rgb(255, 0, 0)"}
              style={{ color: `rgb(255, 0, 0)` }}
              value={`rgb(255, 0, 0)`}
            >
              Red
            </option>
            <option
              selected={pendingColor === "rgb(0, 128, 0)"}
              style={{ color: `rgb(0, 128, 0)` }}
              value={`rgb(0, 128, 0)`}
            >
              Green
            </option>
          </Form.Select>
          <Form.Label>Pending Color Result</Form.Label>
          <div className="bg-white box-shadow-min border-radius-3 mb-3 widget-style1">
            <div className="checkPendingColor text-center">
              <p className="m-0">
                <b>PENDING</b>
              </p>
            </div>
          </div>
        </Form.Group>
        <div className="hstack gap-3 justify-content-end pt-3 footer_button">
          <Button
            onClick={() =>
              updateStatusColorData({
                active: "rgb(0, 128, 0)",
                inactive: "rgb(255, 0, 0)",
                pending: "rgb(128, 128, 128)",
              })
            }
            className="close_circle hstack gap-2 justify-content-center"
          >
            <Spinner
              as="span"
              animation={loader && "border"}
              size="sm"
              role="status"
              aria-hidden="true"
            />
            Reset
          </Button>
          <Button
            onClick={() => updateStatusColorData()}
            className="hstack gap-2 justify-content-center"
          >
            <Spinner
              as="span"
              animation={loader && "border"}
              size="sm"
              role="status"
              aria-hidden="true"
            />
            Save
          </Button>
        </div>
      </Row>
    </div>
  );
};

export default ChangeStatusColor;
