import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { sdtmServices } from "../../../../APIServices/CDISC/sdtmServices";

const AddNewSDTMClassModal = ({
  Show,
  Hide,
  Size,
  Title,
  Submit,
  getClassList,
}) => {
  let [version, setVersion] = useState([]);
  let [versionId, setVersionId] = useState("");
  let [classData, setClassData] = useState("");

  const addNewClass = async () => {
    let userData = {};
    userData.versionId = versionId;
    userData.classData = classData;
    let data = await sdtmServices.addNewClass(userData);

    if (data?.statusCode === 200) {
      closeModal();
      getClassList();
    }
  };

  const getActiveVersions = async () => {
    let data = await sdtmServices.getActiveVersions();
    setVersion(data?.data);
  };

  const closeModal = () => {
    setVersionId("");
    setClassData("");
    Hide();
  };

  useEffect(() => {
    getActiveVersions();
  }, []);

  return (
    <Modal
      show={Show}
      onHide={closeModal}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={closeModal} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>
              Version <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => setVersionId(e.target.value)}
              value={versionId}
            >
              <option value="">Select Version</option>
              {version?.map((item, index) => (
                <option
                  selected={item.versionId === item._id}
                  key={index}
                  value={item._id}
                >
                  {item.version}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>
              Class <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              value={classData}
              onChange={(e) => setClassData(e.target.value)}
              placeholder="Enter Class Name"
            />
          </Form.Group>
        </Row>
        <div className="hstack gap-3 justify-content-end border-top pt-3 footer_button">
          <Button className="close_circle" onClick={() => setClassData("")}>
            Reset
          </Button>
          <Button disabled={!classData} onClick={() => addNewClass()}>
            {Submit}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddNewSDTMClassModal;
