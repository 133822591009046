import React, { useState } from "react";
import { ContextMenuTrigger } from "react-contextmenu";
import Swal from "sweetalert2";
import { Button, Form } from "react-bootstrap";
import UseToggle from "../../Hooks/useToggle";
import RightClick from "../../Hooks/RightClick";
import { sdtmServices } from "../../../APIServices/CDISC/sdtmServices";
import AddTemplate from "./AddTemplate";
import UpdateTemplate from "./UpdateTemplate";

const DataTemplateSidebar = ({
  getDataTemplates,
  libraryList,
  tempId,
  setInputSets,
  setTempId,
  setTempName,
}) => {
  const { toggle, setToggle, toggle1, setToggle1 } = UseToggle();
  const [updateTemp, setUpdateTemp] = useState("");

  const deleteDataTemplates = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete this Template?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await sdtmServices.deleteDataTemplates(id);
        if (data?.statusCode === 200) {
          getDataTemplates();
          setTempId(tempId === id ? "" : tempId);
        }
      }
    });
  };

  const handleUpdate = (item) => {
    setUpdateTemp(item);
    setToggle1();
  };

  return (
    <>
      <div className="hstack justify-content-between gap-2 mb-1">
        <div className="search w-100">
          <Form.Label style={{ fontSize: 12, fontWeight: 600 }}>
            Add Data Template
          </Form.Label>
        </div>
        <div className="footer_button uploadButton">
          <Button className="w-100" title="Add Template" onClick={setToggle}>
            <i className="fa-solid fa-plus"></i>
          </Button>
        </div>
      </div>
      <div>
        <ul className="side-menu pt-2">
          {libraryList?.map((item, index) => (
            <div key={index}>
              <ContextMenuTrigger
                id={`context_${index}_${index}`}
                collect={() => ({ id: index })}
              >
                <li
                  className={`mb-1 ${
                    tempId === item._id ? "activeHelpMenu" : ""
                  }`}
                  onClick={(e) => {
                    setTempId(item?._id);
                    setInputSets(item?.column);
                    setTempName(item?.templateName);
                  }}
                  title={item.templateName}
                >
                  <div
                    className="side-menu-item m-0 py-2"
                    style={{ fontSize: 13 }}
                  >
                    <span
                      className={`titleName text-capitalize  ${
                        item.status === "inactive" ? "text-muted" : ""
                      }`}
                    >
                      {item.templateName}
                    </span>
                  </div>
                </li>
              </ContextMenuTrigger>
              <RightClick
                ContextId={`context_${index}_${index}`}
                Menu1
                MenuName1={"Edit"}
                handleClick1={() => handleUpdate(item)}
                icons1={<i className="fa-solid fa-square-pen edit"></i>}
                className1="edit"
                Menu2
                MenuName2={"Delete"}
                handleClick2={() => deleteDataTemplates(item._id)}
                icons2={<i className="fa-solid fa-trash-can delete"></i>}
                className2="delete"
              />
            </div>
          ))}
        </ul>
      </div>
      <AddTemplate
        Show={!toggle}
        Hide={setToggle}
        getDataTemplates={getDataTemplates}
        Size={"md"}
        Title={"Add Template"}
        Submit={"Submit"}
      />
      <UpdateTemplate
        Show={!toggle1}
        Hide={setToggle1}
        updateTemp={updateTemp}
        getDataTemplates={getDataTemplates}
        Size={"md"}
        Title={"Update Template"}
        Submit={"Update"}
      />
    </>
  );
};

export default DataTemplateSidebar;
