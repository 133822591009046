import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const RestrictedPage = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className="mt-5">
                <div className="row">
                    <div className="col-md-2 text-center">
                        <p><i style={{ color: "#fe696a" }} className="fa fa-exclamation-triangle fa-5x"></i><br /><b>Status Code: 403</b></p>
                    </div>
                    <div className="col-md-10">
                        <h4><b>Forbidden 403 Access denied</b></h4>
                        <p>Sorry, your access has been denied or you have not been granted access to this page due to security reasons for our servers and our sensitive data. Please contact the administrator.
                            <br /><br />
                            Please go back to the previous page to continue browsing.</p>
                        <div className="footer_button">
                            <Button onClick={() => navigate(-1)} className="customButton">Go Back</Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RestrictedPage;
