import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { subscriptionPlanServices } from "../../../APIServices/Editor/subscriptionPlanServices";
import TableHeader from "../../../component/TableHeader";
import UseToggle from "../../Hooks/useToggle";
import Pagination from "../../Hooks/Pagination";
import AddNewSubscription from "./AddNewSubscription";
import EditorSubscriptionsByStatus from "./EditorSubscriptionsByStatus";
import UpdateSubscription from "./UpdateSubscription";
import TransectionHistory from "./TransectionHistory/TransectionHistory";
import SubscriptionsPriceModal from "./SubscriptionsPriceModal";

const AllEditorSubscriptions = ({ onMouseDown, tableRef, themeColor }) => {
  const {
    toggle,
    setToggle,
    toggle1,
    setToggle1,
    toggle2,
    setToggle2,
    toggle3,
    setToggle3,
  } = UseToggle();

  const [subscriptionList, setSubscriptionList] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [status, setStatus] = useState("");
  const [planDetails, setPlanDetails] = useState("");
  // const [creditData, setCreditData] = useState("");
  const getSubscriptionPlanList = async () => {
    setStatus("");
    let data = await subscriptionPlanServices.getSubscriptionPlanList();
    setSubscriptionList(data?.data);
  };

  const getSubscriptionPlanListByStatus = async (status) => {
    setStatus(status ? "active" : "inactive");
    let data = await subscriptionPlanServices.getSubscriptionPlanListByStatus(
      status
    );
    setSubscriptionList(data?.data);
  };

  const itemsPerPage = 5;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = subscriptionList?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(subscriptionList?.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % subscriptionList?.length;
    setItemOffset(newOffset);
  };

  const handleSearch = async (searchValue) => {
    let userData = {};
    userData.searchData = searchValue || searchData;
    if (status) {
      userData.status = status;
    }
    let data = await subscriptionPlanServices.searchSubcriptionPlan(userData);
    setSubscriptionList(data?.data);
  };

  const handleChangeStatus = async (id, status) => {
    let userData = {};
    userData.id = id;
    userData.status = status;
    let data = await subscriptionPlanServices.changeSubscriptionPlanStatus(
      userData
    );
    if (data?.statusCode === 200) {
      getSubscriptionPlanList();
    }
  };

  const handleUpdatePlan = (e, item) => {
    setPlanDetails(item);
    setToggle1();
  };

  const creditPrice = async () => {
    // let data = await creditPrice();
    // setCreditData(data?.data);
    setToggle3();
  };

  useEffect(() => {
    getSubscriptionPlanList();
  }, []);
  return (
    <>
      <TableHeader
        Search
        handleSearch={handleSearch}
        setSearchData={setSearchData}
        SearchReverseData={getSubscriptionPlanList}
        Credit
        handleCredit={creditPrice}
        titleCredit="Manage Price"
        AddNew
        handleAddNew={setToggle}
        titleAddNew="Add New Subscription"
        SubscriptionTransection
        handleSubscriptionTransection={setToggle2}
        titleSubscriptionTransection="Transection History"
        Status={status}
        TextButton1
        TextButtonName1={"ALL"}
        handleTextButton1={() => getSubscriptionPlanList()}
        TextButton2
        TextButtonName2="ACTIVE"
        handleTextButton2={() => getSubscriptionPlanListByStatus(true)}
        TextButton3
        TextButtonName3="INACTIVE"
        handleTextButton3={() => getSubscriptionPlanListByStatus(false)}
      />
      {!status && (
        <>
          <Row className="mt-4">
            {currentItems?.length === 0
              ? ""
              : currentItems?.map((item, index) => (
                  <Col key={index} xl={3} lg={3} md={6} className="mb-20">
                    <div className="box-shadow-min border-radius-3 height-100-p ">
                      <div className="text-center widget-style1 pb-2">
                        <div>
                          <b className="text-uppercase">{item.planName}</b>
                          <i
                            onClick={(e) => handleUpdatePlan(e, item)}
                            className="fa-solid fa-edit mx-2"
                            title="Update Plan Details"
                            style={{ fontSize: 10, cursor: "pointer" }}
                          ></i>
                        </div>
                        <hr style={{ width: 80, margin: "10px auto" }} />
                        <p className="m-0">
                          <b>{item.currency + item.price}</b> / {item.type}
                        </p>
                        <div className="pt-2">
                          <Form.Check
                            onChange={() =>
                              handleChangeStatus(item._id, !item.status)
                            }
                            checked={item.status}
                            type="switch"
                            id={`custom-switch-${item._id}`}
                          />
                        </div>
                      </div>
                      <div className="text-center py-2">
                        <b>Featured</b>
                      </div>
                      <div className="ulheight">
                        <ul>
                          {item.featured.map((element, index) => (
                            <li key={index}>
                              <p className="m-0">{element}</p>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </Col>
                ))}
          </Row>
          <Pagination
            Result={subscriptionList}
            itemsPerPage={itemsPerPage}
            handlePageClick={handlePageClick}
            pageCount={pageCount}
          />
        </>
      )}
      <AddNewSubscription
        Show={!toggle}
        Hide={setToggle}
        Size={"md"}
        Title={"Add New Subscription"}
        getSubscriptionPlanList={getSubscriptionPlanList}
        Submit={"Submit"}
      />
      {status && (
        <EditorSubscriptionsByStatus
          subscriptionList={subscriptionList}
          getSubscriptionPlanList={getSubscriptionPlanList}
          getSubscriptionPlanListByStatus={getSubscriptionPlanListByStatus}
        />
      )}
      <UpdateSubscription
        Show={!toggle1}
        Hide={setToggle1}
        Size={"md"}
        Title={"Update Subscription"}
        planDetails={planDetails}
        getSubscriptionPlanList={getSubscriptionPlanList}
        Submit={"Update"}
      />
      <TransectionHistory
        onMouseDown={onMouseDown}
        tableRef={tableRef}
        Show={!toggle2}
        Hide={setToggle2}
        Size={"xl"}
        Title={"Transection History"}
        planDetails={planDetails}
        themeColor={themeColor}
        getSubscriptionPlanList={getSubscriptionPlanList}
      />

      <SubscriptionsPriceModal
        Show={!toggle3}
        Hide={setToggle3}
        // creditData={creditData}
        Size={"sm"}
        Title={"Manage Price"}
        Submit={"Update"}
      />
    </>
  );
};

export default AllEditorSubscriptions;
