import React, { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { creditPriceServices } from "../../../APIServices/AIAlpha/creditPriceServices";
import Swal from "sweetalert2";

const CreditPriceModal = ({ Show, Hide, Size, Title, Submit, creditData }) => {
  let [id, setId] = useState("");
  let [price, setPrice] = useState("");
  let [currency, setCurrency] = useState("");
  let [token, setToken] = useState("");
  let [SGST, setSGST] = useState("");
  let [CGST, setCGST] = useState("");
  const [activeTab, setActiveTab] = useState("credit");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const updateCreditPriceData = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Credit Price?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = id;
        userData.price = price;
        userData.currency = currency;
        let data = await creditPriceServices.updateCreditPriceData(userData);
        if (data?.statusCode === 200) {
          Hide();
          setActiveTab("credit")
        }
      }
    })
  };

  const updateGSTPriceData = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Credit Price?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = id;
        userData.sgst = SGST;
        userData.cgst = CGST;
        let data = await creditPriceServices.updateGSTPriceData(userData);
        if (data?.statusCode === 200) {
          Hide();
          setActiveTab("credit")
        }
      }
    })
  };

  const updateTokenCreditData = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Credit Price?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = id;
        userData.token = token;
        let data = await creditPriceServices.updateTokenCreditData(userData);
        if (data?.statusCode === 200) {
          Hide();
          setActiveTab("credit")
        }
      }
    })
  };

  useEffect(() => {
    setId(creditData?._id);
    setPrice(creditData?.price);
    setCurrency(creditData?.currency);
    setCGST(creditData?.cgst);
    setSGST(creditData?.sgst);
    setToken(creditData?.token);
  }, [creditData]);

  const renderCreditSection = () => (
    <>
      <Row>
        <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
          <Form.Label>
            Currency <span>*</span>
          </Form.Label>
          <Form.Select
            onChange={(e) => setCurrency(e.target.value)}
            value={currency}
            aria-label="Default select example"
          >
            <option value="$">Dollar ($)</option>
            <option value="₹">INR (₹)</option>
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
          <Form.Label>
            Price <span>*</span>
          </Form.Label>
          <InputGroup>
            <InputGroup.Text id="inputGroupPrepend">{currency}</InputGroup.Text>
            <Form.Control
              type="number"
              onChange={(e) => setPrice(e.target.value)}
              value={price}
              placeholder="Enter Amount"
              required
            />
            <InputGroup.Text id="inputGroupPrepend">1 Credit</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Row>
      <div className="text-end footer_button">
        <Button disabled={!currency || !price} onClick={updateCreditPriceData}>
          {Submit}
        </Button>
      </div>
    </>
  );

  const renderGSTINSection = () => (
    <>
      <Row>
        <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
          <Form.Label>
            CGST <span>*</span>
          </Form.Label>
          <InputGroup>
            <InputGroup.Text id="inputGroupPrepend">%</InputGroup.Text>
            <Form.Control
              type="number"
              onChange={(e) => setCGST(e.target.value)}
              value={CGST}
              placeholder="Enter Percentage"
              required
            />
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
          <Form.Label>
            SGST <span>*</span>
          </Form.Label>
          <InputGroup>
            <InputGroup.Text id="inputGroupPrepend">%</InputGroup.Text>
            <Form.Control
              type="number"
              onChange={(e) => setSGST(e.target.value)}
              value={SGST}
              placeholder="Enter Percentage"
              required
            />
          </InputGroup>
        </Form.Group>
      </Row>
      <div className="text-end footer_button">
        <Button disabled={!CGST || !SGST} onClick={updateGSTPriceData}>
          {Submit}
        </Button>
      </div>
    </>
  );

  const renderTokenSection = () => (
    <>
      <Row>
        <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
          <Form.Label>
            Token <span>*</span>
          </Form.Label>
          <InputGroup>
            <Form.Control
              type="number"
              onChange={(e) => setToken(e.target.value)}
              value={token}
              placeholder="Enter Token"
              required
            />
            <InputGroup.Text id="inputGroupPrepend">1 Credit</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Row>
      <div className="text-end footer_button">
        <Button disabled={!token} onClick={updateTokenCreditData}>
          {Submit}
        </Button>
      </div>
    </>
  );

  return (
    <Modal
      show={Show}
      onHide={Hide}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={() => Hide()} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="hstack justify-content-center gap-3 mb-3 footer_button">
          <Button
            onClick={() => handleTabClick("credit")}
            style={{
              backgroundColor: activeTab === "credit" ? "" : "transparent",
              color: activeTab === "credit" ? "" : "var(--icon_color)",
              padding: 3
            }}
          >
            Price
          </Button>
          <Button
            onClick={() => handleTabClick("token")}
            style={{
              backgroundColor: activeTab === "token" ? "" : "transparent",
              color: activeTab === "token" ? "" : "var(--icon_color)",
              padding: 3
            }}
          >
            Token
          </Button>
          <Button
            onClick={() => handleTabClick("gstin")}
            style={{
              backgroundColor: activeTab === "gstin" ? "" : "transparent",
              color: activeTab === "gstin" ? "" : "var(--icon_color)",
              padding: 3
            }}
          >
            GSTIN
          </Button>
        </div>
        {activeTab === "credit" && renderCreditSection()}
        {activeTab === "token" && renderTokenSection()}
        {activeTab === "gstin" && renderGSTINSection()}
      </Modal.Body>
    </Modal>
  );
};

export default CreditPriceModal;
