import React from "react";
import { ContextMenuTrigger } from "react-contextmenu";
import RightClick from "../../../Hooks/RightClick";

const STDMTerminologySidebar = ({
  STDMId,
  setSTDMId,
  STDMList,
}) => {
  return (
    <>
      <div>
        <ul className="side-menu pt-2">
          {STDMList?.map((item, index) => (
            <div key={index}>
              <ContextMenuTrigger
                id={`context_${index}_${index}`}
                collect={() => ({ id: index })}
              >
                <li
                  className={`mb-1 ${
                    STDMId === item.uniqueId ? "activeHelpMenu" : ""
                  }`}
                  onClick={(e) => setSTDMId(item?.uniqueId)}
                  title={item.name}
                >
                  <div
                    className="side-menu-item m-0 py-2"
                    style={{ fontSize: 13 }}
                  >
                    <span
                      className={`titleName text-capitalize  ${
                        item.status === "inactive" ? "text-muted" : ""
                      }`}
                    >
                      {item.name}
                    </span>
                  </div>
                </li>
              </ContextMenuTrigger>
              <RightClick
                ContextId={`context_${index}_${index}`}
              />
            </div>
          ))}
        </ul>
      </div>
    </>
  );
};

export default STDMTerminologySidebar;
