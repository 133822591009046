import React, { useEffect, useState } from "react";
import UseToggle from "../../Hooks/useToggle";
import { Button, Form } from "react-bootstrap";
import UploadDocumentModal from "./UploadDocumentModal";
import { libraryFolderAndDataServices } from "../../../APIServices/Editor/libraryFolderAndDataServices";
import { ContextMenuTrigger } from "react-contextmenu";
import RightClick from "../../Hooks/RightClick";
import RenameLibraryModal from "./RenameLibraryModal";
import Swal from "sweetalert2";
import LibraryPropertiesModal from "./LibraryPropertiesModal";

const LibraryHelpSidebar = ({
  libraryId,
  setLibraryId,
  getLibraryFolderData,
  setLibraryList,
  libraryList,
  setColumnProperty,
  columnProperty,
  userCurrrentData,
  libraryProperties,
  setLibraryProperties
}) => {
  const { toggle, setToggle, toggle1, setToggle1, toggle2, setToggle2 } =
    UseToggle();
  const [searchData, setSearchData] = useState("");
  const [id, setId] = useState("");
  const [libraryFolderName, setLibraryFolderName] = useState("");
  const [libraryDiscription, setLibraryDiscription] = useState("");
  const [columnList, setColumnList] = useState([]);
  const [columnStatus, setColumnStatus] = useState([]);
  const [activeTab, setActiveTab] = useState("file");

  const openSingleLibraryData = (id) => {
    setLibraryId(id);
    setColumnProperty("")
  };

  const handleSearch = async (searchValue) => {
    let userData = {};
    userData.searchData = searchValue || searchData;
    userData.type = "help";
    let data = await libraryFolderAndDataServices.searchLibraryFolder(userData);
    setLibraryList(data?.data);
  };

  const handleUpdateLibraryData = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Change Title of this File?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = id;
        userData.name = libraryFolderName;
        userData.description = libraryDiscription;
        let data = await libraryFolderAndDataServices.updateLibraryFolderData(
          userData
        );
        if (data?.statusCode === 200) {
          getLibraryFolderData();
        }
      }
    });
  };

  const handleChangeLibraryDataStatus = async (id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Change Status of this File?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = id;
        userData.status = status;
        let data =
          await libraryFolderAndDataServices.changeStatusLibraryFolderData(
            userData
          );
        if (data?.statusCode === 200) {
          getLibraryFolderData();
        }
      }
    });
  };

  const handleDeleteLibraryData = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete this File?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data =
          await libraryFolderAndDataServices.deleteLibraryFolderAndData(id);
        if (data?.statusCode === 200) {
          getLibraryFolderData();
        }
      }
    });
  };

  const handleDuplicateLibraryData = async (libraryId) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Duplicate this File?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Duplicate it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.libraryId = libraryId;
        let data =
          await libraryFolderAndDataServices.duplicateLibraryFolderAndData(
            userData
          );
        if (data?.statusCode === 200) {
          getLibraryFolderData();
        }
      }
    });
  };

  const renameLibraryModal = (item) => {
    setLibraryFolderName(item.name);
    setLibraryDiscription(item.description);
    setId(item._id);
    setToggle1();
  };

  const getLibraryDataByFolderId = async (libraryId) => {
    let data = await libraryFolderAndDataServices.getLibraryDataByFolderId(
      libraryId
    );
    let result = data?.data;
    setColumnList(result?.originalColumn);
    setColumnStatus(result?.columnStatus)
  };

  const libraryPropertiesModal = (item) => {
    setLibraryProperties(item);
    setToggle2();
    getLibraryDataByFolderId(item?.libraryId);
  };

  useEffect(() => {
    getLibraryFolderData();
  }, []);

  return (
    <>
      <div className="hstack justify-content-between gap-2 mb-1">
        <div className="search w-100">
          <Form.Control
            required
            value={searchData}
            onChange={(e) => {
              handleSearch(e.target.value);
              setSearchData(e.target.value);
            }}
            type="search"
            placeholder="Search"
          />
        </div>
        <div className="footer_button uploadButton">
          <Button className="w-100" title="Upload File" onClick={setToggle}>
            <i className="fa-solid fa-upload"></i>
          </Button>
        </div>
      </div>
      <div className={columnProperty && "libraryHeight"}>
        <ul className="side-menu pt-2">
          {libraryList?.map((item, index) => (
            <div key={index}>
              <ContextMenuTrigger
                id={`context_${index}_${index}`}
                collect={() => ({ id: index })}
              >
                <li
                  className={`mb-1 ${
                    libraryId === item.libraryId ? "activeHelpMenu" : ""
                  }`}
                  onClick={(e) => {openSingleLibraryData(item.libraryId); setLibraryProperties(item)}}
                  title={item.name}
                >
                  <div
                    className="side-menu-item m-0 py-2"
                    style={{ fontSize: 13 }}
                  >
                    <span
                      className={`titleName text-capitalize  ${
                        item.status === "inactive" ? "text-muted" : ""
                      }`}
                    >
                      {item.name}
                    </span>
                  </div>
                </li>
              </ContextMenuTrigger>
              <RightClick
                ContextId={`context_${index}_${index}`}
                Menu1
                MenuName1={item.status === "active" ? "Inactive" : "Active"}
                icons1={
                  <i
                    class={`fa-solid ${
                      item.status === "inactive"
                        ? "fa-eye edit"
                        : "fa-eye-slash delete"
                    }`}
                  ></i>
                }
                handleClick1={() =>
                  handleChangeLibraryDataStatus(
                    item._id,
                    item.status === "active" ? "inactive" : "active"
                  )
                }
                className1={item.status === "inactive" ? "edit" : "delete"}
                Menu2
                MenuName2={"Update"}
                icons2={<i className="fa-solid fa-pen-to-square"></i>}
                handleClick2={() => renameLibraryModal(item)}
                className2=""
                Menu3
                MenuName3={"Duplicate"}
                icons3={<i className="fa-solid fa-clone"></i>}
                handleClick3={() => handleDuplicateLibraryData(item.libraryId)}
                className3=""
                Menu4
                MenuName4={"Delete"}
                handleClick4={() => handleDeleteLibraryData(item.libraryId)}
                icons4={<i className="fa-solid fa-trash-can delete"></i>}
                className4="delete"
                Menu5
                MenuName5={"Properties"}
                handleClick5={() => libraryPropertiesModal(item)}
                icons5={<i className="fa-solid fa-wrench"></i>}
                className5=""
              />
            </div>
          ))}
        </ul>
      </div>
      <UploadDocumentModal
        Show={!toggle}
        Hide={setToggle}
        getLibraryFolderData={getLibraryFolderData}
        Size={"md"}
        Title={"Upload File"}
        Submit={"Submit"}
      />
      <RenameLibraryModal
        Show={!toggle1}
        Hide={setToggle1}
        handleUpdateLibraryData={handleUpdateLibraryData}
        setLibraryFolderName={setLibraryFolderName}
        libraryFolderName={libraryFolderName}
        fileDescription={libraryDiscription}
        setFileDescription={setLibraryDiscription}
        Size={"md"}
        Title={"Update File Title & Description"}
        Submit={"Update"}
      />
      <LibraryPropertiesModal
        Show={!toggle2}
        Hide={setToggle2}
        columnList={columnList}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        setColumnProperty={setColumnProperty}
        libraryProperties={libraryProperties}
        setLibraryId={setLibraryId}
        columnStatus={columnStatus}
        userCurrrentData={userCurrrentData}
        getLibraryDataByFolderId={getLibraryDataByFolderId}
        Size={activeTab === "file" ? "md" : "xl"}
        Title={"File & Table Properties"}
        Submit={"Close"}
      />
    </>
  );
};

export default LibraryHelpSidebar;
