import React, { useEffect, useState } from "react";
import Pagination from "../../../Hooks/Pagination";
import TableHeader from "../../../../component/TableHeader";
import PackageRequestTableData from "./PackageRequestTableData";
import { packageServices } from "../../../../APIServices/Editor/packageServices";

const PackageRequest = ({ onMouseDown, tableRef, status, setStatus }) => {
  const [packageList, setPackageList] = useState([]);

  const getRequestedDisabledPackage = async () => {
    let data = await packageServices.getRequestedDisabledPackage()
    setPackageList(data?.data);
  };

  const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = packageList?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(packageList?.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % packageList?.length;
    setItemOffset(newOffset);
  };

  const openCategoryTable = () => {
    setStatus("category");
  };

  const openTagTable = () => {
    setStatus("tag");
  };

  const openReviewPackageTable = () => {
    setStatus("review");
  };

  const openRequestPackageTable = () => {
    setStatus("version");
  };

  const openRequestVersionUpdatePackageTable = () => {
    setStatus("update");
  };

  useEffect(() => {
    getRequestedDisabledPackage();
  }, []);

  return (
    <>
      <TableHeader
        Status={status}
        TextButton4
        TextButtonName4={"Request Packages"}
        handleTextButton4={openRequestPackageTable}
        TextButton5
        TextButtonName5={"Version Update Request"}
        handleTextButton5={openRequestVersionUpdatePackageTable}
        TextButton6
        TextButtonName6={"Review Packages"}
        handleTextButton6={openReviewPackageTable}
        TextButton7
        TextButtonName7={"Categories"}
        handleTextButton7={openCategoryTable}
        TextButton8
        TextButtonName8={"Tags"}
        handleTextButton8={openTagTable}
      />
      <PackageRequestTableData
        onMouseDown={onMouseDown}
        tableRef={tableRef}
        packageList={currentItems}
        getRequestedDisabledPackage={getRequestedDisabledPackage}
      />
      <Pagination
        Result={packageList}
        itemsPerPage={itemsPerPage}
        handlePageClick={handlePageClick}
        pageCount={pageCount}
      />
    </>
  );
};

export default PackageRequest;
