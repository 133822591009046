import React, { useEffect, useState } from "react";
import UseToggle from "../../Hooks/useToggle";
import Pagination from "../../Hooks/Pagination";
import TableHeader from "../../../component/TableHeader";
import CouponHistoryTableData from "./CouponHistoryTableData";
import CreateNewCouponModal from "./CreateNewCouponModal";
import { Modal } from "react-bootstrap";
import { couponServices } from "../../../APIServices/Editor/couponServices";

const CouponHistory = ({ Show, Hide, Size, Title, id }) => {
  const { toggle, setToggle } = UseToggle();

  const [searchData, setSearchData] = useState("");
  const [hideClass, setHideClass] = useState("");
  const [couponList, setCouponList] = useState([]);

  const getCouponList = async () => {
    let data = await couponServices.getCouponListByUserId(id);
    setCouponList(data?.data);
  };

  const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = couponList?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(couponList?.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % couponList?.length;
    setItemOffset(newOffset);
  };

  const handleSearch = async (searchValue) => {
    let userData = {};
    userData.searchData = searchValue || searchData;
    userData.userId = id;
    let data = await couponServices.searchCoupon(userData);
    setCouponList(data?.data);
  };

  const createNewCoupon = () => {
    setToggle();
    setHideClass("d-none");
  };

  useEffect(() => {
    getCouponList();
  }, [id]);
  
  return (
    <Modal
      className={hideClass}
      show={Show}
      onHide={Hide}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={() => Hide()} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <>
          <TableHeader
            handleSearch={handleSearch}
            setSearchData={setSearchData}
            SearchReverseData={getCouponList}
            AddNew
            handleAddNew={createNewCoupon}
            titleAddNew="Add New Coupon"
          />
          <CouponHistoryTableData
            couponList={currentItems}
            getCouponList={getCouponList}
            setHideClass={setHideClass}
          />
          <Pagination
            Result={couponList}
            itemsPerPage={itemsPerPage}
            handlePageClick={handlePageClick}
            pageCount={pageCount}
          />
          <CreateNewCouponModal
            Show={!toggle}
            Hide={setToggle}
            setHideClass={setHideClass}
            Size={"md"}
            Title={"Create a New Coupon Code"}
            getCouponList={getCouponList}
            id={id}
            Submit={"Create"}
          />
        </>
      </Modal.Body>
    </Modal>
  );
};

export default CouponHistory;
