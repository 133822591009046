import React, { useState } from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { creditPriceServices } from "../../../APIServices/AIAlpha/creditPriceServices";

const SendReward = ({
  Show,
  Hide,
  Size,
  Title,
  Submit,
  reward,
  getCreditDetailsofUser,
}) => {
  const [credits, setCredits] = useState("");
  const [confirmCredits, setConfirmCredits] = useState("");
  const [error, setError] = useState("");

  const sendRewardofUser = async () => {
    if (credits !== confirmCredits) {
      setError("* Credits and Confirm credits are not Same");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }

    let userData = {};
    userData.id = reward._id;
    userData.credit = credits;
    let data = await creditPriceServices.sendRewardofUser(userData);
    if (data?.statusCode === 200) {
      getCreditDetailsofUser();
      clearInputData();
    }
  };

  const clearInputData = () => {
    setCredits("");
    setConfirmCredits("");
    Hide();
  };

  return (
    <div>
      <Modal
        show={Show}
        onHide={Hide}
        size={Size}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>{Title}</Modal.Title>
          <div
            onClick={() => clearInputData()}
            className="close_modal"
            title="Close"
          >
            X
          </div>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
              <Form.Label>
                Credits <span>*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">
                  <i className="fa-solid fa-coins"></i>
                </InputGroup.Text>
                <Form.Control
                  type="number"
                  placeholder="Credit"
                  value={credits}
                  onChange={(e) => setCredits(e.target.value)}
                  required
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="12" className="add_sponsor_field">
              <Form.Label>
                Confirm Credits <span>*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">
                  <i className="fa-solid fa-coins"></i>
                </InputGroup.Text>
                <Form.Control
                  type="number"
                  placeholder="Confirm Credit"
                  value={confirmCredits}
                  onChange={(e) => setConfirmCredits(e.target.value)}
                  required
                />
              </InputGroup>
            </Form.Group>
          </Row>
          {error && <p className="text-danger mt-3 mb-0">{error}</p>}
        </Modal.Body>
        <Modal.Footer className="p-0 px-2">
          <div className="text-end footer_button">
            <Button
              disabled={!credits || !confirmCredits}
              onClick={sendRewardofUser}
            >
              {Submit}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SendReward;
