import React, { useEffect, useState } from "react";
import TerminologyComponent from "./TerminologyComponent";
import TerminologySidebar from "./TerminologySidebar";
import { Form, InputGroup } from "react-bootstrap";
import { sdtmServices } from "../../../../APIServices/CDISC/sdtmServices";

const Terminology = ({ onMouseDown, tableRef }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [libraryList, setLibraryList] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [libraryId, setLibraryId] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);

  const getSTDMTerminologyData = async () => {
    let data = await sdtmServices.getSTDMTerminology();
    setLibraryList(data?.data);
  };

  const startIndex = (Number(pageNumber) - 1) * Number(itemsPerPage);
  const endIndex = startIndex + Number(itemsPerPage);
  let result = dataList?.slice(startIndex, endIndex) || [];
  let totalPage = Math.ceil(dataList.length / itemsPerPage);

  useEffect(() => {
    getSTDMTerminologyData();
  }, [libraryId]);

  return (
    <>
      <div className="d-flex">
        <div
          style={{ width: isCollapsed ? "0%" : "20%" }}
          className={`rightHeightColumn ${isCollapsed ? "collapsed" : "collapsed"
            }`}
        >
          <div
            className={`mx-2 ${isCollapsed ? "leaveOptionBox" : "leaveOptionBox "
              }`}
          >
            <TerminologySidebar
              getSTDMTerminologyData={getSTDMTerminologyData}
              libraryList={libraryList}
              setDataList={setDataList}
              libraryId={libraryId}
              setLibraryId={setLibraryId}
            />
          </div>
        </div>
        <div
          style={{
            width: isCollapsed ? "100%" : "80%",
            background: "#fff",
            marginLeft: isCollapsed ? "0" : "auto",
          }}
          className={isCollapsed ? "collapsed" : "collapsed ps-2"}
        >
          <div className="helpComponentSection">
            {libraryId && dataList?.length > 0 && (
              <>
                <TerminologyComponent
                  onMouseDown={onMouseDown}
                  tableRef={tableRef}
                  result={result}
                  pageNumber={pageNumber}
                  itemsPerPage={itemsPerPage}
                />
                <div className="mt-3 add_sponsor_field hstack justify-content-between">
                  <div className="d-flex gap-2">
                    <div className="inputforSecelct">
                      <InputGroup>
                        <InputGroup.Text id="inputGroupPrepend">
                          RESULTS/ PAGE
                        </InputGroup.Text>
                        <Form.Select
                          onChange={(e) => setItemsPerPage(e.target.value)}
                          aria-label="Default select example"
                        >
                          <option selected={itemsPerPage === 5} value={5}>
                            5
                          </option>
                          <option selected={itemsPerPage === 10} value={10}>
                            10
                          </option>
                          <option selected={itemsPerPage === 15} value={15}>
                            15
                          </option>
                          <option selected={itemsPerPage === 25} value={25}>
                            25
                          </option>
                          <option selected={itemsPerPage === 50} value={50}>
                            50
                          </option>
                          <option selected={itemsPerPage === 100} value={100}>
                            100
                          </option>
                          <option selected={itemsPerPage === 500} value={500}>
                            500
                          </option>
                        </Form.Select>
                      </InputGroup>
                    </div>
                    <div className="inputforSecelct">
                      <InputGroup>
                        <InputGroup.Text id="inputGroupPrepend">
                          PAGE
                        </InputGroup.Text>
                        <Form.Control
                          type="number"
                          onChange={(e) => setPageNumber(e.target.value)}
                          value={pageNumber}
                          required
                          style={{ width: 65 }}
                        />
                        <InputGroup.Text id="inputGroupPrepend">
                          / {totalPage}
                        </InputGroup.Text>
                      </InputGroup>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Terminology;
