import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';

const ViewProgramPromptModal = ({ Show, Hide, Size, Title, steps, viewProgramPrompt }) => {
  let data = steps?.map((item, index) => `Step${index + 1}: ${item}`).join(',\n');

  const [copied, setCopied] = useState("");
  const copyToClipboard = (text) => {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    textarea.setAttribute("readonly", "");
    textarea.style.position = "absolute";
    textarea.style.left = "-9999px";
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <Modal
      show={Show}
      onHide={Hide}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>
          {Title}
          <i title="Copy Prompt"
            style={{ cursor: "pointer", marginLeft: 6 }}
            onClick={() => copyToClipboard(`Write a ${viewProgramPrompt} Code, \n{user_input}, \n${data}`)}
            className="fa-solid fa-copy">
          </i>
          <span style={{ fontSize: 10, marginLeft: 4 }}>{copied && "Prompt Copied"}</span>
        </Modal.Title>
        <div onClick={Hide} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="promptText">
          <SyntaxHighlighter>
            {`Write a ${viewProgramPrompt} Code, \n{user_input}, \n${data}`}
          </SyntaxHighlighter>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ViewProgramPromptModal;
