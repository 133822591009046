import React, { useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import "./example.css";

const TableExample = () => {
  const [resizingColumn, setResizingColumn] = useState(null);
  const startX = useRef(0);
  const startWidth = useRef(0);
  const tableRef = useRef(null);

  useEffect(() => {
    const onMouseMove = (e) => {
      if (resizingColumn !== null) {
        const width = startWidth.current + (e.pageX - startX.current);
        const th = tableRef.current.querySelector(
          `th:nth-child(${resizingColumn + 1})`
        );
        th.style.width = `${width}px`;
      }
    };

    const onMouseUp = () => {
      setResizingColumn(null);
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    if (resizingColumn !== null) {
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [resizingColumn]);

  const onMouseDown = (e, columnIndex) => {
    setResizingColumn(columnIndex);
    startX.current = e.pageX;
    startWidth.current = e.target.offsetWidth;
    document.body.style.userSelect = "none";
    const tableColumns = tableRef.current.querySelectorAll("th");
    tableColumns.forEach((column, index) => {
      if (index !== columnIndex) {
        column.style.width = `${column.offsetWidth}px`;
      }
    });
  };
  return (
    <div>
      <Table id="resizable-table" ref={tableRef}>
        <thead>
          <tr>
            <th onMouseDown={(e) => onMouseDown(e, 0)}>Sr.</th>
            <th onMouseDown={(e) => onMouseDown(e, 1)}>Name</th>
            <th onMouseDown={(e) => onMouseDown(e, 2)}>Designation</th>
            <th onMouseDown={(e) => onMouseDown(e, 3)}>Post</th>
            <th onMouseDown={(e) => onMouseDown(e, 4)}>Power</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>John Doe</td>
            <td>Manager</td>
            <td>Project Manager</td>
            <td>High</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Jane Smith</td>
            <td>Engineer</td>
            <td>Software Developer</td>
            <td>Medium</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Michael Johnson</td>
            <td>Analyst</td>
            <td>Data Scientist</td>
            <td>High</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default TableExample;
