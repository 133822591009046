import React from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

const UpdateSDTMClassModal = ({
  Show,
  Hide,
  Size,
  Title,
  versionId,
  setVersionId,
  className,
  setClassName,
  updateClassData,
  Submit,
  version,
}) => {
  return (
    <Modal
      show={Show}
      onHide={Hide}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={() => Hide()} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>
              Version <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => setVersionId(e.target.value)}
              value={versionId}
            >
              <option value="">Select Version</option>
              {version?.map((item, index) => (
                <option
                  selected={item.versionId === item._id}
                  key={index}
                  value={item._id}
                >
                  {item.version}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>
              Class <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Enter Verion"
              value={className}
              onChange={(e) => setClassName(e.target.value)}
            />
          </Form.Group>
        </Row>
        <div className="hstack justify-content-end border-top pt-3 footer_button">
          <Button
            disabled={!className}
            onClick={() => {
              updateClassData();
              Hide();
            }}
          >
            {Submit}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateSDTMClassModal;
