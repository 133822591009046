import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import AddNewVariableMethodModal from "./AddNewVariableMethodModal";
import ExportVariableMethodModal from "./ExportVariableMethodModal";
import VariableMethodTableData from "./VariableMethodTableData";
import TableHeader from "../../../../../component/TableHeader";
import UseToggle from "../../../../Hooks/useToggle";
import Pagination from "../../../../Hooks/Pagination";
import { sdtmServices } from "../../../../../APIServices/CDISC/sdtmServices";

const VariableMethod = ({ onMouseDown, tableRef }) => {
  const { toggle, setToggle, toggle1, setToggle1 } = UseToggle();
  const [searchData, setSearchData] = useState("");
  const [variableMethodList, setVariableMethodList] = useState([]);

  const getVariableMathodsList = async () => {
    let data = await sdtmServices.getVariableMathodsList();
    setVariableMethodList(data?.data);
  };

  const itemsPerPageDesignation = 10;
  const [itemOffsetDesignation, setItemOffsetDesignation] = useState(0);
  const endOffsetDesignation = itemOffsetDesignation + itemsPerPageDesignation;
  const currentItemsDesignation = variableMethodList?.slice(
    itemOffsetDesignation,
    endOffsetDesignation
  );

  const pageCountDesignation = Math.ceil(
    variableMethodList?.length / itemsPerPageDesignation
  );

  const handlePageClickDesignation = (event) => {
    const newOffset =
      (event.selected * itemsPerPageDesignation) % variableMethodList?.length;
    setItemOffsetDesignation(newOffset);
  };

  const handleSearch = async (searchValue) => {
    let userData = {};
    userData.searchData = searchValue || searchData;
    let data = await sdtmServices.searchVariableMathod(userData);
    setVariableMethodList(data?.data);
  };

  useEffect(() => {
    getVariableMathodsList();
  }, []);

  return (
    <>
      <Row>
        <Col md={12} className="mb-3">
          <TableHeader
            Search
            handleSearch={handleSearch}
            setSearchData={setSearchData}
            SearchReverseData={getVariableMathodsList}
            AddNew
            handleAddNew={setToggle}
            titleAddNew="Add New Variable Method"
            Export
            handleExport={setToggle1}
            titleExport="Export Variable Methods Data"
          />
          <VariableMethodTableData
            onMouseDown={onMouseDown}
            tableRef={tableRef}
            designationList={currentItemsDesignation}
            variableMethodList={variableMethodList}
            getVariableMathodsList={getVariableMathodsList}
          />
          <Pagination
            Result={variableMethodList}
            itemsPerPage={itemsPerPageDesignation}
            handlePageClick={handlePageClickDesignation}
            pageCount={pageCountDesignation}
          />
        </Col>
      </Row>
      <AddNewVariableMethodModal
        Show={!toggle}
        variableMethodList={variableMethodList}
        Hide={setToggle}
        Size={"md"}
        Title={"Add New Variable Method"}
        getVariableMathodsList={getVariableMathodsList}
        Submit={"Submit"}
      />
      <ExportVariableMethodModal
        Show={!toggle1}
        Hide={setToggle1}
        Size={"md"}
        Title={"Export VariableMethods"}
        Submit={"Export"}
      />
    </>
  );
};

export default VariableMethod;
