import React, { useEffect, useState } from "react";
import STDMTerminologyComponent from "./STDMTerminologyComponent";
import STDMTerminologySidebar from "./STDMTerminologySidebar";
import { Button, Form, InputGroup } from "react-bootstrap";
import UseToggle from "../../../Hooks/useToggle";
import AddSTDMTerminologyFileData from "./AddSTDMTerminologyFileData";
import { sdtmServices } from "../../../../APIServices/CDISC/sdtmServices";
import SDTMTerminologyFilterData from "./SDTMTerminologyFilterData";

const STDMTerminology = ({ onMouseDown, tableRef }) => {
  const { toggle, setToggle, setToggle1, toggle1 } = UseToggle();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [codeList, setCodeList] = useState([]);
  const [STDMList, setSTDMList] = useState([]);
  const [STDMId, setSTDMId] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);

  const getSTDMList = async () => {
    let data = await sdtmServices.getSTDMList();
    setSTDMList(data?.data || []);
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const getSTDMCodeData = async (STDMId) => {
    let data = await sdtmServices.getSTDMCode(
      STDMId
    );
    setCodeList(data?.data);
  };

  const startIndex = (Number(pageNumber) - 1) * Number(itemsPerPage);
  const endIndex = startIndex + Number(itemsPerPage);
  let result = codeList?.slice(startIndex, endIndex) || [];
  let totalPage = Math.ceil(codeList?.length / itemsPerPage);

  useEffect(() => {
    getSTDMList();
  }, []);

  useEffect(() => {
    getSTDMCodeData(STDMId);
  }, [STDMId]);

  return (
    <>
      <div className="d-flex">
        <div
          style={{ width: isCollapsed ? "0%" : "20%" }}
          className={`rightHeightColumn ${isCollapsed ? "collapsed" : "collapsed"
            }`}
        >
          <div
            className={`mx-2 ${isCollapsed ? "leaveOptionBox" : "leaveOptionBox "
              }`}
          >
            <STDMTerminologySidebar
              setSTDMId={setSTDMId}
              STDMId={STDMId}
              STDMList={STDMList}
            />
          </div>
        </div>
        <div
          style={{
            width: isCollapsed ? "100%" : "80%",
            background: "#fff",
            marginLeft: isCollapsed ? "0" : "auto",
          }}
          className={isCollapsed ? "collapsed" : "collapsed ps-2"}
        >
          <div className="helpComponentSection">
            <div className="d-flex justify-content-between mb-2">
              <div className="hstack gap-2">
                <i
                  id="menu-btn"
                  title={isCollapsed ? "Open Sidebar" : "Close Sidebar"}
                  className={`fa-solid ${isCollapsed ? "fa-bars-staggered" : "fa-bars"
                    }`}
                  onClick={toggleCollapse}
                ></i>
                {STDMId && (
                  <i
                    id="menu-btn"
                    title="Add"
                    className={`fa-solid fa-plus`}
                    onClick={setToggle}
                  ></i>
                )}
                {STDMId && (
                  <i
                    id="menu-btn"
                    title="Filter"
                    className={`fa-solid fa-filter`}
                    onClick={setToggle1}
                  ></i>
                )}
              </div>
            </div>
            {STDMId && codeList?.length > 0 && (
              <>
                <STDMTerminologyComponent
                  onMouseDown={onMouseDown}
                  tableRef={tableRef}
                  codeList={result}
                  getSTDMCodeData={getSTDMCodeData}
                  STDMId={STDMId}
                />
                <div className="mt-3 add_sponsor_field hstack justify-content-between">
                  <div className="d-flex gap-2">
                    <div className="inputforSecelct">
                      <InputGroup>
                        <InputGroup.Text id="inputGroupPrepend">
                          RESULTS/ PAGE
                        </InputGroup.Text>
                        <Form.Select
                          onChange={(e) => setItemsPerPage(e.target.value)}
                          aria-label="Default select example"
                        >
                          <option selected={itemsPerPage === 5} value={5}>
                            5
                          </option>
                          <option selected={itemsPerPage === 10} value={10}>
                            10
                          </option>
                          <option selected={itemsPerPage === 15} value={15}>
                            15
                          </option>
                          <option selected={itemsPerPage === 25} value={25}>
                            25
                          </option>
                          <option selected={itemsPerPage === 50} value={50}>
                            50
                          </option>
                          <option selected={itemsPerPage === 100} value={100}>
                            100
                          </option>
                          <option selected={itemsPerPage === 500} value={500}>
                            500
                          </option>
                        </Form.Select>
                      </InputGroup>
                    </div>
                    <div className="inputforSecelct">
                      <InputGroup>
                        <InputGroup.Text id="inputGroupPrepend">
                          PAGE
                        </InputGroup.Text>
                        <Form.Control
                          type="number"
                          onChange={(e) => setPageNumber(e.target.value)}
                          value={pageNumber}
                          required
                          style={{ width: 65 }}
                        />
                        <InputGroup.Text id="inputGroupPrepend">
                          / {totalPage}
                        </InputGroup.Text>
                      </InputGroup>
                    </div>
                  </div>
                  <p className="m-0">
                    <b>Rows : {codeList?.length}</b>
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <AddSTDMTerminologyFileData
        Show={!toggle}
        Hide={setToggle}
        Size={"md"}
        Title={"Add Terminology"}
        Submit={"Add"}
        STDMId={STDMId}
        getSTDMCodeData={getSTDMCodeData}
      />
      <SDTMTerminologyFilterData
        Show={!toggle1}
        Hide={setToggle1}
        Size={"lg"}
        Title={"Filter Value Levels"}
        Submit={"Export"}
      />
    </>
  );
};

export default STDMTerminology;
