import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import UpdateSDTMClassModal from "./UpdateSDTMClassModal";
import Moment from "react-moment";
import Swal from "sweetalert2";
import UseToggle from "../../../Hooks/useToggle";
import { sdtmServices } from "../../../../APIServices/CDISC/sdtmServices";

const SDTMClassTableData = ({
  classData,
  getClassList,
  onMouseDown,
  tableRef,
}) => {
  const { toggle, setToggle } = UseToggle();
  const ref = useRef(null);
  const [action, setAction] = useState("");
  const [id, setId] = useState("");
  const [className, setClassName] = useState("");
  const [version, setVersion] = useState([]);
  const [versionId, setVersionId] = useState("");

  const handleStatusChange = async (id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Change Status this Class?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = id;
        userData.status = status;
        let data = await sdtmServices.changeClassStatus(
          userData
        );
        if (data?.statusCode === 200) {
          getClassList();
        }
      }
    });
  };

  const updateClassData = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Update this Class?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = id;
        userData.versionId = versionId;
        userData.classData = className;
        let data = await sdtmServices.updateClass(userData);
        if (data?.statusCode === 200) {
          getClassList();
        }
      }
    });
  };

  const updateClassNameModal = (item) => {
    setClassName(item.class);
    setVersionId(item.versionId);
    setId(item._id);
    setToggle();
  };

  const deleteClass = async (e, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete this Class?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await sdtmServices.deleteClass(id);
        if (data?.statusCode === 200) {
          getClassList();
        }
      }
    });
  };

  const getActiveVersions = async () => {
    let data = await sdtmServices.getActiveVersions();
    setVersion(data?.data);
  };

  useEffect(() => {
    getActiveVersions();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setAction("");
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <>
      <div className="table-container overflow-auto">
        <Table id="resizable-table" className="custom-table m-0" ref={tableRef}>
          <thead>
            <tr>
              <th onMouseDown={(e) => onMouseDown(e, 0)} style={{ width: 40 }}>
                Sr.
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 1)}>Version Name</th>
              <th onMouseDown={(e) => onMouseDown(e, 2)}>Class Name</th>
              <th
                onMouseDown={(e) => onMouseDown(e, 3)}
                style={{ width: 120 }}
                className="text-center"
              >
                Status
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 4)} style={{ width: 180 }}>
                Created Date | Time
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 4)} style={{ width: 180 }}>
                Updated Date | Time
              </th>
              <th
                onMouseDown={(e) => onMouseDown(e, 5)}
                style={{ width: 70 }}
                className="text-center"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody ref={ref}>
            {classData?.length > 0 &&
              classData?.map((item, index) => (
                <tr className="relative">
                  <td>{index + 1}</td>
                  <td>{item.version}</td>
                  <td>{item.class}</td>
                  <td className="status_select">
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) =>
                        handleStatusChange(item._id, e.target.value)
                      }
                      className={
                        item.status === "active"
                          ? "active_status"
                          : "inactive_status"
                      }
                    >
                      <option
                        selected={item.status === "active"}
                        value="active"
                      >
                        Active
                      </option>
                      <option
                        selected={item.status === "inactive"}
                        value="inactive"
                      >
                        Inactive
                      </option>
                    </Form.Select>
                  </td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm" withTitle>
                      {item.createdAt}
                    </Moment>
                  </td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm" withTitle>
                      {item.updatedAt}
                    </Moment>
                  </td>
                  <td className="text-center">
                    <div className="text-center action__buttons">
                      {action === item._id ? (
                        <>
                          <div className="saperate_action_button">
                            <Button
                              onClick={() => updateClassNameModal(item)}
                              title="Edit"
                              className="rounded-circle"
                            >
                              <i className="fa-solid fa-pen-to-square"></i>
                            </Button>
                            <Button
                              onClick={(e) => deleteClass(e, item._id)}
                              title="Delete"
                              className="rounded-circle close_circle"
                            >
                              <i className="fa-solid fa-trash-can"></i>
                            </Button>
                          </div>
                          <Button
                            title="Close Action"
                            className="rounded-circle close_circle"
                          >
                            <i className="fa-solid fa-xmark"></i>
                          </Button>
                        </>
                      ) : (
                        <Button
                          onClick={() => setAction(item._id)}
                          title="Open Action"
                          className="rounded-circle "
                        >
                          <i className="fa-solid fa-bars"></i>
                        </Button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {classData?.length > 0 ? (
          ""
        ) : (
          <div className="noRecordFound">Record not found</div>
        )}
      </div>
      <UpdateSDTMClassModal
        Show={!toggle}
        Hide={setToggle}
        Size={"md"}
        Title={"Update Verion"}
        setClassName={setClassName}
        className={className}
        version={version}
        setVersionId={setVersionId}
        versionId={versionId}
        updateClassData={updateClassData}
        Submit={"Update"}
      />
    </>
  );
};

export default SDTMClassTableData;
