import React from "react";
import { Table } from "react-bootstrap";

const ColumnPropertyTable = ({columnProperty}) => {
  return (
    <>
      <div className="table-container overflow-auto">
        <Table id="resizable-table" className="custom-table m-0">
          <thead>
            <tr>
              <th>Property</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Lable</td>
              <td title={columnProperty?.label}>{columnProperty?.label}</td>
            </tr>
            <tr>
              <td>Name</td>
              <td title={columnProperty?.name}>{columnProperty?.name}</td>
            </tr>
            <tr>
              <td>Length</td>
              <td>{columnProperty?.length}</td>
            </tr>
            <tr>
              <td>Type</td>
              <td className="text-capitalize">{columnProperty?.type}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default ColumnPropertyTable;
