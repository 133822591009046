import React, { Suspense, useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Panel from "./Pages/Panel";
import { ForgetPassword, Login, ResetPassword } from "./component/Auth";
import SendUserInvitation from "./Pages/Public/SendUserInvitation";
import { authServices } from "./APIServices/authServices";
import Loading from "./Loading/Loading";
import { Permission } from "./ProtectRoute/PermissionRoutes";
import { themeSettingsServices } from "./APIServices/themeSettingsServices";
import { designationPermissionServices } from "./APIServices/designationPermissionServices";
import NotFoundPage from "./component/NotFoundPage";
import InvoiceReceipt from "./Pages/AIAlpha/Revenue/InvoiceReceipt";
import TableExample from "./Table/Table";
import TransectionInvoiceReceipt from "./Pages/Editor/Subcriptions/TransectionHistory/TransectionInvoiceReceipt";

const App = () => {
  const { LoginPermission, DashboardPermission } = Permission;

  const [twoFA, setTwoFA] = useState(false);
  const [roleType, setRoleType] = useState("");
  const [passwordUpdate, setPasswordUpdate] = useState(false);
  const [permission, setPermission] = useState("");
  const [userPermissionStatus, setUserPermissionStatus] = useState("");
  const [email, setEmail] = useState("");
  const [authType, setAuthType] = useState("");
  const [userCurrrentData, setUserCurrrentData] = useState("");
  const [themeColor, setThemeColor] = useState("");
  const [font, setFont] = useState("");
  const [activeColor, setActiveColor] = useState("rgb(0, 128, 0)");
  const [inActiveColor, setInActiveColor] = useState("rgb(255, 0, 0)");
  const [pendingColor, setPendingColor] = useState("rgb(128, 128, 128)");

  let currentUserData = async () => {
    let result = await authServices.getCurrectLoginUser();
    let data = result?.currentData;
    setRoleType(data?.roleType);
    setEmail(data?.email);
    setTwoFA(data?.twoFA);
    setPasswordUpdate(data?.isUpdatePassword);
    setAuthType(data?.authType);
    setUserCurrrentData(data);
    setUserPermissionStatus(result?.permissionStatus);
  };

  const getThemeColor = async () => {
    let data = await themeSettingsServices.getThemeColor();
    setThemeColor(data?.rgbColor);
  };

  const getThemeFont = async () => {
    let data = await themeSettingsServices.getThemeFont();
    setFont(data?.fontName);
  };

  const getStatusColor = async () => {
    let data = await themeSettingsServices.getStatusColor();
    setActiveColor(data?.statusColor?.active);
    setInActiveColor(data?.statusColor?.inactive);
    setPendingColor(data?.statusColor?.pending);
  };

  const getCurrentUserAssignedPermission = async () => {
    let data =
      await designationPermissionServices.getCurrentUserAssignedPermission();
    setPermission(data?.data);
  };

  document.documentElement.style.setProperty(
    "--header_bgColor",
    `rgb(${themeColor ? themeColor : "0 183 235"} / 5%)`
  );
  document.documentElement.style.setProperty(
    "--bg_active_color",
    `rgb(${themeColor ? themeColor : "0 183 235"} / 15%)`
  );
  document.documentElement.style.setProperty(
    "--icon_color",
    `rgb(${themeColor ? themeColor : "0 183 235"} / 100%)`
  );
  document.documentElement.style.setProperty("--font_family", font);
  document.documentElement.style.setProperty("--active_color", activeColor);
  document.documentElement.style.setProperty("--inactive_color", inActiveColor);
  document.documentElement.style.setProperty("--pending_color", pendingColor);

  useEffect(() => {
    getCurrentUserAssignedPermission();
    getThemeColor();
    getThemeFont();
    currentUserData();
    getStatusColor();
  }, []);
  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/" element={<LoginPermission />}>
              <Route path="/" element={<Login />} />
            </Route>

            <Route path="/login" element={<LoginPermission />}>
              <Route path="/login" element={<Login />} />
            </Route>

            <Route path="/forgetPassword" element={<LoginPermission />}>
              <Route path="/forgetPassword" element={<ForgetPassword />} />
            </Route>

            <Route path="/resetPassword" element={<LoginPermission />}>
              <Route path="/resetPassword" element={<ResetPassword />} />
            </Route>

            <Route
              path="/dashboard/:permalink"
              element={
                <DashboardPermission
                  twoFA={twoFA}
                  authType={authType}
                  email={email}
                  passwordUpdate={passwordUpdate}
                  currentUserData={currentUserData}
                />
              }
            >
              <Route
                path="/dashboard/:permalink"
                element={
                  <Panel
                    themeColor={themeColor}
                    roleType={roleType}
                    userCurrrentData={userCurrrentData}
                    currentUserData={currentUserData}
                    getThemeColor={getThemeColor}
                    getThemeFont={getThemeFont}
                    font={font}
                    getStatusColor={getStatusColor}
                    activeColor={activeColor}
                    inActiveColor={inActiveColor}
                    pendingColor={pendingColor}
                    permission={
                      userPermissionStatus === "active" ? permission : ""
                    }
                  />
                }
              />
            </Route>

            <Route
              path="/invoiceReceipt/:transactionId"
              element={
                <DashboardPermission
                  twoFA={twoFA}
                  authType={authType}
                  email={email}
                  passwordUpdate={passwordUpdate}
                  currentUserData={currentUserData}
                />
              }
            >
              <Route
                path="/invoiceReceipt/:transactionId"
                element={<InvoiceReceipt />}
              />
            </Route>

            <Route
              path="/transectionInvoiceReceipt/:transactionId"
              element={
                <DashboardPermission
                  twoFA={twoFA}
                  authType={authType}
                  email={email}
                  passwordUpdate={passwordUpdate}
                  currentUserData={currentUserData}
                />
              }
            >
              <Route
                path="/transectionInvoiceReceipt/:transactionId"
                element={<TransectionInvoiceReceipt />}
              />
            </Route>

            <Route path="/invite/:token" element={<LoginPermission />}>
              <Route path="/invite/:token" element={<SendUserInvitation />} />
            </Route>
            <Route path="*" element={<NotFoundPage />} />
            <Route path="blank" element={<TableExample />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
};

export default App;
