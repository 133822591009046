import React, { useEffect, useState } from "react";
import { Button, Col, Dropdown, Form, InputGroup, Modal } from "react-bootstrap";
import { packageServices } from "../../../APIServices/Editor/packageServices";

const UpdatePublishChangelogVersion = ({
  Show,
  Submit,
  Hide,
  Size,
  Title,
  getPackageForReview,
  id,
  setId,
  setChangeLog,
  changeLog,
  baseModal
}) => {
  const [changeLogs, setChangeLogs] = useState([]);
  const updatePublishPackageChangelogData = async () => {
    let userData = {};
    userData.id = id;
    userData.changeLogs = changeLogs;
    let data = packageServices.updatePublishPackageChangelogData(userData);
    if (data?.statusCode === 200) {
      setId("");
      getPackageForReview();
      setChangeLog("")
      onHide()
    }
  };
  
  const handleAddChangelog = (type) => {
    setChangeLogs([
      ...changeLogs,
      {type : type, changelog: ""},
    ]);
  };
  
  const handleRemoveChangelogs = (sectionIndex) => {
    const updatedChangelog = changeLogs.filter(
      (_, index) => index !== sectionIndex
    );
    setChangeLogs(updatedChangelog);
  };
  
  const updateChangelogState = (e, sectionIndex, key) => {
    const updatedChangelog = [...changeLogs];
    updatedChangelog[sectionIndex][key] = e.target.value;
    setChangeLogs(updatedChangelog);
  };
  
  useEffect(() => {
    setChangeLogs(changeLog);
  }, [changeLog]);
  
  const onHide = () => {
    baseModal()
    Hide()
  }

  return (
    <Modal
      show={Show}
      onHide={onHide}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={onHide} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
          <Form.Label className="DropDownAdd hstack">
            Change Logs
            <Dropdown>
              <Dropdown.Toggle
                variant="none"
                id="dropdown-basic"
                className="m-0 p-1 px-2"
              >
                <i
                  className="fa-solid fa-circle-plus"
                  title="Download Generated Data"
                ></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="#" title="Added" onClick={()=>handleAddChangelog("Added")}>
                  <i className="me-2 fa-regular fa-square-plus"></i> Added
                </Dropdown.Item>
                <Dropdown.Item href="#" title="Updated"  onClick={()=>handleAddChangelog("Updated")}>
                  <i className="me-2 fa-solid fa-pen-to-square"></i> Updated
                </Dropdown.Item>  
                <Dropdown.Item href="#" title="Fixed"  onClick={()=>handleAddChangelog("Fixed")}>
                  <i className="me-2 fa-solid fa-ban"></i> Fixed
                </Dropdown.Item>
                <Dropdown.Item href="#" title="Deleted"  onClick={()=>handleAddChangelog("Deleted")} style={{color :"red"}}>
                  <i className="me-2 fa-solid fa-trash-can" style={{color :"red"}}></i> Deleted
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Form.Label>
          {changeLogs?.map((item, index) => (
          <InputGroup className="mb-3" key={index}>
            <InputGroup.Text className="changeLogs" style={item.type === "Added" ? {backgroundColor : "green"} : item.type === "Updated" ? {backgroundColor : "orange"} : item.type === "Deleted" ? {backgroundColor : "red"} : {backgroundColor : "#000"}} id="basic-addon1">{item.type}</InputGroup.Text>
            <Form.Control
              placeholder={`Enter ${item.type} functinality Changelogs`}
              onChange={(e) => updateChangelogState(e, index, "changelog")}
              value={item.changelog}
            />
            <div className="d-flex align-items-center ">
            <i onClick={()=>handleRemoveChangelogs(index)} style={{fontSize : 12, color: "red", cursor: "pointer"}} className="mx-2 fa-regular fa-trash-can"></i>
            </div>
          </InputGroup>
          ))}
        </Form.Group>
      </Modal.Body>
      <Modal.Footer className="p-0 px-2">
        <div className="text-end footer_button">
          <Button
            disabled={changeLogs?.length === 0 || !id}
            onClick={() => {
              updatePublishPackageChangelogData();
              Hide();
            }}
          >
            {Submit}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdatePublishChangelogVersion;
