import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { libraryFolderAndDataServices } from "../../../APIServices/Editor/libraryFolderAndDataServices";

const UploadDocumentModal = ({
  Show,
  Hide,
  Size,
  Title,
  Submit,
  getLibraryFolderData,
}) => {
  const [step, setStep] = useState(1);
  const [selectFormat, setSelectFormat] = useState("");
  const [uploadFile, setUploadFile] = useState(null);
  const [selectName, setSelectName] = useState("");
  const [selectLabel, setSelectLabel] = useState("");
  const [fileTitle, setFileTitle] = useState("");
  const [fileDescription, setFileDescription] = useState("");

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePrevStep = () => {
    setStep(step - 1);
    if (step === 2) setUploadFile(null);
    else if (step === 3) {
      setSelectName("");
      setSelectLabel("");
    } else if (step === 4) setFileTitle("");
    setFileDescription("");
  };

  const handleFormatChange = (format) => {
    setSelectFormat(format);
    setUploadFile(null);
  };

  const cleanInputBox = () => {
    Hide();
    setStep(1);
    setSelectFormat("");
    setUploadFile("");
    setSelectName("");
    setSelectLabel("");
    setFileTitle("");
    setFileDescription("");
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const csvType = file.type === "text/csv";
    const excelType =
      file.type === "application/vnd.ms-excel" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if (
      (selectFormat === "csv" && csvType) ||
      (selectFormat === "excel" && excelType)
    )
      setUploadFile(file);
    else {
      setUploadFile(null);
      alert(`Please select a ${selectFormat.toUpperCase()} file.`);
    }
  };

  const handleImageChange = async () => {
    if (uploadFile) {
      const formData = new FormData();
      formData.append("file", uploadFile);
      formData.append("label", selectLabel);
      formData.append("name", selectName);
      formData.append("fileName", fileTitle);
      formData.append("description", fileDescription);
      formData.append("type", "help");
      let result = await libraryFolderAndDataServices.uploadFileinLibrary(
        formData
      );
      if (result?.statusCode === 200) {
        cleanInputBox();
        getLibraryFolderData();
      }
    }
  };
  const [error, setError] = useState("");

  const validateFileTitle = (input) => {
    const fileTitleRegex = /^[a-zA-Z0-9_]+$/;

    if (!fileTitleRegex.test(input)) {
      setError(
        "File Title should only contain capital letters, small letters, or numbers"
      );
    } else {
      setError("");
    }
  };

  return (
    <div>
      <Modal
        show={Show}
        onHide={Hide}
        size={Size}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>{Title}</Modal.Title>
          <div onClick={cleanInputBox} className="close_modal" title="Close">
            X
          </div>
        </Modal.Header>
        <Modal.Body>
          {step === 1 && (
            <Row>
              <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
                <Form.Label>
                  Format <span>*</span>
                </Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => handleFormatChange(e.target.value)}
                  value={selectFormat}
                  required
                >
                  <option value="">Select Format</option>
                  <option value="csv">CSV</option>
                  <option value="excel">Excel</option>
                </Form.Select>
              </Form.Group>
            </Row>
          )}
          {step === 2 && (
            <Row>
              <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
                <Form.Label>
                  Upload {selectFormat.toUpperCase()} File <span>*</span>
                </Form.Label>
                <Form.Control
                  type="file"
                  onChange={handleFileChange}
                  accept={selectFormat === "csv" ? ".csv" : ".xls, .xlsx"}
                  required
                />
              </Form.Group>
            </Row>
          )}
          {step === 3 && (
            <Row>
              <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
                <Form.Label>Select Name</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => setSelectName(e.target.value)}
                  value={selectName}
                >
                  <option>Select Name</option>
                  <option value={0}>Row 1</option>
                  <option value={1}>Row 2</option>
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
                <Form.Label>Select Label</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => setSelectLabel(e.target.value)}
                  value={selectLabel}
                >
                  <option value={null}>Select Label</option>
                  <option value={0}>Row 1</option>
                  <option value={1}>Row 2</option>
                </Form.Select>
              </Form.Group>
            </Row>
          )}
          {step === 4 && (
            <Row>
              <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
                <Form.Label>
                  Title <span>*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter File Title"
                  onChange={(e) => {
                    setFileTitle(e.target.value);
                    validateFileTitle(e.target.value);
                  }}
                  value={fileTitle}
                  required
                />
                {error && (
                  <span style={{ fontSize: 10, color: "red", fontWeight: 600 }}>
                    File Title should only contain capital letters, small
                    letters, underScore and numbers "Space Not Allowed"
                  </span>
                )}
              </Form.Group>
              <Form.Group as={Col} md="12" className="add_sponsor_field">
                <Form.Label>
                  Description <span>*</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Enter File Description"
                  onChange={(e) => setFileDescription(e.target.value)}
                  value={fileDescription}
                  required
                />
              </Form.Group>
            </Row>
          )}
          <div className="hstack gap-3 justify-content-end border-top pt-3 footer_button">
            {step > 1 && (
              <Button variant="secondary" onClick={handlePrevStep}>
                Previous
              </Button>
            )}
            {step < 4 ? (
              <Button
                onClick={handleNextStep}
                disabled={
                  (step === 1 && !selectFormat) || (step === 2 && !uploadFile)
                }
              >
                Next
              </Button>
            ) : (
              <Button
                onClick={handleImageChange}
                disabled={
                  (step === 4 && !fileTitle) ||
                  (step === 4 && !fileDescription) ||
                  (step === 4 && error)
                }
              >
                {Submit}
              </Button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UploadDocumentModal;
