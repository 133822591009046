import React from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

const UpdatePermissionModal = ({ Show, Hide, Size, Title, Submit, permissionName, setPermissionName, handleUpdate }) => {
  return (
    <Modal
      show={Show}
      onHide={Hide}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={()=>Hide()} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>Permission<span>*</span></Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Enter Permission Name"
              value={permissionName}
              onChange={(e) => setPermissionName(e.target.value)}
            />
          </Form.Group>
        </Row>
        <div className="hstack justify-content-end border-top pt-3 footer_button">
          <Button disabled={!permissionName} onClick={() => { handleUpdate(); Hide() }}>{Submit}</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UpdatePermissionModal;
