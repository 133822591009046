import React, { useEffect, useRef, useState } from "react";
import { Button, Table } from "react-bootstrap";
import UseToggle from "../../../Hooks/useToggle";
import UpdateSTDMTerminologyFileData from "./UpdateSTDMTerminologyFileData";
import { sdtmServices } from "../../../../APIServices/CDISC/sdtmServices";
import Swal from "sweetalert2";

const STDMTerminologyComponent = ({
  onMouseDown,
  tableRef,
  codeList,
  getSTDMCodeData,
  STDMId
}) => {
  const { toggle, setToggle } = UseToggle();
  let [codeData, setCodeData] = useState("");
  const ref = useRef(null);
  const [action, setAction] = useState("");
  let updateCodeData = (item) => {
    setCodeData(item)
    setToggle()
  }

  const handleDelete = async (e, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete this STDM Code?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await sdtmServices.deleteSTDMCode(id);
        if (data?.statusCode === 200) {
          getSTDMCodeData(STDMId);
        }
      }
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setAction("");
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  return (
    <>
      <div className="table-container tableLibrarySection overflow-auto">
        <Table
          id="resizable-table"
          className="m-0 custom-table"
          ref={tableRef}
        >
          <thead className="headerFixed">
            <tr>
              <th onMouseDown={(e) => onMouseDown(e, 0)}>Order</th>
              <th onMouseDown={(e) => onMouseDown(e, 1)}>CodeList</th>
              <th onMouseDown={(e) => onMouseDown(e, 2)}>CodeName</th>
              <th onMouseDown={(e) => onMouseDown(e, 3)}>Type</th>
              <th onMouseDown={(e) => onMouseDown(e, 4)}>Data Type</th>
              <th onMouseDown={(e) => onMouseDown(e, 5)}>Terms</th>
              <th onMouseDown={(e) => onMouseDown(e, 6)}>Decode Value</th>
              <th style={{ width: 70 }} onMouseDown={(e) => onMouseDown(e, 7)}>Action</th>
            </tr>
          </thead>
          <tbody>
            {codeList?.map((item, index) => (
              <tr className="relative" key={index}>
                <td>{item?.order}</td>
                <td>{item?.codeId}</td>
                <td>{item?.codeName}</td>
                <td className="text-capitalize">{item?.type}</td>
                <td className="text-capitalize">{item?.dataType}</td>
                <td>{item?.terms}</td>
                <td>{item?.decodeValue}</td>
                <td className="text-center">
                  <div className="text-center action__buttons">
                    {action === item._id ? (
                      <>
                        <div className="saperate_action_button">
                          <Button
                            onClick={() => updateCodeData(item)}
                            title="Edit"
                            className="rounded-circle"
                          >
                            <i className="fa-solid fa-pen-to-square"></i>
                          </Button>
                          <Button
                            onClick={(e) => handleDelete(e, item._id)}
                            title="Delete"
                            className="rounded-circle close_circle"
                          >
                            <i className="fa-solid fa-trash-can"></i>
                          </Button>
                        </div>
                        <Button
                          title="Close Action"
                          className="rounded-circle close_circle"
                        >
                          <i className="fa-solid fa-xmark"></i>
                        </Button>
                      </>
                    ) : (
                      <Button
                        onClick={() => setAction(item._id)}
                        title="Open Action"
                        className="rounded-circle "
                      >
                        <i className="fa-solid fa-bars"></i>
                      </Button>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <UpdateSTDMTerminologyFileData
        Show={!toggle}
        Hide={setToggle}
        Size={"md"}
        Title={"Update Terminology"}
        Submit={"Update"}
        codeData={codeData}
        getSTDMCodeData={getSTDMCodeData}
        STDMId={STDMId}
      />
    </>
  );
};

export default STDMTerminologyComponent;
