import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import UseToggle from "../../Hooks/useToggle";
import Pagination from "../../Hooks/Pagination";
import AddNewPermissionModal from "./AddNewPermissionModal";
import { designationPermissionServices } from "../../../APIServices/designationPermissionServices";
import TableHeader from "../../../component/TableHeader";
import PermissionTableData from "./PermissionTableData";
import AccessCheckboxList from "./AccessCheckboxList";
import ExportPermissionModal from "./ExportPermissionModal";

const Permission = ({ onMouseDown, tableRef }) => {
  const { toggle, setToggle, toggle1, setToggle1 } = UseToggle();

  const [viewAccessId, setViewAccessId] = useState("");
  const [viewAccessList, setViewAccessList] = useState([]);
  const [permissionList, setPermissionList] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [permissionName, setPermissionName] = useState("");

  const getPermissionsList = async () => {
    let data = await designationPermissionServices.getPermissionsList();
    setPermissionList(data?.data);
  };

  const itemsPerPage = 5;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = permissionList?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(permissionList?.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % permissionList?.length;
    setItemOffset(newOffset);
  };

  const handleSearch = async (searchValue) => {
    let userData = {};
    userData.searchData = searchValue || searchData;
    let data = await designationPermissionServices.searchPermission(userData);
    setPermissionList(data?.data);
  };

  const getAssignedPermission = async (viewAccessId) => {
    let data = await designationPermissionServices.getAssignedPermission(
      viewAccessId
    );
    setViewAccessList(data?.data);
  };

  useEffect(() => {
    getPermissionsList();
  }, []);
  return (
    <>
      <Row>
        <Col md={6} className="mb-3">
          <TableHeader
            Search
            handleSearch={handleSearch}
            setSearchData={setSearchData}
            SearchReverseData={getPermissionsList}
            AddNew
            handleAddNew={setToggle}
            titleAddNew="Add New Permission"
            Export
            handleExport={setToggle1}
            titleExport="Export Permissions Data"
          />
          <PermissionTableData
            onMouseDown={onMouseDown}
            tableRef={tableRef}
            permissionList={currentItems}
            getAssignedPermission={getAssignedPermission}
            setViewAccessId={setViewAccessId}
            getPermissionsList={getPermissionsList}
            setPermissionNameData={setPermissionName}
          />
          <Pagination
            Result={currentItems}
            itemsPerPage={itemsPerPage}
            handlePageClick={handlePageClick}
            pageCount={pageCount}
          />
        </Col>
        <Col md={6}>
          {viewAccessId && (
            <AccessCheckboxList
              permissionName={permissionName}
              viewAccessId={viewAccessId}
              viewAccessList={viewAccessList}
              getPermissionsList={getPermissionsList}
            />
          )}
        </Col>
      </Row>
      <AddNewPermissionModal
        Show={!toggle}
        Hide={setToggle}
        Size={"md"}
        Title={"Add New Permission"}
        getPermissionsList={getPermissionsList}
        Submit={"Submit"}
      />
      <ExportPermissionModal
        Show={!toggle1}
        Hide={setToggle1}
        Size={"md"}
        Title={"Export Permission Data"}
        Submit={"Export"}
      />
    </>
  );
};

export default Permission;
