import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import AddNewGlobalClassModal from "./AddNewGlobalClassModal";
import ExportGlobalClassModal from "./ExportGlobalClassModal";
import GlobalClassTableData from "./GlobalClassTableData";
import { designationPermissionServices } from "../../../../APIServices/designationPermissionServices";
import UseToggle from "../../../Hooks/useToggle";
import Pagination from "../../../Hooks/Pagination";
import TableHeader from "../../../../component/TableHeader";

const GlobalClass = ({ onMouseDown, tableRef }) => {
  const { toggle, setToggle, toggle1, setToggle1 } = UseToggle();
  const [searchData, setSearchData] = useState("");
  const [designationList, setDesignationList] = useState([]);

  const getDesignationsList = async () => {
    let data = await designationPermissionServices.getDesignationsList();
    setDesignationList(data?.data);
  };

  const itemsPerPageDesignation = 10;
  const [itemOffsetDesignation, setItemOffsetDesignation] = useState(0);
  const endOffsetDesignation = itemOffsetDesignation + itemsPerPageDesignation;
  const currentItemsDesignation = designationList?.slice(
    itemOffsetDesignation,
    endOffsetDesignation
  );

  const pageCountDesignation = Math.ceil(
    designationList?.length / itemsPerPageDesignation
  );

  const handlePageClickDesignation = (event) => {
    const newOffset =
      (event.selected * itemsPerPageDesignation) % designationList?.length;
    setItemOffsetDesignation(newOffset);
  };

  const handleSearch = async (searchValue) => {
    let userData = {};
    userData.searchData = searchValue || searchData;
    let data = await designationPermissionServices.searchDesignation(userData);
    setDesignationList(data?.data);
  };

  useEffect(() => {
    getDesignationsList();
  }, []);

  return (
    <>
      <Row>
        <Col md={12} className="mb-3">
          <TableHeader
            Search
            handleSearch={handleSearch}
            setSearchData={setSearchData}
            SearchReverseData={getDesignationsList}
            AddNew
            handleAddNew={setToggle}
            titleAddNew="Add New STDM"
            Export
            handleExport={setToggle1}
            titleExport="Export STDMs Data"
          />
          <GlobalClassTableData
            onMouseDown={onMouseDown}
            tableRef={tableRef}
            designationList={currentItemsDesignation}
            getDesignationsList={getDesignationsList}
          />
          <Pagination
            Result={currentItemsDesignation}
            itemsPerPage={itemsPerPageDesignation}
            handlePageClick={handlePageClickDesignation}
            pageCount={pageCountDesignation}
          />
        </Col>
      </Row>
      <AddNewGlobalClassModal
        Show={!toggle}
        getDesignationsList={getDesignationsList}
        Hide={setToggle}
        Size={"md"}
        Title={"Add New Global Class"}
        Submit={"Add"}
      />
      <ExportGlobalClassModal
        Show={!toggle1}
        Hide={setToggle1}
        Size={"md"}
        Title={"Export Global Class"}
        Submit={"Export"}
      />
    </>
  );
};

export default GlobalClass;
