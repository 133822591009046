import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { couponServices } from "../../../APIServices/Editor/couponServices";
import { userServices } from "../../../APIServices/usersServices";

const CreateNewCouponModal = ({ Show, Hide, Size, Title, Submit, setHideClass, id, getCouponList }) => {
  const [couponName, setCouponName] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [discountType, setDiscountType] = useState("");
  const [expiryTime, setExpiryTime] = useState("");
  const [discount, setDiscount] = useState("");
  const [couponType, setCouponType] = useState("");
  const [userId, setUserId] = useState("");
  const [userList, setUserList] = useState([]);

  const getConsultantUser = async() => {
    let data = await userServices.getUserData("consultant")
    setUserList(data?.data)
  }

  const addNewCoupon = async () => {
    let userDatas = {}
    userDatas.userId = userId;
    userDatas.couponName = couponName;
    userDatas.couponCode = couponCode;
    userDatas.discountType = discountType;
    userDatas.expiryTime = expiryTime;
    userDatas.discount = discount;
    userDatas.couponType = couponType;
    let data = await couponServices.addNewCoupon(userDatas)
    if (data?.statusCode === 200) {
      hideModal();
      getCouponList();
    }
  }

  const hideModal = () => {
    clearInputField();
    Hide();
    setHideClass("")
  }

  const clearInputField = () => {
    setCouponName("");
    setCouponCode("");
    setDiscountType("");
    setExpiryTime("");
    setDiscount("");
    setCouponType("");
  }

  const generateCouponCode = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@#$%&';
    let length = 8
    let newCouponCode = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      newCouponCode += characters.charAt(randomIndex);
    }

    setCouponCode(newCouponCode);
  }

  useEffect(() => {
    getConsultantUser();
  }, []);

  return (
    <Modal
      show={Show}
      onHide={Hide}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={hideModal} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>Select Consultant <span>*</span></Form.Label>
            <Form.Select
              onChange={(e) => setUserId(e.target.value)}
              aria-label="Default select example"
            >
              <option>Select Consultant</option>
              {userList?.map((item, index) => (
                <option key={index} value={item._id}>{item.fullName}</option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>Coupon Name <span>*</span></Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Enter Coupon Name"
              value={couponName}
              onChange={(e) => setCouponName(e.target.value)}
            />
          </Form.Group>

          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>Coupon Code <span>*</span></Form.Label>
            <div className="hstack gap-2">
              <Form.Control
                required
                type="text"
                disabled
                placeholder="Click on Generate Button"
                value={couponCode}
                onChange={(e) => setCouponCode(e.target.value)}
              />
              <div className="footer_button">
                <Button onClick={generateCouponCode}> Generate </Button>
              </div>
            </div>
          </Form.Group>

          <Form.Group as={Col} md="6" className="add_sponsor_field mb-3">
            <Form.Label>Discount Type <span>*</span></Form.Label>
            <Form.Select
              onChange={(e) => setDiscountType(e.target.value)}
              aria-label="Default select example"
            >
              <option>Select Discount Type</option>
              <option selected={discountType === "flat"} value="flat">Flat</option>
              <option selected={discountType === "percentage"} value="percentage">Persentage</option>
            </Form.Select>
          </Form.Group>

          <Form.Group as={Col} md="6" className="add_sponsor_field mb-3">
            <Form.Label>Expiry Time <span>*</span></Form.Label>
            <Form.Select
              onChange={(e) => setExpiryTime(e.target.value)}
              aria-label="Default select example"
            >
              <option>Select Day</option>
              <option selected={expiryTime === 1} value={1}>1 Day</option>
              <option selected={expiryTime === 2} value={2}>2 Days</option>
              <option selected={expiryTime === 3} value={3}>3 Days</option>
              <option selected={expiryTime === 4} value={4}>4 Days</option>
              <option selected={expiryTime === 5} value={5}>5 Days</option>
              <option selected={expiryTime === 6} value={6}>6 Days</option>
              <option selected={expiryTime === 7} value={7}>7 Days</option>
            </Form.Select>
          </Form.Group>

          <Form.Group as={Col} md="6" className="add_sponsor_field mb-3">
            <Form.Label>Discount <span>*</span></Form.Label>
            <div className="input-group mb-2">
              <div className="input-group-prepend">
                <div className="input-group-text">{discountType === "percentage" ? "%" : "$"}</div>
              </div>
              <Form.Control
                required
                type="number"
                onChange={(e) => setDiscount(e.target.value)}
                value={discount}
                placeholder={`Enter ${discountType === "percentage" ? "Percentage" : "Price"}`}
              />
            </div>
          </Form.Group>

          <Form.Group as={Col} md="6" className="add_sponsor_field mb-3">
            <Form.Label>Coupon Type <span>*</span></Form.Label>
            <Form.Select
              onChange={(e) => setCouponType(e.target.value)}
              aria-label="Default select example"
            >
              <option>Select Coupon Type</option>
              <option selected={couponType === "subscription"} value="subscription">Subscription</option>
              <option selected={couponType === "package"} value="package">Package</option>
            </Form.Select>
          </Form.Group>
        </Row>
        <div className="hstack gap-3 justify-content-end border-top pt-3 footer_button">
          <Button className="close_circle" onClick={() => clearInputField()}>Reset</Button>
          <Button
            disabled={!couponName || !couponCode || !discountType || !expiryTime || !discount || !couponType}
            onClick={addNewCoupon}>{Submit}</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CreateNewCouponModal;
