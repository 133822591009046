import React from "react";
import { Form, Table } from "react-bootstrap";

const LibraryTableProperties = ({
  columnList,
  selectedRows,
  handleChange
}) => {
  return (
    <>
      <div className="tablePropertiesHeight">
        <Table>
          <thead className="headerFixed">
            <tr>
              <th>
                <Form.Check
                  type="checkbox"
                  name="selectAll"
                  className="text-center"
                  title="Checked For Hide All Column"
                  checked={selectedRows?.length === 0 ? false : selectedRows?.length === columnList?.length}
                  onChange={(e) => handleChange(e)}
                />
              </th>
              <th style={{ width: "32%" }}>Column Name</th>
              <th>Column Label</th>
              <th>Type</th>
              <th>Length</th>
            </tr>
          </thead>
          <tbody>
            {columnList?.map((item, index) => (
              <tr key={index}>
                <td>
                  <Form.Check
                    type="checkbox"
                    name={item?.name}
                    id={item?.name}
                    className="text-center"
                    title="Checked For Hide Column"
                    checked={selectedRows?.includes(index)}
                    onChange={(e) => handleChange(e, index)}
                  />
                </td>
                <td>{item.name}</td>
                <td>{item.label}</td>
                <td className="text-capitalize">{item.type}</td>
                <td>{item.length}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default LibraryTableProperties;
