import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import UseToggle from "../../Hooks/useToggle";
import Moment from "react-moment";
import ViewOrderDetails from "./ViewOrderDetails";

const PackageTableData = ({ orderList, onMouseDown, tableRef }) => {
  const { toggle, setToggle } = UseToggle();

  const ref = useRef(null);
  const [action, setAction] = useState("");
  const [orderId, setOrderId] = useState("");

  const viewOrderDetails = async (e, orderId) => {
    setOrderId(orderId);
    setToggle();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setAction("");
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  return (
    <>
      <div className="table-container overflow-auto">
        <Table id="resizable-table" className="custom-table m-0" ref={tableRef}>
          <thead>
            <tr>
              <th onMouseDown={(e) => onMouseDown(e, 0)} style={{ width: 40 }}>
                Sr.
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 1)}>OrderId</th>
              <th onMouseDown={(e) => onMouseDown(e, 2)}>Name</th>
              <th onMouseDown={(e) => onMouseDown(e, 3)}>Email</th>
              <th onMouseDown={(e) => onMouseDown(e, 4)}>Items</th>
              <th onMouseDown={(e) => onMouseDown(e, 5)}>Payment</th>
              <th
                onMouseDown={(e) => onMouseDown(e, 6)}
                style={{ width: 120 }}
                className="text-center"
              >
                Status
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 7)} style={{ width: 125 }}>
                Order Date | Time
              </th>
              <th
                onMouseDown={(e) => onMouseDown(e, 8)}
                style={{ width: 70 }}
                className="text-center"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody ref={ref}>
            {orderList?.length > 0 &&
              orderList?.map((item, index) => (
                <tr className="relative">
                  <td>{index + 1}</td>
                  <td>#{item.orderId}</td>
                  <td>{item.name}</td>
                  <td>{item.email}</td>
                  <td>{item.items?.length}</td>
                  <td>{item.currency + item.payment}</td>
                  <td className="status_select">
                    <Form.Select
                      aria-label="Default select example"
                      className={
                        item.status === "completed"
                          ? "active_status"
                          : "inactive_status"
                      }
                    >
                      <option
                        selected={item.status === "completed"}
                        value="completed"
                      >
                        Completed
                      </option>
                      <option
                        selected={item.status === "failed"}
                        value="failed"
                      >
                        Failed
                      </option>
                    </Form.Select>
                  </td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm" withTitle>
                      {item.createdAt}
                    </Moment>
                  </td>
                  <td className="text-center">
                    <div className="text-center action__buttons">
                      {action === item._id ? (
                        <>
                          <div className="saperate_action_button">
                            <Button
                              onClick={(e) => viewOrderDetails(e, item.orderId)}
                              title="View Order Details"
                              className="rounded-circle"
                            >
                              <i className="fa-solid fa-eye"></i>
                            </Button>
                            <Button
                              title="Generate Invoice"
                              className="rounded-circle"
                            >
                              <i className="fa-solid fa-download"></i>
                            </Button>
                          </div>
                          <Button
                            onClick={() => setAction("")}
                            title="Close Action"
                            className="rounded-circle close_circle"
                          >
                            <i className="fa-solid fa-xmark"></i>
                          </Button>
                        </>
                      ) : (
                        <Button
                          onClick={() => setAction(item._id)}
                          title="Open Action"
                          className="rounded-circle"
                        >
                          <i className="fa-solid fa-bars"></i>
                        </Button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {orderList?.length > 0 ? (
          ""
        ) : (
          <div className="noRecordFound">Record not found</div>
        )}
      </div>
      <ViewOrderDetails
        Show={!toggle}
        Hide={setToggle}
        Size={"lg"}
        Title={"Order Details"}
        orderId={orderId}
      />
    </>
  );
};

export default PackageTableData;
