import React, { useEffect, useState } from "react";
import Pagination from "../../../Hooks/Pagination";
import TableHeader from "../../../../component/TableHeader";
import NewVersionPackageTableData from "./NewVersionPackageTableData";
import { packageServices } from "../../../../APIServices/Editor/packageServices";
import AddNewVersionRequestModal from "./AddNewVersionRequestModal";
import UseToggle from "../../../Hooks/useToggle";

const NewVersionPackage = ({ onMouseDown, tableRef, status, setStatus, roleType }) => {
  const { toggle, setToggle } = UseToggle();
  const [packageUpdateRequestList, setPackageUpdateRequestList] = useState([]);

  const getUpdateRequestPackage = async () => {
    let data = await packageServices.getUpdateRequestPackage()
    setPackageUpdateRequestList(data?.data);
  };

  const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = packageUpdateRequestList?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(packageUpdateRequestList?.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % packageUpdateRequestList?.length;
    setItemOffset(newOffset);
  };

  const openCategoryTable = () => {
    setStatus("category");
  };

  const openTagTable = () => {
    setStatus("tag");
  };

  const openReviewPackageTable = () => {
    setStatus("review");
  };

  const openRequestPackageTable = () => {
    setStatus("version");
  };

  const openRequestVersionUpdatePackageTable = () => {
    setStatus("update");
  };

  useEffect(() => {
    getUpdateRequestPackage();
  }, []);

  return (
    <>
      <TableHeader
        AddNew={roleType === "admnin"}
        handleAddNew={setToggle}
        titleAddNew="Add New Version Request"
        Status={status}
        TextButton4={roleType === "admnin"}
        TextButtonName4={"Request Packages"}
        handleTextButton4={openRequestPackageTable}
        TextButton5={roleType === "admnin"}
        TextButtonName5={"Version Update Request"}
        handleTextButton5={openRequestVersionUpdatePackageTable}
        TextButton6={roleType === "admnin"}
        TextButtonName6={"Review Packages"}
        handleTextButton6={openReviewPackageTable}
        TextButton7={roleType === "admnin"}
        TextButtonName7={"Categories"}
        handleTextButton7={openCategoryTable}
        TextButton8={roleType === "admnin"}
        TextButtonName8={"Tags"}
        handleTextButton8={openTagTable}
      />
      <NewVersionPackageTableData
        onMouseDown={onMouseDown}
        tableRef={tableRef}
        packageUpdateRequestList={currentItems}
        roleType={roleType}
        getUpdateRequestPackage={getUpdateRequestPackage}
      />
      <Pagination
        Result={packageUpdateRequestList}
        itemsPerPage={itemsPerPage}
        handlePageClick={handlePageClick}
        pageCount={pageCount}
      />
      <AddNewVersionRequestModal
        Show={!toggle}
        getUpdateRequestPackage={getUpdateRequestPackage}
        Hide={setToggle}
        Size={"md"}
        Title={"Add New Request For Package Update"}
        Submit={"Submit"}
      />
    </>
  );
};

export default NewVersionPackage;
