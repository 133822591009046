import React, { useEffect, useRef, useState } from "react";
import { Button, Container } from "react-bootstrap";
import OTP_Verification from "../../img/login.jpg";
import { NavLink } from "react-router-dom";
import { authServices } from "../../APIServices/authServices";

const OtpVerification = ({ authType, email, currentUserData }) => {
  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
  const otpInputs = useRef([]);

  const handleInputChange = (index, e) => {
    const value = e.target.value;
    if (value === "" || /^[0-9]$/.test(value)) {
      const updatedValues = [...otpValues];
      updatedValues[index] = value;
      setOtpValues(updatedValues);

      if (value !== "" && index < 5) {
        otpInputs.current[index + 1].focus();
      }

      if (value === "" && index > 0) {
        otpInputs.current[index - 1].focus();
      }
    }
  };

  const twpFactorAuthenticationVerify = async () => {
    let endPoint = authType === "apps" ? authServices.verifyTwoFAForAppLogin : authServices.verifyTwoFAForEmailLogin
    let userData = {}
    userData.otp = otpValues
    let data = await endPoint(userData)
    if (data?.statusCode === 200) {
      setOtpValues(["", "", "", "", "", ""])
      currentUserData()
    }
  }

  const logout = async () => {
    await authServices.logout();
  };

  const [timer, setTimer] = useState(120);
  const [display, setDisplay] = useState("02:00");

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (timer > 0) {
        const minutes = Math.floor(timer / 60);
        const seconds = timer % 60;
        const displayValue = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        setDisplay(displayValue);
        setTimer(timer => timer - 1);
      } else {
        setDisplay('00:00');
        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timer]);

  const sendOTPForEmailTwoFa = async () => {
    await authServices.emailOTPFor2FA()
  }

  function maskEmail(email) {
    const [username, domain] = email.split('@');
  
    const usernameLength = username.length;
  
    if (usernameLength <= 4) {
      return email;
    }
  
    const firstTwoChars = username.substring(0, 2);
  
    const lastThreeChars = username.substring(usernameLength - 3);
  
    const maskedUsername = `${firstTwoChars}************${lastThreeChars}`;
  
    return `${maskedUsername}@${domain}`;
  }

  return (
    <Container fluid className="p-0 section__Module">
      <div className="hero">
        <div className="overlay-black"></div>
        <img className="hero-img" src={OTP_Verification} alt="Hero" />
      </div>
      <section className="sign-up">
        <form
          className="otp-form sign-up__form"
          name="otp-form"
        >
          <div className="title">
            {authType === "email" ?
              <p className="info text-center">
                An OTP has been sent to {maskEmail(email)}
              </p>
              :
              <p className="info text-center">
                You will use the OTP of the authenticator app.
              </p>
            }
            <p className="msg text-center">Please enter OTP to verify</p>
          </div>
          <div className="otp-input-fields">
            {otpValues.map((value, index) => (
              <input
                key={index}
                type="text"
                pattern="\d*"
                maxLength="1"
                className={`otp__digit otp__field__${index + 1}`}
                value={value}
                onChange={(e) => handleInputChange(index, e)}
                ref={(input) => (otpInputs.current[index] = input)}
              />
            ))}
          </div>
          <div className="hstack gap-3 justify-content-end py-2 footer_button">
            <Button className="w-100" onClick={twpFactorAuthenticationVerify}>Verify OTP</Button>
          </div>
          <div className={authType === "email" ? "d-flex justify-content-between mt-2" : "d-flex justify-content-end mt-2"}>
            <p className="message">
              <NavLink onClick={logout}>Logout</NavLink>
            </p>
            {authType === "email" &&
              <p className="message">
                {display === "00:00" ?
                  <NavLink onClick={sendOTPForEmailTwoFa}> Resend OTP</NavLink>
                  :
                  <NavLink>{display}</NavLink>
                }
              </p>
            }
          </div>
        </form>
      </section>
    </Container>
  );
};

export default OtpVerification;
