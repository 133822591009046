import React, { useEffect, useState } from "react";
import Pagination from "../../Hooks/Pagination";
import TableHeader from "../../../component/TableHeader";
import CreditsTableData from "./CreditsTableData";
import UseToggle from "../../Hooks/useToggle";
import { creditPriceServices } from "../../../APIServices/AIAlpha/creditPriceServices";
import ExportCreditsTableDataModal from "./ExportCreditsTableDataModal";

const Credits = ({ onMouseDown, tableRef }) => {
  const { toggle, setToggle } = UseToggle();
  const [searchData, setSearchData] = useState("");
  const [creditDetais, setCreditDetais] = useState([]);

  const getCreditDetailsofUser = async () => {
    let data = await creditPriceServices.getCreditDetailsofUser();
    setCreditDetais(data?.data);
  };

  const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = creditDetais?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(creditDetais?.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % creditDetais?.length;
    setItemOffset(newOffset);
  };

  const handleSearch = async (searchValue) => {
    let userData = {};
    userData.searchData = searchValue || searchData;
    let data = await creditPriceServices.searchCreditDetailsForUser(userData);
    setCreditDetais(data?.data);
  };


  useEffect(() => {
    getCreditDetailsofUser();
  }, []);
  return (
    <>
      <TableHeader
        Search
        handleSearch={handleSearch}
        setSearchData={setSearchData}
        SearchReverseData={getCreditDetailsofUser}
        Export
        handleExport={setToggle}
        titleExport="Export Credit Records"
      />
      <CreditsTableData
        onMouseDown={onMouseDown}
        tableRef={tableRef}
        creditDetais={currentItems}
        getCreditDetailsofUser={getCreditDetailsofUser}
      />
      <Pagination
        Result={currentItems}
        itemsPerPage={itemsPerPage}
        handlePageClick={handlePageClick}
        pageCount={pageCount}
      />
      <ExportCreditsTableDataModal
        Show={!toggle}
        Hide={setToggle}
        Size={"md"}
        Title={"Export Credit Records"}
        Submit={"Export"}
      />
    </>
  );
};

export default Credits;
