import React, { useEffect, useRef, useState } from "react";
import { Button, Table } from "react-bootstrap";
import Moment from "react-moment";
import Swal from "sweetalert2";
import { invitedDataServices } from "../../../APIServices/invitedDataServices";

const UserInvitationTableData = ({
  invitedList,
  getInvitation,
  permission,
  roleType,
  role,
  roleData,
  onMouseDown,
  tableRef,
}) => {
  const ref = useRef(null);
  const [action, setAction] = useState("");

  const cancelInvitation = async (e, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to cancel invitation for ${roleData}?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, cancel it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = id;
        let data = await invitedDataServices.cancelInvitation(userData);
        if (data?.statusCode === 200) {
          getInvitation();
        }
      }
    });
  };

  const resendInvitation = async (e, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to resend invitation for ${roleData}?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, resend it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = id;
        let data = await invitedDataServices.resendInvitation(userData);
        if (data?.statusCode === 200) {
          getInvitation();
        }
      }
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setAction("");
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  return (
    <>
      <div className="table-container overflow-auto">
        <Table id="resizable-table" className="custom-table m-0" ref={tableRef}>
          <thead>
            <tr>
              <th
                onMouseDown={(e) => onMouseDown(e, 0)}
                style={{ width: "40px" }}
              >
                Sr.
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 1)}>Email</th>
              <th onMouseDown={(e) => onMouseDown(e, 2)}>Status</th>
              <th
                onMouseDown={(e) => onMouseDown(e, 3)}
                style={{ width: 180 }}
              >
                Invitation Date | Time
              </th>
              {roleType === "admin" ||
              (role === "aiuser"
                ? permission?.resendAIUserInvitation &&
                  permission?.cancelAIUserInvitation
                : role === "organization"
                ? permission?.resendOrganizationInvitation &&
                  permission?.cancelOrganizationInvitation
                : role === "developer"
                ? permission?.resendDeveloperInvitation &&
                  permission?.cancelDeveloperInvitation
                : role === "cdisc"
                ? permission?.resendCDISCInvitation &&
                  permission?.cancelCDISCInvitation
                : "") ? (
                <th
                  onMouseDown={(e) => onMouseDown(e, 4)}
                  style={{ width:  70 }}
                  className="text-center"
                >
                  Action
                </th>
              ) : roleType === "subadmin" &&
                (role === "aiuser"
                  ? permission?.resendAIUserInvitation
                  : role === "consultant"
                  ? permission?.resendConsultantInvitation
                  : role === "organization"
                  ? permission?.resendOrganizationInvitation
                  : role === "developer"
                  ? permission?.resendDeveloperInvitation
                  : role === "cdisc"
                  ? permission?.resendCDISCInvitation
                  : "") ? (
                <th
                  onMouseDown={(e) => onMouseDown(e, 5)}
                  style={{ width: "40px" }}
                  className="text-center"
                >
                  Resend
                </th>
              ) : roleType === "subadmin" &&
                (role === "aiuser"
                  ? permission?.cancelAIUserInvitation
                  : role === "consultant"
                  ? permission?.cancelConsultantInvitation
                  : role === "organization"
                  ? permission?.cancelOrganizationInvitation
                  : role === "developer"
                  ? permission?.cancelDeveloperInvitation
                  : role === "cdisc"
                  ? permission?.cancelCDISCInvitation
                  : "") ? (
                <th
                  onMouseDown={(e) => onMouseDown(e, 6)}
                  style={{ width: "40px" }}
                  className="text-center"
                >
                  Cancel
                </th>
              ) : (
                ""
              )}
            </tr>
          </thead>
          <tbody ref={ref}>
            {invitedList?.length > 0 &&
              invitedList?.map((item, index) => (
                <tr className="relative">
                  <td>{index + 1}</td>
                  <td>{item.email}</td>
                  <td className="text-capitalize">{item.status}</td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm" withTitle>
                      {item.inVitationDate}
                    </Moment>
                  </td>
                  {(role === "aiuser"
                    ? permission?.resendAIUserInvitation &&
                      permission?.cancelAIUserInvitation
                    : role === "consultant"
                    ? permission?.resendConsultantInvitation &&
                      permission?.cancelConsultantInvitation
                    : role === "organization"
                    ? permission?.resendOrganizationInvitation &&
                      permission?.cancelOrganizationInvitation
                    : role === "developer"
                    ? permission?.resendDeveloperInvitation &&
                      permission?.cancelDeveloperInvitation
                    : role === "cdisc"
                    ? permission?.resendCDISCInvitation &&
                      permission?.cancelCDISCInvitation
                    : "") || roleType === "admin" ? (
                    <td className="text-center">
                      <div className="text-center action__buttons">
                        {action === item._id ? (
                          <>
                            <div className="saperate_action_button">
                              <Button
                                onClick={(e) => resendInvitation(e, item._id)}
                                title="Resend"
                                className="rounded-circle"
                              >
                                <i className="fa-solid fa-paper-plane"></i>
                              </Button>
                              <Button
                                title="Cancel"
                                disabled={
                                  item.status === "cancelled" ||
                                  item.status === "registered"
                                }
                                onClick={(e) => cancelInvitation(e, item._id)}
                                className="rounded-circle close_circle"
                              >
                                <i className="fa-solid fa-ban"></i>
                              </Button>
                            </div>
                            <Button
                              onClick={() => setAction("")}
                              title="Close Action"
                              className="rounded-circle close_circle"
                            >
                              <i className="fa-solid fa-xmark"></i>
                            </Button>
                          </>
                        ) : (
                          <Button
                            onClick={() => setAction(item._id)}
                            title="Open Action"
                            className="rounded-circle"
                          >
                            <i className="fa-solid fa-bars"></i>
                          </Button>
                        )}
                      </div>
                    </td>
                  ) : roleType === "subadmin" &&
                    (role === "aiuser"
                      ? permission?.resendAIUserInvitation
                      : role === "consultant"
                      ? permission?.resendConsultantInvitation
                      : role === "organization"
                      ? permission?.resendOrganizationInvitation
                      : role === "developer"
                      ? permission?.resendDeveloperInvitation
                      : role === "cdisc"
                      ? permission?.resendCDISCInvitation
                      : "") ? (
                    <td className="text-center">
                      <div className="text-center action__buttons">
                        <Button
                          onClick={(e) => resendInvitation(e, item._id)}
                          title="Resend"
                          className="rounded-circle"
                        >
                          <i className="fa-solid fa-paper-plane"></i>
                        </Button>
                      </div>
                    </td>
                  ) : roleType === "subadmin" &&
                    (role === "aiuser"
                      ? permission?.cancelAIUserInvitation
                      : role === "consultant"
                      ? permission?.cancelConsultantInvitation
                      : role === "organization"
                      ? permission?.cancelOrganizationInvitation
                      : role === "developer"
                      ? permission?.cancelDeveloperInvitation
                      : role === "cdisc"
                      ? permission?.cancelCDISCInvitation
                      : "") ? (
                    <td className="text-center">
                      <div className="text-center action__buttons">
                        <Button
                          title="Cancel"
                          disabled={
                            item.status === "cancelled" ||
                            item.status === "registered"
                          }
                          onClick={(e) => cancelInvitation(e, item._id)}
                          className="rounded-circle close_circle"
                        >
                          <i className="fa-solid fa-ban"></i>
                        </Button>
                      </div>
                    </td>
                  ) : (
                    ""
                  )}
                </tr>
              ))}
          </tbody>
        </Table>
        {invitedList?.length > 0 ? (
          ""
        ) : (
          <div className="noRecordFound">Record not found</div>
        )}
      </div>
    </>
  );
};

export default UserInvitationTableData;
