import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import UpdateVariableMethodModal from "./UpdateVariableMethodModal";
import Moment from "react-moment";
import Swal from "sweetalert2";
import UseToggle from "../../../../Hooks/useToggle";
import { sdtmServices } from "../../../../../APIServices/CDISC/sdtmServices";

const VariableMethodTableData = ({
  variableMethodList,
  getVariableMathodsList,
  onMouseDown,
  tableRef,
}) => {
  const { toggle, setToggle } = UseToggle();
  const ref = useRef(null);
  const [action, setAction] = useState("");
  const [id, setId] = useState("");
  let [mathodId, setMethodId] = useState("");
  let [description, setDescription] = useState("");

  const handleStatusChange = async (id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Change Status this Variable Method?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = id;
        userData.status = status;
        let data = await sdtmServices.changeVariableMathodsStatus(userData);
        if (data?.statusCode === 200) {
          getVariableMathodsList();
        }
      }
    });
  };

  const handleUpdate = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Update this Variable Method?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = id;
        userData.mathodId = mathodId;
        userData.description = description;
        let data = await sdtmServices.updateVariableMathods(userData);
        if (data?.statusCode === 200) {
          getVariableMathodsList();
        }
      }
    });
  };

  const updateVariableMethodModal = (item) => {
    setMethodId(item.mathodId);
    setDescription(item.description);
    setId(item._id);
    setToggle();
  };

  const handleDelete = async (e, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete this Variable Method?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await sdtmServices.deleteVariableMathods(id);
        if (data?.statusCode === 200) {
          getVariableMathodsList();
        }
      }
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setAction("");
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <>
      <div className="table-container overflow-auto">
        <Table id="resizable-table" className="custom-table m-0" ref={tableRef}>
          <thead>
            <tr>
              <th onMouseDown={(e) => onMouseDown(e, 0)} style={{ width: 40 }}>
                Sr.
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 1)}>Method Id</th>
              <th onMouseDown={(e) => onMouseDown(e, 2)}>Method Name</th>
              <th onMouseDown={(e) => onMouseDown(e, 3)}>Method Type</th>
              <th onMouseDown={(e) => onMouseDown(e, 4)}>Description</th>
              <th
                onMouseDown={(e) => onMouseDown(e, 5)}
                style={{ width: 120 }}
                className="text-center"
              >
                Status
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 6)} style={{ width: 180 }}>
                Created Date | Time
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 6)} style={{ width: 180 }}>
                Updated Date | Time
              </th>
              <th
                onMouseDown={(e) => onMouseDown(e, 7)}
                style={{ width: 70 }}
                className="text-center"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody ref={ref}>
            {variableMethodList?.length > 0 &&
              variableMethodList?.map((item, index) => (
                <tr className="relative">
                  <td>{index + 1}</td>
                  <td>{item.mathodId}</td>
                  <td>{item.name}</td>
                  <td className="text-capitalize">{item.type}</td>
                  <td>{item.description}</td>
                  <td className="status_select">
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) =>
                        handleStatusChange(item._id, e.target.value)
                      }
                      className={
                        item.status === "active"
                          ? "active_status"
                          : "inactive_status"
                      }
                    >
                      <option
                        selected={item.status === "active"}
                        value="active"
                      >
                        Active
                      </option>
                      <option
                        selected={item.status === "inactive"}
                        value="inactive"
                      >
                        Inactive
                      </option>
                    </Form.Select>
                  </td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm" withTitle>
                      {item.createdAt}
                    </Moment>
                  </td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm" withTitle>
                      {item.updatedAt}
                    </Moment>
                  </td>
                  <td className="text-center">
                    <div className="text-center action__buttons">
                      {action === item._id ? (
                        <>
                          <div className="saperate_action_button">
                            <Button
                              onClick={() => updateVariableMethodModal(item)}
                              title="Edit"
                              className="rounded-circle"
                            >
                              <i className="fa-solid fa-pen-to-square"></i>
                            </Button>
                            <Button
                              onClick={(e) => handleDelete(e, item._id)}
                              title="Delete"
                              className="rounded-circle close_circle"
                            >
                              <i className="fa-solid fa-trash-can"></i>
                            </Button>
                          </div>
                          <Button
                            title="Close Action"
                            className="rounded-circle close_circle"
                          >
                            <i className="fa-solid fa-xmark"></i>
                          </Button>
                        </>
                      ) : (
                        <Button
                          onClick={() => setAction(item._id)}
                          title="Open Action"
                          className="rounded-circle "
                        >
                          <i className="fa-solid fa-bars"></i>
                        </Button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {variableMethodList?.length > 0 ? (
          ""
        ) : (
          <div className="noRecordFound">Record not found</div>
        )}
      </div>
      <UpdateVariableMethodModal
        Show={!toggle}
        Hide={setToggle}
        Size={"md"}
        Title={"Update VariableMethod"}
        handleUpdate={handleUpdate}
        getVariableMathodsList={getVariableMathodsList}
        description={description}
        setDescription={setDescription}
        mathodId={mathodId}
        setMethodId={setMethodId}
        Submit={"Update"}
      />
    </>
  );
};

export default VariableMethodTableData;
