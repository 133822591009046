import React, { useEffect, useState } from "react";
import Pagination from "../../Hooks/Pagination";
import UseToggle from "../../Hooks/useToggle";
import { creditPriceServices } from "../../../APIServices/AIAlpha/creditPriceServices";
import TableHeader from "../../../component/TableHeader";
import ExportAiBackupModal from "./ExportAiBackupModal";
import AIBackupTableData from "./AIBackupTableData";
import ViewBackupChartModal from "./ViewBackupChartModal";

const AIBackup = ({ onMouseDown, tableRef, themeColor }) => {
  const { toggle, setToggle, toggle2, setToggle2 } =
    UseToggle();
  const [searchData, setSearchData] = useState("");
  const [backupList, setBackupList] = useState([]);

  const getAllBackupList = async (type) => {
    let data = await creditPriceServices.getBillingTransections(type);
    setBackupList(data?.data);
  };

  const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = backupList?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(backupList?.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % backupList?.length;
    setItemOffset(newOffset);
  };

  const handleSearch = async (searchValue) => {
    let userData = {};
    userData.searchData = searchValue || searchData;
  };

  useEffect(() => {
    getAllBackupList("all");
  }, []);
  return (
    <>
      <TableHeader
        Search
        handleSearch={handleSearch}
        setSearchData={setSearchData}
        SearchReverseData={getAllBackupList}
        Analytics
        titleAnalytics="View Backup Record"
        handleAnalytics={setToggle2}
        Export
        handleExport={setToggle}
        titleExport="Export Backup Records"
      />
      <AIBackupTableData
        onMouseDown={onMouseDown}
        tableRef={tableRef}
        getAllBackupList={currentItems}
      />
      <Pagination
        Result={currentItems}
        itemsPerPage={itemsPerPage}
        handlePageClick={handlePageClick}
        pageCount={pageCount}
      />
      <ExportAiBackupModal
        Show={!toggle}
        Hide={setToggle}
        Size={"md"}
        Title={"Export Backup Records"}
        Submit={"Export"}
      />
      <ViewBackupChartModal
        Show={!toggle2}
        Hide={setToggle2}
        Size={"lg"}
        Title="View Backup Record"
        themeColor={themeColor}
      />
    </>
  );
};

export default AIBackup;
