import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Pagination from "../../Hooks/Pagination";
import UseToggle from "../../Hooks/useToggle";
import UpdateSubscription from "./UpdateSubscription";
import { subscriptionPlanServices } from "../../../APIServices/Editor/subscriptionPlanServices";

const EditorSubscriptionsByStatus = ({ subscriptionList, getSubscriptionPlanList, getSubscriptionPlanListByStatus }) => {
  const { toggle, setToggle } = UseToggle();
  const [planDetails, setPlanDetails] = useState("");
  const itemsPerPage = 5;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = subscriptionList?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(subscriptionList?.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % subscriptionList?.length;
    setItemOffset(newOffset);
  };

  const handleChangeStatus = async (id, status) => {
    let userData = {}
    userData.id = id
    userData.status = status
    let data = await subscriptionPlanServices.changeSubscriptionPlanStatus(userData)
    if (data?.statusCode === 200) {
      getSubscriptionPlanListByStatus(!status)
    }
  }

  const handleUpdatePlan = (e, item) => {
    setPlanDetails(item)
    setToggle()
  }
  return (
    <>
      <Row className="mt-4">
        {currentItems?.length === 0 ? "" :
          currentItems?.map((item, index) => (
            <Col key={index} xl={3} lg={3} md={6} className="mb-20">
              <div className="box-shadow-min border-radius-3 height-100-p ">
                <div className="text-center widget-style1 pb-2">
                  <div><b className="text-uppercase">{item.planName}</b><i onClick={(e) => handleUpdatePlan(e, item)} className="fa-solid fa-edit mx-2" title="Update Plan Details" style={{ fontSize: 10, cursor: "pointer" }}></i></div>
                  <hr style={{ width: 80, margin: "10px auto" }} />
                  <p className="m-0"><b>{item.currency + item.price}</b> / {item.type}</p>
                  <div className="pt-2">
                    <Form.Check
                      onChange={() => handleChangeStatus(item._id, !(item.status))}
                      checked={item.status}
                      type="switch"
                      id={`custom-switch-${item._id}`}
                    />
                  </div>
                </div>
                <div className="text-center py-2">
                  <b>Featured</b>
                </div>
                <div className="ulheight">
                  <ul>
                    {(item.featured).map((element, index) => (
                      <li key={index}><p className="m-0">{element}</p></li>
                    ))}
                  </ul>
                </div>
              </div>
            </Col>
          ))}
      </Row>
      <Pagination
        Result={subscriptionList}
        itemsPerPage={itemsPerPage}
        handlePageClick={handlePageClick}
        pageCount={pageCount}
      />
      <UpdateSubscription
        Show={!toggle}
        Hide={setToggle}
        Size={"md"}
        Title={"Update Subscription"}
        planDetails={planDetails}
        getSubscriptionPlanList={getSubscriptionPlanList}
        Submit={"Update"}
      />
    </>
  );
};

export default EditorSubscriptionsByStatus;
