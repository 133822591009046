import React from "react";
import { Form, Modal} from "react-bootstrap";

const ViewRequestedChangeLogs = ({ Show, Hide, Size, Title, updateRequestData, roleType }) => {
  return (
    <Modal
      show={Show}
      onHide={Hide}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={() => Hide()} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="log_change mb-3">
          {(updateRequestData?.changelogs)?.map((data, key) => (
            <pre key={key}>- {data.type}:, {data?.changelog}</pre>
          ))}
        </div>
        <Form.Label style={{ fontSize: 13, fontWeight: 600 }}>Validator Remark</Form.Label>
        {roleType === "admin" ?
          <p>{updateRequestData?.adminValidationRemark}</p>
          :
          <p>{updateRequestData?.validationRemark}</p>
        }
      </Modal.Body>
    </Modal>
  );
};

export default ViewRequestedChangeLogs;
