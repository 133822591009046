import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import UpdateGlobalClassModal from "./UpdateGlobalClassModal";
import Moment from "react-moment";
import Swal from "sweetalert2";
import { designationPermissionServices } from "../../../../APIServices/designationPermissionServices";
import UseToggle from "../../../Hooks/useToggle";

const GlobalClassTableData = ({
  designationList,
  getDesignationsList,
  onMouseDown,
  tableRef,
}) => {
  const { toggle, setToggle } = UseToggle();
  const ref = useRef(null);
  const [action, setAction] = useState("");
  const [id, setId] = useState("");
  const [designationName, setDesignationName] = useState("");

  const handleUpdate = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Update this Global Class?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = id;
        userData.designation = designationName;
        let data = await designationPermissionServices.updateDesignations(
          userData
        );
        if (data?.statusCode === 200) {
          getDesignationsList();
        }
      }
    });
  };

  const updateDesignationModal = (item) => {
    setDesignationName(item.designation);
    setId(item._id);
    setToggle();
  };

  const handleDelete = async (e, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete this Global Class?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await designationPermissionServices.deleteDesignations(id);
        if (data?.statusCode === 200) {
          getDesignationsList();
        }
      }
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setAction("");
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  return (
    <>
      <div className="table-container overflow-auto">
        <Table id="resizable-table" className="custom-table m-0" ref={tableRef}>
          <thead>
            <tr>
              <th onMouseDown={(e) => onMouseDown(e, 0)} style={{ width: 40 }}>
                Sr.
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 1)}>Global Class</th>
              <th onMouseDown={(e) => onMouseDown(e, 3)} style={{ width: 180 }}>
                Created Date | Time
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 3)} style={{ width: 180 }}>
                Updated Date | Time
              </th>
              <th
                onMouseDown={(e) => onMouseDown(e, 4)}
                style={{ width: 70 }}
                className="text-center"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody ref={ref}>
            {designationList?.length > 0 &&
              designationList?.map((item, index) => (
                <tr className="relative">
                  <td>{index + 1}</td>
                  <td>{item.designation}</td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm" withTitle>
                      {item.createdAt}
                    </Moment>
                  </td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm" withTitle>
                      {item.updatedAt}
                    </Moment>
                  </td>
                  <td className="text-center">
                    <div className="text-center action__buttons">
                      {action === item._id ? (
                        <>
                          <div className="saperate_action_button">
                            <Button
                              onClick={() => updateDesignationModal(item)}
                              title="Edit"
                              className="rounded-circle"
                            >
                              <i className="fa-solid fa-pen-to-square"></i>
                            </Button>
                            <Button
                              onClick={(e) => handleDelete(e, item._id)}
                              title="Delete"
                              className="rounded-circle close_circle"
                            >
                              <i className="fa-solid fa-trash-can"></i>
                            </Button>
                          </div>
                          <Button
                            title="Close Action"
                            className="rounded-circle close_circle"
                          >
                            <i className="fa-solid fa-xmark"></i>
                          </Button>
                        </>
                      ) : (
                        <Button
                          onClick={() => setAction(item._id)}
                          title="Open Action"
                          className="rounded-circle "
                        >
                          <i className="fa-solid fa-bars"></i>
                        </Button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {designationList?.length > 0 ? (
          ""
        ) : (
          <div className="noRecordFound">Record not found</div>
        )}
      </div>
      <UpdateGlobalClassModal
        Show={!toggle}
        Hide={setToggle}
        Size={"md"}
        Title={"Update Global Class"}
        setDesignationName={setDesignationName}
        designationName={designationName}
        handleUpdate={handleUpdate}
        Submit={"Update"}
      />
    </>
  );
};

export default GlobalClassTableData;
