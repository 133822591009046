import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { subscriptionPlanServices } from "../../../APIServices/Editor/subscriptionPlanServices";

const UpdateSubscription = ({ Show, Hide, Size, Title, Submit, planDetails, getSubscriptionPlanList }) => {
  const [planName, setPlanName] = useState("");
  const [features, setFeatures] = useState(['']);

  const updateSubscriptionPlan = async () => {
    let userData = {}
    userData.id = planDetails._id;
    userData.featured = features;
    userData.planName = planName;
    let data = await subscriptionPlanServices.updateSubscriptionPlan(userData)
    if (data?.statusCode === 200) {
      getSubscriptionPlanList();
      Hide();
    }
  }

  const handleAddInput = () => {
    setFeatures([...features, '']);
  };

  const handleRemoveInput = (index) => {
    const newFeatures = [...features];
    newFeatures.splice(index, 1);
    setFeatures(newFeatures);
  };

  const handleChange = (index, event) => {
    const newFeatures = [...features];
    newFeatures[index] = event.target.value;
    setFeatures(newFeatures);
  };

  useEffect(() => {
    setPlanName(planDetails.planName);
    setFeatures(planDetails?.featured);
  }, [planDetails]);

  return (
    <Modal
      show={Show}
      onHide={Hide}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={()=>Hide()} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>
              Plan Name <span>*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              onChange={(e) => setPlanName(e.target.value)}
              value={planName}
              placeholder="Enter Plan Name" />
          </Form.Group>
          <Form.Group as={Col} md="6" className="add_sponsor_field mb-3">
            <Form.Label>Price <span>*</span></Form.Label>
            <div className="input-group mb-2">
              <div className="input-group-prepend">
                <div className="input-group-text">$</div>
              </div>
              <Form.Control
                required
                disabled
                type="number"
                value={planDetails?.price}
                placeholder="Enter Price" />
            </div>
          </Form.Group>
          <Form.Group as={Col} md="6" className="add_sponsor_field mb-3">
            <Form.Label>
              Type <span>*</span>
            </Form.Label>
            <Form.Select
              disabled
              aria-label="Default select example"
            >
              <option>Select Type</option>
              <option selected={planDetails?.type === "month"} value="month">Month</option>
              <option selected={planDetails?.type === "year"} value="year">Year</option>
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-2">
            <Form.Label>
              Feature <span>*</span>
              <i className="fa-solid fa-circle-plus ms-2 buttonBaseColor" onClick={handleAddInput} title="Add Feature"></i>
            </Form.Label>
            {features?.map((feature, index) => (
              <div key={index} className="input-groups position-relative">
                <Form.Control
                  required
                  type="text"
                  placeholder="Type Feature"
                  value={feature}
                  className="mb-2"
                  onChange={(e) => handleChange(index, e)}
                />
                {features?.length > 1 &&
                  <div className="input-group-appends" title="Remove">
                    <span onClick={() => handleRemoveInput(index)}>x</span>
                  </div>
                }
              </div>
            ))}
          </Form.Group>
        </Row>
        <div className="hstack gap-3 justify-content-end border-top pt-3 footer_button">
          <Button
            disabled={!planName}
            onClick={updateSubscriptionPlan}
          >
            {Submit}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateSubscription;
