import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { designationPermissionServices } from "../../../APIServices/designationPermissionServices";

const AddNewPermissionModal = ({ Show, Hide, Size, Title, Submit, getPermissionsList }) => {
  let [permission, setPermission] = useState("")

  const addNewPermission = async () => {
    let userData = {}
    userData.permission = permission
    let data = await designationPermissionServices.addNewPermission(userData)
    if (data?.statusCode === 200) {
      closeModal()
      getPermissionsList()
    }
  }

  const closeModal = () => {
    setPermission("")
    Hide()
  }
  return (
    <Modal
      show={Show}
      onHide={closeModal}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={closeModal} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>Permission<span>*</span></Form.Label>
            <Form.Control
              required
              type="text"
              value={permission}
              onChange={(e) => setPermission(e.target.value)}
              placeholder="Enter Permission"
            />
          </Form.Group>
        </Row>
        <div className="hstack gap-3 justify-content-end border-top pt-3 footer_button">
          <Button className="close_circle" onClick={() => setPermission("")}>Reset</Button>
          <Button disabled={!permission} onClick={addNewPermission}>{Submit}</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddNewPermissionModal;
