import React, { useEffect, useState } from "react";
import AddNewValidatorModal from "./AddNewValidatorModal";
import UseToggle from "../Hooks/useToggle";
import Pagination from "../Hooks/Pagination";
import ExportValidatorModal from "./ExportValidatorModal";
import TableHeader from "../../component/TableHeader";
import ValidatorTableData from "./ValidatorTableData";
import { userServices } from "../../APIServices/usersServices";
import ValidatorsInvitation from "./ValidatorInvitation/ValidatorsInvitation";
import { invitedDataServices } from "../../APIServices/invitedDataServices";

const Validators = ({ onMouseDown, tableRef }) => {
  const { toggle, setToggle, toggle1, setToggle1, toggle2, setToggle2 } =
    UseToggle();

  const [validatorList, setValidatorList] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [invitedList, setInvitedList] = useState([]);

  const getInvitation = async () => {
    let data = await invitedDataServices.getInvitation("validator");
    setInvitedList(data?.data);
  };

  const getUserData = async () => {
    let data = await userServices.getUserData("validator");
    setValidatorList(data?.data);
  };

  const itemsPerPage = 5;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = validatorList?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(validatorList?.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % validatorList?.length;
    setItemOffset(newOffset);
  };

  const handleSearch = async (searchValue) => {
    let userData = {};
    userData.searchData = searchValue || searchData;
    userData.roleType = "validator";
    let data = await userServices.searchUserData(userData);
    setValidatorList(data?.data);
  };

  const openInvitationHistory = () => {
    setToggle2();
    getInvitation();
  };

  useEffect(() => {
    getUserData();
  }, []);
  return (
    <>
      <TableHeader
        Search
        handleSearch={handleSearch}
        setSearchData={setSearchData}
        SearchReverseData={getUserData}
        AddNew
        handleAddNew={setToggle}
        titleAddNew="Add New Validator"
        InviteHistory
        handleInviteHistory={openInvitationHistory}
        titleInviteHistory="Invitation History"
        Export
        handleExport={setToggle1}
        titleExport="Export Validators Data"
      />
      <ValidatorTableData
        onMouseDown={onMouseDown}
        tableRef={tableRef}
        validatorList={currentItems}
        getUserData={getUserData}
      />
      <Pagination
        Result={validatorList}
        itemsPerPage={itemsPerPage}
        handlePageClick={handlePageClick}
        pageCount={pageCount}
      />
      <AddNewValidatorModal
        Show={!toggle}
        Hide={setToggle}
        Size={"lg"}
        Title={"Add New Validator"}
        getUserData={getUserData}
        Submit={"Submit"}
      />
      <ValidatorsInvitation
        onMouseDown={onMouseDown}
        tableRef={tableRef}
        Show={!toggle2}
        Hide={setToggle2}
        Size={"lg"}
        Title={"Validators Invitation History"}
        invitedList={invitedList}
        setInvitedList={setInvitedList}
        getInvitation={getInvitation}
      />
      <ExportValidatorModal
        Show={!toggle1}
        Hide={setToggle1}
        Size={"md"}
        Title={"Export Validators"}
        Submit={"Export"}
      />
    </>
  );
};

export default Validators;
