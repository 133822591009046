import config from "../config/config.json";
import { headers } from "../APIHelpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../APIHelpers/Responses";

export const invitedDataServices = {
  // Invited Data
  inviteCompany,
  registeredCompanyByInvitation,
  inviteUser,
  registeredUserByInvitation,
  cancelInvitation,
  searchInvitation,
  getInvitation,
  deleteInvite,
  resendInvitation,
  getInvitationData,
};

// Invite Company
async function inviteCompany(userData) {
  const response = await fetch(
    `${config.API_URL}inviteCompany`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Registered Company By invitation
async function registeredCompanyByInvitation(userData) {
  let headerData = {
    "content-type": "application/json",
  };
  const response = await fetch(
    `${config.API_URL}registeredCompanyByInvitation`,
    headersWithAuth("POST", userData, headerData)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Invite User
async function inviteUser(userData) {
  const response = await fetch(
    `${config.API_URL}inviteUser`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Registered User By invitation
async function registeredUserByInvitation(userData) {
  let headerData = {
    "content-type": "application/json",
  };
  const response = await fetch(
    `${config.API_URL}registeredUserByInvitation`,
    headersWithAuth("POST", userData, headerData)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Update Invite
async function cancelInvitation(userData) {
  const response = await fetch(
    `${config.API_URL}cancelInvitation`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Search Invite
async function searchInvitation(userData) {
  const response = await fetch(
    `${config.API_URL}searchInvitation`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Get All Invitation
async function getInvitation(roleType) {
  const response = await fetch(
    `${config.API_URL}getInvitation/${roleType}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Delete Invite
async function deleteInvite(id) {
  const response = await fetch(
    `${config.API_URL}deleteInvite/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Resend Invitation
async function resendInvitation(userData) {
  const response = await fetch(
    `${config.API_URL}resendInvitation`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Get Inivitation Details
async function getInvitationData(token) {
  const response = await fetch(
    `${config.API_URL}getInvitationData/${token}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}